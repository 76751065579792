// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "contacts.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ContactsService } from "./contacts";
import type { GetAutoSyncResponse } from "./contacts";
import type { GetAutoSyncRequest } from "./contacts";
import type { DeleteAutoSyncRequest } from "./contacts";
import type { CreateAutoSyncRequest } from "./contacts";
import type { SyncIntegrationGroupsMembersResponse } from "./contacts";
import type { SyncIntegrationGroupsMembersRequest } from "./contacts";
import type { PaginatedListIntegrationGroupsMembersResponse } from "./contacts";
import type { PaginatedListIntegrationGroupsMembersRequest } from "./contacts";
import type { ListIntegrationGroupsMembersResponse } from "./contacts";
import type { ListIntegrationGroupsMembersRequest } from "./contacts";
import type { Empty } from "./google/protobuf/empty";
import type { People } from "./contacts";
import type { ListPeopleRequest } from "./contacts";
import type { TeamDeleteResponse } from "./contacts";
import type { TeamDeleteRequest } from "./contacts";
import type { Team } from "./contacts";
import type { TeamUpdateRequest } from "./contacts";
import type { TeamListResponse } from "./contacts";
import type { TeamListRequest } from "./contacts";
import type { CreateTeamResponse } from "./contacts";
import type { CreateTeamRequest } from "./contacts";
import type { ActivateSocialAccountsResponse } from "./contacts";
import type { ActivateSocialAccountsRequest } from "./contacts";
import type { DeactivateSocialAccountsResponse } from "./contacts";
import type { DeactivateSocialAccountsRequest } from "./contacts";
import type { ContactsLicenseResponse } from "./contacts";
import type { ContactsLicenseRequest } from "./contacts";
import type { ContactsTokenValidateResponse } from "./contacts";
import type { ContactsTokenValidateRequest } from "./contacts";
import type { ContactsSocialRegistrationRequest } from "./contacts";
import type { ContactsRegistrationRequest } from "./contacts";
import type { ContactsRequest } from "./contacts";
import type { ContactResponse } from "./contacts";
import type { ContactsUpdateStatusRequest } from "./contacts";
import type { Contact } from "./contacts";
import type { ContactsUpdateRequest } from "./contacts";
import type { ContactsInviteResponse } from "./contacts";
import type { ContactsInviteRequest } from "./contacts";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ContactsListResponse } from "./contacts";
import type { ContactsListRequest } from "./contacts";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service ContactsService
 */
export interface IContactsServiceClient {
    /**
     * ContactList return a list of all contacts for tenant
     *
     * @generated from protobuf rpc: ContactList(ContactsListRequest) returns (ContactsListResponse);
     */
    contactList(input: ContactsListRequest, options?: RpcOptions): UnaryCall<ContactsListRequest, ContactsListResponse>;
    /**
     * ContactInvite create pending contact and send invitation to email
     * NOTE: for now we support only one admin role for one tenant
     *
     * @generated from protobuf rpc: ContactInvite(ContactsInviteRequest) returns (ContactsInviteResponse);
     */
    contactInvite(input: ContactsInviteRequest, options?: RpcOptions): UnaryCall<ContactsInviteRequest, ContactsInviteResponse>;
    /**
     * ContactUpdate change contact personal info
     *
     * @generated from protobuf rpc: ContactUpdate(ContactsUpdateRequest) returns (Contact);
     */
    contactUpdate(input: ContactsUpdateRequest, options?: RpcOptions): UnaryCall<ContactsUpdateRequest, Contact>;
    /**
     * ContactUpdate change contact personal info
     *
     * @generated from protobuf rpc: ContactUpdateStatus(ContactsUpdateStatusRequest) returns (ContactResponse);
     */
    contactUpdateStatus(input: ContactsUpdateStatusRequest, options?: RpcOptions): UnaryCall<ContactsUpdateStatusRequest, ContactResponse>;
    /**
     * ContactDelete remove contact
     *
     * @generated from protobuf rpc: ContactDelete(ContactsRequest) returns (ContactResponse);
     */
    contactDelete(input: ContactsRequest, options?: RpcOptions): UnaryCall<ContactsRequest, ContactResponse>;
    /**
     * ContactResendInvite resend invitation email
     *
     * @generated from protobuf rpc: ContactResendInvite(ContactsRequest) returns (ContactResponse);
     */
    contactResendInvite(input: ContactsRequest, options?: RpcOptions): UnaryCall<ContactsRequest, ContactResponse>;
    /**
     * ContactRegistration create new user for invited contact
     *
     * @generated from protobuf rpc: ContactRegistration(ContactsRegistrationRequest) returns (Contact);
     */
    contactRegistration(input: ContactsRegistrationRequest, options?: RpcOptions): UnaryCall<ContactsRegistrationRequest, Contact>;
    /**
     * ContactSocialRegistration create new user for invited contact by social token
     *
     * @generated from protobuf rpc: ContactSocialRegistration(ContactsSocialRegistrationRequest) returns (Contact);
     */
    contactSocialRegistration(input: ContactsSocialRegistrationRequest, options?: RpcOptions): UnaryCall<ContactsSocialRegistrationRequest, Contact>;
    /**
     * ContactInviteTokenValidate check is invitation token is valid
     *
     * @generated from protobuf rpc: ContactInviteTokenValidate(ContactsTokenValidateRequest) returns (ContactsTokenValidateResponse);
     */
    contactInviteTokenValidate(input: ContactsTokenValidateRequest, options?: RpcOptions): UnaryCall<ContactsTokenValidateRequest, ContactsTokenValidateResponse>;
    /**
     * ContactLicense update contact license status and activate/deactivate account
     *
     * @generated from protobuf rpc: ContactLicense(ContactsLicenseRequest) returns (ContactsLicenseResponse);
     */
    contactLicense(input: ContactsLicenseRequest, options?: RpcOptions): UnaryCall<ContactsLicenseRequest, ContactsLicenseResponse>;
    /**
     * DeactivateSocialAccounts deactivate all active accounts with social login method if company not allow SSO.
     *
     * @generated from protobuf rpc: DeactivateSocialAccounts(DeactivateSocialAccountsRequest) returns (DeactivateSocialAccountsResponse);
     */
    deactivateSocialAccounts(input: DeactivateSocialAccountsRequest, options?: RpcOptions): UnaryCall<DeactivateSocialAccountsRequest, DeactivateSocialAccountsResponse>;
    /**
     * ActivateSocialAccounts activate back all active accounts with social login method if company allow SSO.
     *
     * @generated from protobuf rpc: ActivateSocialAccounts(ActivateSocialAccountsRequest) returns (ActivateSocialAccountsResponse);
     */
    activateSocialAccounts(input: ActivateSocialAccountsRequest, options?: RpcOptions): UnaryCall<ActivateSocialAccountsRequest, ActivateSocialAccountsResponse>;
    /**
     * TeamCreate create new team for customer
     *
     * @generated from protobuf rpc: CreateTeam(CreateTeamRequest) returns (CreateTeamResponse);
     */
    createTeam(input: CreateTeamRequest, options?: RpcOptions): UnaryCall<CreateTeamRequest, CreateTeamResponse>;
    /**
     * TeamList get all teams for customer
     *
     * @generated from protobuf rpc: ListTeams(TeamListRequest) returns (TeamListResponse);
     */
    listTeams(input: TeamListRequest, options?: RpcOptions): UnaryCall<TeamListRequest, TeamListResponse>;
    /**
     * TeamUpdate update team
     *
     * @generated from protobuf rpc: UpdateTeam(TeamUpdateRequest) returns (Team);
     */
    updateTeam(input: TeamUpdateRequest, options?: RpcOptions): UnaryCall<TeamUpdateRequest, Team>;
    /**
     * TeamDelete delete team
     *
     * @generated from protobuf rpc: DeleteTeam(TeamDeleteRequest) returns (TeamDeleteResponse);
     */
    deleteTeam(input: TeamDeleteRequest, options?: RpcOptions): UnaryCall<TeamDeleteRequest, TeamDeleteResponse>;
    /**
     * List people from Dashboard contacts.
     * If user has personal calendar set, will list the people in the company directory.
     * Returns a list of people sorted by display name.
     *
     * @generated from protobuf rpc: ListPeople(ListPeopleRequest) returns (People);
     */
    listPeople(input: ListPeopleRequest, options?: RpcOptions): UnaryCall<ListPeopleRequest, People>;
    /**
     * Sync trigger sync account info between sql, graph and firebase. The source of true about account info is sql.
     *
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * @generated from protobuf rpc: ListIntegrationGroupsMembers(ListIntegrationGroupsMembersRequest) returns (ListIntegrationGroupsMembersResponse);
     */
    listIntegrationGroupsMembers(input: ListIntegrationGroupsMembersRequest, options?: RpcOptions): UnaryCall<ListIntegrationGroupsMembersRequest, ListIntegrationGroupsMembersResponse>;
    /**
     * @generated from protobuf rpc: PaginatedListIntegrationGroupsMembers(PaginatedListIntegrationGroupsMembersRequest) returns (PaginatedListIntegrationGroupsMembersResponse);
     */
    paginatedListIntegrationGroupsMembers(input: PaginatedListIntegrationGroupsMembersRequest, options?: RpcOptions): UnaryCall<PaginatedListIntegrationGroupsMembersRequest, PaginatedListIntegrationGroupsMembersResponse>;
    /**
     * @generated from protobuf rpc: SyncIntegrationGroupsMembers(SyncIntegrationGroupsMembersRequest) returns (SyncIntegrationGroupsMembersResponse);
     */
    syncIntegrationGroupsMembers(input: SyncIntegrationGroupsMembersRequest, options?: RpcOptions): UnaryCall<SyncIntegrationGroupsMembersRequest, SyncIntegrationGroupsMembersResponse>;
    /**
     * @generated from protobuf rpc: CreateAutoSync(CreateAutoSyncRequest) returns (google.protobuf.Empty);
     */
    createAutoSync(input: CreateAutoSyncRequest, options?: RpcOptions): UnaryCall<CreateAutoSyncRequest, Empty>;
    /**
     * @generated from protobuf rpc: DeleteAutoSync(DeleteAutoSyncRequest) returns (google.protobuf.Empty);
     */
    deleteAutoSync(input: DeleteAutoSyncRequest, options?: RpcOptions): UnaryCall<DeleteAutoSyncRequest, Empty>;
    /**
     * @generated from protobuf rpc: GetAutoSync(GetAutoSyncRequest) returns (GetAutoSyncResponse);
     */
    getAutoSync(input: GetAutoSyncRequest, options?: RpcOptions): UnaryCall<GetAutoSyncRequest, GetAutoSyncResponse>;
    /**
     * @generated from protobuf rpc: StartAutoSync(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    startAutoSync(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
}
/**
 * @generated from protobuf service ContactsService
 */
export class ContactsServiceClient implements IContactsServiceClient, ServiceInfo {
    typeName = ContactsService.typeName;
    methods = ContactsService.methods;
    options = ContactsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * ContactList return a list of all contacts for tenant
     *
     * @generated from protobuf rpc: ContactList(ContactsListRequest) returns (ContactsListResponse);
     */
    contactList(input: ContactsListRequest, options?: RpcOptions): UnaryCall<ContactsListRequest, ContactsListResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsListRequest, ContactsListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactInvite create pending contact and send invitation to email
     * NOTE: for now we support only one admin role for one tenant
     *
     * @generated from protobuf rpc: ContactInvite(ContactsInviteRequest) returns (ContactsInviteResponse);
     */
    contactInvite(input: ContactsInviteRequest, options?: RpcOptions): UnaryCall<ContactsInviteRequest, ContactsInviteResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsInviteRequest, ContactsInviteResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactUpdate change contact personal info
     *
     * @generated from protobuf rpc: ContactUpdate(ContactsUpdateRequest) returns (Contact);
     */
    contactUpdate(input: ContactsUpdateRequest, options?: RpcOptions): UnaryCall<ContactsUpdateRequest, Contact> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsUpdateRequest, Contact>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactUpdate change contact personal info
     *
     * @generated from protobuf rpc: ContactUpdateStatus(ContactsUpdateStatusRequest) returns (ContactResponse);
     */
    contactUpdateStatus(input: ContactsUpdateStatusRequest, options?: RpcOptions): UnaryCall<ContactsUpdateStatusRequest, ContactResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsUpdateStatusRequest, ContactResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactDelete remove contact
     *
     * @generated from protobuf rpc: ContactDelete(ContactsRequest) returns (ContactResponse);
     */
    contactDelete(input: ContactsRequest, options?: RpcOptions): UnaryCall<ContactsRequest, ContactResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsRequest, ContactResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactResendInvite resend invitation email
     *
     * @generated from protobuf rpc: ContactResendInvite(ContactsRequest) returns (ContactResponse);
     */
    contactResendInvite(input: ContactsRequest, options?: RpcOptions): UnaryCall<ContactsRequest, ContactResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsRequest, ContactResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactRegistration create new user for invited contact
     *
     * @generated from protobuf rpc: ContactRegistration(ContactsRegistrationRequest) returns (Contact);
     */
    contactRegistration(input: ContactsRegistrationRequest, options?: RpcOptions): UnaryCall<ContactsRegistrationRequest, Contact> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsRegistrationRequest, Contact>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactSocialRegistration create new user for invited contact by social token
     *
     * @generated from protobuf rpc: ContactSocialRegistration(ContactsSocialRegistrationRequest) returns (Contact);
     */
    contactSocialRegistration(input: ContactsSocialRegistrationRequest, options?: RpcOptions): UnaryCall<ContactsSocialRegistrationRequest, Contact> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsSocialRegistrationRequest, Contact>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactInviteTokenValidate check is invitation token is valid
     *
     * @generated from protobuf rpc: ContactInviteTokenValidate(ContactsTokenValidateRequest) returns (ContactsTokenValidateResponse);
     */
    contactInviteTokenValidate(input: ContactsTokenValidateRequest, options?: RpcOptions): UnaryCall<ContactsTokenValidateRequest, ContactsTokenValidateResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsTokenValidateRequest, ContactsTokenValidateResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactLicense update contact license status and activate/deactivate account
     *
     * @generated from protobuf rpc: ContactLicense(ContactsLicenseRequest) returns (ContactsLicenseResponse);
     */
    contactLicense(input: ContactsLicenseRequest, options?: RpcOptions): UnaryCall<ContactsLicenseRequest, ContactsLicenseResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactsLicenseRequest, ContactsLicenseResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeactivateSocialAccounts deactivate all active accounts with social login method if company not allow SSO.
     *
     * @generated from protobuf rpc: DeactivateSocialAccounts(DeactivateSocialAccountsRequest) returns (DeactivateSocialAccountsResponse);
     */
    deactivateSocialAccounts(input: DeactivateSocialAccountsRequest, options?: RpcOptions): UnaryCall<DeactivateSocialAccountsRequest, DeactivateSocialAccountsResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeactivateSocialAccountsRequest, DeactivateSocialAccountsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ActivateSocialAccounts activate back all active accounts with social login method if company allow SSO.
     *
     * @generated from protobuf rpc: ActivateSocialAccounts(ActivateSocialAccountsRequest) returns (ActivateSocialAccountsResponse);
     */
    activateSocialAccounts(input: ActivateSocialAccountsRequest, options?: RpcOptions): UnaryCall<ActivateSocialAccountsRequest, ActivateSocialAccountsResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ActivateSocialAccountsRequest, ActivateSocialAccountsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * TeamCreate create new team for customer
     *
     * @generated from protobuf rpc: CreateTeam(CreateTeamRequest) returns (CreateTeamResponse);
     */
    createTeam(input: CreateTeamRequest, options?: RpcOptions): UnaryCall<CreateTeamRequest, CreateTeamResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateTeamRequest, CreateTeamResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * TeamList get all teams for customer
     *
     * @generated from protobuf rpc: ListTeams(TeamListRequest) returns (TeamListResponse);
     */
    listTeams(input: TeamListRequest, options?: RpcOptions): UnaryCall<TeamListRequest, TeamListResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<TeamListRequest, TeamListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * TeamUpdate update team
     *
     * @generated from protobuf rpc: UpdateTeam(TeamUpdateRequest) returns (Team);
     */
    updateTeam(input: TeamUpdateRequest, options?: RpcOptions): UnaryCall<TeamUpdateRequest, Team> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<TeamUpdateRequest, Team>("unary", this._transport, method, opt, input);
    }
    /**
     * TeamDelete delete team
     *
     * @generated from protobuf rpc: DeleteTeam(TeamDeleteRequest) returns (TeamDeleteResponse);
     */
    deleteTeam(input: TeamDeleteRequest, options?: RpcOptions): UnaryCall<TeamDeleteRequest, TeamDeleteResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<TeamDeleteRequest, TeamDeleteResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List people from Dashboard contacts.
     * If user has personal calendar set, will list the people in the company directory.
     * Returns a list of people sorted by display name.
     *
     * @generated from protobuf rpc: ListPeople(ListPeopleRequest) returns (People);
     */
    listPeople(input: ListPeopleRequest, options?: RpcOptions): UnaryCall<ListPeopleRequest, People> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListPeopleRequest, People>("unary", this._transport, method, opt, input);
    }
    /**
     * Sync trigger sync account info between sql, graph and firebase. The source of true about account info is sql.
     *
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListIntegrationGroupsMembers(ListIntegrationGroupsMembersRequest) returns (ListIntegrationGroupsMembersResponse);
     */
    listIntegrationGroupsMembers(input: ListIntegrationGroupsMembersRequest, options?: RpcOptions): UnaryCall<ListIntegrationGroupsMembersRequest, ListIntegrationGroupsMembersResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListIntegrationGroupsMembersRequest, ListIntegrationGroupsMembersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PaginatedListIntegrationGroupsMembers(PaginatedListIntegrationGroupsMembersRequest) returns (PaginatedListIntegrationGroupsMembersResponse);
     */
    paginatedListIntegrationGroupsMembers(input: PaginatedListIntegrationGroupsMembersRequest, options?: RpcOptions): UnaryCall<PaginatedListIntegrationGroupsMembersRequest, PaginatedListIntegrationGroupsMembersResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<PaginatedListIntegrationGroupsMembersRequest, PaginatedListIntegrationGroupsMembersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SyncIntegrationGroupsMembers(SyncIntegrationGroupsMembersRequest) returns (SyncIntegrationGroupsMembersResponse);
     */
    syncIntegrationGroupsMembers(input: SyncIntegrationGroupsMembersRequest, options?: RpcOptions): UnaryCall<SyncIntegrationGroupsMembersRequest, SyncIntegrationGroupsMembersResponse> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<SyncIntegrationGroupsMembersRequest, SyncIntegrationGroupsMembersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateAutoSync(CreateAutoSyncRequest) returns (google.protobuf.Empty);
     */
    createAutoSync(input: CreateAutoSyncRequest, options?: RpcOptions): UnaryCall<CreateAutoSyncRequest, Empty> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateAutoSyncRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteAutoSync(DeleteAutoSyncRequest) returns (google.protobuf.Empty);
     */
    deleteAutoSync(input: DeleteAutoSyncRequest, options?: RpcOptions): UnaryCall<DeleteAutoSyncRequest, Empty> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteAutoSyncRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAutoSync(GetAutoSyncRequest) returns (GetAutoSyncResponse);
     */
    getAutoSync(input: GetAutoSyncRequest, options?: RpcOptions): UnaryCall<GetAutoSyncRequest, GetAutoSyncResponse> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAutoSyncRequest, GetAutoSyncResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StartAutoSync(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    startAutoSync(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
}
