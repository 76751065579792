import React, { useState } from "react";
import format from "date-fns/format";
import _, { isString } from "lodash";
import { toast } from "react-toastify";

import { useRoomFormik } from "../../../shared/Forms/RoomForm/lib/useRoomFormik";
import { useDisplaySettingsFormik } from "../../../shared/Forms/DisplaySettingsForm/lib/useDisplaySettingsForm";
import { useTemplatesClient } from "../../../../api/grpc/workplaces/useTemplatesClient";
import { useTemplateFormik } from "../../lib/useTemplateFormik";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useWorkingHoursData } from "../../../Account/helpers/useWorkingHoursData";
import { useCustomerPermissions } from "../../../Billings/lib/useCustomerPermissions";
import { useRoomTemplateContext } from "../../../../lib/context/Templates/RoomTemplate/RoomTemplateContext";
import { useScrollToTop } from "../../../../lib/hooks/useScrollToTop";

import { dateHelper } from "../../../shared/Forms/DisplaySettingsForm/lib/displayDateHelper";
import { TEMPLATE_ROOT_PATH } from "../../../../lib/routes/routes";
import { roomAmenetiesNames } from "../../../shared/Forms/RoomForm/RoomForm";
import { RoomProfileForm } from "./Form/RoomProfileForm";
import { Button } from "../../../shared/Button/Button";
import { PageHeader } from "../../../shared/PageHeader/PageHeader";
import { CustomerLanguages } from "../../../../api/grpc/workplaces/workplaces";
import { CancelProfileButton } from "../../shared/CancelProfileButton";
import { tooltips } from "../../../../lib/utils/tooltips";

export const AddRoomProfile = () => {
  useScrollToTop();
  const [loading, setLoading] = useState(false);
  const { createRoomTemplate } = useTemplatesClient();
  const { refetchRoomTemplates } = useRoomTemplateContext();
  const { history } = useRouter();
  const {
    loading: workingHoursLoading,
    error,
    workingHoursData,
    handleWorkingHours,
    refetch,
  } = useWorkingHoursData();
  const {
    data: customerPermissions,
    error: customerError,
    isBussinesOrPro,
  } = useCustomerPermissions();

  const templateFormik = useTemplateFormik({
    onSubmit: async () => {},
  });

  const formik = useRoomFormik({
    onSubmit: async () => {},
  });

  const displaySettingsFormik = useDisplaySettingsFormik(
    {},
    {
      onSubmit: async () => {},
    }
  );

  const handleCreateRoomProfile = async () => {
    try {
      if (
        Object.keys(displaySettingsFormik.errors).length ||
        Object.keys(templateFormik.errors).length ||
        templateFormik.values.profileName.length <= 0
      ) {
        toast.error(
          "Field validation failed. Make sure you write data as validation rules require."
        );
        return;
      }
      if (!!error || !!customerError) {
        toast.error(
          "Failed to fetch working hours, please contact your administrator."
        );
        return;
      }

      setLoading(true);

      const {
        description,
        tags,
        roomAmenities,
        numberOfSeats,
        isBlocked,
        brokenAmenitiesReporting,
      } = formik.values;

      const { main, display, reservation } = displaySettingsFormik.values;

      const amenities = roomAmenetiesNames.reduce((acc: any, cur) => {
        acc[cur] = roomAmenities.includes(cur);

        return acc;
      }, {});

      const {
        response: { id },
      } = await createRoomTemplate({
        name: templateFormik.values.profileName,
        description: templateFormik.values.profileDescription,
        roomSettings: {
          description,
          numberOfSeats,
          brokenAmenitiesReporting,
          isBlocked,
          tags: tags || [],
          amenities: amenities,
        },
        displaySettings: {
          adminPin: main.adminPin,
          backgroundImageUrl: main.backgroundImageUrl,
          logoImageUrl: main.logoImageUrl,
          showOrganizer: display.showOrganizer,
          showMeetingTitle: display.showMeetingTitle,
          showAttendees: display.showAttendees,
          showRoomCapacity: display.showRoomCapacity,
          showRoomAmeneties: display.showRoomAmeneties,
          showCheckinRequired: !!display.showCheckinRequired,
          pendingTimeBeforeMeeting: display.pendingTimeBeforeMeeting,
          pendingTimeAfterMeeting: display.pendingTimeAfterMeeting,
          soonFree: display.soonFree,
          checkinReminder: display.checkinReminder,
          enableScreenLock: display.enableScreenLock,
          screenLock: display.screenLock,
          energySaveMode: display.energySaveMode,
          oneTimePinProtection: reservation.onScreenReservation
            ? reservation.onScreenReservation.oneTimePinProtection || false
            : false,
          onScreenReservation: !!reservation.onScreenReservation,
          showMeetingTitleInput: reservation.onScreenReservation
            ? reservation.onScreenReservation.showMeetingTitleInput || false
            : false,
          requireMeetingTitleInput: reservation.onScreenReservation
            ? reservation.onScreenReservation.requireMeetingTitleInput || false
            : false,
          showYourNameInput: reservation.onScreenReservation
            ? reservation.onScreenReservation.showYourNameInput || false
            : false,
          requireYourNameInput: reservation.onScreenReservation
            ? reservation.onScreenReservation.requireYourNameInput || false
            : false,
          workingHrsActivated: reservation.onScreenReservation
            ? reservation.onScreenReservation.workingHrsActivated || false
            : false,
          requireHeadcount: reservation.onScreenReservation
            ? reservation.onScreenReservation.requireHeadcount || false
            : false,
          energySaveModeStart: format(
            dateHelper(
              reservation.onScreenReservation?.workingHrsEnd || "",
              "endTime",
              workingHoursData
            ),
            "HH:mm:ss"
          ),
          energySaveModeEnd: format(
            dateHelper(
              reservation.onScreenReservation?.workingHrsStart || "",
              "startTime",
              workingHoursData
            ),
            "HH:mm:ss"
          ),
          qrCodeRequired: display.qrCodeRequired,
          contentBoardActivated: display.contentBoardActivated,
          contentBoardURL: display.contentBoardURL,
          customLanguageId: display?.customLanguage?.id || "",
          customLanguage: !!display.customLanguage
            ? ({
                id: display.customLanguage?.id || "",
                value: display.customLanguage?.value || "",
                name: display.customLanguage?.name || "",
                isGlobal: display.customLanguage?.isGlobal || false,
              } as CustomerLanguages)
            : undefined,
          bwFilter: display.bwFilter,
          workingHrsStart: !!reservation.onScreenReservation?.workingHrsStart
            ? isString(reservation.onScreenReservation.workingHrsStart) &&
              reservation.onScreenReservation.workingHrsStart.length <= 9
              ? reservation.onScreenReservation.workingHrsStart
              : format(
                  new Date(
                    reservation.onScreenReservation.workingHrsStart || ""
                  ),
                  "HH:mm:ss"
                )
            : "",
          workingHrsEnd: !!reservation.onScreenReservation?.workingHrsEnd
            ? isString(reservation.onScreenReservation.workingHrsEnd) &&
              reservation.onScreenReservation.workingHrsEnd.length <= 9
              ? reservation.onScreenReservation.workingHrsEnd
              : format(
                  new Date(reservation.onScreenReservation.workingHrsEnd || ""),
                  "HH:mm:ss"
                )
            : "",
          deleteMeeting: reservation.deleteMeeting,
          endMeetingEarly: reservation.endMeetingEarly,
          extendMeeting: reservation.extendMeeting,
          nearbyRooms: reservation.nearbyRooms,
          nearbyEnabled: customerPermissions?.nearbyRooms || false,
          mapView: reservation.mapView,
          floorId: reservation?.floor?.id || "",
          floor: !reservation.floor?.id
            ? undefined
            : {
                id: reservation.floor.id,
                name: "",
              },
        },
      });

      toast.success("Room template added successfully.");

      await refetchRoomTemplates();

      history.push(`${TEMPLATE_ROOT_PATH}/edit-room/${id}`);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="TemplateManage">
      <PageHeader
        title="Add room template"
        hideBorder
        message={tooltips.templates.addRoomTemplate}
      />
      <div className="flex-a-center justify-content-end gap-1 mb-2">
        <CancelProfileButton />

        <Button
          color="primary"
          size="small"
          title="Save"
          onClick={handleCreateRoomProfile}
          disabled={loading}
        />
      </div>

      <RoomProfileForm
        templateFormik={templateFormik}
        formik={formik}
        displaySettingsFormik={displaySettingsFormik}
        isBussinesOrPro={isBussinesOrPro}
        error={error}
        handleWorkingHours={handleWorkingHours}
        loading={workingHoursLoading}
        workingHoursData={workingHoursData}
        refetch={refetch}
      />
    </div>
  );
};
