import React from "react";
import cs from "classnames";

import { useSidebarContext } from "../../lib/context/Sidebar/SidebarContext";
import { useValidPersonalToken } from "../../lib/context/ValidateToken/ValidateTokenContext";
import { useCalendarContext } from "../../lib/context/Calendar/CalendarContext";

import { SidebarOverlay } from "../Sidebar/SidebarOverlay/SidebarOverlay";
import { CalendarCredentialsStatus } from "../../api/grpc/calendarprovider/calendarprovider";

interface Props {
  children: React.ReactNode;
}

export const MainApp = (props: Props) => {
  const { closeSidebar } = useSidebarContext();
  const { statusOfToken } = useValidPersonalToken();
  const { dynamicStyles } = useCalendarContext();

  const classes = cs("MainApp d-flex", {
    "MainApp--adjustable":
      statusOfToken ===
      CalendarCredentialsStatus.CalendarCredentialsStatusInvalid,
  });

  return (
    <div
      className={classes}
      onClick={() => {
        closeSidebar();
      }}
    >
      <SidebarOverlay />
      <div className="MainApp__content" style={dynamicStyles}>
        {props.children}
      </div>
    </div>
  );
};
