import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import { Icon } from "../../../shared/Icon/Icon";
import { BookingsMapReserve } from "./BookingsMapReserve";
import { BookingsMapDeleteModal } from "./BookingsMapDeleteModal/BookingsMapDeleteModal";
import { BusyTimeSlots } from "../../../../api/grpc/desk/ggevent/ggevent";
import { MainResources } from "../../../../lib/types/main.types";

import "./BookingsButtonsModal.scss";

interface Props {
  id: string;
  type: string;
  typeOfWorkplace: MainResources;
  isOpen: boolean;
  toggleModal: () => void;
  busySlots: BusyTimeSlots[];
  refetch: () => void;
}

export const BookingsButtonsModal = ({
  id,
  type,
  typeOfWorkplace,
  isOpen,
  toggleModal,
  busySlots,
  refetch,
}: Props) => {
  return (
    <Modal isOpen={isOpen} className="BookingsModal BookingsButtonsModal">
      <ModalHeader>
        <span onClick={toggleModal} className="btn-close">
          <Icon icon="close-icon" />
        </span>
      </ModalHeader>
      {type === "add" ? (
        <BookingsMapReserve
          id={id}
          typeOfWorkplace={typeOfWorkplace}
          refetch={refetch}
        />
      ) : (
        <BookingsMapDeleteModal
          type={typeOfWorkplace}
          id={id}
          busySlots={busySlots}
          toggleModal={toggleModal}
          refetch={refetch}
        />
      )}
    </Modal>
  );
};
