import React, { FC } from "react";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useWorkingHoursData } from "../../../Account/helpers/useWorkingHoursData";
import { tooltips } from "../../../../lib/utils/tooltips";

import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { WorkHours } from "./WorkHours/WorkHours";
import { ServiceEmail } from "./ServiceEmail/ServiceEmail";

type Props = {
  workingHoursDataQuery: ReturnType<typeof useWorkingHoursData>;
};

export const BookingAndNotificationsCard: FC<Props> = ({
  workingHoursDataQuery: {
    loading,
    error,
    workingHoursData,
    handleWorkingHours,
  },
}) => {
  const workHoursToggleModal = useToggleModal();

  return (
    <InfoCard
      title="Booking & notifications"
      message={
        tooltips.organizationSettings.generalSettings.bookingsNotifications
      }
      direction="right"
    >
      <div className="OrganizationSettigs__cardContent OrganizationSettigs__workHours">
        <WorkHours
          hasButton
          isOpen={workHoursToggleModal.isOpen}
          toggleModal={workHoursToggleModal.toggleModal}
          loading={loading}
          data={workingHoursData}
          handleWorkingHours={handleWorkingHours}
        />
        <ServiceEmail
          errorReportingEmail={workingHoursData?.errorReportingEmail}
          handleErrorReportingEmail={handleWorkingHours}
          loading={loading}
          error={error}
        />
      </div>
    </InfoCard>
  );
};
