import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { useAuthContext } from "./context/Auth/AuthContext";

import { RouteType } from "./routes/routes";
import { NoPermission } from "../components/shared/NoPermission/NoPermission";

interface Props extends RouteProps {
  routeType: RouteType;
}

export const AppRoute = (props: Props) => {
  const { routeType, ...otherProps } = props;
  const { isAuthenticated, userAccess, user } = useAuthContext();
  if (routeType === RouteType.PUBLIC) {
    return <Route {...otherProps} />;
  }

  if (
    (userAccess === "no-permission" || isAuthenticated) &&
    routeType === RouteType.RESTRICTED
  ) {
    return <Redirect to="/" />;
  }

  if (
    isAuthenticated &&
    (user?.claims.role === "contact" || user?.claims.role === "member") &&
    props.path !== "/bookings" &&
    props.path !== "/account"
  ) {
    return <Redirect to="/bookings" />;
  }

  if (userAccess === "no-permission") {
    return <NoPermission />;
  }

  if (!isAuthenticated && routeType === RouteType.RESTRICTED) {
    return <Route {...otherProps} />;
  }

  if (!isAuthenticated && routeType === RouteType.PRIVATE) {
    return <Redirect to="/login" />;
  }

  return <Route {...otherProps} />;
};
