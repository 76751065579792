import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {
  IntegrationGroupMember,
  SyncStatus,
} from "../../../api/grpc/contacts/contacts";

export interface DirectoryContext {
  selectedUsers: IntegrationGroupMember[];
  setSelectedUsers: Dispatch<SetStateAction<IntegrationGroupMember[]>>;
  selectAllUsers: boolean;
  setSelectAllUsers: Dispatch<SetStateAction<boolean>>;
  filterStatus: SyncStatus[];
  setFilterStatus: Dispatch<SetStateAction<SyncStatus[]>>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

export const DirectoryContext = createContext<DirectoryContext>({
  selectedUsers: [],
  setSelectedUsers: () => {},
  selectAllUsers: false,
  setSelectAllUsers: () => {},
  filterStatus: [],
  setFilterStatus: () => {},
  searchValue: "",
  setSearchValue: () => {},
});

export const useDirectoryContext = () => useContext(DirectoryContext);

export const { Provider } = DirectoryContext;
