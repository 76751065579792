import React, { useEffect, useMemo, useRef } from "react";
import { useFloorData, useMarkerData } from "./floorData";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Popup } from "../MarkerPopup/Popup";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { floorPlanHelpers } from "./floorPlanHelpers";
import { FloorFields } from "../../../api/graphql/floors/floors";
import { FloorChildrenType } from "../FloorChildrenList/FloorChildrenDefaultList";
import { useTypesHelper } from "./useTypesHelper";

type MarkerSettings = {
  scaleFactor?: number;
};

export const useMarker = (
  data: FloorFields,
  setDisableZoom?: React.Dispatch<React.SetStateAction<boolean>>,
  disableZoom?: boolean,
  settings?: MarkerSettings
) => {
  const { getFloorEntityLeafletIcon, getScaledIcon } = floorPlanHelpers();

  const { roomsOfFloor, desksOfFloor, wayfindersfFloor } = useFloorData(data);
  const { user } = useAuthContext();
  const markerData = useMarkerData(
    roomsOfFloor,
    desksOfFloor,
    wayfindersfFloor
  );
  const markersCacheRef = useRef<L.Marker[]>([]);
  const { methodTypes } = useTypesHelper();

  const markers = useMemo<L.Marker[]>(() => {
    // clear cached markers
    markersCacheRef.current.forEach((cachedMarker) => {
      cachedMarker.remove();
    });

    const _markers = markerData.flat().map((floorEntity: any) => {
      const newMarker: L.Marker = L.marker(floorEntity?.latlng, {
        icon: getFloorEntityLeafletIcon(floorEntity, "floor"),
        draggable: true,
        attribution: floorEntity?.id,
      }).bindPopup(
        ReactDOMServer.renderToString(
          <Popup
            name={floorEntity?.name}
            id={floorEntity?.id}
            type={floorEntity?.type}
            location={floorEntity?.location ? floorEntity.location : ""}
            user={floorEntity?.user ? floorEntity.user : ""}
            // onClick={() => deleteMarker(floorEntity.id)}
          />
        )
      );

      newMarker.on("dragend", (e) => {
        let { lat, lng } = e.target.getLatLng();
        let methodName =
          methodTypes[
            e.target.getIcon().options.attribution as FloorChildrenType
          ];

        methodName({
          id: e.target.getAttribution(),
          marker: {
            latitude: Math.ceil(lat),
            longitude: Math.ceil(lng),
          },
          tenantId: user?.customerid || "",
        });

        if (disableZoom === false && setDisableZoom) {
          setDisableZoom(true);
        }

        return;
      });

      return newMarker;
    });

    markersCacheRef.current = _markers;

    return _markers;
  }, [data]);

  // apply scale factor
  useEffect(() => {
    const rollback: (() => void)[] = [];

    markers.forEach((marker) => {
      const currentIcon = marker.getIcon();

      if (currentIcon && settings?.scaleFactor) {
        marker.setIcon(
          getScaledIcon(
            currentIcon as L.Icon,
            settings.scaleFactor,
            currentIcon.options.attribution === "wayfinder"
          )
        );

        rollback.push(() => {
          marker.setIcon(currentIcon);
        });
      }
    });

    return () => {
      rollback.forEach((f) => f());
    };
  }, [markers, settings, getScaledIcon]);

  const openMarkerPopup = (id: string) => {
    let clickedMarker = markers.filter(
      (marker) => marker.options.attribution === id
    );

    if (clickedMarker[0].isPopupOpen()) {
      return clickedMarker[0].closePopup();
    }
    return clickedMarker[0].openPopup();
  };

  return {
    markers,
    roomsOfFloor,
    desksOfFloor,
    wayfindersfFloor,
    openMarkerPopup,
  };
};
