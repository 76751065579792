import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDirectoryIntegrationsRequests } from "../../../api/grpc/calendarprovider/useDirectoryIntegrationsRequests";

import { CalendarType } from "../../../api/grpc/calendarprovider/calendarprovider";
import { CalendarsWizardFooter } from "../../Calendars/CalendarsWizardForm/CalendarsWizardFooter/CalendarsWizardFooter";
import { CalendarsWizardHeader } from "../../Calendars/CalendarsWizardForm/CalendarsWizardHeader/CalendarsWizardHeader";
import { ProviderButton } from "../../Insights/shared/ProviderButtons/ProviderButton";

interface Props {
  activeStep: number;
  activeCalendar: string;
  handlePrevClick: () => void;
}

export const DirectorySyncServiceAccount = ({
  activeStep,
  activeCalendar,
  handlePrevClick,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const { generateDirectoryIntegrationAuthLink } =
    useDirectoryIntegrationsRequests();

  let isGoogle = activeCalendar === "gsuite";

  const redirectURL = isGoogle
    ? (process.env.REACT_APP_GCP_DIRECTORY as string)
    : (process.env.REACT_APP_O365_DIRECTORY as string);

  const integrationType = isGoogle
    ? CalendarType.GSUITE_SYNC
    : CalendarType.O365_SYNC;

  const handleGenerateLink = async () => {
    try {
      setLoading(true);
      const { response } = await generateDirectoryIntegrationAuthLink({
        redirectURL,
        integrationType,
      });

      if (!response.authLink.length) {
        toast.error("Failed to generate the authentification link");
        return;
      }

      window.location.href = response.authLink;
      return;
    } catch (error: any) {
      return toast.error(error?.message ?? "Failed to generate link!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="CalendarServiceAccount">
      <CalendarsWizardHeader
        activeStep={activeStep}
        handleClick={(n) => console.info(n)}
        isDirectoryUser={true}
      />

      <ProviderButton
        className="GSuiteConnectButton"
        handleClick={() => {
          if (loading) {
            return;
          }

          handleGenerateLink();
        }}
        type={isGoogle ? "Google" : "Microsoft"}
      />

      <CalendarsWizardFooter
        disabledNext={!activeCalendar}
        activeStep={activeStep}
        activeCalendar={activeCalendar}
        handlePrevClick={handlePrevClick}
      />
    </div>
  );
};
