import React, { PropsWithChildren, useEffect, useState } from "react";
import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useDirectoryContext } from "../../../../components/DirectorySync/Context/DirectoryContext";

import { DirectoryIntegrationsContent } from "../../../../components/DirectorySync/DirectoryIntegrations/DirectoryIntegrationsContent/DirectoryIntegrationsContent";
import { DirectoryIntegrationsHeader } from "../../../../components/DirectorySync/DirectoryIntegrations/DirectoryIntegrationsHeader/DirectoryIntegrationsHeader";
import { IntegrationGroupMember } from "../../../../api/grpc/contacts/contacts";

export const DirectoryIntegration = ({
  refetch,
}: PropsWithChildren<{
  refetch: () => void;
}>) => {
  const { listDirectoryUsers } = useContacts();
  const { query } = useRouter();
  const { filterStatus, searchValue, selectedUsers } = useDirectoryContext();
  const [nextPageToken, setNextPageToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [paginationMembers, setPaginationMembers] = useState<
    IntegrationGroupMember[] | undefined
  >(undefined);

  const handleListOfDirectory = async (offset?: string) => {
    //if offset is not provided, it means it's the first page
    //we want offset to be empty when users click the refetch icon in the header

    try {
      setLoading(true);

      let pageFromResponse = "";
      let availableMembers: IntegrationGroupMember[] = [];

      do {
        const {
          response: { members, offsetToken },
        } = await listDirectoryUsers({
          providerID: query.id,
          limit: 100,
          filter: filterStatus,
          offsetToken: pageFromResponse.length
            ? pageFromResponse
            : offset ?? nextPageToken,
          search: searchValue,
        });

        availableMembers = [...availableMembers, ...members];
        pageFromResponse = offsetToken;
      } while (!availableMembers.length && pageFromResponse.length);

      //if users are selected, AND returned from filter by name or status, we show this member as additional member in
      //paginationMembers array
      const newSelectedUsers = selectedUsers.filter(
        (user) => !paginationMembers?.some((member) => member.id === user.id)
      );

      //if member was returned from filter by name or status AND selected, we don't show this member
      //since it's already shown from newSelectedUsers
      const newMembers = availableMembers.filter(
        (member) => !selectedUsers.some((user) => user.id === member.id)
      );

      const filteredSelectedUsers = () => {
        if (filterStatus.length && !searchValue.length) {
          return selectedUsers.filter((user) =>
            filterStatus.includes(user.status)
          );
        }

        if (!filterStatus.length && searchValue.length) {
          return selectedUsers.filter((user) =>
            user.name.includes(searchValue)
          );
        }

        if (filterStatus.length && searchValue.length) {
          return selectedUsers.filter(
            (user) =>
              user.name.includes(searchValue) &&
              filterStatus.includes(user.status)
          );
        }

        return selectedUsers;
      };

      if (
        paginationMembers &&
        offset === undefined
        // !searchValue.length &&
        // !filterStatus.length
      ) {
        setPaginationMembers([
          ...newSelectedUsers,
          ...paginationMembers,
          ...newMembers,
        ]);
      } else {
        setPaginationMembers([...filteredSelectedUsers(), ...newMembers]);
      }

      setNextPageToken(pageFromResponse);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filterStatus.length || searchValue.length) {
      //a debouce function to prevent multiple calls
      const timer = setTimeout(() => {
        if (paginationMembers?.length) {
          setPaginationMembers(undefined);
        }

        handleListOfDirectory("");
      }, 800);

      return () => {
        clearTimeout(timer);
      };
    }

    if (paginationMembers?.length) {
      setPaginationMembers(undefined);
    }

    handleListOfDirectory("");
  }, [filterStatus, searchValue, query.id]);

  return (
    <div>
      <DirectoryIntegrationsHeader
        id={query.id}
        title={query.name}
        refetch={refetch}
        refetchListOfDirectory={handleListOfDirectory}
      />

      <DirectoryIntegrationsContent
        loading={loading}
        error={error}
        members={paginationMembers}
        showLoadMoreButton={!!nextPageToken.length}
        handleListOfDirectory={handleListOfDirectory}
      />
    </div>
  );
};
