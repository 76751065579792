import React from "react";
import { toast } from "react-toastify";

import { DebugInfoItem } from "./DebugInfoItem";
import { UserFields } from "../../../../../lib/context/Auth/AuthContext";

import "./DebugInfo.scss";

interface Props {
  user: UserFields | undefined;
}

export const DebugInfo = ({ user }: Props) => {
  const debugItems = [
    { title: "customerID", value: user?.customerid },
    { title: "accountID", value: user?.claims?.user_id },
    { title: "email", value: user?.email },
    { title: "subscriptionID", value: user?.claims?.subscriptionId },
  ];

  let clipBoardText = `
  customerID: ${user?.customerid}
  accountID: ${user?.claims?.user_id}
  email: ${user?.email}
  subscriptionID: ${user?.claims?.subscriptionId}
  `;

  const copyToClipboard = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(clipBoardText);
    toast.success("Copied to clipboard!");
  };

  return (
    <div className="DebugInfo">
      <h4>For Goget support:</h4>
      <ul>
        {debugItems.map((item) => {
          return (
            <DebugInfoItem
              title={item.title}
              value={item.value}
              key={item.title}
            />
          );
        })}
      </ul>
      <div className="center">
        <button onClick={(e) => copyToClipboard(e)}>Copy to clipboard</button>
      </div>
    </div>
  );
};
