import React, { FC, useState } from "react";
import { useWorkingHoursData } from "../../../../Account/helpers/useWorkingHoursData";
import { useCustomerPermissions } from "../../../../Billings/lib/useCustomerPermissions";

import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { Switch } from "../../../../shared/Switch/Switch";

import "./EnableSSO.scss";

type Props = {
  workingHoursDataQuery: ReturnType<typeof useWorkingHoursData>;
};

export const EnableSSO: FC<Props> = ({
  workingHoursDataQuery: {
    loading,
    error,
    workingHoursData,
    handleWorkingHours,
  },
}) => {
  const [disabled, setDisabled] = useState(false);

  const { data } = useCustomerPermissions();

  const handleUpdateSSO = async (value: boolean) => {
    if (!data?.id.toLowerCase().includes("pro")) {
      return;
    }
    try {
      setDisabled(true);

      await handleWorkingHours({
        ssoEnable: value,
      });
    } catch (error: any) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setDisabled(false);
      }, 2000);
    }
  };

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<LoadingBox minHeight={55} />}
    >
      <HandleRequestState
        state={!!error}
        placeholder={error?.message ?? <p>Error fetching customer settings!</p>}
      >
        <Switch
          name="ssoEnable"
          title="Allow users to sign in with Google or Microsoft 365"
          onChange={(value) => {
            handleUpdateSSO(value);
          }}
          spinner={disabled}
          value={
            !data?.id.toLowerCase().includes("pro")
              ? false
              : workingHoursData?.ssoEnable
          }
          disabled={disabled || !data?.id.toLowerCase().includes("pro")}
          className="EnableSSO__switch"
        />
      </HandleRequestState>
    </HandleLoadingState>
  );
};
