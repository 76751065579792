import React, { PropsWithChildren, useEffect } from "react";
import { utcToZonedTime } from "date-fns-tz";
import { addMinutes, setHours, setMinutes, setSeconds } from "date-fns";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { FormGroup, Label } from "reactstrap";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import { Input } from "../../../shared/Input/Input";
import { tooltips } from "../../../../lib/utils/tooltips";

export const AllDayEvent = ({
  handleUpdateTime,
}: PropsWithChildren<{
  handleUpdateTime: (date: Date) => void;
}>) => {
  const { timeZone } = useTimeZoneContext();
  const { allDay, setAllDay } = useBookingsMapContext();

  useEffect(() => {
    if (allDay === true) {
      return setAllDay(false);
    }
  }, []);
  return (
    <FormGroup className="flex-a-center">
      <Input
        type="checkbox"
        id="allDay"
        name="allDay"
        data-cy="allDay"
        onChange={() => {
          const definedTime =
            allDay === false
              ? setHours(
                  setMinutes(
                    setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                    0
                  ),
                  24
                )
              : addMinutes(new Date(), 15);

          handleUpdateTime(definedTime);

          setAllDay((prev) => !prev);
        }}
      />
      <Label for="allDay" className="checkbox_label">
        <p className="pr-1 mb-0">All day</p>
        <span className="checkbox_box"></span>
      </Label>
      <InformationBox message={tooltips.bookings.rooms.allDay} />
    </FormGroup>
  );
};
