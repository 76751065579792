import React, { useMemo } from "react";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useFormatReservationDate as formatReservationDate } from "../../../lib/datePickerHelper";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { useReservationHelpers } from "../../../Reservations/helpers/reservationUpdateHelpers";
import { useDeskReservations } from "../../../Reservations/helpers/useDeskReservations";
import { utcToZonedTime } from "date-fns-tz";

import { ModalFooter } from "reactstrap";
import { BusyTimeSlots } from "../../../../../api/grpc/desk/ggevent/ggevent";
import { Button } from "../../../../shared/Button/Button";
import { dateToHoursAndMinutes } from "../../../lib/dateInputConvert";
import { UserModalLoading } from "../../../shared/UserModal/UserModalLoading";
import { BookingsModalBody } from "../shared/BookingsModalBody";

interface Props {
  id: string;
  busySlots: BusyTimeSlots[];
  toggleModal: () => void;
  refetch: () => void;
}

export const BookingsMapDeleteDesk = ({
  id,
  busySlots,
  toggleModal,
  refetch,
}: Props) => {
  const { deleteDeskReservation } = useReservationHelpers();
  const { user } = useAuthContext();
  const { deskData, loadingDesk, errorDesk } = useDeskReservations();
  const { timeZone } = useTimeZoneContext();

  let choosedDesk = deskData?.desks.filter((desk) => desk.deskID === id);

  let eventToDelete =
    choosedDesk &&
    choosedDesk[0]?.event.filter(
      (event) =>
        event.startTime === busySlots[0].startTime &&
        event.endTime === busySlots[0].endTime
    );

  const isLoading = useMemo<boolean>(() => {
    return loadingDesk || eventToDelete === undefined;
  }, [loadingDesk, eventToDelete]);

  const handleDeskDelete = async () => {
    return await deleteDeskReservation(
      id,
      (eventToDelete && eventToDelete[0].metaData.DESK_EVENT_ID) || "",
      refetch
    );
  };

  return (
    <>
      {isLoading ? (
        <UserModalLoading />
      ) : errorDesk ? (
        <div>Error loading reservation data, please reload and try again</div>
      ) : (
        <>
          <>
            <BookingsModalBody
              title="Delete reservation?"
              icon="delete-reservation"
              workspaceName={(choosedDesk && choosedDesk[0].deskName) || ""}
              workspaceIconType="desk-reserve"
              day={formatReservationDate(
                utcToZonedTime(
                  new Date(eventToDelete ? eventToDelete[0].startTime : ""),
                  timeZone
                )
              )}
              startTime={dateToHoursAndMinutes(
                new Date(eventToDelete ? eventToDelete[0].startTime : ""),
                timeZone
              )}
              endTime={
                busySlots[0].isAllDay === true
                  ? "00:00"
                  : dateToHoursAndMinutes(
                      new Date(eventToDelete ? eventToDelete[0].endTime : ""),
                      timeZone
                    )
              }
              userName={user?.displayName || ""}
              locationPath={[]}
              type="delete"
              subject={eventToDelete ? eventToDelete[0].title : ""}
              allDay={busySlots[0]?.isAllDay}
            />

            <ModalFooter className="modal-footer-row">
              <Button
                size="small"
                color="outline-secondary"
                title="Cancel"
                onClick={toggleModal}
              />

              <Button
                size="small"
                color="primary"
                title="Delete"
                onClick={handleDeskDelete}
              />
            </ModalFooter>
          </>
        </>
      )}
    </>
  );
};
