import React, { useState } from "react";
import { toast } from "react-toastify";
import { isEqual } from "lodash";

import { useEditUserFormik } from "./lib/useEditUserFormik";
import { useContacts } from "../../../api/grpc/contacts/useContacts";
import { usePeopleContext } from "../../../views/Users/People/lib/PeopleContext";
import { useUpdateDeskCache } from "./lib/useUpdateDeskCache";

import { EditUserForm } from "./EditUserForm/EditUserForm";
import { Contact } from "../../../api/grpc/contacts/contacts";
import ModalSidebar from "../../Modals/ModalSidebar/ModalSidebar";
import { LoginMethod } from "../../../api/grpc/account/account";

import "./EditUser.scss";
import { useDefineLicense } from "../../../lib/hooks/useDefineLicense";
import { EntityType } from "../../../api/grpc/subscription/subscription";

interface Props {
  contact: Contact;
  onToggle: () => void;
}

export const EditUser = ({
  contact: {
    firstName,
    lastName,
    role,
    email,
    status,
    licensed,
    id,
    desks,
    loginMethod,
  },
  onToggle,
}: Props) => {
  const { updateDesk } = useUpdateDeskCache();
  const { defineLicenseNumber, refetch } = useDefineLicense(EntityType.USER);
  //we need to return a string array that contains ids of desks to match the type defined by
  //edit contect (backend)
  const desksToString: string[] = desks.map((desk) => {
    return desk.iD;
  });

  const [loading, setLoading] = useState(false);

  const { editContact } = useContacts();
  const { updateUsersList } = usePeopleContext();

  const formik = useEditUserFormik({
    initialValues: {
      id: id,
      emailID: email,
      firstName,
      lastName,
      role,
      status,
      licensed,
      team: "",
      desk: desksToString,
      loginMethod: loginMethod === 0 ? LoginMethod.EMAIL : loginMethod,
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);

        await editContact({
          id,
          firstName: values.firstName,
          lastName: values.lastName,
          role: values.role,
          deskID: values.desk.includes("") ? [] : values.desk,
        });

        if (!isEqual(desksToString, values.desk)) {
          updateDesk(desks, values, id, desksToString);
        }

        updateUsersList();

        onToggle();

        toast.success("Contact updated successfully!");
      } catch (error: any) {
        console.error(error.toString());
        toast.error(
          "Contact couldn't be updated, please reload and try again!"
        );
        setLoading(false);
      }
    },
  });

  const handleUpdateAfterLicense = () => {
    if (licensed !== formik.values.licensed) {
      updateUsersList();
    }

    return onToggle();
  };

  return (
    <ModalSidebar
      isOpen={true}
      loading={loading}
      title="Edit User"
      onToggle={handleUpdateAfterLicense}
      onSubmit={formik.handleSubmit}
    >
      <EditUserForm
        formik={formik}
        defineLicenseNumber={defineLicenseNumber}
        refetch={refetch}
      />
    </ModalSidebar>
  );
};
