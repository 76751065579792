import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useCalendarRequests } from "../../../../api/grpc/calendarprovider/useCalendarRequests";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";

import {
  CalendarType,
  CalendarProviderDeviceType,
  GGCalendarProviderStatus,
} from "../../../../api/grpc/calendarprovider/calendarprovider";
import { ProviderButton } from "../../../Insights/shared/ProviderButtons/ProviderButton";

import "./GSuiteConnectButton.scss";

interface Props {
  toggleModal?: () => void;
  type?: string;
}

export const config = {
  apiKey: process.env.REACT_APP_GCP_API_KEY,
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
  clientId: process.env.REACT_APP_GCP_CLIENT_ID,
  scope:
    "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/directory.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.events",
};

export const GSuiteConnectButton = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user, updateUserAvatarAfterPersonalLinked } = useAuthContext();
  const { createGsuiteProvider, linkPersonalGsuiteCalendar } =
    useCalendarRequests();
  const { addProvider, setPersonalCalendar } = useCalendarContext();

  const [tkn, setTkn] = useState<any>({});

  const handleConnect = () => {
    tkn.requestCode();
  };

  useEffect(() => {
    const calendarConsentInit = async () => {
      try {
        setTkn(
          window.google.accounts.oauth2.initCodeClient({
            client_id: config.clientId,
            scope: config.scope,
            callback: async (tokenResponse: any) => {
              if (tokenResponse && tokenResponse.code) {
                await handleGsuiteFormSubmit(tokenResponse.code);
              }
            },
          })
        );
      } catch (e) {
        toast.error(
          "Couldn't connect to the Google API, please reload and try again!"
        );
      } finally {
        setIsLoading(false);
      }
    };

    calendarConsentInit();
  }, []);

  const handleGsuiteFormSubmit = async (token: string) => {
    if (!user) {
      return;
    }

    try {
      let isPersonalIntegration = props.type === "personal";

      const result = isPersonalIntegration
        ? await linkPersonalGsuiteCalendar("name", {
            oAuthToken: token,
            calendarType: CalendarType.GSUITE,
          })
        : await createGsuiteProvider("name", {
            oAuthToken: token,
            calendarType: CalendarType.GSUITE,
          });

      if (!isPersonalIntegration) {
        addProvider({
          calType: CalendarType.GSUITE,
          iD: result.response.iD,
          name: result.response.name,
          deviceType: CalendarProviderDeviceType.WEB,
          token: "",
          email: "",
          status: GGCalendarProviderStatus.ACTIVE,
          apiError: "",
          hostUrl: "",
        });
      }

      if (isPersonalIntegration) {
        setPersonalCalendar([
          {
            iD: result.response.iD,
            name: result.response.name,
            email: "",
            hostUrl: "",
          },
        ]);

        await updateUserAvatarAfterPersonalLinked();
      }

      if (props.toggleModal) {
        props.toggleModal();
      }

      toast.success("Calendar added successfully!");
    } catch (error: any) {
      console.error(error.message);

      toast.error(error?.message ?? "Calendar couldn't be added!");
    }
  };

  return (
    <ProviderButton
      className="GSuiteConnectButton"
      handleClick={handleConnect}
      disabled={isLoading}
      type="Google"
    />
  );
};
