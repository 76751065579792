import React, { PropsWithChildren, useEffect } from "react";
import { toast } from "react-toastify";
import { useDirectoryIntegrationsRequests } from "../../../api/grpc/calendarprovider/useDirectoryIntegrationsRequests";
import { useRouter } from "../../../lib/hooks/useRouter";
import {
  CalendarType,
  ListDirectoryProviderResponse,
} from "../../../api/grpc/calendarprovider/calendarprovider";
import { WorkplacesTreeLoader } from "../../../views/Manage/Workplaces/WorkplacesTree/WorkplacesTreeLoader/WorkplacesTreeLoader";
import { AddCalendarConnections } from "../../Calendars/AddCalendarConnections/AddCalendarConnections";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { PageSidebar } from "../../shared/PageSidebar/PageSidebar";
import { PageSidebarHeader } from "../../shared/PageSidebar/PageSidebarHeader/PageSidebarHeader";
import { DirectoryIntegrationsList } from "../DirectoryIntegrations/DirectoryIntegrationsList/DirectoryIntegrationsList";
import { DIRECTORY_ROOT_PATH } from "../../../lib/routes/routes";
import { tooltips } from "../../../lib/utils/tooltips";

export const DirectoryIntegrationPicker = ({
  loading,
  error,
  data,
  refetch,
}: PropsWithChildren<{
  loading: boolean;
  error?: Error;
  data?: ListDirectoryProviderResponse | undefined;
  refetch: () => void;
}>) => {
  const { pathname, location, history } = useRouter();
  const { createDirectoryIntegration } = useDirectoryIntegrationsRequests();

  const isDirectoryPage = pathname === DIRECTORY_ROOT_PATH;

  useEffect(() => {
    finishDirectoryIntegration();
  }, []);

  const finishDirectoryIntegration = async () => {
    // microsoft returns "#" instead of "?", so we can't simply access code query
    // instead we need an extra step which is to get the hash value from the entire url:
    const searchParams = new URLSearchParams(location.search);
    let definedCode;

    const type = searchParams.get("type");

    if (type && type === "google") {
      definedCode = searchParams.get("code");
    } else {
      const hash = window.location.hash;

      const codeFromHash = new URLSearchParams(hash.slice(1)).get("code");

      definedCode = codeFromHash;
    }

    if (!definedCode) {
      return;
    }

    toast.info("Finishing adding integration...");

    try {
      await createDirectoryIntegration({
        oAuthToken: definedCode,
        providerType:
          type === "google" ? CalendarType.GSUITE_SYNC : CalendarType.O365_SYNC,
      });

      refetch();

      toast.success("Integration added successfully!");
    } catch (error: any) {
      console.error(error.message);
      toast.error(error?.message ?? "Calendar couldn't be added!");
    } finally {
      setTimeout(() => {
        history.push("/users/sync");
      }, 2000);
    }
  };

  return (
    <PageSidebar
      className="PageSidebar--default PageSidebar--Custom"
      showOnInitialization={isDirectoryPage}
      hideToggler={isDirectoryPage}
      alwaysShowToggler={true}
    >
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<WorkplacesTreeLoader />}
      >
        <HandleRequestState
          state={!!error}
          placeholder={<p>Data couldn't be loaded.</p>}
        >
          <PageSidebarHeader
            title="Directory Integrations"
            message={tooltips.directorySync.integrations}
            direction="left"
            onTitleClick={() => {}}
            hasZIndex
          />

          <DirectoryIntegrationsList data={data?.integrations} />
          <AddCalendarConnections isDirectoryUser={true} />
        </HandleRequestState>
      </HandleLoadingState>
    </PageSidebar>
  );
};
