import React from "react";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useInsightsContext } from "../../Context/InsightsContext";
import { Icon } from "../../../shared/Icon/Icon";
import { InsightsLocationInfo } from "./InsightsLocationInfo";

import "./InsightsFor.scss";

export const InsightsForInfo = () => {
  const {
    insightsLocation: { locationId, locationName, locationType },
  } = useInsightsContext();

  const { pathname } = useRouter();

  const typeOfWorkspace: "desks" | "rooms" = pathname
    .toLowerCase()
    .includes("room")
    ? "rooms"
    : "desks";

  return (
    <div className="InsightsFor flex-a-center">
      <div className="InsightsFor__title">Insights for</div>

      <Icon icon="chevron-simple-right" />

      {locationType === "Room" || locationType === "Desk" ? (
        <InsightsLocationInfo
          icon={locationType === "Room" ? "rooms-icon" : "desk_icon_18x18"}
          name={locationName}
        />
      ) : (
        <>
          <InsightsLocationInfo
            icon="location-reserve"
            name={locationId.length <= 0 ? "All locations" : locationName}
          />

          <Icon icon="arrow-right" />

          <InsightsLocationInfo
            // icon="rooms-multiple"
            icon={
              typeOfWorkspace === "rooms" ? "rooms-multiple" : "desk_icon_18x18"
            }
            name={`All ${typeOfWorkspace}`}
          />
        </>
      )}
    </div>
  );
};
