// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "contacts.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { LoginMethod } from "./account/account";
import { Status } from "./account/account";
import { Role } from "./account/account";
import { Avatar } from "./ggevent/ggevent";
/**
 * Request for Listing People
 *
 * @generated from protobuf message ListPeopleRequest
 */
export interface ListPeopleRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string; // Google Tenant ID
    /**
     * @generated from protobuf field: string accountID = 2;
     */
    accountID: string; // Firebase Account ID
    /**
     * @generated from protobuf field: string personalCalendarID = 3;
     */
    personalCalendarID: string; // Personal calendar ID in the format of 0365_MOBILE | GSUITE_MOBILE | EWS_MOBILE
    /**
     * @generated from protobuf field: string query = 4;
     */
    query: string; // Query to match against displayName for now. Can be expanded in the future.
    /**
     * @generated from protobuf field: ListPeopleFilter filter = 5;
     */
    filter: ListPeopleFilter; // filter by People accountType
}
/**
 * People response
 *
 * @generated from protobuf message People
 */
export interface People {
    /**
     * @generated from protobuf field: repeated Person people = 1;
     */
    people: Person[]; // List of persons
}
/**
 * Person
 *
 * @generated from protobuf message Person
 */
export interface Person {
    /**
     * @generated from protobuf field: string displayName = 1;
     */
    displayName: string; // Display name of person which first name and surname joined together
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string; // Email of person
    /**
     * @generated from protobuf field: ggevent.Avatar avatar = 3;
     */
    avatar?: Avatar; // Avatar profile picture. Either base64 encoded string or an image url.
    /**
     * @generated from protobuf field: string accountID = 4;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 5;
     */
    customerID: string;
}
/**
 * @generated from protobuf message Contact
 */
export interface Contact {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string firstName = 3;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string lastName = 4;
     */
    lastName: string;
    /**
     * @generated from protobuf field: Role role = 5;
     */
    role: Role;
    /**
     * @generated from protobuf field: Status status = 6;
     */
    status: Status;
    /**
     * @generated from protobuf field: bool licensed = 7;
     */
    licensed: boolean;
    /**
     * @generated from protobuf field: string customerID = 8;
     */
    customerID: string;
    /**
     * @generated from protobuf field: int32 teamID = 9;
     */
    teamID: number;
    /**
     * @generated from protobuf field: repeated DeskObject desks = 10;
     */
    desks: DeskObject[];
    /**
     * @generated from protobuf field: LoginMethod loginMethod = 11;
     */
    loginMethod: LoginMethod;
}
/**
 * @generated from protobuf message DeskObject
 */
export interface DeskObject {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    iD: string;
    /**
     * @generated from protobuf field: string deskName = 2;
     */
    deskName: string;
}
/**
 * @generated from protobuf message ContactsListRequest
 */
export interface ContactsListRequest {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: NullableBoolean licensed = 5;
     */
    licensed: NullableBoolean;
    /**
     * @generated from protobuf field: repeated Role role = 6;
     */
    role: Role[];
    /**
     * @generated from protobuf field: repeated Status status = 7;
     */
    status: Status[];
    /**
     * @generated from protobuf field: NullableBoolean desks = 8;
     */
    desks: NullableBoolean; // based on if user has desk assigned or not
}
/**
 * @generated from protobuf message ContactsListResponse
 */
export interface ContactsListResponse {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int32 PagesCount = 3 [json_name = "PagesCount"];
     */
    pagesCount: number;
    /**
     * @generated from protobuf field: repeated Contact contacts = 4;
     */
    contacts: Contact[];
}
/**
 * @generated from protobuf message ContactsInviteRequest
 */
export interface ContactsInviteRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: Role role = 3;
     */
    role: Role;
    /**
     * @generated from protobuf field: int32 teamID = 4;
     */
    teamID: number;
    /**
     * @generated from protobuf field: repeated string deskID = 5;
     */
    deskID: string[];
    /**
     * @generated from protobuf field: LoginMethod loginMethod = 6;
     */
    loginMethod: LoginMethod;
}
/**
 * @generated from protobuf message ContactsInviteResponse
 */
export interface ContactsInviteResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message ContactsUpdateRequest
 */
export interface ContactsUpdateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string firstName = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string lastName = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: Role role = 4;
     */
    role: Role;
    /**
     * @generated from protobuf field: string customerID = 5;
     */
    customerID: string;
    /**
     * @generated from protobuf field: int32 teamID = 6;
     */
    teamID: number;
    /**
     * @generated from protobuf field: repeated string deskID = 7;
     */
    deskID: string[];
}
/**
 * @generated from protobuf message ContactsUpdateStatusRequest
 */
export interface ContactsUpdateStatusRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: Status status = 2;
     */
    status: Status;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
}
/**
 * @generated from protobuf message ContactsRequest
 */
export interface ContactsRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message ContactResponse
 */
export interface ContactResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message ContactsTokenValidateRequest
 */
export interface ContactsTokenValidateRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
}
/**
 * @generated from protobuf message ContactsTokenValidateResponse
 */
export interface ContactsTokenValidateResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
    /**
     * @generated from protobuf field: LoginMethod loginMethod = 2;
     */
    loginMethod: LoginMethod;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ContactsRegistrationRequest
 */
export interface ContactsRegistrationRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string firstName = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string lastName = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string phone_number = 4;
     */
    phoneNumber: string;
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string;
    /**
     * @generated from protobuf field: string customerID = 6;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string validation_token = 7;
     */
    validationToken: string;
}
/**
 * @generated from protobuf message ContactsSocialRegistrationRequest
 */
export interface ContactsSocialRegistrationRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string social_user_id = 2;
     */
    socialUserId: string;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ContactsLicenseRequest
 */
export interface ContactsLicenseRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool isLicensed = 2;
     */
    isLicensed: boolean;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
}
/**
 * @generated from protobuf message ContactsLicenseResponse
 */
export interface ContactsLicenseResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message LocationPlace
 */
export interface LocationPlace {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: LocationType type = 3;
     */
    type: LocationType;
}
/**
 * @generated from protobuf message CreateTeamRequest
 */
export interface CreateTeamRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: repeated LocationPlace location = 3;
     */
    location: LocationPlace[];
    /**
     * @generated from protobuf field: repeated string members = 4;
     */
    members: string[];
}
/**
 * @generated from protobuf message CreateTeamResponse
 */
export interface CreateTeamResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message TeamMember
 */
export interface TeamMember {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string displayName = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
}
/**
 * @generated from protobuf message Team
 */
export interface Team {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
    /**
     * @generated from protobuf field: repeated TeamMember members = 4;
     */
    members: TeamMember[];
    /**
     * @generated from protobuf field: repeated LocationPlace location = 5;
     */
    location: LocationPlace[];
}
/**
 * @generated from protobuf message TeamListRequest
 */
export interface TeamListRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message TeamListResponse
 */
export interface TeamListResponse {
    /**
     * @generated from protobuf field: repeated Team teams = 1;
     */
    teams: Team[];
}
/**
 * @generated from protobuf message TeamUpdateRequest
 */
export interface TeamUpdateRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
    /**
     * @generated from protobuf field: repeated string members = 4;
     */
    members: string[];
    /**
     * @generated from protobuf field: repeated LocationPlace location = 5;
     */
    location: LocationPlace[];
}
/**
 * @generated from protobuf message TeamUpdateResponse
 */
export interface TeamUpdateResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message TeamDeleteRequest
 */
export interface TeamDeleteRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message TeamDeleteResponse
 */
export interface TeamDeleteResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message DeactivateSocialAccountsRequest
 */
export interface DeactivateSocialAccountsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message DeactivateSocialAccountsResponse
 */
export interface DeactivateSocialAccountsResponse {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: int32 totalDeactivatedAccounts = 2;
     */
    totalDeactivatedAccounts: number;
}
/**
 * @generated from protobuf message ActivateSocialAccountsRequest
 */
export interface ActivateSocialAccountsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message ActivateSocialAccountsResponse
 */
export interface ActivateSocialAccountsResponse {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: int32 totalActivatedAccounts = 2;
     */
    totalActivatedAccounts: number;
}
/**
 * @generated from protobuf message ListIntegrationGroupsMembersRequest
 */
export interface ListIntegrationGroupsMembersRequest {
    /**
     * @generated from protobuf field: string providerID = 1;
     */
    providerID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: int32 limit = 3;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated SyncStatus filter = 4;
     */
    filter: SyncStatus[];
}
/**
 * @generated from protobuf message PaginatedListIntegrationGroupsMembersRequest
 */
export interface PaginatedListIntegrationGroupsMembersRequest {
    /**
     * @generated from protobuf field: string providerID = 1;
     */
    providerID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: int32 limit = 3;
     */
    limit: number;
    /**
     * @generated from protobuf field: string offsetToken = 4;
     */
    offsetToken: string;
    /**
     * @generated from protobuf field: repeated SyncStatus filter = 5;
     */
    filter: SyncStatus[];
    /**
     * @generated from protobuf field: string search = 6;
     */
    search: string;
}
/**
 * @generated from protobuf message IntegraionGroup
 */
export interface IntegraionGroup {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
}
/**
 * @generated from protobuf message IntegrationGroupMember
 */
export interface IntegrationGroupMember {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string role = 4;
     */
    role: string;
    /**
     * @generated from protobuf field: SyncStatus status = 5;
     */
    status: SyncStatus;
    /**
     * @generated from protobuf field: repeated IntegraionGroup groups = 6;
     */
    groups: IntegraionGroup[];
    /**
     * @generated from protobuf field: LoginMethod loginMethod = 7;
     */
    loginMethod: LoginMethod;
    /**
     * @generated from protobuf field: string org_unit_path = 8;
     */
    orgUnitPath: string;
}
/**
 * @generated from protobuf message ListIntegrationGroupsMembersResponse
 */
export interface ListIntegrationGroupsMembersResponse {
    /**
     * @generated from protobuf field: repeated IntegrationGroupMember members = 1;
     */
    members: IntegrationGroupMember[];
    /**
     * @generated from protobuf field: int64 total_new = 2;
     */
    totalNew: number;
    /**
     * @generated from protobuf field: int64 total_removed = 3;
     */
    totalRemoved: number;
    /**
     * @generated from protobuf field: int64 total_updated = 4;
     */
    totalUpdated: number;
}
/**
 * @generated from protobuf message PaginatedListIntegrationGroupsMembersResponse
 */
export interface PaginatedListIntegrationGroupsMembersResponse {
    /**
     * @generated from protobuf field: repeated IntegrationGroupMember members = 1;
     */
    members: IntegrationGroupMember[];
    /**
     * @generated from protobuf field: int64 total_new = 2;
     */
    totalNew: number;
    /**
     * @generated from protobuf field: int64 total_removed = 3;
     */
    totalRemoved: number;
    /**
     * @generated from protobuf field: int64 total_updated = 4;
     */
    totalUpdated: number;
    /**
     * @generated from protobuf field: string offsetToken = 5;
     */
    offsetToken: string;
}
/**
 * @generated from protobuf message SyncIntegrationGroupsMembersRequest
 */
export interface SyncIntegrationGroupsMembersRequest {
    /**
     * @generated from protobuf field: string providerID = 1;
     */
    providerID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: repeated IntegrationGroupMember members = 3;
     */
    members: IntegrationGroupMember[];
    /**
     * @generated from protobuf field: bool syncAll = 4;
     */
    syncAll: boolean;
    /**
     * @generated from protobuf field: LoginMethod loginMethod = 5;
     */
    loginMethod: LoginMethod;
}
/**
 * @generated from protobuf message SyncIntegrationGroupsMembersResponse
 */
export interface SyncIntegrationGroupsMembersResponse {
    /**
     * @generated from protobuf field: repeated string addedMembers = 1;
     */
    addedMembers: string[];
    /**
     * @generated from protobuf field: repeated string removedMembers = 2;
     */
    removedMembers: string[];
    /**
     * @generated from protobuf field: repeated string updatedMembers = 3;
     */
    updatedMembers: string[];
    /**
     * @generated from protobuf field: repeated string errors = 4;
     */
    errors: string[];
}
/**
 * @generated from protobuf message CreateAutoSyncRequest
 */
export interface CreateAutoSyncRequest {
    /**
     * @generated from protobuf field: string providerID = 1;
     */
    providerID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: LoginMethod loginMethod = 3;
     */
    loginMethod: LoginMethod;
}
/**
 * @generated from protobuf message DeleteAutoSyncRequest
 */
export interface DeleteAutoSyncRequest {
    /**
     * @generated from protobuf field: string providerID = 1;
     */
    providerID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message GetAutoSyncRequest
 */
export interface GetAutoSyncRequest {
    /**
     * @generated from protobuf field: string providerID = 1;
     */
    providerID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message GetAutoSyncResponse
 */
export interface GetAutoSyncResponse {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: LoginMethod loginMethod = 2;
     */
    loginMethod: LoginMethod;
    /**
     * @generated from protobuf field: string providerID = 3;
     */
    providerID: string;
    /**
     * @generated from protobuf field: string customerID = 4;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string providerType = 5;
     */
    providerType: string;
}
/**
 * @generated from protobuf enum ListPeopleFilter
 */
export enum ListPeopleFilter {
    /**
     * @generated from protobuf enum value: ALL = 0;
     */
    ALL = 0,
    /**
     * @generated from protobuf enum value: CONTACTS = 1;
     */
    CONTACTS = 1
}
/**
 * @generated from protobuf enum NullableBoolean
 */
export enum NullableBoolean {
    /**
     * @generated from protobuf enum value: NULL = 0;
     */
    NULL = 0,
    /**
     * @generated from protobuf enum value: TRUE = 1;
     */
    TRUE = 1,
    /**
     * @generated from protobuf enum value: FALSE = 2;
     */
    FALSE = 2
}
/**
 * @generated from protobuf enum LocationType
 */
export enum LocationType {
    /**
     * @generated from protobuf enum value: All = 0;
     */
    All = 0,
    /**
     * @generated from protobuf enum value: Company = 1;
     */
    Company = 1,
    /**
     * @generated from protobuf enum value: Site = 2;
     */
    Site = 2,
    /**
     * @generated from protobuf enum value: Building = 3;
     */
    Building = 3,
    /**
     * @generated from protobuf enum value: Floor = 4;
     */
    Floor = 4,
    /**
     * @generated from protobuf enum value: Room = 5;
     */
    Room = 5,
    /**
     * @generated from protobuf enum value: Desk = 6;
     */
    Desk = 6
}
/**
 * @generated from protobuf enum SyncStatus
 */
export enum SyncStatus {
    /**
     * @generated from protobuf enum value: UNKNOWN_SYNC_STATUS = 0;
     */
    UNKNOWN_SYNC_STATUS = 0,
    /**
     * @generated from protobuf enum value: NEW = 1;
     */
    NEW = 1,
    /**
     * @generated from protobuf enum value: REMOVED = 2;
     */
    REMOVED = 2,
    /**
     * @generated from protobuf enum value: UPDATED = 3;
     */
    UPDATED = 3,
    /**
     * @generated from protobuf enum value: NO_CHANGES = 4;
     */
    NO_CHANGES = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class ListPeopleRequest$Type extends MessageType<ListPeopleRequest> {
    constructor() {
        super("ListPeopleRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "personalCalendarID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "filter", kind: "enum", T: () => ["ListPeopleFilter", ListPeopleFilter] }
        ]);
    }
    create(value?: PartialMessage<ListPeopleRequest>): ListPeopleRequest {
        const message = { customerID: "", accountID: "", personalCalendarID: "", query: "", filter: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListPeopleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPeopleRequest): ListPeopleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 2:
                    message.accountID = reader.string();
                    break;
                case /* string personalCalendarID */ 3:
                    message.personalCalendarID = reader.string();
                    break;
                case /* string query */ 4:
                    message.query = reader.string();
                    break;
                case /* ListPeopleFilter filter */ 5:
                    message.filter = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPeopleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 2; */
        if (message.accountID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountID);
        /* string personalCalendarID = 3; */
        if (message.personalCalendarID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.personalCalendarID);
        /* string query = 4; */
        if (message.query !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.query);
        /* ListPeopleFilter filter = 5; */
        if (message.filter !== 0)
            writer.tag(5, WireType.Varint).int32(message.filter);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListPeopleRequest
 */
export const ListPeopleRequest = new ListPeopleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class People$Type extends MessageType<People> {
    constructor() {
        super("People", [
            { no: 1, name: "people", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Person }
        ]);
    }
    create(value?: PartialMessage<People>): People {
        const message = { people: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<People>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: People): People {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Person people */ 1:
                    message.people.push(Person.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: People, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Person people = 1; */
        for (let i = 0; i < message.people.length; i++)
            Person.internalBinaryWrite(message.people[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message People
 */
export const People = new People$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Person$Type extends MessageType<Person> {
    constructor() {
        super("Person", [
            { no: 1, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "avatar", kind: "message", T: () => Avatar },
            { no: 4, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Person>): Person {
        const message = { displayName: "", email: "", accountID: "", customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Person>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Person): Person {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string displayName */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* ggevent.Avatar avatar */ 3:
                    message.avatar = Avatar.internalBinaryRead(reader, reader.uint32(), options, message.avatar);
                    break;
                case /* string accountID */ 4:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 5:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Person, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string displayName = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* ggevent.Avatar avatar = 3; */
        if (message.avatar)
            Avatar.internalBinaryWrite(message.avatar, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string accountID = 4; */
        if (message.accountID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 5; */
        if (message.customerID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Person
 */
export const Person = new Person$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contact$Type extends MessageType<Contact> {
    constructor() {
        super("Contact", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "firstName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "lastName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "role", kind: "enum", T: () => ["Role", Role] },
            { no: 6, name: "status", kind: "enum", T: () => ["Status", Status] },
            { no: 7, name: "licensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "teamID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeskObject },
            { no: 11, name: "loginMethod", kind: "enum", T: () => ["LoginMethod", LoginMethod, "LOGIN_METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<Contact>): Contact {
        const message = { id: "", email: "", firstName: "", lastName: "", role: 0, status: 0, licensed: false, customerID: "", teamID: 0, desks: [], loginMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Contact>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Contact): Contact {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string firstName */ 3:
                    message.firstName = reader.string();
                    break;
                case /* string lastName */ 4:
                    message.lastName = reader.string();
                    break;
                case /* Role role */ 5:
                    message.role = reader.int32();
                    break;
                case /* Status status */ 6:
                    message.status = reader.int32();
                    break;
                case /* bool licensed */ 7:
                    message.licensed = reader.bool();
                    break;
                case /* string customerID */ 8:
                    message.customerID = reader.string();
                    break;
                case /* int32 teamID */ 9:
                    message.teamID = reader.int32();
                    break;
                case /* repeated DeskObject desks */ 10:
                    message.desks.push(DeskObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* LoginMethod loginMethod */ 11:
                    message.loginMethod = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Contact, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string firstName = 3; */
        if (message.firstName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.firstName);
        /* string lastName = 4; */
        if (message.lastName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastName);
        /* Role role = 5; */
        if (message.role !== 0)
            writer.tag(5, WireType.Varint).int32(message.role);
        /* Status status = 6; */
        if (message.status !== 0)
            writer.tag(6, WireType.Varint).int32(message.status);
        /* bool licensed = 7; */
        if (message.licensed !== false)
            writer.tag(7, WireType.Varint).bool(message.licensed);
        /* string customerID = 8; */
        if (message.customerID !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customerID);
        /* int32 teamID = 9; */
        if (message.teamID !== 0)
            writer.tag(9, WireType.Varint).int32(message.teamID);
        /* repeated DeskObject desks = 10; */
        for (let i = 0; i < message.desks.length; i++)
            DeskObject.internalBinaryWrite(message.desks[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* LoginMethod loginMethod = 11; */
        if (message.loginMethod !== 0)
            writer.tag(11, WireType.Varint).int32(message.loginMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Contact
 */
export const Contact = new Contact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskObject$Type extends MessageType<DeskObject> {
    constructor() {
        super("DeskObject", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deskName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeskObject>): DeskObject {
        const message = { iD: "", deskName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeskObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskObject): DeskObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.string();
                    break;
                case /* string deskName */ 2:
                    message.deskName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.iD);
        /* string deskName = 2; */
        if (message.deskName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deskName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskObject
 */
export const DeskObject = new DeskObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsListRequest$Type extends MessageType<ContactsListRequest> {
    constructor() {
        super("ContactsListRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "licensed", kind: "enum", T: () => ["NullableBoolean", NullableBoolean] },
            { no: 6, name: "role", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["Role", Role] },
            { no: 7, name: "status", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["Status", Status] },
            { no: 8, name: "desks", kind: "enum", T: () => ["NullableBoolean", NullableBoolean] }
        ]);
    }
    create(value?: PartialMessage<ContactsListRequest>): ContactsListRequest {
        const message = { page: 0, pageSize: 0, customerID: "", name: "", licensed: 0, role: [], status: [], desks: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsListRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsListRequest): ContactsListRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* NullableBoolean licensed */ 5:
                    message.licensed = reader.int32();
                    break;
                case /* repeated Role role */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.role.push(reader.int32());
                    else
                        message.role.push(reader.int32());
                    break;
                case /* repeated Status status */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.status.push(reader.int32());
                    else
                        message.status.push(reader.int32());
                    break;
                case /* NullableBoolean desks */ 8:
                    message.desks = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsListRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* NullableBoolean licensed = 5; */
        if (message.licensed !== 0)
            writer.tag(5, WireType.Varint).int32(message.licensed);
        /* repeated Role role = 6; */
        if (message.role.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.role.length; i++)
                writer.int32(message.role[i]);
            writer.join();
        }
        /* repeated Status status = 7; */
        if (message.status.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.status.length; i++)
                writer.int32(message.status[i]);
            writer.join();
        }
        /* NullableBoolean desks = 8; */
        if (message.desks !== 0)
            writer.tag(8, WireType.Varint).int32(message.desks);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsListRequest
 */
export const ContactsListRequest = new ContactsListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsListResponse$Type extends MessageType<ContactsListResponse> {
    constructor() {
        super("ContactsListResponse", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "PagesCount", kind: "scalar", jsonName: "PagesCount", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "contacts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Contact }
        ]);
    }
    create(value?: PartialMessage<ContactsListResponse>): ContactsListResponse {
        const message = { page: 0, pageSize: 0, pagesCount: 0, contacts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsListResponse): ContactsListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* int32 PagesCount = 3 [json_name = "PagesCount"];*/ 3:
                    message.pagesCount = reader.int32();
                    break;
                case /* repeated Contact contacts */ 4:
                    message.contacts.push(Contact.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* int32 PagesCount = 3 [json_name = "PagesCount"]; */
        if (message.pagesCount !== 0)
            writer.tag(3, WireType.Varint).int32(message.pagesCount);
        /* repeated Contact contacts = 4; */
        for (let i = 0; i < message.contacts.length; i++)
            Contact.internalBinaryWrite(message.contacts[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsListResponse
 */
export const ContactsListResponse = new ContactsListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsInviteRequest$Type extends MessageType<ContactsInviteRequest> {
    constructor() {
        super("ContactsInviteRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role", kind: "enum", T: () => ["Role", Role] },
            { no: 4, name: "teamID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "deskID", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "loginMethod", kind: "enum", T: () => ["LoginMethod", LoginMethod, "LOGIN_METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<ContactsInviteRequest>): ContactsInviteRequest {
        const message = { customerID: "", email: "", role: 0, teamID: 0, deskID: [], loginMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsInviteRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsInviteRequest): ContactsInviteRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* Role role */ 3:
                    message.role = reader.int32();
                    break;
                case /* int32 teamID */ 4:
                    message.teamID = reader.int32();
                    break;
                case /* repeated string deskID */ 5:
                    message.deskID.push(reader.string());
                    break;
                case /* LoginMethod loginMethod */ 6:
                    message.loginMethod = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsInviteRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* Role role = 3; */
        if (message.role !== 0)
            writer.tag(3, WireType.Varint).int32(message.role);
        /* int32 teamID = 4; */
        if (message.teamID !== 0)
            writer.tag(4, WireType.Varint).int32(message.teamID);
        /* repeated string deskID = 5; */
        for (let i = 0; i < message.deskID.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.deskID[i]);
        /* LoginMethod loginMethod = 6; */
        if (message.loginMethod !== 0)
            writer.tag(6, WireType.Varint).int32(message.loginMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsInviteRequest
 */
export const ContactsInviteRequest = new ContactsInviteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsInviteResponse$Type extends MessageType<ContactsInviteResponse> {
    constructor() {
        super("ContactsInviteResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsInviteResponse>): ContactsInviteResponse {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsInviteResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsInviteResponse): ContactsInviteResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsInviteResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsInviteResponse
 */
export const ContactsInviteResponse = new ContactsInviteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsUpdateRequest$Type extends MessageType<ContactsUpdateRequest> {
    constructor() {
        super("ContactsUpdateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "firstName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "lastName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "role", kind: "enum", T: () => ["Role", Role] },
            { no: 5, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "teamID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "deskID", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsUpdateRequest>): ContactsUpdateRequest {
        const message = { id: "", firstName: "", lastName: "", role: 0, customerID: "", teamID: 0, deskID: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsUpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsUpdateRequest): ContactsUpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string firstName */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string lastName */ 3:
                    message.lastName = reader.string();
                    break;
                case /* Role role */ 4:
                    message.role = reader.int32();
                    break;
                case /* string customerID */ 5:
                    message.customerID = reader.string();
                    break;
                case /* int32 teamID */ 6:
                    message.teamID = reader.int32();
                    break;
                case /* repeated string deskID */ 7:
                    message.deskID.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsUpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string firstName = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string lastName = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* Role role = 4; */
        if (message.role !== 0)
            writer.tag(4, WireType.Varint).int32(message.role);
        /* string customerID = 5; */
        if (message.customerID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerID);
        /* int32 teamID = 6; */
        if (message.teamID !== 0)
            writer.tag(6, WireType.Varint).int32(message.teamID);
        /* repeated string deskID = 7; */
        for (let i = 0; i < message.deskID.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.deskID[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsUpdateRequest
 */
export const ContactsUpdateRequest = new ContactsUpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsUpdateStatusRequest$Type extends MessageType<ContactsUpdateStatusRequest> {
    constructor() {
        super("ContactsUpdateStatusRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "enum", T: () => ["Status", Status] },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsUpdateStatusRequest>): ContactsUpdateStatusRequest {
        const message = { id: "", status: 0, customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsUpdateStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsUpdateStatusRequest): ContactsUpdateStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* Status status */ 2:
                    message.status = reader.int32();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsUpdateStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* Status status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsUpdateStatusRequest
 */
export const ContactsUpdateStatusRequest = new ContactsUpdateStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsRequest$Type extends MessageType<ContactsRequest> {
    constructor() {
        super("ContactsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsRequest>): ContactsRequest {
        const message = { id: "", customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsRequest): ContactsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsRequest
 */
export const ContactsRequest = new ContactsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactResponse$Type extends MessageType<ContactResponse> {
    constructor() {
        super("ContactResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactResponse>): ContactResponse {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactResponse): ContactResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactResponse
 */
export const ContactResponse = new ContactResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsTokenValidateRequest$Type extends MessageType<ContactsTokenValidateRequest> {
    constructor() {
        super("ContactsTokenValidateRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsTokenValidateRequest>): ContactsTokenValidateRequest {
        const message = { email: "", token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsTokenValidateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsTokenValidateRequest): ContactsTokenValidateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsTokenValidateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsTokenValidateRequest
 */
export const ContactsTokenValidateRequest = new ContactsTokenValidateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsTokenValidateResponse$Type extends MessageType<ContactsTokenValidateResponse> {
    constructor() {
        super("ContactsTokenValidateResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "loginMethod", kind: "enum", T: () => ["LoginMethod", LoginMethod, "LOGIN_METHOD_"] },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsTokenValidateResponse>): ContactsTokenValidateResponse {
        const message = { message: "", loginMethod: 0, customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsTokenValidateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsTokenValidateResponse): ContactsTokenValidateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                case /* LoginMethod loginMethod */ 2:
                    message.loginMethod = reader.int32();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsTokenValidateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        /* LoginMethod loginMethod = 2; */
        if (message.loginMethod !== 0)
            writer.tag(2, WireType.Varint).int32(message.loginMethod);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsTokenValidateResponse
 */
export const ContactsTokenValidateResponse = new ContactsTokenValidateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsRegistrationRequest$Type extends MessageType<ContactsRegistrationRequest> {
    constructor() {
        super("ContactsRegistrationRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "firstName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "lastName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "validation_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsRegistrationRequest>): ContactsRegistrationRequest {
        const message = { email: "", firstName: "", lastName: "", phoneNumber: "", password: "", customerID: "", validationToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsRegistrationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsRegistrationRequest): ContactsRegistrationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string firstName */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string lastName */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string phone_number */ 4:
                    message.phoneNumber = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                case /* string customerID */ 6:
                    message.customerID = reader.string();
                    break;
                case /* string validation_token */ 7:
                    message.validationToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsRegistrationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string firstName = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string lastName = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string phone_number = 4; */
        if (message.phoneNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phoneNumber);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        /* string customerID = 6; */
        if (message.customerID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.customerID);
        /* string validation_token = 7; */
        if (message.validationToken !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.validationToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsRegistrationRequest
 */
export const ContactsRegistrationRequest = new ContactsRegistrationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsSocialRegistrationRequest$Type extends MessageType<ContactsSocialRegistrationRequest> {
    constructor() {
        super("ContactsSocialRegistrationRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "social_user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsSocialRegistrationRequest>): ContactsSocialRegistrationRequest {
        const message = { email: "", socialUserId: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsSocialRegistrationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsSocialRegistrationRequest): ContactsSocialRegistrationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string social_user_id */ 2:
                    message.socialUserId = reader.string();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsSocialRegistrationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string social_user_id = 2; */
        if (message.socialUserId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.socialUserId);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsSocialRegistrationRequest
 */
export const ContactsSocialRegistrationRequest = new ContactsSocialRegistrationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsLicenseRequest$Type extends MessageType<ContactsLicenseRequest> {
    constructor() {
        super("ContactsLicenseRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isLicensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsLicenseRequest>): ContactsLicenseRequest {
        const message = { id: "", isLicensed: false, customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsLicenseRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsLicenseRequest): ContactsLicenseRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool isLicensed */ 2:
                    message.isLicensed = reader.bool();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsLicenseRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool isLicensed = 2; */
        if (message.isLicensed !== false)
            writer.tag(2, WireType.Varint).bool(message.isLicensed);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsLicenseRequest
 */
export const ContactsLicenseRequest = new ContactsLicenseRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactsLicenseResponse$Type extends MessageType<ContactsLicenseResponse> {
    constructor() {
        super("ContactsLicenseResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactsLicenseResponse>): ContactsLicenseResponse {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactsLicenseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactsLicenseResponse): ContactsLicenseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactsLicenseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactsLicenseResponse
 */
export const ContactsLicenseResponse = new ContactsLicenseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationPlace$Type extends MessageType<LocationPlace> {
    constructor() {
        super("LocationPlace", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["LocationType", LocationType] }
        ]);
    }
    create(value?: PartialMessage<LocationPlace>): LocationPlace {
        const message = { id: "", name: "", type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationPlace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationPlace): LocationPlace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* LocationType type */ 3:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationPlace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* LocationType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LocationPlace
 */
export const LocationPlace = new LocationPlace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateTeamRequest$Type extends MessageType<CreateTeamRequest> {
    constructor() {
        super("CreateTeamRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "location", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationPlace },
            { no: 4, name: "members", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateTeamRequest>): CreateTeamRequest {
        const message = { name: "", customerID: "", location: [], members: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateTeamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateTeamRequest): CreateTeamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* repeated LocationPlace location */ 3:
                    message.location.push(LocationPlace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string members */ 4:
                    message.members.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateTeamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* repeated LocationPlace location = 3; */
        for (let i = 0; i < message.location.length; i++)
            LocationPlace.internalBinaryWrite(message.location[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string members = 4; */
        for (let i = 0; i < message.members.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.members[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateTeamRequest
 */
export const CreateTeamRequest = new CreateTeamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateTeamResponse$Type extends MessageType<CreateTeamResponse> {
    constructor() {
        super("CreateTeamResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateTeamResponse>): CreateTeamResponse {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateTeamResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateTeamResponse): CreateTeamResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateTeamResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateTeamResponse
 */
export const CreateTeamResponse = new CreateTeamResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamMember$Type extends MessageType<TeamMember> {
    constructor() {
        super("TeamMember", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TeamMember>): TeamMember {
        const message = { id: "", displayName: "", email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TeamMember>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TeamMember): TeamMember {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string displayName */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TeamMember, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string displayName = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamMember
 */
export const TeamMember = new TeamMember$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Team$Type extends MessageType<Team> {
    constructor() {
        super("Team", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "members", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TeamMember },
            { no: 5, name: "location", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationPlace }
        ]);
    }
    create(value?: PartialMessage<Team>): Team {
        const message = { id: 0, name: "", customerID: "", members: [], location: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Team>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Team): Team {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                case /* repeated TeamMember members */ 4:
                    message.members.push(TeamMember.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated LocationPlace location */ 5:
                    message.location.push(LocationPlace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Team, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        /* repeated TeamMember members = 4; */
        for (let i = 0; i < message.members.length; i++)
            TeamMember.internalBinaryWrite(message.members[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated LocationPlace location = 5; */
        for (let i = 0; i < message.location.length; i++)
            LocationPlace.internalBinaryWrite(message.location[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Team
 */
export const Team = new Team$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamListRequest$Type extends MessageType<TeamListRequest> {
    constructor() {
        super("TeamListRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TeamListRequest>): TeamListRequest {
        const message = { customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TeamListRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TeamListRequest): TeamListRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TeamListRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamListRequest
 */
export const TeamListRequest = new TeamListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamListResponse$Type extends MessageType<TeamListResponse> {
    constructor() {
        super("TeamListResponse", [
            { no: 1, name: "teams", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Team }
        ]);
    }
    create(value?: PartialMessage<TeamListResponse>): TeamListResponse {
        const message = { teams: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TeamListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TeamListResponse): TeamListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Team teams */ 1:
                    message.teams.push(Team.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TeamListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Team teams = 1; */
        for (let i = 0; i < message.teams.length; i++)
            Team.internalBinaryWrite(message.teams[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamListResponse
 */
export const TeamListResponse = new TeamListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamUpdateRequest$Type extends MessageType<TeamUpdateRequest> {
    constructor() {
        super("TeamUpdateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "members", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "location", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationPlace }
        ]);
    }
    create(value?: PartialMessage<TeamUpdateRequest>): TeamUpdateRequest {
        const message = { id: 0, name: "", customerID: "", members: [], location: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TeamUpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TeamUpdateRequest): TeamUpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                case /* repeated string members */ 4:
                    message.members.push(reader.string());
                    break;
                case /* repeated LocationPlace location */ 5:
                    message.location.push(LocationPlace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TeamUpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        /* repeated string members = 4; */
        for (let i = 0; i < message.members.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.members[i]);
        /* repeated LocationPlace location = 5; */
        for (let i = 0; i < message.location.length; i++)
            LocationPlace.internalBinaryWrite(message.location[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamUpdateRequest
 */
export const TeamUpdateRequest = new TeamUpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamUpdateResponse$Type extends MessageType<TeamUpdateResponse> {
    constructor() {
        super("TeamUpdateResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TeamUpdateResponse>): TeamUpdateResponse {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TeamUpdateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TeamUpdateResponse): TeamUpdateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TeamUpdateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamUpdateResponse
 */
export const TeamUpdateResponse = new TeamUpdateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamDeleteRequest$Type extends MessageType<TeamDeleteRequest> {
    constructor() {
        super("TeamDeleteRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TeamDeleteRequest>): TeamDeleteRequest {
        const message = { id: 0, customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TeamDeleteRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TeamDeleteRequest): TeamDeleteRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TeamDeleteRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamDeleteRequest
 */
export const TeamDeleteRequest = new TeamDeleteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeamDeleteResponse$Type extends MessageType<TeamDeleteResponse> {
    constructor() {
        super("TeamDeleteResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TeamDeleteResponse>): TeamDeleteResponse {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TeamDeleteResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TeamDeleteResponse): TeamDeleteResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TeamDeleteResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TeamDeleteResponse
 */
export const TeamDeleteResponse = new TeamDeleteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateSocialAccountsRequest$Type extends MessageType<DeactivateSocialAccountsRequest> {
    constructor() {
        super("DeactivateSocialAccountsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeactivateSocialAccountsRequest>): DeactivateSocialAccountsRequest {
        const message = { customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateSocialAccountsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateSocialAccountsRequest): DeactivateSocialAccountsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateSocialAccountsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeactivateSocialAccountsRequest
 */
export const DeactivateSocialAccountsRequest = new DeactivateSocialAccountsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateSocialAccountsResponse$Type extends MessageType<DeactivateSocialAccountsResponse> {
    constructor() {
        super("DeactivateSocialAccountsResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "totalDeactivatedAccounts", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DeactivateSocialAccountsResponse>): DeactivateSocialAccountsResponse {
        const message = { customerID: "", totalDeactivatedAccounts: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateSocialAccountsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateSocialAccountsResponse): DeactivateSocialAccountsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* int32 totalDeactivatedAccounts */ 2:
                    message.totalDeactivatedAccounts = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateSocialAccountsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* int32 totalDeactivatedAccounts = 2; */
        if (message.totalDeactivatedAccounts !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalDeactivatedAccounts);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeactivateSocialAccountsResponse
 */
export const DeactivateSocialAccountsResponse = new DeactivateSocialAccountsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateSocialAccountsRequest$Type extends MessageType<ActivateSocialAccountsRequest> {
    constructor() {
        super("ActivateSocialAccountsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ActivateSocialAccountsRequest>): ActivateSocialAccountsRequest {
        const message = { customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActivateSocialAccountsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateSocialAccountsRequest): ActivateSocialAccountsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateSocialAccountsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ActivateSocialAccountsRequest
 */
export const ActivateSocialAccountsRequest = new ActivateSocialAccountsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateSocialAccountsResponse$Type extends MessageType<ActivateSocialAccountsResponse> {
    constructor() {
        super("ActivateSocialAccountsResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "totalActivatedAccounts", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ActivateSocialAccountsResponse>): ActivateSocialAccountsResponse {
        const message = { customerID: "", totalActivatedAccounts: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActivateSocialAccountsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateSocialAccountsResponse): ActivateSocialAccountsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* int32 totalActivatedAccounts */ 2:
                    message.totalActivatedAccounts = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateSocialAccountsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* int32 totalActivatedAccounts = 2; */
        if (message.totalActivatedAccounts !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalActivatedAccounts);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ActivateSocialAccountsResponse
 */
export const ActivateSocialAccountsResponse = new ActivateSocialAccountsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIntegrationGroupsMembersRequest$Type extends MessageType<ListIntegrationGroupsMembersRequest> {
    constructor() {
        super("ListIntegrationGroupsMembersRequest", [
            { no: 1, name: "providerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["SyncStatus", SyncStatus] }
        ]);
    }
    create(value?: PartialMessage<ListIntegrationGroupsMembersRequest>): ListIntegrationGroupsMembersRequest {
        const message = { providerID: "", customerID: "", limit: 0, filter: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListIntegrationGroupsMembersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIntegrationGroupsMembersRequest): ListIntegrationGroupsMembersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string providerID */ 1:
                    message.providerID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* int32 limit */ 3:
                    message.limit = reader.int32();
                    break;
                case /* repeated SyncStatus filter */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.filter.push(reader.int32());
                    else
                        message.filter.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListIntegrationGroupsMembersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string providerID = 1; */
        if (message.providerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.providerID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* int32 limit = 3; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        /* repeated SyncStatus filter = 4; */
        if (message.filter.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.filter.length; i++)
                writer.int32(message.filter[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListIntegrationGroupsMembersRequest
 */
export const ListIntegrationGroupsMembersRequest = new ListIntegrationGroupsMembersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaginatedListIntegrationGroupsMembersRequest$Type extends MessageType<PaginatedListIntegrationGroupsMembersRequest> {
    constructor() {
        super("PaginatedListIntegrationGroupsMembersRequest", [
            { no: 1, name: "providerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "offsetToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "filter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["SyncStatus", SyncStatus] },
            { no: 6, name: "search", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaginatedListIntegrationGroupsMembersRequest>): PaginatedListIntegrationGroupsMembersRequest {
        const message = { providerID: "", customerID: "", limit: 0, offsetToken: "", filter: [], search: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaginatedListIntegrationGroupsMembersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaginatedListIntegrationGroupsMembersRequest): PaginatedListIntegrationGroupsMembersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string providerID */ 1:
                    message.providerID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* int32 limit */ 3:
                    message.limit = reader.int32();
                    break;
                case /* string offsetToken */ 4:
                    message.offsetToken = reader.string();
                    break;
                case /* repeated SyncStatus filter */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.filter.push(reader.int32());
                    else
                        message.filter.push(reader.int32());
                    break;
                case /* string search */ 6:
                    message.search = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaginatedListIntegrationGroupsMembersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string providerID = 1; */
        if (message.providerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.providerID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* int32 limit = 3; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        /* string offsetToken = 4; */
        if (message.offsetToken !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.offsetToken);
        /* repeated SyncStatus filter = 5; */
        if (message.filter.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.filter.length; i++)
                writer.int32(message.filter[i]);
            writer.join();
        }
        /* string search = 6; */
        if (message.search !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.search);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaginatedListIntegrationGroupsMembersRequest
 */
export const PaginatedListIntegrationGroupsMembersRequest = new PaginatedListIntegrationGroupsMembersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntegraionGroup$Type extends MessageType<IntegraionGroup> {
    constructor() {
        super("IntegraionGroup", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IntegraionGroup>): IntegraionGroup {
        const message = { id: "", name: "", email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IntegraionGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IntegraionGroup): IntegraionGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IntegraionGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IntegraionGroup
 */
export const IntegraionGroup = new IntegraionGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntegrationGroupMember$Type extends MessageType<IntegrationGroupMember> {
    constructor() {
        super("IntegrationGroupMember", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "status", kind: "enum", T: () => ["SyncStatus", SyncStatus] },
            { no: 6, name: "groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IntegraionGroup },
            { no: 7, name: "loginMethod", kind: "enum", T: () => ["LoginMethod", LoginMethod, "LOGIN_METHOD_"] },
            { no: 8, name: "org_unit_path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IntegrationGroupMember>): IntegrationGroupMember {
        const message = { id: "", email: "", name: "", role: "", status: 0, groups: [], loginMethod: 0, orgUnitPath: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IntegrationGroupMember>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IntegrationGroupMember): IntegrationGroupMember {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string role */ 4:
                    message.role = reader.string();
                    break;
                case /* SyncStatus status */ 5:
                    message.status = reader.int32();
                    break;
                case /* repeated IntegraionGroup groups */ 6:
                    message.groups.push(IntegraionGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* LoginMethod loginMethod */ 7:
                    message.loginMethod = reader.int32();
                    break;
                case /* string org_unit_path */ 8:
                    message.orgUnitPath = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IntegrationGroupMember, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string role = 4; */
        if (message.role !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.role);
        /* SyncStatus status = 5; */
        if (message.status !== 0)
            writer.tag(5, WireType.Varint).int32(message.status);
        /* repeated IntegraionGroup groups = 6; */
        for (let i = 0; i < message.groups.length; i++)
            IntegraionGroup.internalBinaryWrite(message.groups[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* LoginMethod loginMethod = 7; */
        if (message.loginMethod !== 0)
            writer.tag(7, WireType.Varint).int32(message.loginMethod);
        /* string org_unit_path = 8; */
        if (message.orgUnitPath !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orgUnitPath);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IntegrationGroupMember
 */
export const IntegrationGroupMember = new IntegrationGroupMember$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIntegrationGroupsMembersResponse$Type extends MessageType<ListIntegrationGroupsMembersResponse> {
    constructor() {
        super("ListIntegrationGroupsMembersResponse", [
            { no: 1, name: "members", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IntegrationGroupMember },
            { no: 2, name: "total_new", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "total_removed", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "total_updated", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListIntegrationGroupsMembersResponse>): ListIntegrationGroupsMembersResponse {
        const message = { members: [], totalNew: 0, totalRemoved: 0, totalUpdated: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListIntegrationGroupsMembersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIntegrationGroupsMembersResponse): ListIntegrationGroupsMembersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated IntegrationGroupMember members */ 1:
                    message.members.push(IntegrationGroupMember.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total_new */ 2:
                    message.totalNew = reader.int64().toNumber();
                    break;
                case /* int64 total_removed */ 3:
                    message.totalRemoved = reader.int64().toNumber();
                    break;
                case /* int64 total_updated */ 4:
                    message.totalUpdated = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListIntegrationGroupsMembersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated IntegrationGroupMember members = 1; */
        for (let i = 0; i < message.members.length; i++)
            IntegrationGroupMember.internalBinaryWrite(message.members[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total_new = 2; */
        if (message.totalNew !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalNew);
        /* int64 total_removed = 3; */
        if (message.totalRemoved !== 0)
            writer.tag(3, WireType.Varint).int64(message.totalRemoved);
        /* int64 total_updated = 4; */
        if (message.totalUpdated !== 0)
            writer.tag(4, WireType.Varint).int64(message.totalUpdated);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListIntegrationGroupsMembersResponse
 */
export const ListIntegrationGroupsMembersResponse = new ListIntegrationGroupsMembersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaginatedListIntegrationGroupsMembersResponse$Type extends MessageType<PaginatedListIntegrationGroupsMembersResponse> {
    constructor() {
        super("PaginatedListIntegrationGroupsMembersResponse", [
            { no: 1, name: "members", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IntegrationGroupMember },
            { no: 2, name: "total_new", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "total_removed", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "total_updated", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "offsetToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaginatedListIntegrationGroupsMembersResponse>): PaginatedListIntegrationGroupsMembersResponse {
        const message = { members: [], totalNew: 0, totalRemoved: 0, totalUpdated: 0, offsetToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaginatedListIntegrationGroupsMembersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaginatedListIntegrationGroupsMembersResponse): PaginatedListIntegrationGroupsMembersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated IntegrationGroupMember members */ 1:
                    message.members.push(IntegrationGroupMember.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total_new */ 2:
                    message.totalNew = reader.int64().toNumber();
                    break;
                case /* int64 total_removed */ 3:
                    message.totalRemoved = reader.int64().toNumber();
                    break;
                case /* int64 total_updated */ 4:
                    message.totalUpdated = reader.int64().toNumber();
                    break;
                case /* string offsetToken */ 5:
                    message.offsetToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaginatedListIntegrationGroupsMembersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated IntegrationGroupMember members = 1; */
        for (let i = 0; i < message.members.length; i++)
            IntegrationGroupMember.internalBinaryWrite(message.members[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total_new = 2; */
        if (message.totalNew !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalNew);
        /* int64 total_removed = 3; */
        if (message.totalRemoved !== 0)
            writer.tag(3, WireType.Varint).int64(message.totalRemoved);
        /* int64 total_updated = 4; */
        if (message.totalUpdated !== 0)
            writer.tag(4, WireType.Varint).int64(message.totalUpdated);
        /* string offsetToken = 5; */
        if (message.offsetToken !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.offsetToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaginatedListIntegrationGroupsMembersResponse
 */
export const PaginatedListIntegrationGroupsMembersResponse = new PaginatedListIntegrationGroupsMembersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncIntegrationGroupsMembersRequest$Type extends MessageType<SyncIntegrationGroupsMembersRequest> {
    constructor() {
        super("SyncIntegrationGroupsMembersRequest", [
            { no: 1, name: "providerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "members", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IntegrationGroupMember },
            { no: 4, name: "syncAll", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "loginMethod", kind: "enum", T: () => ["LoginMethod", LoginMethod, "LOGIN_METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<SyncIntegrationGroupsMembersRequest>): SyncIntegrationGroupsMembersRequest {
        const message = { providerID: "", customerID: "", members: [], syncAll: false, loginMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncIntegrationGroupsMembersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncIntegrationGroupsMembersRequest): SyncIntegrationGroupsMembersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string providerID */ 1:
                    message.providerID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* repeated IntegrationGroupMember members */ 3:
                    message.members.push(IntegrationGroupMember.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool syncAll */ 4:
                    message.syncAll = reader.bool();
                    break;
                case /* LoginMethod loginMethod */ 5:
                    message.loginMethod = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncIntegrationGroupsMembersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string providerID = 1; */
        if (message.providerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.providerID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* repeated IntegrationGroupMember members = 3; */
        for (let i = 0; i < message.members.length; i++)
            IntegrationGroupMember.internalBinaryWrite(message.members[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool syncAll = 4; */
        if (message.syncAll !== false)
            writer.tag(4, WireType.Varint).bool(message.syncAll);
        /* LoginMethod loginMethod = 5; */
        if (message.loginMethod !== 0)
            writer.tag(5, WireType.Varint).int32(message.loginMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SyncIntegrationGroupsMembersRequest
 */
export const SyncIntegrationGroupsMembersRequest = new SyncIntegrationGroupsMembersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncIntegrationGroupsMembersResponse$Type extends MessageType<SyncIntegrationGroupsMembersResponse> {
    constructor() {
        super("SyncIntegrationGroupsMembersResponse", [
            { no: 1, name: "addedMembers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "removedMembers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "updatedMembers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SyncIntegrationGroupsMembersResponse>): SyncIntegrationGroupsMembersResponse {
        const message = { addedMembers: [], removedMembers: [], updatedMembers: [], errors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncIntegrationGroupsMembersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncIntegrationGroupsMembersResponse): SyncIntegrationGroupsMembersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string addedMembers */ 1:
                    message.addedMembers.push(reader.string());
                    break;
                case /* repeated string removedMembers */ 2:
                    message.removedMembers.push(reader.string());
                    break;
                case /* repeated string updatedMembers */ 3:
                    message.updatedMembers.push(reader.string());
                    break;
                case /* repeated string errors */ 4:
                    message.errors.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncIntegrationGroupsMembersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string addedMembers = 1; */
        for (let i = 0; i < message.addedMembers.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.addedMembers[i]);
        /* repeated string removedMembers = 2; */
        for (let i = 0; i < message.removedMembers.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.removedMembers[i]);
        /* repeated string updatedMembers = 3; */
        for (let i = 0; i < message.updatedMembers.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.updatedMembers[i]);
        /* repeated string errors = 4; */
        for (let i = 0; i < message.errors.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.errors[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SyncIntegrationGroupsMembersResponse
 */
export const SyncIntegrationGroupsMembersResponse = new SyncIntegrationGroupsMembersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAutoSyncRequest$Type extends MessageType<CreateAutoSyncRequest> {
    constructor() {
        super("CreateAutoSyncRequest", [
            { no: 1, name: "providerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "loginMethod", kind: "enum", T: () => ["LoginMethod", LoginMethod, "LOGIN_METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<CreateAutoSyncRequest>): CreateAutoSyncRequest {
        const message = { providerID: "", customerID: "", loginMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateAutoSyncRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAutoSyncRequest): CreateAutoSyncRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string providerID */ 1:
                    message.providerID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* LoginMethod loginMethod */ 3:
                    message.loginMethod = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAutoSyncRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string providerID = 1; */
        if (message.providerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.providerID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* LoginMethod loginMethod = 3; */
        if (message.loginMethod !== 0)
            writer.tag(3, WireType.Varint).int32(message.loginMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateAutoSyncRequest
 */
export const CreateAutoSyncRequest = new CreateAutoSyncRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAutoSyncRequest$Type extends MessageType<DeleteAutoSyncRequest> {
    constructor() {
        super("DeleteAutoSyncRequest", [
            { no: 1, name: "providerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteAutoSyncRequest>): DeleteAutoSyncRequest {
        const message = { providerID: "", customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteAutoSyncRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAutoSyncRequest): DeleteAutoSyncRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string providerID */ 1:
                    message.providerID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAutoSyncRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string providerID = 1; */
        if (message.providerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.providerID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteAutoSyncRequest
 */
export const DeleteAutoSyncRequest = new DeleteAutoSyncRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAutoSyncRequest$Type extends MessageType<GetAutoSyncRequest> {
    constructor() {
        super("GetAutoSyncRequest", [
            { no: 1, name: "providerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAutoSyncRequest>): GetAutoSyncRequest {
        const message = { providerID: "", customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAutoSyncRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAutoSyncRequest): GetAutoSyncRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string providerID */ 1:
                    message.providerID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAutoSyncRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string providerID = 1; */
        if (message.providerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.providerID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetAutoSyncRequest
 */
export const GetAutoSyncRequest = new GetAutoSyncRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAutoSyncResponse$Type extends MessageType<GetAutoSyncResponse> {
    constructor() {
        super("GetAutoSyncResponse", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "loginMethod", kind: "enum", T: () => ["LoginMethod", LoginMethod, "LOGIN_METHOD_"] },
            { no: 3, name: "providerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "providerType", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAutoSyncResponse>): GetAutoSyncResponse {
        const message = { enabled: false, loginMethod: 0, providerID: "", customerID: "", providerType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAutoSyncResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAutoSyncResponse): GetAutoSyncResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* LoginMethod loginMethod */ 2:
                    message.loginMethod = reader.int32();
                    break;
                case /* string providerID */ 3:
                    message.providerID = reader.string();
                    break;
                case /* string customerID */ 4:
                    message.customerID = reader.string();
                    break;
                case /* string providerType */ 5:
                    message.providerType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAutoSyncResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* LoginMethod loginMethod = 2; */
        if (message.loginMethod !== 0)
            writer.tag(2, WireType.Varint).int32(message.loginMethod);
        /* string providerID = 3; */
        if (message.providerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.providerID);
        /* string customerID = 4; */
        if (message.customerID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerID);
        /* string providerType = 5; */
        if (message.providerType !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.providerType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetAutoSyncResponse
 */
export const GetAutoSyncResponse = new GetAutoSyncResponse$Type();
/**
 * @generated ServiceType for protobuf service ContactsService
 */
export const ContactsService = new ServiceType("ContactsService", [
    { name: "ContactList", options: { "google.api.http": { get: "/v1/contacts" } }, I: ContactsListRequest, O: ContactsListResponse },
    { name: "ContactInvite", options: { "google.api.http": { post: "/v1/contacts" } }, I: ContactsInviteRequest, O: ContactsInviteResponse },
    { name: "ContactUpdate", options: { "google.api.http": { put: "/v1/contacts/{id}" } }, I: ContactsUpdateRequest, O: Contact },
    { name: "ContactUpdateStatus", options: { "google.api.http": { put: "/v1/contacts/{id}/status" } }, I: ContactsUpdateStatusRequest, O: ContactResponse },
    { name: "ContactDelete", options: { "google.api.http": { delete: "/v1/contacts/{id}" } }, I: ContactsRequest, O: ContactResponse },
    { name: "ContactResendInvite", options: { "google.api.http": { put: "/v1/contacts/{id}/resend" } }, I: ContactsRequest, O: ContactResponse },
    { name: "ContactRegistration", options: { "google.api.http": { post: "/v1/contacts/register" } }, I: ContactsRegistrationRequest, O: Contact },
    { name: "ContactSocialRegistration", options: { "google.api.http": { post: "/v1/contacts/register/social" } }, I: ContactsSocialRegistrationRequest, O: Contact },
    { name: "ContactInviteTokenValidate", options: { "google.api.http": { put: "/v1/contacts/validate" } }, I: ContactsTokenValidateRequest, O: ContactsTokenValidateResponse },
    { name: "ContactLicense", options: { "google.api.http": { put: "/v1/contacts/{id}/license", body: "*" } }, I: ContactsLicenseRequest, O: ContactsLicenseResponse },
    { name: "DeactivateSocialAccounts", options: { "google.api.http": { delete: "/v1/contacts/social", body: "*" } }, I: DeactivateSocialAccountsRequest, O: DeactivateSocialAccountsResponse },
    { name: "ActivateSocialAccounts", options: { "google.api.http": { post: "/v1/contacts/social", body: "*" } }, I: ActivateSocialAccountsRequest, O: ActivateSocialAccountsResponse },
    { name: "CreateTeam", options: { "google.api.http": { post: "/v1/contacts/teams", body: "*" } }, I: CreateTeamRequest, O: CreateTeamResponse },
    { name: "ListTeams", options: { "google.api.http": { get: "/v1/contacts/teams" } }, I: TeamListRequest, O: TeamListResponse },
    { name: "UpdateTeam", options: { "google.api.http": { put: "/v1/contacts/teams/{id}", body: "*" } }, I: TeamUpdateRequest, O: Team },
    { name: "DeleteTeam", options: { "google.api.http": { delete: "/v1/contacts/teams/{id}" } }, I: TeamDeleteRequest, O: TeamDeleteResponse },
    { name: "ListPeople", options: { "google.api.http": { get: "/v1/contacts/people" } }, I: ListPeopleRequest, O: People },
    { name: "Sync", options: {}, I: Empty, O: Empty },
    { name: "ListIntegrationGroupsMembers", options: { "google.api.http": { get: "/v1/contacts/groups" } }, I: ListIntegrationGroupsMembersRequest, O: ListIntegrationGroupsMembersResponse },
    { name: "PaginatedListIntegrationGroupsMembers", options: { "google.api.http": { get: "/v1/contacts/groups/paginated" } }, I: PaginatedListIntegrationGroupsMembersRequest, O: PaginatedListIntegrationGroupsMembersResponse },
    { name: "SyncIntegrationGroupsMembers", options: { "google.api.http": { post: "/v1/contacts/groups/sync", body: "*" } }, I: SyncIntegrationGroupsMembersRequest, O: SyncIntegrationGroupsMembersResponse },
    { name: "CreateAutoSync", options: { "google.api.http": { post: "/v1/contacts/groups/autosync/create", body: "*" } }, I: CreateAutoSyncRequest, O: Empty },
    { name: "DeleteAutoSync", options: { "google.api.http": { delete: "/v1/contacts/groups/autosync/delete", body: "*" } }, I: DeleteAutoSyncRequest, O: Empty },
    { name: "GetAutoSync", options: { "google.api.http": { get: "/v1/contacts/groups/autosync/get", body: "*" } }, I: GetAutoSyncRequest, O: GetAutoSyncResponse },
    { name: "StartAutoSync", options: { "google.api.http": { post: "/v1/contacts/groups/autosync/start", body: "*" } }, I: Empty, O: Empty }
]);
