import React from "react";
import cs from "classnames";

import { StructureHeader } from "../StructureHeader/StructureHeader";

import "./PageHeader.scss";

interface Props {
  title: string;
  message?: string;
  hideBorder?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const PageHeader = ({
  title,
  message,
  children,
  hideBorder,
  className,
}: Props) => {
  const classes = cs("PageHeader", className, {
    "PageHeader--hide-border": hideBorder,
  });

  return (
    <div className={classes}>
      <StructureHeader
        title={title}
        message={message}
        hideBorder={hideBorder}
      />
      {children}
    </div>
  );
};
