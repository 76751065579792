import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import cs from "classnames";
// Import React FilePond
import { Button, Modal, ModalFooter } from "reactstrap";
import { FilePond, registerPlugin } from "react-filepond";

import { useAccountRequests } from "../../../api/grpc/account/useAccountRequests";

import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";

import { CropImage } from "./CropImage/CropImage";
import {
  ImageEditor,
  CropInformation,
  UploadImageContext,
} from "./UploadImageContext/UploadImageContext";
import getCroppedImg from "./CropImage/lib/cropImage";
import { convertDataURIToBinary } from "./CropImage/lib/convertDataURIToBinary";
import { useWorkplacesClient } from "../../../api/grpc/workplaces/useWorkplacesClient";
import { useApolloClient } from "@apollo/client";
import { FLOOR_FIELDS_FRAGMENT } from "../../../api/graphql/floors/floors";
import { useFloorsRequests } from "../../../api/graphql/floors/useFloorsRequests";
import { Icon } from "../Icon/Icon";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import "./UploadImage.scss";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginImageEdit
);

interface Props {
  image?: string;
  title: string;
  floorId?: string;
  imageUrl?: string;
  buttonText?: JSX.Element;
  onUpload: (image?: string) => void;
  hasImage?: boolean;
}

export const UploadImage = (props: Props) => {
  const { image, title, onUpload, buttonText, floorId, imageUrl, hasImage } =
    props;

  const { removeMapFromFloor } = useFloorsRequests();

  const client = useApolloClient();

  const [zoom, setZoom] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [editImage, setEditImage] = useState<string | null>(null);
  const [images, setImages] = useState<any>(() => (image ? [image] : []));

  const cropInformation = useRef<CropInformation>(undefined);
  const { uploadProfilePicture, deleteProfilePicture } = useAccountRequests();
  const { uploadMap, deleteMap } = useWorkplacesClient();

  const modalClasses = cs("UploadImage__modal", {
    "UploadImage__modal--crop": !!editImage,
  });

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const [editor] = useState<ImageEditor>({
    open: (file) => setEditImage(URL.createObjectURL(file)),
    onconfirm: () => {},
    oncancel: () => {},
    onclose: () => {},
  });

  const handleCropSubmit = async () => {
    if (!editImage || !cropInformation.current) {
      return;
    }

    try {
      const croppedImg = await getCroppedImg(
        editImage,
        cropInformation.current.croppedAreaPixels
      );

      setImages([croppedImg]);

      setEditImage(null);
    } catch (error: any) {
      console.error(error.message);
      toast.error("Couldn't crop image, please reload and try again!");
    }
  };

  const deletePicture = async () => {
    if (!floorId) {
      await deleteProfilePicture();

      onUpload();
      handleToggle();

      return;
    }

    const floorData = client.readFragment({
      id: `Floor:${floorId}`,
      fragmentName: "FloorFields",
      fragment: FLOOR_FIELDS_FRAGMENT,
    });

    if (floorData?.map === null) {
      return;
    }

    await removeMapFromFloor({
      id: floorId || "",
      mapId: floorData?.map?.id,
    });

    await deleteMap(imageUrl ? imageUrl : "");

    onUpload();
    handleToggle();
    return;
  };

  const handleImageSubmit = async () => {
    try {
      const image = convertDataURIToBinary(
        images[0].getFileEncodeBase64String()
      );

      if (floorId) {
        const { response } = await uploadMap(
          image,
          images[0].fileType,
          floorId
        );

        onUpload(response.imageUrl);
        handleToggle();

        const previousData = client.readFragment({
          id: `Floor:${floorId}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Floor:${floorId}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            map: {
              id: previousData?.map?.id,
              url: response.imageUrl,
              opacity: 100,
              showGridline: previousData?.map?.showGridline,
            },
          },
        });

        return;
      }

      const { response } = await uploadProfilePicture(
        image,
        images[0].fileType
      );

      onUpload(response.imageUrl);
      handleToggle();
    } catch (error: any) {
      console.error(error.message);
      toast.error("Couldn't upload image, please reload and try again!");
    }
  };

  const handleSubmit = async () => {
    if (editImage) {
      await handleCropSubmit();
      return;
    }

    await handleImageSubmit();
  };

  return (
    <div className="UploadImage">
      <UploadImageContext.Provider
        value={{
          zoom,
          editor,
          setZoom,
          editImage: undefined,
          onSubmit: handleSubmit,
          cropInformation: cropInformation,
        }}
      >
        <>
          <div
            onClick={handleToggle}
            className={
              buttonText
                ? ""
                : `UploadImage__field ${
                    !image ? "UploadImage__field--empty" : ""
                  }`
            }
          >
            {buttonText ? (
              buttonText
            ) : (
              <>{image && <img src={image} alt="User Profile" />}</>
            )}
          </div>
          <Modal isOpen={isOpen} toggle={handleToggle} className={modalClasses}>
            <div className="UploadImage__header main-flex">
              <h3>{title}</h3>
              <span onClick={handleToggle}>
                <Icon icon="close-icon" />
              </span>
            </div>
            <div className="UploadImage__content">
              <FilePond
                name="files"
                maxFiles={1}
                files={images}
                maxFileSize="5MB"
                allowMultiple={false}
                allowFileEncode={true}
                imageEditEditor={editor}
                onupdatefiles={setImages}
                allowFileSizeValidation={true}
                acceptedFileTypes={["image/png", "image/jpeg"]}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
              {editImage && <CropImage image={editImage} />}
            </div>
            <ModalFooter className="ConfirmationModal__footer d-flex">
              {hasImage && (
                <Button
                  color="outline-secondary"
                  onClick={() => deletePicture()}
                >
                  Delete
                </Button>
              )}

              <Button
                color="primary"
                className="px-4 py-2"
                onClick={handleSubmit}
                disabled={images.length === 0}
              >
                {editImage ? "Crop" : "Update"}
              </Button>
            </ModalFooter>
          </Modal>
        </>
      </UploadImageContext.Provider>
    </div>
  );
};
