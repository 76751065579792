import React from "react";
import {
  GeneralStatsByRoomsResponse,
  GeneralStatsResponse,
  TimeRangeEventsResponse,
} from "../../../../../api/grpc/insights/insights";

export interface RoomInsights {
  generalRoomStats?: GeneralStatsResponse;
  errorGeneralStats?: Error;
  loadingGeneralStats: boolean;
  errorRooms?: Error;
  loadingRooms: boolean;
  rooms?: GeneralStatsByRoomsResponse;
  errorRange?: Error;
  loadingRange: boolean;
  roomsTimeRange?: TimeRangeEventsResponse;
}

const values: RoomInsights = {
  generalRoomStats: undefined,
  loadingGeneralStats: false,
  errorGeneralStats: undefined,
  loadingRooms: false,
  errorRooms: undefined,
  rooms: undefined,
  loadingRange: false,
  errorRange: undefined,
  roomsTimeRange: undefined,
};

export const RoomInsightsContext = React.createContext(values);

export const useRoomInsightsContext = () => {
  const context = React.useContext(RoomInsightsContext);

  if (!context) {
    throw new Error(
      "useRoomInsightsContext must be used inside of RoomInsightsContext"
    );
  }

  return context;
};
