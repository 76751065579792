import { addMinutes } from "date-fns";
import React, { useState } from "react";
import { MatchingDesk } from "../../../api/grpc/desk/desk";
import {
  AmenitiesFilter,
  AvailableRoom,
} from "../../../api/grpc/workplaces/workplaces";
import { useRouter } from "../../../lib/hooks/useRouter";
import { BookingsContext } from "./BookingsContext";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";

interface Props {
  children: React.ReactNode;
}

export const BookingsContextProvider = ({ children }: Props) => {
  const { history } = useRouter();
  const { timeZone } = useTimeZoneContext();

  const [matchedDesks, setMatchedDesks] = useState<MatchingDesk[] | null>(null);
  const [matchedRooms, setMatchedRooms] = useState<AvailableRoom[] | null>(
    null
  );
  const [day, setDay] = useState(new Date());

  const [locationDefaultName, setLocationDefaultName] =
    useState("All locations");

  const [location, setLocation] = useState({
    locationId: "",
    locationName: "",
  });
  const [activeTagsFilter, setActiveTagsFilter] = useState<string[]>([]);
  const [reservDeskVariables, setReservDeskVariables] = useState({
    title: "",
    startTime: utcToZonedTime(new Date(), timeZone),
    endTime: utcToZonedTime(addMinutes(new Date(), 15), timeZone),
  });
  const [attendees, setAttendees] = useState<string[]>([]);
  const [subject, setSubject] = useState("");

  const [equipment, setEquipment] = useState<AmenitiesFilter>({
    id: "",
    accessibility: false,
    audio: false,
    casting: false,
    chargingPort: false,
    climate: false,
    desk: false,
    presentationTech: false,
    videoConference: false,
    faluty: false,
  });

  const [seats, setSeats] = useState("Any");

  const [isOnlineMeeting, setIsOnlineMeeting] = useState(false);
  const [makePrivate, setMakePrivate] = useState(false);

  const handleMatchedDesks = (desks: MatchingDesk[] | null) => {
    setMatchedDesks(desks);
  };

  const handleAttendees = (attende: string[]) => {
    setAttendees([...attende]);
  };

  const handleEquipmentChange = (key: keyof AmenitiesFilter) => {
    setEquipment({
      ...equipment,
      [key]: !equipment[key],
    });
  };

  const handleMatchedRooms = (rooms: AvailableRoom[] | null) => {
    setMatchedRooms(rooms);
  };

  const handleLocationId = (locationId: string, locationName: string) => {
    setLocation({
      locationId: locationId,
      locationName: locationName,
    });
  };

  const handleReserveDeskVariables = (startTime: Date, endTime: Date) => {
    setReservDeskVariables({
      title: "Default",
      startTime: startTime,
      endTime: endTime,
    });
  };

  const handleActiveTagsFilter = (tag: string) => {
    if (activeTagsFilter.includes(tag)) {
      setActiveTagsFilter(activeTagsFilter.filter((item) => item !== tag));
      return;
    }
    setActiveTagsFilter([...activeTagsFilter, tag]);
  };

  const emptyActiveTags = () => {
    setActiveTagsFilter([]);
  };

  const handleDayChange = (day: Date) => {
    setDay(day);
  };

  const handleSeats = (seat: string) => {
    setSeats(seat);
  };

  const handleSubject = (subject: string) => {
    setSubject(subject);
  };

  const handleLocationDefaultName = (location: string) => {
    setLocationDefaultName(location);
  };

  const handleIsOnlineMeeting = () => {
    setIsOnlineMeeting((prev) => !prev);
  };

  const handleMakePrivate = () => {
    setMakePrivate((prev) => !prev);
  };

  const handleModalClose = () => {
    if (matchedDesks && !!matchedDesks.length) {
      handleMatchedDesks(null);
    }

    if (matchedRooms && !!matchedRooms.length) {
      handleMatchedRooms(null);
    }

    if (!!attendees.length) {
      setAttendees([]);
    }

    if (seats !== "Any") {
      setSeats("Any");
    }

    setEquipment({
      id: "",
      accessibility: false,
      audio: false,
      casting: false,
      chargingPort: false,
      climate: false,
      desk: false,
      presentationTech: false,
      videoConference: false,
      faluty: false,
    });

    handleReserveDeskVariables(
      utcToZonedTime(new Date(), timeZone),
      utcToZonedTime(addMinutes(new Date(), 15), timeZone)
    );

    if (isOnlineMeeting) {
      setIsOnlineMeeting(false);
    }

    history.push("/bookings");
  };

  const context: BookingsContext = {
    day,
    matchedDesks,
    handleMatchedDesks,
    matchedRooms,
    handleMatchedRooms,
    location,
    handleLocationId,
    reservDeskVariables,
    handleDayChange,
    handleReserveDeskVariables,
    activeTagsFilter,
    handleActiveTagsFilter,
    emptyActiveTags,
    equipment,
    handleEquipmentChange,
    seats,
    handleSeats,
    attendees,
    handleAttendees,
    subject,
    handleSubject,
    handleModalClose,
    locationDefaultName,
    handleLocationDefaultName,
    isOnlineMeeting,
    handleIsOnlineMeeting,
    makePrivate,
    handleMakePrivate,
  };
  return (
    <BookingsContext.Provider value={context}>
      {children}
    </BookingsContext.Provider>
  );
};
