import React from "react";

interface Props {
  tags?: string[];
}

export const PopUpTags = ({ tags }: Props) => {
  return (
    <div className="PopUpTags">
      {tags &&
        tags.map((tag, index) => (
          <span className="PopUpTags__tag" key={`${index}-${tag}`}>
            {tag}
          </span>
        ))}
    </div>
  );
};
