import L, { Map, Marker } from "leaflet";
import { Size } from "../../components/Floors/FloorPlan/FloorPlan";
import { initGeometryUtil } from "../plugins/leaflet/leaflet-geometry-util";
import { initLeafletSnap } from "../plugins/leaflet/leaflet-snap";

type LeafletIconParams = {
  svgUrl: string;
  size: [number, number];
  anchor?: [number, number];
  type: string;
};

export const createLeafletIcon = ({
  svgUrl,
  size,
  anchor,
  type,
}: LeafletIconParams): L.Icon => {
  return L.icon({
    iconSize: size,
    iconAnchor: anchor || [size[0] / 2, size[1] / 2],
    popupAnchor: [0, -size[1] / 2],
    iconUrl: svgUrl,
    attribution: type,
    shadowSize: [40, 50],
  });
};

export const getMarkerIndex = (marker: Marker): number => {
  // @ts-ignore
  return marker._index;
};

export const setMarkerIndex = (marker: Marker, index: number): void => {
  // @ts-ignore
  marker._index = index;
};

export const isMarkersOnOneLine = (
  latlngs: [L.LatLngLiteral, L.LatLngLiteral]
) => {
  const lngDifference = Math.abs(latlngs[0].lng - latlngs[1].lng);
  const latDifference = Math.abs(latlngs[0].lat - latlngs[1].lat);

  return lngDifference < 5 || latDifference < 5;
};

export const drawMarkers = (
  map: Map,
  markers: L.Marker[],
  config: {
    mapSize: Size;
  },
  options: {
    snapEnabled: boolean;
  },
  openMarkerId?: string
) => {
  initGeometryUtil(L);
  initLeafletSnap(L);

  markers.forEach((marker, index) => {
    marker.addTo(map);

    setMarkerIndex(marker, index);

    if (openMarkerId && marker.options.attribution === openMarkerId) {
      marker.openPopup();
    }
  });

  if (!options.snapEnabled) {
    return;
  }

  const polyline = L.polyline([], {
    interactive: false,
    weight: 2,
  }).addTo(map);

  markers.forEach((marker) => {
    // @ts-ignore
    marker.snapediting = new L.Handler.MarkerSnap(map, marker);
    // @ts-ignore
    marker.snapediting.addGuideLayer(polyline);
    // @ts-ignore
    marker.snapediting.enable();

    marker.on("drag", (e) => {
      const alignedMarkers = markers.filter((_marker) => {
        if (getMarkerIndex(_marker) === getMarkerIndex(e.target)) {
          return false;
        }

        return isMarkersOnOneLine([e.target.getLatLng(), _marker.getLatLng()]);
      });

      if (alignedMarkers.length === 0) {
        polyline.setLatLngs([]);

        return;
      }

      const polylinePositions: L.LatLng[][] = [];

      alignedMarkers.forEach((alignedMarker) => {
        const lngDifference = Math.abs(
          alignedMarker.getLatLng().lng - e.target.getLatLng().lng
        );
        const latDifference = Math.abs(
          alignedMarker.getLatLng().lat - e.target.getLatLng().lat
        );

        if (lngDifference < 5) {
          const leftPoint: L.LatLng = new L.LatLng(
            -config.mapSize.height,
            alignedMarker.getLatLng().lng
          );
          const rightPoint: L.LatLng = new L.LatLng(
            config.mapSize.height,
            alignedMarker.getLatLng().lng
          );
          polylinePositions.push([leftPoint, rightPoint]);
          return;
        }

        if (latDifference < 5) {
          const leftPoint: L.LatLng = new L.LatLng(
            alignedMarker.getLatLng().lat,
            -config.mapSize.width
          );
          const rightPoint: L.LatLng = new L.LatLng(
            alignedMarker.getLatLng().lat,
            config.mapSize.width
          );
          polylinePositions.push([leftPoint, rightPoint]);
          return;
        }
      });

      polyline.setLatLngs(polylinePositions);
    });

    marker.on("dragend", (e) => {
      polyline.setLatLngs([]);
    });
  });
};
