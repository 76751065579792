import React, { useEffect } from "react";
import { useWorkplaceRequests } from "../../../api/graphql/useWorkplaceRequests";
import { WorkplaceFields } from "../../../api/graphql/workplaces";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { tooltips } from "../../../lib/utils/tooltips";
import { WorkplacesTreeLoader } from "../../../views/Manage/Workplaces/WorkplacesTree/WorkplacesTreeLoader/WorkplacesTreeLoader";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { WayFinderFormik } from "../../shared/Forms/WayFinderForm/lib/useWayFinderFormik";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { Icon } from "../../shared/Icon/Icon";
import { PageSidebar } from "../../shared/PageSidebar/PageSidebar";
import { PageSidebarHeader } from "../../shared/PageSidebar/PageSidebarHeader/PageSidebarHeader";
import { WayFinderTreeLevel } from "./WayFinderTreeLevel";
import { WayFinderWorkspaceFooterPicker } from "./WayFinderWorkspaceFooterPicker";

interface Props {
  closePicker: () => void;
  handleWorkspace: (
    item: WorkplaceFields,
    type: "desks" | "rooms",
    methodType: "add" | "delete"
  ) => void;
  clearWorkspaces: () => void;
  formik: WayFinderFormik;
  maxNumberOfRooms: boolean;
  maxNumberOfDesks: boolean;
}

export const WayFinderWorkspacePicker = ({
  closePicker,
  handleWorkspace,
  formik,
  clearWorkspaces,
  maxNumberOfRooms,
  maxNumberOfDesks,
}: Props) => {
  const { user } = useAuthContext();
  const { getRootWorkplaces } = useWorkplaceRequests();
  const [request, { loading, error, data }] = getRootWorkplaces;

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  return (
    <PageSidebar
      className={`PageSidebar--default PageSidebar--toggled PageSidebar--Custom PageSidebar--Wayfinder`}
      showOnInitialization={false}
      showOnDesktop={false}
      hideToggler
    >
      <PageSidebarHeader
        title="Workspace picker"
        message={tooltips.wayfinders.settings.picker}
        direction="left"
      />

      <div onClick={closePicker} className="PageSidebar--Wayfinder--closer">
        <Icon icon="close-icon" />
      </div>

      <div
        className="
      PageSidebar--Wayfinder--desc
      "
      >
        Pick your rooms and desks
      </div>

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<WorkplacesTreeLoader />}
      >
        <ApolloErrorGuard
          error={error}
          errorComponent={<p>Data couldn't be loaded.</p>}
        >
          <div className="PageSidebar--Wayfinder--items">
            {rootWorkplaces.map((item: any) => {
              if (typeof item !== "object") {
                return;
              }

              return item.map((workplace: any) => {
                return (
                  <WayFinderTreeLevel
                    item={workplace}
                    toggleParent={() => {}}
                    key={`${workplace.id}-tree-root`}
                    handleWorkspace={handleWorkspace}
                    formik={formik}
                    maxNumberOfDesks={maxNumberOfDesks}
                    maxNumberOfRooms={maxNumberOfRooms}
                  />
                );
              });
            })}
          </div>
        </ApolloErrorGuard>
      </HandleLoadingState>
      <WayFinderWorkspaceFooterPicker
        desksCounter={formik.values.desks.length}
        roomsCounter={formik.values.rooms.length}
        clearWorkspaces={clearWorkspaces}
        closePicker={closePicker}
      />
    </PageSidebar>
  );
};
