// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "insights.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Insights } from "./insights";
import type { DesksMeetingBehaviorResponse } from "./insights";
import type { DesksTotalOccupancyResponse } from "./insights";
import type { GeneralStatsByDesksResponse } from "./insights";
import type { DeskInsightsOverviewResponse } from "./insights";
import type { MeetingExportResponse } from "./insights";
import type { MeetingBehaviorResponse } from "./insights";
import type { SankeyChartResponse } from "./insights";
import type { TotalOccupancyResponse } from "./insights";
import type { HeatMapResponse } from "./insights";
import type { GeneralStatsByRoomsResponse } from "./insights";
import type { GeneralStatsResponse } from "./insights";
import type { TimeRangeEventsResponse } from "./insights";
import type { TimeRangeEventsRequest } from "./insights";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { InsightsSyncResponse } from "./insights";
import type { Empty } from "./google/protobuf/empty";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Insights
 */
export interface IInsightsClient {
    /**
     * Sync trigger room events update process for customers
     *
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (InsightsSyncResponse);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, InsightsSyncResponse>;
    /**
     * RoomTimeRangeEvents count all evens in time span and return day distribution
     *
     * @generated from protobuf rpc: RoomTimeRangeEvents(TimeRangeEventsRequest) returns (TimeRangeEventsResponse);
     */
    roomTimeRangeEvents(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, TimeRangeEventsResponse>;
    /**
     * RoomTimeRangeEvents count all evens in time span and return day distribution
     *
     * @generated from protobuf rpc: RoomGeneralStats(TimeRangeEventsRequest) returns (GeneralStatsResponse);
     */
    roomGeneralStats(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, GeneralStatsResponse>;
    /**
     * GeneralStatsByRooms count evens per rooms in time span and return day distribution
     *
     * @generated from protobuf rpc: GeneralStatsByRooms(TimeRangeEventsRequest) returns (GeneralStatsByRoomsResponse);
     */
    generalStatsByRooms(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, GeneralStatsByRoomsResponse>;
    /**
     * RoomTimeRangeEvents count all evens in time span and return day distribution
     * Note: this values adjusted to working hrs
     *
     * @generated from protobuf rpc: RoomEventsHeatMap(TimeRangeEventsRequest) returns (HeatMapResponse);
     */
    roomEventsHeatMap(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, HeatMapResponse>;
    /**
     * TotalOccupancy return total occupancy percentage,
     * occupancy distribution by day of week, occupancy distribution by room and
     * occupancy distribution by seats range
     * Note: this values adjusted to working hrs
     *
     * @generated from protobuf rpc: TotalOccupancy(TimeRangeEventsRequest) returns (TotalOccupancyResponse);
     */
    totalOccupancy(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, TotalOccupancyResponse>;
    /**
     * RoomSankeyChart return info for build sankey chart for reservation flow.
     * More info about Sankey chart here: https://developers.google.com/chart/interactive/docs/gallery/sankey
     *
     * @generated from protobuf rpc: RoomSankeyChart(TimeRangeEventsRequest) returns (SankeyChartResponse);
     */
    roomSankeyChart(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, SankeyChartResponse>;
    /**
     * RoomMeetingBehavior return adHoc and planned events for:
     *  - selected room location in time span;
     *  - percentage value for no shows event for each active room;
     *  - attendance by room capacity
     *  - realized meetings funnel
     *
     * @generated from protobuf rpc: RoomMeetingBehavior(TimeRangeEventsRequest) returns (MeetingBehaviorResponse);
     */
    roomMeetingBehavior(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, MeetingBehaviorResponse>;
    /**
     * RoomInsightsExport return dump info for available room to csv file and return link for download
     *
     * @generated from protobuf rpc: RoomInsightsExport(TimeRangeEventsRequest) returns (MeetingExportResponse);
     */
    roomInsightsExport(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, MeetingExportResponse>;
    /**
     * @generated from protobuf rpc: DeskInsightsOverview(TimeRangeEventsRequest) returns (DeskInsightsOverviewResponse);
     */
    deskInsightsOverview(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, DeskInsightsOverviewResponse>;
    /**
     * DeskReservationsTimeRange count all evens in time span and return day distribution
     *
     * @generated from protobuf rpc: DeskReservationsTimeRange(TimeRangeEventsRequest) returns (TimeRangeEventsResponse);
     */
    deskReservationsTimeRange(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, TimeRangeEventsResponse>;
    /**
     * GeneralStatsByDesks count evens per desk in time span and return day distribution
     *
     * @generated from protobuf rpc: GeneralStatsByDesks(TimeRangeEventsRequest) returns (GeneralStatsByDesksResponse);
     */
    generalStatsByDesks(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, GeneralStatsByDesksResponse>;
    /**
     * RoomTimeRangeEvents count all evens in time span and return day distribution
     * Note: this values adjusted to working hrs
     *
     * @generated from protobuf rpc: DeskEventsHeatMap(TimeRangeEventsRequest) returns (HeatMapResponse);
     */
    deskEventsHeatMap(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, HeatMapResponse>;
    /**
     * TotalOccupancy return total occupancy percentage,
     * occupancy distribution by day of week, occupancy distribution by room and
     * occupancy distribution by seats range
     * Note: this values adjusted to working hrs
     *
     * @generated from protobuf rpc: DesksTotalOccupancy(TimeRangeEventsRequest) returns (DesksTotalOccupancyResponse);
     */
    desksTotalOccupancy(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, DesksTotalOccupancyResponse>;
    /**
     * RoomSankeyChart return info for build sankey chart for reservation flow.
     * More info about Sankey chart here: https://developers.google.com/chart/interactive/docs/gallery/sankey
     *
     * @generated from protobuf rpc: DesksSankeyChart(TimeRangeEventsRequest) returns (SankeyChartResponse);
     */
    desksSankeyChart(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, SankeyChartResponse>;
    /**
     * RoomMeetingBehavior return adHoc and planned events for:
     *  - selected room location in time span;
     *  - percentage value for no shows event for each active room;
     *  - attendance by room capacity
     *  - realized meetings funnel
     *
     * @generated from protobuf rpc: DesksMeetingBehavior(TimeRangeEventsRequest) returns (DesksMeetingBehaviorResponse);
     */
    desksMeetingBehavior(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, DesksMeetingBehaviorResponse>;
    /**
     * RoomInsightsExport return dump info for available room to csv file and return link for download
     *
     * @generated from protobuf rpc: DesksInsightsExport(TimeRangeEventsRequest) returns (MeetingExportResponse);
     */
    desksInsightsExport(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, MeetingExportResponse>;
}
/**
 * @generated from protobuf service Insights
 */
export class InsightsClient implements IInsightsClient, ServiceInfo {
    typeName = Insights.typeName;
    methods = Insights.methods;
    options = Insights.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Sync trigger room events update process for customers
     *
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (InsightsSyncResponse);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, InsightsSyncResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, InsightsSyncResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomTimeRangeEvents count all evens in time span and return day distribution
     *
     * @generated from protobuf rpc: RoomTimeRangeEvents(TimeRangeEventsRequest) returns (TimeRangeEventsResponse);
     */
    roomTimeRangeEvents(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, TimeRangeEventsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, TimeRangeEventsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomTimeRangeEvents count all evens in time span and return day distribution
     *
     * @generated from protobuf rpc: RoomGeneralStats(TimeRangeEventsRequest) returns (GeneralStatsResponse);
     */
    roomGeneralStats(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, GeneralStatsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, GeneralStatsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GeneralStatsByRooms count evens per rooms in time span and return day distribution
     *
     * @generated from protobuf rpc: GeneralStatsByRooms(TimeRangeEventsRequest) returns (GeneralStatsByRoomsResponse);
     */
    generalStatsByRooms(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, GeneralStatsByRoomsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, GeneralStatsByRoomsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomTimeRangeEvents count all evens in time span and return day distribution
     * Note: this values adjusted to working hrs
     *
     * @generated from protobuf rpc: RoomEventsHeatMap(TimeRangeEventsRequest) returns (HeatMapResponse);
     */
    roomEventsHeatMap(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, HeatMapResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, HeatMapResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * TotalOccupancy return total occupancy percentage,
     * occupancy distribution by day of week, occupancy distribution by room and
     * occupancy distribution by seats range
     * Note: this values adjusted to working hrs
     *
     * @generated from protobuf rpc: TotalOccupancy(TimeRangeEventsRequest) returns (TotalOccupancyResponse);
     */
    totalOccupancy(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, TotalOccupancyResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, TotalOccupancyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomSankeyChart return info for build sankey chart for reservation flow.
     * More info about Sankey chart here: https://developers.google.com/chart/interactive/docs/gallery/sankey
     *
     * @generated from protobuf rpc: RoomSankeyChart(TimeRangeEventsRequest) returns (SankeyChartResponse);
     */
    roomSankeyChart(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, SankeyChartResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, SankeyChartResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomMeetingBehavior return adHoc and planned events for:
     *  - selected room location in time span;
     *  - percentage value for no shows event for each active room;
     *  - attendance by room capacity
     *  - realized meetings funnel
     *
     * @generated from protobuf rpc: RoomMeetingBehavior(TimeRangeEventsRequest) returns (MeetingBehaviorResponse);
     */
    roomMeetingBehavior(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, MeetingBehaviorResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, MeetingBehaviorResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomInsightsExport return dump info for available room to csv file and return link for download
     *
     * @generated from protobuf rpc: RoomInsightsExport(TimeRangeEventsRequest) returns (MeetingExportResponse);
     */
    roomInsightsExport(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, MeetingExportResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, MeetingExportResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeskInsightsOverview(TimeRangeEventsRequest) returns (DeskInsightsOverviewResponse);
     */
    deskInsightsOverview(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, DeskInsightsOverviewResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, DeskInsightsOverviewResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeskReservationsTimeRange count all evens in time span and return day distribution
     *
     * @generated from protobuf rpc: DeskReservationsTimeRange(TimeRangeEventsRequest) returns (TimeRangeEventsResponse);
     */
    deskReservationsTimeRange(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, TimeRangeEventsResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, TimeRangeEventsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GeneralStatsByDesks count evens per desk in time span and return day distribution
     *
     * @generated from protobuf rpc: GeneralStatsByDesks(TimeRangeEventsRequest) returns (GeneralStatsByDesksResponse);
     */
    generalStatsByDesks(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, GeneralStatsByDesksResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, GeneralStatsByDesksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomTimeRangeEvents count all evens in time span and return day distribution
     * Note: this values adjusted to working hrs
     *
     * @generated from protobuf rpc: DeskEventsHeatMap(TimeRangeEventsRequest) returns (HeatMapResponse);
     */
    deskEventsHeatMap(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, HeatMapResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, HeatMapResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * TotalOccupancy return total occupancy percentage,
     * occupancy distribution by day of week, occupancy distribution by room and
     * occupancy distribution by seats range
     * Note: this values adjusted to working hrs
     *
     * @generated from protobuf rpc: DesksTotalOccupancy(TimeRangeEventsRequest) returns (DesksTotalOccupancyResponse);
     */
    desksTotalOccupancy(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, DesksTotalOccupancyResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, DesksTotalOccupancyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomSankeyChart return info for build sankey chart for reservation flow.
     * More info about Sankey chart here: https://developers.google.com/chart/interactive/docs/gallery/sankey
     *
     * @generated from protobuf rpc: DesksSankeyChart(TimeRangeEventsRequest) returns (SankeyChartResponse);
     */
    desksSankeyChart(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, SankeyChartResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, SankeyChartResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomMeetingBehavior return adHoc and planned events for:
     *  - selected room location in time span;
     *  - percentage value for no shows event for each active room;
     *  - attendance by room capacity
     *  - realized meetings funnel
     *
     * @generated from protobuf rpc: DesksMeetingBehavior(TimeRangeEventsRequest) returns (DesksMeetingBehaviorResponse);
     */
    desksMeetingBehavior(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, DesksMeetingBehaviorResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, DesksMeetingBehaviorResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomInsightsExport return dump info for available room to csv file and return link for download
     *
     * @generated from protobuf rpc: DesksInsightsExport(TimeRangeEventsRequest) returns (MeetingExportResponse);
     */
    desksInsightsExport(input: TimeRangeEventsRequest, options?: RpcOptions): UnaryCall<TimeRangeEventsRequest, MeetingExportResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeRangeEventsRequest, MeetingExportResponse>("unary", this._transport, method, opt, input);
    }
}
