import React, { useEffect, useMemo } from "react";

import { Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {
  addMinutes,
  differenceInMinutes,
  format,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  subMinutes,
  differenceInDays,
  startOfDay,
} from "date-fns";

import { useSidebarContext } from "../../../lib/context/Sidebar/SidebarContext";
import { useBookingsContext } from "../Context/BookingsContext";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime } from "date-fns-tz";
import { useBookingsMapContext } from "../BookingsMap/Context/BookingsMapContext";

import { selectStyles } from "../../../lib/utils/selectStyles";
import { timeFormat } from "../helpers/displayTimeFormat";
import { tooltips } from "../../../lib/utils/tooltips";
import { trimText } from "../../../lib/helpers/trimText";

import { Button } from "../../../components/shared/Button/Button";
import { SearchWorkplaceFormik } from "../lib/useSearchWorkplaceFormik";
import { TagsButton } from "./TagsButton/TagsButton";
import { FindPeopleButton } from "./FindPeopleButton/FindPeopleButton";
import { Capabilities } from "./Capabilities/Capabilities";
import { Input } from "../../shared/Input/Input";
import { LabelWithInformationBox } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { InformationBox } from "../../shared/InformationBox/InformationBox";
import { BookingTypes } from "../BookingsSearch/BookingsSearch";
import { defineDatesPicker } from "../lib/defineDatesPicker";
import { GetDefaultLocationPathResponse } from "../../../api/graphql/bookings/bookings";
import { BookingsSearchButton } from "../BookingsSearch/BookingsSearchButton/BookingsSearchButton";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";

import "react-datepicker/dist/react-datepicker.css";
import "./SearchWorkplaceForm.scss";

interface Props {
  formik: SearchWorkplaceFormik;
  type: BookingTypes;
  defaultLocation?: GetDefaultLocationPathResponse;
  loading?: boolean;
}

export const SearchWorkplaceForm = ({
  formik,
  type,
  defaultLocation,
  loading,
}: Props) => {
  const { togglePageSidebar } = useSidebarContext();
  const { timeZone } = useTimeZoneContext();
  const {
    location: { locationName },
    activeTagsFilter,
    handleActiveTagsFilter,
    handleReserveDeskVariables,
    reservDeskVariables: { startTime, endTime },
    handleDayChange,
    handleIsOnlineMeeting,
    handleMakePrivate,
    subject,
    handleSubject,
  } = useBookingsContext();
  const { allDay, setAllDay } = useBookingsMapContext();

  const { datePickOptions } = defineDatesPicker(timeZone);

  let pickedDayFormated = parseISO(formik.values.day.value);

  const filterPassedTime = (time: Date) => {
    if (
      differenceInDays(
        startOfDay(pickedDayFormated),
        startOfDay(utcToZonedTime(new Date(), timeZone))
      ) > 0
    ) {
      return true;
    }
    const currentDate = utcToZonedTime(new Date(), timeZone);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const locationNameForSearch = useMemo(() => {
    if (locationName && locationName !== "RefreshedId") {
      return locationName;
    }

    return "All locations";
  }, [locationName]);

  useEffect(() => {
    const currentDate = utcToZonedTime(new Date(), timeZone);
    handleReserveDeskVariables(currentDate, addMinutes(currentDate, 15));
    //we have to parseISO date because for diefferent timezones it would not work stable and take -1 day
    handleDayChange(parseISO(format(currentDate, "yyyy-MM-dd")));
  }, []);

  return (
    <Form className="SearchWorkplaceForm">
      <FormGroup className="SearchWorkplaceForm--text">
        <label className="pb-2">When</label>

        <Select
          name="day"
          options={datePickOptions}
          theme={selectStyles}
          minMenuHeight={55}
          isSearchable={false}
          className="SearchWorkplaceFrom__select"
          errorMessage={formik.errors.day}
          invalid={!!formik.errors.day && formik.touched.day}
          onChange={(values) => {
            if (values === null) {
              return;
            }

            handleDayChange(parseISO(values.value));
            formik.setFieldValue("day", values);
            formik.setFieldTouched("day", true);

            if (allDay === true) {
              const definedTimeEndTime = setHours(
                setMinutes(
                  setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                  0
                ),
                24
              );

              const definedTimeStartTime =
                differenceInDays(
                  startOfDay(parseISO(values.value)),
                  startOfDay(utcToZonedTime(new Date(), timeZone))
                ) > 0
                  ? setHours(
                      setMinutes(
                        setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                        0
                      ),
                      24
                    )
                  : utcToZonedTime(new Date(), timeZone);

              handleReserveDeskVariables(
                definedTimeStartTime,
                definedTimeEndTime
              );

              formik.setFieldValue("until", definedTimeEndTime);
              formik.setFieldValue("from", definedTimeStartTime);
              formik.setFieldTouched("until", true);
              formik.setFieldTouched("from", true);
            }
            return;
          }}
          value={
            formik.values.day.value
              ? {
                  label: formik.values.day.label,
                  value: formik.values.day.value,
                }
              : datePickOptions[0]
          }
        ></Select>
      </FormGroup>

      <FormGroup className="SearchWorkplaceForm--text">
        <label>From</label>
        <DatePicker
          selected={startTime}
          onChange={(date) => {
            if (!date) {
              return;
            }
            let diffMinutes = differenceInMinutes(date, endTime);
            let shouldUpdateEndTime =
              (date >= endTime || (diffMinutes >= -15 && diffMinutes < 0)) &&
              !allDay;

            handleReserveDeskVariables(
              date,
              shouldUpdateEndTime ? addMinutes(new Date(date), 15) : endTime
            );

            if (shouldUpdateEndTime) {
              formik.setFieldValue("until", addMinutes(new Date(date), 15));
            }

            formik.setFieldValue("from", date);

            formik.setFieldTouched("from", true);
            return;
          }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          className="SearchWorkplaceForm__timePicker"
          filterTime={filterPassedTime}
          dateFormat={timeFormat}
          timeFormat={timeFormat}
        />
      </FormGroup>
      <FormGroup className="SearchWorkplaceForm--text">
        <label>Until</label>
        <DatePicker
          selected={endTime}
          onChange={(date) => {
            if (!date) {
              return;
            }
            handleReserveDeskVariables(
              date <= startTime ? subMinutes(new Date(date), 15) : startTime,
              date
            );

            if (date <= startTime && !allDay) {
              formik.setFieldValue("from", subMinutes(new Date(date), 15));
            }

            formik.setFieldValue("until", date);
            formik.setFieldTouched("until", true);
            return;
          }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          filterTime={filterPassedTime}
          dateFormat={timeFormat}
          timeFormat={timeFormat}
          // injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
        />
      </FormGroup>

      <FormGroup className="flex-a-center">
        <Input
          type="checkbox"
          id="allDay"
          name="allDay"
          data-cy="allDay"
          onChange={() => {
            const definedTimeEndTime =
              allDay === false
                ? setHours(
                    setMinutes(
                      setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                      0
                    ),
                    24
                  )
                : utcToZonedTime(addMinutes(new Date(), 15), timeZone);

            const definedTimeStartTime =
              allDay === false &&
              differenceInDays(
                startOfDay(pickedDayFormated),
                startOfDay(utcToZonedTime(new Date(), timeZone))
              ) > 0
                ? setHours(
                    setMinutes(
                      setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                      0
                    ),
                    24
                  )
                : utcToZonedTime(new Date(), timeZone);

            handleReserveDeskVariables(
              definedTimeStartTime,
              definedTimeEndTime
            );

            formik.setFieldValue("until", definedTimeEndTime);
            formik.setFieldValue("from", definedTimeStartTime);
            formik.setFieldTouched("until", true);
            formik.setFieldTouched("from", true);

            setAllDay((prev) => !prev);
          }}
        />
        <Label for="allDay" className="checkbox_label">
          <p className="pr-1">All day</p>
          <span className="checkbox_box"></span>
        </Label>
        <InformationBox message={tooltips.bookings.rooms.allDay} />
      </FormGroup>

      {type === "room" && (
        <>
          <FormGroup className="SearchWorkplaceForm__subject SearchWorkplaceForm--text">
            <LabelWithInformationBox
              title="Subject"
              for="subject"
              message={tooltips.bookings.rooms.subject}
              informationBoxClassName="pt-2"
            />
            <Input
              type="text"
              placeholder="Add Subject"
              id="subject"
              name="subject"
              value={subject}
              onChange={(e) => {
                handleSubject(e.target.value);
              }}
            />
          </FormGroup>

          <FormGroup className="flex-a-center">
            <Input
              type="checkbox"
              id="isOnlineMeeting"
              name="isOnlineMeeting"
              data-cy="isOnlineMeeting"
              onChange={() => handleIsOnlineMeeting()}
            />
            <Label for="isOnlineMeeting" className="checkbox_label">
              <p className="pr-1">Add online meeting</p>
              <span className="checkbox_box"></span>
            </Label>
            <InformationBox message={tooltips.bookings.rooms.onlineMeeting} />
          </FormGroup>

          <FormGroup className="flex-a-center">
            <Input
              type="checkbox"
              id="makePrivate"
              name="makePrivate"
              data-cy="makePrivate"
              onChange={() => handleMakePrivate()}
            />
            <Label for="makePrivate" className="checkbox_label">
              <p className="pr-1">Make private</p>
              <span className="checkbox_box"></span>
            </Label>
          </FormGroup>
        </>
      )}

      <FormGroup className="flex-a-center SearchWorkplaceForm__couple">
        <TagsButton
          onClick={(e) => {
            handleActiveTagsFilter(e);
          }}
          hasActiveTags={activeTagsFilter.length > 0}
          type={type}
        />

        {type === "room" && <Capabilities />}
      </FormGroup>

      <FormGroup className="SearchWorkplaceForm--text">
        <LabelWithInformationBox
          title="Location"
          for="location"
          message={tooltips.bookings.location}
          informationBoxClassName="pt-2"
        />

        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={<LoadingBox minHeight={50} />}
        >
          {defaultLocation?.queryDefaultLocationPath[0]?.referenceId &&
          (!!!locationName.length || locationName === "RefreshedId") ? (
            <BookingsSearchButton
              id={defaultLocation.queryDefaultLocationPath[0].referenceId || ""}
              togglePageSidebar={togglePageSidebar}
              className="SearchWorkplaceForm__location pt-2"
            />
          ) : (
            <Button
              title={trimText(locationNameForSearch ?? "", 25)}
              color="primary"
              icon="location-reserve"
              onClick={togglePageSidebar}
              className="SearchWorkplaceForm__location pt-2"
            />
          )}
        </HandleLoadingState>
      </FormGroup>
      <FormGroup>
        <FindPeopleButton type={type} />
      </FormGroup>
    </Form>
  );
};
