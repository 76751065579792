import React, { useEffect } from "react";
import cs from "classnames";
import { toast } from "react-toastify";
import { useValidPersonalToken } from "../../../lib/context/ValidateToken/ValidateTokenContext";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";

import { CalendarCredentialsStatus } from "../../../api/grpc/calendarprovider/calendarprovider";
import { IntegrationBanner } from "../shared/IntegrationBanner/IntegrationBanner";

export const PersonalCalendarValidation = () => {
  const { reAuthButton, setOpenReAuthModal, statusOfToken, setStatusOfToken } =
    useValidPersonalToken();
  const { personalCalendar } = useCalendarContext();

  const { validateCalendarIntegrationCredentials } = useCalendarRequests();
  const { pathname } = useRouter();

  const classes = cs("TokenToast", {
    TokenToast__show:
      statusOfToken ===
      CalendarCredentialsStatus.CalendarCredentialsStatusInvalid,
  });

  useEffect(() => {
    if (
      pathname.includes("bookings") ||
      pathname !== "/" ||
      personalCalendar.length <= 0
    ) {
      return;
    }

    if (pathname === "/" && localStorage.getItem("initiateLogin") !== "1") {
      return;
    }

    const checkPersonalToken = async () => {
      try {
        const {
          response: { status },
        } = await validateCalendarIntegrationCredentials(
          personalCalendar[0].iD
        );

        setStatusOfToken(status);
      } catch (error: any) {
        toast.error(error?.message);
        return;
      }
    };

    checkPersonalToken();

    if (localStorage.getItem("initiateLogin") === "1") {
      localStorage.removeItem("initiateLogin");
    }
  }, [personalCalendar]);

  return (
    <IntegrationBanner
      classes={classes}
      reAuthButton={reAuthButton}
      setOpenReAuthModal={setOpenReAuthModal}
      header="User re-authentication required."
    />
  );
};
