import React, { useState } from "react";
import { toast } from "react-toastify";

import { useEWSFormik } from "../lib/useEWSFormik";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useCustomerPermissions } from "../../Billings/lib/useCustomerPermissions";

import { CalendarSystem } from "../CalendarSystem/CalendarSystem";
import { CalendarServiceAccount } from "../CalendarServiceAccount";
import {
  CalendarType,
  EwsConnection,
  CalendarProviderDeviceType,
  GGCalendarProviderStatus,
} from "../../../api/grpc/calendarprovider/calendarprovider";

import "./CalendarsWizardForm.scss";

export interface StepProps {
  id: string;
  title: string;
}

export interface WizardFormFields extends EwsConnection {
  name: string;
}

interface CalendarsWizardFormProps {
  toggle: () => void;
  type?: string;
}

export const CalendarsWizardForm = (props: CalendarsWizardFormProps) => {
  const { user, updateUserAvatarAfterPersonalLinked } = useAuthContext();
  const { addProvider, providers, personalCalendar, setPersonalCalendar } =
    useCalendarContext();
  const { createEWSProvider, linkPersonalEWSCalendar } = useCalendarRequests();
  const [activeStep, setActiveStep] = useState(1);
  const { data, loading, error } = useCustomerPermissions();
  const [calendarSystem, setCalendarSystem] = useState("");

  const formik = useEWSFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        if (!user || !values) {
          return;
        }

        let isPersonalIntegration = props.type === "personal";

        const { response } = isPersonalIntegration
          ? await linkPersonalEWSCalendar(values.name, values)
          : await createEWSProvider(values.name, values);

        if (!isPersonalIntegration) {
          addProvider({
            calType: CalendarType.EWS,
            iD: response.iD,
            name: response.name,
            deviceType: CalendarProviderDeviceType.WEB,
            token: "",
            email: "",
            status: GGCalendarProviderStatus.ACTIVE,
            apiError: "",
            hostUrl: "",
          });
        }

        formikHelpers.resetForm();
        props.toggle();

        if (isPersonalIntegration) {
          setPersonalCalendar([
            {
              iD: response.iD,
              name: response.name,
              email: "",
              hostUrl: "",
            },
          ]);
          await updateUserAvatarAfterPersonalLinked();
        }
        toast.success("Calendar added successfully!");
      } catch (error: any) {
        console.error(error.message);

        toast.error(error?.message ?? "Calendar couldn't be added!");
      }
    },
  });

  const handlePrevClick = () => {
    if (activeStep === 1) {
      return;
    }

    setActiveStep((prevValue) => prevValue - 1);
  };

  const handleStepUpdate = (n: number) => {
    if (n === 2 && !calendarSystem) return;

    setActiveStep(n);
  };

  return (
    <div className="CalendarsWizardForm">
      {activeStep === 1 && props.type !== "reAuth" && (
        <CalendarSystem
          activeStep={activeStep}
          activeCalendar={calendarSystem}
          handlePrevClick={handlePrevClick}
          handleNextClick={(n: number) => handleStepUpdate(n)}
          onCalendarSelect={(id) => setCalendarSystem(id)}
          type={props.type}
          providers={providers}
          loading={loading}
          error={error}
          nameOfPlan={data?.id || ""}
        />
      )}

      {(activeStep === 2 || props.type === "reAuth") && (
        <CalendarServiceAccount
          formik={formik}
          activeStep={activeStep}
          toggleModal={props.toggle}
          activeCalendar={
            props.type === "reAuth"
              ? personalCalendar[0].iD.toLowerCase().includes("gsuite")
                ? "gsuite"
                : personalCalendar[0].iD.toLowerCase().includes("o365")
                ? "o365"
                : "ews"
              : calendarSystem
          }
          handlePrevClick={handlePrevClick}
          handleSubmitClick={formik.submitForm}
          type={props.type}
        />
      )}
    </div>
  );
};
