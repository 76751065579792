import React, { useState } from "react";
import { toast } from "react-toastify";

import { useRoomFormik } from "../../../../shared/Forms/RoomForm/lib/useRoomFormik";
import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
import {
  useCustomerContext,
  useLicensesContext,
} from "../../../../../lib/context/Customer/CustomerContext";
import { useCalendarRequests } from "../../../../../api/grpc/calendarprovider/useCalendarRequests";
import { useCustomerPermissions } from "../../../../Billings/lib/useCustomerPermissions";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useWorkingHoursData } from "../../../../Account/helpers/useWorkingHoursData";
import { useDefineLicense } from "../../../../../lib/hooks/useDefineLicense";
import { useRoomsQuery } from "../../../../../lib/hooks/useRoomsQuery";
import { useRoomTemplateContext } from "../../../../../lib/context/Templates/RoomTemplate/RoomTemplateContext";

import { WorkplaceModalProps } from "../AddWorkplaceModal";
import { AmenitiesFilter } from "../../../../../api/grpc/workplaces/workplaces";
import {
  RoomForm,
  roomAmenetiesNames,
} from "../../../../shared/Forms/RoomForm/RoomForm";
import ModalSidebar from "../../../../Modals/ModalSidebar/ModalSidebar";
import { RoomRequestVariables } from "../../../../../api/graphql/rooms/rooms";
import { CalendarType } from "../../../../../api/grpc/calendarprovider/calendarprovider";
import { EntityType } from "../../../../../api/grpc/subscription/subscription";

export const AddRoomModal = (props: WorkplaceModalProps) => {
  const { isOpen, toggle, parentId, parentType } = props;
  const { user } = useAuthContext();
  const { refetch: refetchNearbyRooms } = useRoomsQuery();
  const { addRoom } = useWorkplaceRequests();
  const [loading, setLoading] = useState(false);
  const { retrieveTenant } = useCustomerContext();
  const { handleLicenseUpdate } = useLicensesContext();
  const { validateEWSCalendar } = useCalendarRequests();
  const {
    loading: workingHoursLoading,
    error,
    workingHoursData,
    handleWorkingHours,
  } = useWorkingHoursData();
  const { loadingRoomTemplate, errorRoomTemplates, roomTemplates } =
    useRoomTemplateContext();
  const {
    data: customerPermissions,
    error: customerError,
    isBussinesOrPro,
  } = useCustomerPermissions();
  const { defineLicenseNumber } = useDefineLicense(EntityType.ROOM);

  const formik = useRoomFormik({
    onSubmit: async (values) => {
      try {
        if (!!error || !!customerError) {
          toast.error(
            "Failed to fetch working hours, please contact your administrator."
          );
          return;
        }

        setLoading(true);

        const { id, tenantId } = await retrieveTenant();

        const amenities = roomAmenetiesNames.reduce((acc: any, cur) => {
          acc[cur] = values.roomAmenities.includes(cur);

          return acc;
        }, {});

        if (values.calendarType === CalendarType.EWS) {
          await validateEWSCalendar({
            calendarID: values.calendarId,
            calendarProviderID: values.calendarProviderId,
          });
        }

        const variables: RoomRequestVariables = {
          tenantId,
          customerId: user?.customerid || "",
          name: values.name,
          tags: values.tags || [],
          id: parentId || id,
          description: values.description,
          amenities: {
            ...amenities,
            // reportingEmail: values.reportingEmail,
          },
          displaySettings: values.displaySettings,
          brokenAmenitiesReporting: values.brokenAmenitiesReporting,
          calendarId: values.calendarId,
          numberOfSeats: values.numberOfSeats,
          calendarProviderActive: values.calendarProviderActive,
          isBooked: values.isBooked,
          isBlocked: values.isBlocked,
          showCheckInTime: values.showCheckInTime,
          checkInTime: values.checkInTime,
          marker: { latitude: 0, longitude: 0 },
          roomDirection: [{ distance: 0, direction: 0 }],
          calendarProviderId: values.calendarProviderId,
          resourceEmail: values.resourceEmail || values.calendarId,
          workingWeekDays: !!workingHoursData?.workingWeekDays.length
            ? workingHoursData.workingWeekDays
            : [1, 2, 3, 4, 5],
          nearbyEnabled: customerPermissions?.nearbyRooms || false,
          // customLanguage: {

          // }
        };

        const handleAddRoom = async () => {
          try {
            const response = await addRoom(variables, parentType);

            const roomId = response.data?.addRoom.room[0].id;

            if (values.licensed && roomId) {
              await handleLicenseUpdate({
                id: roomId,
                type: EntityType.ROOM,
                value: values.licensed,
              });
            }

            if (response.data?.addRoom.room[0]) {
              //this refetch is needed to get the latest rooms in room's display settings's  Nearby rooms field
              refetchNearbyRooms();
            }
            toggle();
            toast.success("Room added successfully!");
          } catch (error: any) {
            toast.error(
              error.networkError.result.message
                ? error.networkError.result.message
                : "Couldn't add room, please reaload and try again!"
            );
            setLoading(false);
            return;
          }
        };
        await handleAddRoom();
      } catch (error: any) {
        toast.error(
          error.message
            ? error.message
            : "Couldn't add room, please reaload and try again!"
        );
        console.error(error.message);
        setLoading(false);
      }
    },
  });

  const defineRoomAmenities = (
    amenitiesObj: AmenitiesFilter | undefined
  ): string[] => {
    if (!amenitiesObj) {
      return [];
    }

    return Object.keys(amenitiesObj).filter(
      (key) => key !== "id" && amenitiesObj[key as keyof AmenitiesFilter]
    );
  };

  const fillFieldsFromProfile = (id: string) => {
    if (loadingRoomTemplate) {
      return;
    }

    if (errorRoomTemplates) {
      return toast.error(
        errorRoomTemplates ||
          "Cannot fill fields from profile due to error fetching them!"
      );
    }

    const room = roomTemplates.find((room) => room.id === id);

    if (!room) {
      return toast.error("Room profile not found!");
    }

    formik.setFieldValue("description", room.description);
    formik.setFieldValue("numberOfSeats", room.roomSettings?.numberOfSeats);
    formik.setFieldValue("tags", room.roomSettings?.tags);
    formik.setFieldValue("isBlocked", room.roomSettings?.isBlocked);
    formik.setFieldValue("displaySettings", room.displaySettings);
    formik.setFieldValue(
      "roomAmenities",
      defineRoomAmenities(room.roomSettings?.amenities)
    );
    formik.setFieldValue(
      "brokenAmenitiesReporting",
      room.roomSettings?.brokenAmenitiesReporting
    );
  };

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add new Room"
      onSubmit={formik.handleSubmit}
    >
      <RoomForm
        formik={formik}
        informationBoxDirection="left"
        loading={workingHoursLoading}
        error={error}
        workingHoursData={workingHoursData}
        handleWorkingHours={handleWorkingHours}
        defineLicenseNumber={defineLicenseNumber}
        isBussinesOrPro={isBussinesOrPro}
        fillFieldsFromProfile={fillFieldsFromProfile}
      />
    </ModalSidebar>
  );
};
