import React from "react";
import {
  ListPeopleFilter,
  ListPeopleRequest,
  People,
} from "../../../../api/grpc/contacts/contacts";
import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { tooltips } from "../../../../lib/utils/tooltips";
import { Icon } from "../../../shared/Icon/Icon";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { useBookingsContext } from "../../Context/BookingsContext";
import { CreatablePeopleList } from "./CreatablePeopleList";
import { SelectPeopleList } from "./SelectPeopleList";

interface Props {
  type: BookingTypes;
}

const WorkplaceComponent: {
  [key in BookingTypes]: React.FunctionComponent<any>;
} = {
  room: CreatablePeopleList,
  desk: SelectPeopleList,
};

export const FindPeopleButton = ({ type }: Props) => {
  const { isOpen, toggleModal } = useToggleModal();
  const { listPeople } = useContacts();
  const { attendees } = useBookingsContext();

  const { personalCalendar } = useCalendarContext();

  const { loading, error, data } = useGrpcQuery<ListPeopleRequest, People>({
    method: listPeople,
    variables: {
      personalCalendarID: type === "desk" ? "" : personalCalendar[0]?.iD || "",
      customerID: "",
      query: "",
      accountID: "",
      filter: ListPeopleFilter.ALL,
    },
  });

  const Component = WorkplaceComponent[type];

  let componentProps = {
    loading: loading,
    error: error,
    data: data,
  };

  return (
    <>
      <div className="flex-a-center SearchWorkplaceForm__people">
        <div className="flex-a-center SearchWorkplaceForm__people--counter">
          <div
            onClick={toggleModal}
            className="flex-a-center SearchWorkplaceForm__people--toggler"
          >
            <Icon icon="add-people" />

            <h5>{type === "room" ? "Add" : "Find"} people</h5>
          </div>
          <InformationBox
            message={
              type === "room"
                ? tooltips.bookings.rooms.addPeople
                : tooltips.bookings.desks.findPeople
            }
          />
          {type === "room" && attendees.length >= 1 && (
            <span>{"+" + attendees.length}</span>
          )}
        </div>
      </div>

      {isOpen && <Component {...componentProps} />}
    </>
  );
};
