import React, { useEffect, useState } from "react";
import { useTemplatesClient } from "../../../../api/grpc/workplaces/useTemplatesClient";
import { DeskTemplate } from "../../../../api/grpc/workplaces/workplaces";
import { DeskTemplateContext } from "./DeskTemplateContext";

interface Props {
  children: React.ReactNode;
}

export const DeskTemplateContextProvider = ({ children }: Props) => {
  const [deskTemplates, setDeskTemplates] = useState<DeskTemplate[]>([]);
  const [loadingDeskTemplate, setLoadingDeskTemplate] = useState(false);
  const [errorDeskTemplates, setErrorDeskTemplates] = useState<
    string | undefined
  >(undefined);
  const { listDeskTemplates } = useTemplatesClient();

  useEffect(() => {
    fetchDeskTemplates();
  }, []);

  const fetchDeskTemplates = async () => {
    try {
      setLoadingDeskTemplate(true);

      const {
        response: { results },
      } = await listDeskTemplates();

      setDeskTemplates(results);
    } catch (error: any) {
      setErrorDeskTemplates(error?.message);
    } finally {
      setLoadingDeskTemplate(false);
    }
  };

  const context: DeskTemplateContext = {
    deskTemplates,
    errorDeskTemplates,
    loadingDeskTemplate,
    refetchDeskTemplates: fetchDeskTemplates,
  };

  return (
    <DeskTemplateContext.Provider value={context}>
      {children}
    </DeskTemplateContext.Provider>
  );
};
