import {
  Interval,
  ListMeetingsRequest,
  ListMeetingsResponse,
} from "../../../../api/grpc/booking/booking";
import { useBookingRequest } from "../../../../api/grpc/booking/useBookingRequest";

import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";

export const useRoomReservations = (
  calendarProviderId?: string,
  personalEmail?: string
) => {
  const { listBookings } = useBookingRequest();
  const { timeZone } = useTimeZoneContext();

  const {
    loading: loadingRoom,
    error: errorRoom,
    data: roomData,
    refetch: refetchRoom,
  } = useGrpcQuery<ListMeetingsRequest, ListMeetingsResponse>({
    method: listBookings,
    variables: {
      calendarId: "",
      calendarproviderId: calendarProviderId || "",
      isPersonal: true,
      limit: 30,
      week: 1,
      personalEmail: personalEmail || "",
      customerId: "",
      interval: Interval.SEVENDAYS,
      timeZone,
    },
  });

  return {
    loadingRoom,
    errorRoom,
    roomData,
    refetchRoom,
  };
};
