import React from "react";
import cs from "classnames";

import { useSidebarContext } from "../../lib/context/Sidebar/SidebarContext";
import { useValidPersonalToken } from "../../lib/context/ValidateToken/ValidateTokenContext";
import { useCalendarContext } from "../../lib/context/Calendar/CalendarContext";

import { SidebarSubMenu } from "./SidebarSubMenu/SidebarSubMenu";
import { SidebarMainMenu } from "./SidebarMainMenu/SidebarMainMenu";
import { CalendarCredentialsStatus } from "../../api/grpc/calendarprovider/calendarprovider";

import "./Sidebar.scss";

export const Sidebar = () => {
  const { visibileMainMenu, isSubMenuActive } = useSidebarContext();
  const { statusOfToken } = useValidPersonalToken();
  const { dynamicStyles } = useCalendarContext();

  const classes = cs("Sidebar", {
    "Sidebar--visible": visibileMainMenu,
    "Sidebar--sub-active": isSubMenuActive,
    "Sidebar--adjustable":
      statusOfToken ===
      CalendarCredentialsStatus.CalendarCredentialsStatusInvalid,
  });

  return (
    <div className={classes} style={dynamicStyles}>
      <div className="Sidebar__menu_container border-right">
        <SidebarMainMenu />
      </div>
      <SidebarSubMenu />
    </div>
  );
};
