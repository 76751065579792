import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";

import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useDevicesRequests } from "../../../../api/graphql/devices/useDevicesRequests";
import { useEditDeviceFormik } from "../../../shared/Forms/DeviceForm/lib/useEditDeviceFormik";

import ModalSidebar from "../../../Modals/ModalSidebar/ModalSidebar";
import { DeviceFormFields } from "../../../../api/graphql/workplaces";
import { EditDeviceForm } from "../../../shared/Forms/DeviceForm/EditDeviceForm";
import { UpdateDeviceVariables } from "../../../../api/graphql/devices/devices";
import { DeviceInformation } from "./types";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  data: DeviceFormFields;
}

export const EditDevice = (props: Props) => {
  const { isOpen, toggle, data } = props;

  const [loading, setLoading] = useState(false);
  const [shouldUpdateDevice, setshouldUpdateDevice] = useState(false);

  const { user } = useAuthContext();
  const { checkDeviceVersion } = useWorkplacesClient();
  const { updateDevice } = useWorkplaceRequests();
  const {
    disconnectDevice,
    disconnectDeviceWayfinder,
    updateDeviceRoom,
    updateDeviceWayfinder,
  } = useDevicesRequests();

  useEffect(() => {
    let checkDevice = async () => {
      if (!!!data.softwareInfo) {
        return setshouldUpdateDevice(false);
      }

      let { response } = await checkDeviceVersion({
        deviceId: data.deviceId || "",
      });

      setshouldUpdateDevice(response.isUpdateRequired);
    };

    checkDevice();
  }, []);
  /*
    Submit callback calls three mutations.
    disconnectDevice & updateDeviceRoom are executed each in certain case, these two mutations are used to trigger the events sent to RDX.
    updateDevice is used to update the other data for that device.
  */
  const formik = useEditDeviceFormik({
    initialValues: {
      name: data.name,
      description: data.description,
      roomId: data?.roomId,
      wayFinderId: data?.wayFinderId,
      autoUpdate: data.autoUpdate,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);

      if (!user) {
        return;
      }

      try {
        const variables: UpdateDeviceVariables = {
          id: data.id || "",
          name: values.name,
          tenantId: user.customerid,
          description: values.description,
          autoUpdate: values.autoUpdate,
        };

        // Unpair the device if the new value is set to null
        if (data.wayFinderId && values.wayFinderId === null) {
          await disconnectDeviceWayfinder({
            id: data.id || "",
            wayFinderId: data.wayFinderId,
            unassignedSince: Math.floor(Date.now() / 1000),
            warnedUnassigned: true,
          });
        }

        if (data.roomId && values.roomId === null) {
          await disconnectDevice({
            id: data.id || "",
            roomId: data.roomId,
            unassignedSince: Math.floor(Date.now() / 1000),
            warnedUnassigned: true,
          });
        }

        if (
          (values.roomId !== null && data.roomId !== values.roomId) ||
          (values.wayFinderId !== null &&
            data.wayFinderId !== values.wayFinderId)
        ) {
          // Update the device room or wayfinder if the new value is different from the initial
          if (!!values.wayFinderId) {
            await updateDeviceWayfinder({
              id: data.id || "",
              wayFinderId: values.wayFinderId || "",
              oldWayfinderId: data.wayFinderId,
              tenantId: user.customerid,
              unassignedSince: 0,
              warnedUnassigned: false,
            });
          } else {
            await updateDeviceRoom({
              id: data.id || "",
              roomId: values.roomId || "",
              oldRoomId: data.roomId,
              tenantId: user.customerid,
              unassignedSince: 0,
              warnedUnassigned: false,
            });
          }
        }

        await updateDevice(variables);

        toggle();
        toast.success("Device edited successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Device couldn't be updated, please reload and try again!"
        );
        setLoading(false);
        console.error(error);
      }
    },
  });

  const deviceInfo: DeviceInformation | null = useMemo(() => {
    //if device unassigned return null
    if (!data.roomId && !data.wayFinderId) {
      return null;
    }

    if (!!data.hardwareInfo && !!data.softwareInfo) {
      const {
        softwareInfo: { androidOS, app },
        hardwareInfo: {
          brand,
          model,
          serialNumber,
          resultion,
          dpi,
          ip,
          mac,
          firmwareVersion,
          // lastPing,
        },
      } = data;
      return {
        softwareInfo: {
          "Android OS": androidOS,
          App: app,
        },
        hardwareInfo: {
          Brand: brand,
          Model: model,
          "Serial number": serialNumber,
          "Build number": firmwareVersion,
          "Resolution & DPI": resultion + "/" + dpi,
          "IP address": ip,
          "MAC address": mac,
          // "Last ping": !!lastPing
          //   ? format(new Date(lastPing), "yyyy-MM-dd HH:mm")
          //   : "",
        },
      };
    } else {
      return null;
    }
  }, [data]);

  const workspaceId = useMemo(() => {
    if (!!!data.wayFinderId && !!!data.roomId) {
      return "Unassigned";
    }

    return !!data.roomId ? data.roomId : data.wayFinderId;
  }, [data]);

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title={data.name}
      onSubmit={formik.handleSubmit}
    >
      <EditDeviceForm
        formik={formik}
        isWayfinderDevice={data.typeOfWorkspace === "wayFinder"}
        deviceInfo={deviceInfo}
        deviceId={data.deviceId || ""}
        workspaceId={workspaceId}
        shouldUpdateDevice={shouldUpdateDevice}
        appVersion={data.softwareInfo?.version}
      />
    </ModalSidebar>
  );
};
