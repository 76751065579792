import React from "react";

import { InformationBox } from "../InformationBox/InformationBox";

import "./StructureHeader.scss";

interface Props {
  title: string;
  hideBorder?: boolean;
  message?: string | undefined;
}

export const StructureHeader = ({ title, hideBorder, message }: Props) => {
  return (
    <div className="StructureHeader">
      <div className="StructureHeader__title">
        <h1>{title}</h1>
        {message && <InformationBox message={message} />}
      </div>
    </div>
  );
};
