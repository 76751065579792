import React from "react";
import { MatchingDesk } from "../../../../api/grpc/desk/desk";
import {
  BookingsSearchHeader,
  SearchHeaderInfo,
} from "../BookingsSearchHeader/BookingsSearchHeader";
import { BookingsDesksTable } from "../BookingsSearchTable/BookingsDesksTable";

interface Props {
  desks: MatchingDesk[] | null;
  info: SearchHeaderInfo;
}

export const BookingsDesksResult = ({ desks, info }: Props) => {
  return (
    <>
      {desks && desks?.length > 0 ? (
        <div>
          <BookingsSearchHeader type="desk" info={info} />
          <BookingsDesksTable desks={desks} />
        </div>
      ) : (
        <div>No results...</div>
      )}
    </>
  );
};
