import React, { useState } from "react";
import { toast } from "react-toastify";
import { utcToZonedTime } from "date-fns-tz";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { Event as DeskEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Event as RoomEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";

interface Props {
  type: BookingTypes;
  workplaceId: string;
  event: DeskEvent | RoomEvent;
  refetchDesk: () => void;
  startTime: string;
  endTime: string;
  name: string;
  locationPath: any;
  isOpen: boolean;
  toggleModal: () => void;
  userName: string;
}

export const CheckInDeskModal = ({
  type,
  workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
}: Props) => {
  const { checkInDesk } = useDeskBookingRequest();
  const { timeZone } = useTimeZoneContext();
  const [loading, setLoading] = useState(false);

  const handleCheckInDesk = async () => {
    try {
      setLoading(true);
      await checkInDesk(event?.metaData?.DESK_EVENT_ID);

      toast.success("Check-in finished successfully");

      refetchDesk();
    } catch (error: any) {
      setLoading(false);
      toast.error(
        error?.message || "Check-in failed, please reaload and try again!"
      );
    }
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="Check in?"
      icon="check-in-reservation"
      workspaceIconType={type === "desk" ? "desk-reserve" : "room-reserve"}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={dateToHoursAndMinutes(new Date(endTime), timeZone)}
      userName={userName}
      locationPath={locationPath}
      type="check-in"
      buttonClick={handleCheckInDesk}
      buttonTitle="Check in"
      subject={event?.title}
      handleModalClose={toggleModal}
      classNames="modal-footer-row"
    />
  );
};
