import { LatLngLiteral } from "leaflet";
import React, { useEffect, useRef } from "react";
import { Marker, Popup } from "react-leaflet";

export interface Props {
  position: LatLngLiteral;
  defaultOpen?: boolean;
  onOpen?: () => void;
  icon: L.Icon | L.DivIcon;
  children?: React.ReactNode;
}

export const BookingsFloorMapMarker = ({
  position,
  children,
  defaultOpen,
  icon,
}: Props) => {
  const markerRef = useRef<L.Marker>(null);

  useEffect(() => {
    let currentMarker = markerRef.current;

    if (currentMarker === null) {
      return;
    }

    if (defaultOpen === undefined && currentMarker.isPopupOpen()) {
      currentMarker.closePopup();
      return;
    }

    if (defaultOpen) {
      if (currentMarker.isPopupOpen()) {
        currentMarker.closePopup();
        return;
      }
      currentMarker.openPopup();
    }
  }, [defaultOpen]);

  return (
    <Marker icon={icon} ref={markerRef} position={position} draggable={false}>
      <Popup>{children}</Popup>
    </Marker>
  );
};
