import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { useValidPersonalToken } from "../../../lib/context/ValidateToken/ValidateTokenContext";
import { tooltips } from "../../../lib/utils/tooltips";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { PageHeader } from "../../shared/PageHeader/PageHeader";
import { ReservationListLoading } from "./ReservationListLoading";
import { Reservations } from "./Reservations";

export const ReservationsRoute = () => {
  const {
    loadingPersonalCalendar: loading,
    errorPersonalCalendar: error,
    personalCalendar,
  } = useCalendarContext();
  const { setStatusOfToken } = useValidPersonalToken();
  const { validateCalendarIntegrationCredentials } = useCalendarRequests();

  useEffect(() => {
    if (personalCalendar.length <= 0) {
      //no need to check token's validation if the user doens't have a personal calendar added
      return;
    }

    const checkPersonalToken = async () => {
      try {
        const {
          response: { status },
        } = await validateCalendarIntegrationCredentials(
          personalCalendar[0].iD
        );

        setStatusOfToken(status);
      } catch (error: any) {
        toast.error(error?.message);
        return;
      }
    };

    checkPersonalToken();

    if (localStorage.getItem("initiateLogin") === "1") {
      localStorage.removeItem("initiateLogin");
    }
  }, [personalCalendar]);

  return (
    <div className="Reservations default-page">
      <PageHeader
        title="My reservations"
        message={tooltips.bookings.reservations}
      />
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<ReservationListLoading />}
      >
        <HandleRequestState
          state={error}
          placeholder={
            <div>
              Couldn't fetch personal calendar. Please reload and try again.
            </div>
          }
        >
          {!!personalCalendar ? (
            <Reservations
              calendarProviderId={personalCalendar[0]?.iD}
              personalEmail={personalCalendar[0]?.name}
            />
          ) : (
            <ReservationListLoading />
          )}
        </HandleRequestState>
      </HandleLoadingState>
    </div>
  );
};
