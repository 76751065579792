import React from "react";
import { useGetDirectories } from "../../../components/DirectorySync/lib/useGetDirectories";
import { DirectoryIntegrationPicker } from "../../../components/DirectorySync/DirectoryIntegrationPicker/DirectoryIntegrationPicker";
import { DirectoryRotues } from "./DirectoryRotues/DirectoryRotues";

import "./DirectorySync.scss";

export const DirectorySync = () => {
  const { loading, error, data, refetch } = useGetDirectories();

  return (
    <div className="default-page DirectorySync">
      <DirectoryIntegrationPicker
        loading={loading}
        error={error}
        data={data}
        refetch={refetch}
      />

      <DirectoryRotues refetch={refetch} />
    </div>
  );
};
