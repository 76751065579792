import React from "react";
import cs from "classnames";
import { Icon } from "../Icon/Icon";

import { IconDefinition } from "../Icon/Icon.generated";

import "./DropdownList.scss";

export interface DropdownListOptions {
  type: string;
  label: string;
  disabled?: boolean;
  icon?: IconDefinition | string;
}

interface Props {
  extended?: boolean;
  color?: "gray" | "black";
  options: DropdownListOptions[];
  onClick: (value: string) => void;
}

export const DropdownList = ({
  color = "black",
  extended,
  options,
  onClick,
}: Props) => {
  const classes = cs("DropdownList", {
    "DropdownList--extended": extended,
    "DropdownList--gray": color === "gray",
  });

  return (
    <ul className={classes}>
      {options?.map((item) => (
        <li
          onClick={() => {
            if (item.disabled) {
              return;
            }

            onClick(item.type);
          }}
          key={`${item.label}-${item.icon}-listing`}
          className={item.disabled ? "disabled" : ""}
        >
          <Icon icon={item.icon as IconDefinition} />
          <h5>{item.label}</h5>
        </li>
      ))}
    </ul>
  );
};
