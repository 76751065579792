import React, { PropsWithChildren } from "react";
import { Icon } from "../../../shared/Icon/Icon";

export const IntegrationBanner = ({
  classes,
  reAuthButton,
  setOpenReAuthModal,
  header,
}: PropsWithChildren<{
  classes: string;
  reAuthButton: () => any;
  setOpenReAuthModal: (value: React.SetStateAction<boolean>) => void;
  header: string | JSX.Element;
}>) => {
  return (
    <div className={classes}>
      <div className="flex-a-center">
        <Icon icon="alert-exclamation" />
        {typeof header === "string" ? (
          <span className="ml-2">{header}</span>
        ) : (
          header
        )}
      </div>

      <div className="flex-a-center TokenToast__buttons ml-5">
        <button className="TokenToast__buttons--fix" onClick={reAuthButton}>
          Fix now
        </button>
        <div className="divider"></div>
        <button
          className="font-weight-300"
          onClick={() => {
            setOpenReAuthModal(true);
          }}
        >
          Why do I get this warning?
        </button>
      </div>
    </div>
  );
};
