import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "../../../../components/shared/Button/Button";
import { Icon } from "../../../../components/shared/Icon/Icon";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { RESERVATIONS_MAP_ROOT_PATH } from "../../../../lib/routes/routes";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { useBookingsContext } from "../../Context/BookingsContext";

import "./BookingOptions.scss";

export const BookingOptions = () => {
  const { history } = useRouter();
  const {
    emptyActiveTags,
    handleMatchedDesks,
    handleMatchedRooms,
    handleLocationId,
  } = useBookingsContext();

  const handleButtonClick = (type: BookingTypes) => () => {
    emptyActiveTags();
    handleMatchedDesks(null);
    handleMatchedRooms(null);

    // to handle some edge cases, we refresh the location when we go to desk/room booking search form
    // most important edge case is to handle when user add a custom workspace as location and that workspace is deleted
    handleLocationId("refreshedId", "RefreshedId");
    return history.push(`/bookings/reserve/search/${type}`);
  };

  return (
    <div className="BookingOptions">
      <h4>Make a reservation</h4>

      <Button
        title="Room"
        icon="room-reserve"
        size="medium"
        color="primary"
        onClick={handleButtonClick("room")}
      />
      <Button
        title="Desk"
        icon="desk-reserve"
        size="medium"
        color="primary"
        onClick={handleButtonClick("desk")}
      />

      <NavLink to={RESERVATIONS_MAP_ROOT_PATH}>
        <h4>Maps</h4>
        <span className="BookingOptions__map">
          <Icon icon="map-reserve" />
        </span>
      </NavLink>
    </div>
  );
};
