import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../../../lib/hooks/useOutsideClickDetection";
import { useWorkingHoursData } from "../../../../Account/helpers/useWorkingHoursData";

import { MeetingSpaceCostContent } from "./MeetingSpaceCostContent";

import "./MeetingSpaceCost.scss";

export const MeetingSpaceCost = () => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // we set state for cost widget opener here because if we do it in the MeetingSpaceCostContent
  // it has a bug that when we click on the content it toggles and closes the widget.
  const [openCost, setIsOpenCost] = useState(false);

  const { loading, error, handleWorkingHours, workingHoursData } =
    useWorkingHoursData();

  useOutsideClickDetection(
    ref,
    () => {
      if (openCost) {
        return;
      }
      return setIsOpen(false);
    },
    openCost
  );

  return (
    <div className="MeetingSpaceCost" ref={ref}>
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex-a-center MeetingSpaceCost__toggler"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      {isOpen && (
        <MeetingSpaceCostContent
          value={Math.round(workingHoursData?.meetingSpaceCostPerHr || 0)}
          closeModal={() => setIsOpen(false)}
          handleCostPerHr={handleWorkingHours}
          currency={workingHoursData?.currency}
          error={error}
          loading={loading}
          isOpen={openCost}
          setIsOpen={setIsOpenCost}
        />
      )}
    </div>
  );
};
