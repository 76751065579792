import React, { useMemo } from "react";
import { FloorFields } from "../../../api/graphql/floors/floors";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { trimText } from "../../../lib/helpers/trimText";
import { useEmptyRoomsAndDesks } from "../../Bookings/lib/useEmptyRoomsAndDesks";
import { Icon } from "../../shared/Icon/Icon";
import { useMarker } from "../lib/useMarkerData";
import { useTypesHelper } from "../lib/useTypesHelper";
import { AddMarkerButton } from "../MarkerButtons/AddMarkerButton";
import { DeleteMarkerButton } from "../MarkerButtons/DeleteMarkerButton";
import { EntityData, FloorChildrenList } from "./FloorChildrenList";

export type FloorChildrenType = "room" | "desk" | "wayfinder";
export type TypeOfFloorChildrenMethod = "add" | "delete";

interface Props {
  data: FloorFields;
  onClick: (id: string) => void;
}

export const FloorChildrenDefaultList = ({ data, onClick }: Props) => {
  const { user } = useAuthContext();

  const { methodTypes, markerDataTypes } = useTypesHelper();
  const { roomsOfFloor, desksOfFloor, wayfindersfFloor } = useMarker(data);

  const emptyData = useEmptyRoomsAndDesks(
    roomsOfFloor,
    desksOfFloor,
    wayfindersfFloor
  );

  // we check if map exists and store it in the variable below
  // if it doesnt exists we dont need to show Add/Delete buttons in rooms/desks list
  const mapDoesntExists = data?.map === null;

  const handleOpenMarkerPopup =
    (markerIsOnMap: boolean, id: string) => async () => {
      if (!markerIsOnMap) {
        return;
      }
      return onClick(id);
    };

  const handleFloorChildrenUpdate =
    (
      id: string,
      type: FloorChildrenType,
      typeOfMethod: TypeOfFloorChildrenMethod
    ) =>
    () => {
      let methodName = methodTypes[type];
      let methodType = markerDataTypes[typeOfMethod];

      return methodName({
        id: id,
        marker: methodType,
        tenantId: user?.customerid || "",
      });
    };

  const rowsData: EntityData[] = useMemo(() => {
    return [
      {
        data:
          data?.rooms &&
          roomsOfFloor?.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(
                    !!item?.marker?.latitude,
                    item.id
                  )}
                  className={
                    !!item?.marker?.latitude && !mapDoesntExists
                      ? "primary--text"
                      : ""
                  }
                >
                  {trimText(item.name, 15)}
                </span>
              ),
              location: item.zoneName ? item.zoneName : "",
              add:
                // item.marker.latitude is a number value that is bigger or lower than 0 if the marker is in map
                // we can use this in multiple cases to check if marker is on map as seen here, if its = 0 it means is not on the map
                mapDoesntExists ? (
                  <></>
                ) : item?.marker?.latitude === 0 ? (
                  <AddMarkerButton
                    className="AddMarkerButton"
                    onClick={handleFloorChildrenUpdate(item.id, "room", "add")}
                  />
                ) : (
                  <DeleteMarkerButton
                    className="DeleteMarkerButton"
                    color="third"
                    onClick={handleFloorChildrenUpdate(
                      item.id,
                      "room",
                      "delete"
                    )}
                  />
                ),
            };
          }),
        type: "Room",
        icon: "rooms-icon",
      },
      {
        data:
          data?.desks &&
          desksOfFloor?.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(
                    !!item?.marker?.latitude,
                    item.id
                  )}
                  className={!!item?.marker?.latitude ? "primary--text" : ""}
                >
                  {trimText(item.name, 15)}
                  {!!item.account ? <Icon icon="single-user-icon" /> : ""}
                </span>
              ),
              location: item.zoneName ? item.zoneName : "",

              add: mapDoesntExists ? (
                <></>
              ) : item?.marker?.latitude === 0 ? (
                <AddMarkerButton
                  className="AddMarkerButton"
                  onClick={handleFloorChildrenUpdate(item.id, "desk", "add")}
                />
              ) : (
                <DeleteMarkerButton
                  className="DeleteMarkerButton"
                  color="third"
                  onClick={handleFloorChildrenUpdate(item.id, "desk", "delete")}
                />
              ),
            };
          }),
        type: "Desk",
        icon: "desk_icon_48x48",
      },
      {
        data:
          data?.wayfinders &&
          wayfindersfFloor?.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(
                    !!item?.marker?.latitude,
                    item.id
                  )}
                  className={!!item?.marker?.latitude ? "primary--text" : ""}
                >
                  {trimText(item.name, 15)}
                  {!!item.account ? <Icon icon="single-user-icon" /> : ""}
                </span>
              ),
              location: item.zoneName ? item.zoneName : "",
              add: mapDoesntExists ? (
                <></>
              ) : item.marker === null || item?.marker?.latitude === 0 ? (
                <AddMarkerButton
                  className="AddMarkerButton"
                  onClick={handleFloorChildrenUpdate(
                    item.id,
                    "wayfinder",
                    "add"
                  )}
                />
              ) : (
                <DeleteMarkerButton
                  className="DeleteMarkerButton"
                  color="third"
                  onClick={handleFloorChildrenUpdate(
                    item.id,
                    "wayfinder",
                    "delete"
                  )}
                />
              ),
            };
          }),
        type: "Wayfinder",
        icon: "wayFinder_icon_48x48",
      },
    ];
  }, [data]);

  return emptyData ? (
    <h4 className="FloorChildrenDefaultList__empty">
      No rooms, desks or wayfinders
    </h4>
  ) : (
    <FloorChildrenList rows={rowsData} />
  );
};
