import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SubRouteProps } from "../../../lib/routes/routes";

import { useRouter } from "../../../lib/hooks/useRouter";
import { PageHeader } from "../../shared/PageHeader/PageHeader";
import { ResourceNavigation } from "../../shared/ResourceNavigation/ResourceNavigation";

import { InsightsHeaderButtons } from "../shared/InsightsHeaderButtons/InsightsHeaderButtons";
import { DeskValueInsights } from "./Value/DeskValueInsights";
import { DeskUtilization } from "./Utilization/DeskUtilization";
import { DeskRelease } from "./DeskRelease/DeskRelease";
import { InsightsContextProvider } from "../Context/InsightsContextProvider";
import { DateRangeContextProvider } from "../Context/DateRangeContext/DateRangeContextProvider";
import { InsightsForInfo } from "../shared/InsightsForInfo/InsightsForInfo";
import { tooltips } from "../../../lib/utils/tooltips";
import { TimeZoneLabel } from "../shared/TimeZoneLabel/TimeZoneLabel";
import { OverviewContextProvider } from "../Context/Desk/Overview/OverviewContextProvider";
import { UtilizationContextProvider } from "../Context/Desk/Utilization/UtilizationContextProvider";
import { BehaviourContextProvider } from "../Context/Desk/Behaviour/BehaviourContextProvider";

export const DeskInsights = () => {
  const { match, pathname } = useRouter();

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Overview",
      to: match.url,
      id: "desk-booking-insights-page",
      component: DeskValueInsights,
    },
    {
      exact: true,
      label: "Utilization",
      to: `${match.url}/utilization`,
      id: "desk-utilization-page",
      component: DeskUtilization,
    },
    {
      exact: true,
      label: "Behaviour",
      to: `${match.url}/behaviour`,
      id: "desk-behaviour-page",
      component: DeskRelease,
    },
  ];
  return (
    <DateRangeContextProvider>
      <InsightsContextProvider>
        <OverviewContextProvider>
          <UtilizationContextProvider>
            <BehaviourContextProvider>
              <div className="WorkplaceInsights default-page">
                <div className="PeopleHeader position-relative">
                  <PageHeader
                    title="Desk Insights Dashboard"
                    hideBorder
                    message={tooltips.insights.desks.heading}
                  >
                    <TimeZoneLabel />
                  </PageHeader>

                  <ResourceNavigation routes={routes}>
                    <InsightsHeaderButtons pathname={pathname} />
                  </ResourceNavigation>
                </div>

                <div className="position-relative">
                  <InsightsForInfo />

                  <Switch>
                    {routes.map((item) => (
                      <Route
                        key={item.id}
                        path={item.to}
                        component={item.component}
                        exact={item.exact ? true : undefined}
                      />
                    ))}
                    <Redirect to={routes[0].to} />
                  </Switch>
                </div>
              </div>
            </BehaviourContextProvider>
          </UtilizationContextProvider>
        </OverviewContextProvider>
      </InsightsContextProvider>
    </DateRangeContextProvider>
  );
};
