import React, { useMemo, useState } from "react";
import Select from "react-select";
import { People, Person } from "../../../../api/grpc/contacts/contacts";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { UserModal } from "../../shared/UserModal/UserModal";

interface Props {
  loading: boolean;
  data?: People;
  error: any;
}

// const { Option } = components;

// const CustomSelectOption = (props: any) => (
//   <Option {...props}>
//     <i className={`icon icon-${props.data.icon}`} />
//     {props.data.label}
//   </Option>
// );

// const CustomSelectValue = (props: any) => (
//   <div>
//     <i className={`icon icon-${props.data.icon}`} />
//     {props.data.label}
//   </div>
// );

// const options = [
//   { value: "item1", label: "Item 1", icon: "bar" },
//   { value: "item2", label: "Item 2", icon: "folder" },
// ];

export const SelectPeopleList = ({ loading, error, data }: Props) => {
  const [selectedUser, setSelectedUser] = useState<Person | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => {
    return data?.people.map((item) => ({
      value: item,
      label: item.displayName,
      // icon: item.avatar?.url || item.displayName.charAt(0),
      // label: (
      //   <PersonSelectItem
      //     imageUrl={item?.avatar?.url}
      //     name={item?.displayName}
      //   />
      // ),
    }));
  }, [data]);

  const handleSelectChange = (person: Person | null) => {
    if (!person) {
      setSelectedUser(null);
      return;
    }
    setSelectedUser(person);
    setIsOpen(true);
  };

  const selectedValue = useMemo(() => {
    return {
      label: selectedUser?.displayName,
      value: selectedUser,
    };
  }, [selectedUser]);

  return (
    <>
      {loading ? (
        <LoadingBox minHeight={50} />
      ) : error ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <Select
          // components={{
          //   Option: CustomSelectOption,
          //   SingleValue: CustomSelectValue,
          // }}
          className="FindPeople FindPeopleSearchDesk no-min-height"
          isClearable={true}
          options={options}
          onChange={(e) => handleSelectChange(e?.value || null)}
          value={selectedValue}
        />
      )}

      {isOpen && (
        <UserModal
          person={selectedUser}
          isOpen={isOpen}
          handleModalClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
