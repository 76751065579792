import React, { useState } from "react";
import { toast } from "react-toastify";

import { useWorkingHoursData } from "../../../../Account/helpers/useWorkingHoursData";
import { useWayfindersQuery } from "../../../../../lib/hooks/useWayfindersQuery";
import { useWayFinderFormik } from "../../../../shared/Forms/WayFinderForm/lib/useWayFinderFormik";
import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";

import { WorkplaceModalProps } from "../AddWorkplaceModal";
import { WayFinderForm } from "../../../../shared/Forms/WayFinderForm/WayFinderForm";
import ModalSidebar from "../../../../Modals/ModalSidebar/ModalSidebar";
import { WayfinderRequestVariables } from "../../../../../api/graphql/wayfinder/wayfinder";
import {
  useCustomerContext,
  useLicensesContext,
} from "../../../../../lib/context/Customer/CustomerContext";
import { EntityType } from "../../../../../api/grpc/subscription/subscription";

export const AddWayFinderModal = (props: WorkplaceModalProps) => {
  const { isOpen, toggle, parentId, parentType } = props;
  const { addWayfinder } = useWorkplaceRequests();
  const { retrieveTenant } = useCustomerContext();
  const { refetch } = useWayfindersQuery();
  const { user } = useAuthContext();
  const { handleLicenseUpdate } = useLicensesContext();
  const { workingHoursData } = useWorkingHoursData();

  const [loading, setLoading] = useState<boolean>(false);
  const formik = useWayFinderFormik({
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { id, tenantId } = await retrieveTenant();

        // only check for free slots if user tries to license
        // if (values.licensed) {
        //   const {
        //     response: { quantity },
        //   } = await licenseFreeSlots(EntityType.WAYFINDER);

        //   if (typeof quantity !== "number") {
        //     throw new Error("Licenses couldn't be loaded, please try again!");
        //   }

        //   if (quantity === 0) {
        //     throw new Error(
        //       "You've exceeded the quantity of licenses for wayfinders!"
        //     );
        //   }
        // }

        const variables: WayfinderRequestVariables = {
          tenantId,
          id: parentId || id,
          customerId: user?.customerid || "",
          tags: values.tags || [],
          description: values.description,
          name: values.name,
          marker: { latitude: 0, longitude: 0 },
          desks: values.desks || [],
          rooms: values.rooms || [],
          distanceUnit: values.distanceUnit,
          workingWeekDays: !!workingHoursData?.workingWeekDays.length
            ? workingHoursData.workingWeekDays
            : [1, 2, 3, 4, 5],
        };

        const handleAddWayfinder = async () => {
          try {
            const response = await addWayfinder(variables, parentType);

            const wayFinderId = response.data?.addWayfinder.wayfinder[0].id;

            if (values.licensed && wayFinderId) {
              await handleLicenseUpdate({
                id: wayFinderId,
                type: EntityType.WAYFINDER,
                value: values.licensed,
              });
            }

            if (response.data?.addWayfinder.wayfinder[0]) {
              //this refetch is needed to get the latest wayfinders in Device page if WFX is clickeds
              refetch();
            }

            toggle();
            toast.success("Wayfinder added successfully");
          } catch (error: any) {
            toast.error(
              error.networkError.result.message
                ? error.networkError.result.message
                : "Couldn't add wayfinder, please reload and try again!"
            );
            setLoading(false);
            return;
          }
        };

        await handleAddWayfinder();
      } catch (error: any) {
        toast.error(error.toString());
        setLoading(false);
      }
    },
  });

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add new Wayfinder"
      onSubmit={formik.handleSubmit}
    >
      <WayFinderForm formik={formik} />
    </ModalSidebar>
  );
};
