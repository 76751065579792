import React from "react";
import cs from "classnames";
import { NavLink } from "react-router-dom";

import { SubRouteProps } from "../../../lib/routes/routes";

import "./ResourceNavigation.scss";

interface Props {
  children?: React.ReactNode;
  routes: SubRouteProps[];
}

export const ResourceNavigation = (props: Props) => {
  const { routes, children } = props;

  const classes = cs("ResourceNavigation", {
    "ResourceNavigation--content": !!children,
  });

  return (
    <div className={classes}>
      {routes.map((item) => (
        <NavLink
          replace
          to={item.to}
          key={`${item.id}-navlink`}
          className="ResourceNavigation__link"
          exact={item.exact ? true : undefined}
        >
          {item.label}
        </NavLink>
      ))}
      {children}
    </div>
  );
};
