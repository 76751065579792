import { ListDirectoryProviderResponse } from "../../../api/grpc/calendarprovider/calendarprovider";
import { useDirectoryIntegrationsRequests } from "../../../api/grpc/calendarprovider/useDirectoryIntegrationsRequests";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";

export const useGetDirectories = () => {
  const { listOfDirectoryIntegration } = useDirectoryIntegrationsRequests();

  const { loading, error, data, refetch } = useGrpcQuery<
    {},
    ListDirectoryProviderResponse
  >({
    method: listOfDirectoryIntegration,
    variables: {},
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
