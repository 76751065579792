// this function returns all children of a floor and handles the case
// when a floor has a Zone that cotains rooms/desks:

import { WorkplaceFields } from "../../../api/graphql/workplaces";
import { Attendee } from "../../../api/grpc/ggevent/ggevent";
import { BusyTimeSlots } from "../../../api/grpc/workplaces/ggevent/ggevent";

interface Marker {
  id: string;
  type: any;
  name: string;
  latlng: [number, number];
  location: string;
  isBooked?: boolean;
  isBlocked?: boolean;
  tags: string[];
  isAssigned?: boolean;
  user?: string;
  username?: string;
  busySlots?: any;
}

export interface FloorChildrenType extends WorkplaceFields {
  zoneName?: string;
  busySlots?: BusyTimeSlots[];
  isAssigned?: boolean;
  organizer?: Attendee;
  allowDeleteMeetings?: boolean;
}

// @TODO fix any below:

export const useFloorData = (data: any) => {
  let roomsOfFloor: FloorChildrenType[] = [];
  let desksOfFloor: FloorChildrenType[] = [];
  let wayfindersfFloor: FloorChildrenType[] = [];

  if (data?.zones) {
    data.zones.map((zone: WorkplaceFields) => {
      zone.rooms?.map((room: FloorChildrenType) => {
        room = {
          ...room,
          zoneName: zone.name,
        };

        roomsOfFloor.push(room);
      });

      zone.desks?.map((desk: FloorChildrenType) => {
        desk = {
          ...desk,
          zoneName: zone.name,
        };
        desksOfFloor.push(desk);
      });

      zone.wayfinders?.map((wayfinder: FloorChildrenType) => {
        wayfinder = {
          ...wayfinder,
          zoneName: zone.name,
        };
        wayfindersfFloor.push(wayfinder);
      });
    });
  }

  data?.rooms?.map((room: WorkplaceFields) => {
    roomsOfFloor.push(room);
  });

  data?.desks?.map((desk: WorkplaceFields) => {
    desksOfFloor.push(desk);
  });

  data?.wayfinders?.map((wayfinder: WorkplaceFields) => {
    wayfindersfFloor.push(wayfinder);
  });

  return { roomsOfFloor, desksOfFloor, wayfindersfFloor };
};

// this function creates a new array that contains data of rooms and desks
// those data are then added as markers

export const useMarkerData = (
  rooms: FloorChildrenType[],
  desks: FloorChildrenType[],
  wayfinders: FloorChildrenType[]
) => {
  let markerData: Array<Array<Marker>> = [
    rooms
      .filter(
        (room) =>
          room?.marker?.latitude !== undefined && room?.marker?.latitude !== 0
      )
      .map((item) => {
        return {
          id: item.id,
          type: "Room",
          name: item.name,
          latlng: [item?.marker?.latitude, item?.marker?.longitude],
          isBooked: item.isBooked,
          location: item.zoneName,
          busySlots: item.busySlots,
          tags: item.tags,
        } as Marker;
      }),
    desks
      .filter(
        (desk) =>
          desk?.marker?.latitude !== undefined && desk?.marker?.latitude !== 0
      )
      .map((item) => {
        return {
          id: item.id,
          type: "Desk",
          name: item.name,
          latlng: [item?.marker?.latitude, item?.marker?.longitude],
          isBooked: item.isBooked,
          isBlocked: item.isBlocked,
          isAssigned: item.isAssigned,
          location: item.zoneName,
          user: !!item.account ? item.account.displayName : "",
          username: !!item.organizer ? item.organizer.displayName : "",
          busySlots: item.busySlots,
          tags: item.tags,
        } as Marker;
      }),
    wayfinders
      .filter(
        (wayfinder) =>
          wayfinder?.marker?.latitude !== undefined &&
          wayfinder?.marker?.latitude !== 0
      )
      .map((item) => {
        return {
          id: item.id,
          type: "Wayfinder",
          name: item.name,
          latlng: [item?.marker?.latitude, item?.marker?.longitude],
          location: item.zoneName,
          user: !!item.account ? item.account.displayName : "",
          tags: item.tags,
        } as Marker;
      }),
  ];

  return markerData;
};
