import { toast } from "react-toastify";
import { useBookingRequest } from "../../../../api/grpc/booking/useBookingRequest";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";

import { Event } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Event as RoomEvent } from "../../../../api/grpc/booking/ggevent/ggevent";

export const useReservationHelpers = () => {
  const { deleteDeskBooking, updateDeskBooking } = useDeskBookingRequest();
  const { updateRoomBookings, deleteRoomBooking } = useBookingRequest();

  const deleteDeskReservation = async (
    deskID: string,
    eventID: string,
    refetch: () => void
  ) => {
    try {
      await deleteDeskBooking({
        customerID: "",
        accountID: "",
        deskID: deskID,
        eventID: eventID,
      });

      toast.success("Reservation deleted successfully");

      refetch();
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Deletion of reservation failed, please reload and try again!"
      );
    }
  };

  const updateDeskReservation = async (
    deskID: string,
    eventID: string,
    refetch: () => void,
    event: Event
  ) => {
    try {
      await updateDeskBooking({
        customerID: "",
        accountID: "",
        deskID: deskID,
        eventID: eventID,
        event: event,
      });

      toast.success("Reservation updated successfully");

      refetch();
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Update of reservation failed, please reload and try again!"
      );
    }
  };

  const updateRoomBooking = async (
    calendarId: string,
    calendarproviderId: string,
    meeting: RoomEvent,
    refetch: () => void
  ) => {
    try {
      await updateRoomBookings({
        calendarId: calendarId,
        calendarproviderId: calendarproviderId,
        customerId: "",
        meeting: meeting,
        activateCheckOut: false,
      });
      toast.success("Reservation updated successfully");

      refetch();
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Update of reservation failed, please reload and try again!"
      );
    }
  };

  const deleteRoomReservation = async (
    calendarId: string,
    calendarproviderId: string,
    eventId: any,
    refetch: () => void,
    systemDelete: boolean,
    organizerEmail: string
  ) => {
    try {
      await deleteRoomBooking({
        customerId: "",
        calendarId: calendarId,
        calendarproviderId: calendarproviderId,
        metaData: eventId,
        systemDelete: systemDelete,
        organizerEmail: organizerEmail,
      });

      toast.success("Reservation deleted successfully");

      refetch();
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Deletion of reservation failed, please reload and try again!"
      );
    }
  };

  return {
    deleteDeskReservation,
    updateDeskReservation,
    updateRoomBooking,
    deleteRoomReservation,
  };
};
