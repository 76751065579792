/*
 * Used as part of GOGET react-native application
 *
 *  - intended for display map in mobile app via WebView
 *  - not intended for use elsewhere
 */

import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MapContainer, ImageOverlay, Marker, MapConsumer } from "react-leaflet";
import L from "leaflet";
import { ConsumerAction, Resource } from "@gogetcorp/floor-map-bridge";
// components
import { RoomIcon } from "./components/RoomIcon";
import { DeskIcon } from "./components/DeskIcon";
import { ReservationsIcon } from "./components/ReservationsIcon";
// containers
import { MessageListener } from "./containers/MessageListener";
import { MapBoundsController } from "./containers/MapBoundsController";
import { PanToController } from "./containers/PanToController";
// utils
import { sendMessageToRn } from "./utils/send-message-to-rn";
import { getImageSize } from "./utils/overlay.utils";

import "./RNMapView.scss";
import { WayfinderIcon } from "./components/WayfinderIcon";

type Size = {
  width: number;
  height: number;
};

const initialZoom = 0;
const maxZoom = 6;
const minZoom = -6;
const style = {
  minHeight: "100vh",
  maxHeight: "100vh",
  backgroundColor: "transparent",
};
const center: L.LatLngTuple = [0, 0];

const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const onResize = useCallback(() => {
    if (
      window.innerHeight !== size.height ||
      window.innerWidth !== size.width
    ) {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
  }, [size]);

  useEffect(() => {
    document.addEventListener("resize", onResize);

    return () => {
      document.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return [size, onResize] as const;
};

const RNMapView: FC = () => {
  // state
  const [windowSize, refreshWindowSize] = useWindowSize();
  const [overlay, setOverlay] = useState<
    ConsumerAction.OverlaySet["payload"] | null
  >(null);
  const [markers, setMarkers] = useState<ConsumerAction.MarkersSet["payload"]>({
    rooms: [],
    desks: [],
    wayfinders: [],
  });
  const [bgColor, setBgColor] =
    useState<ConsumerAction.SetBgColor["payload"]>("#FFFFFF");
  const [mapConfig, setMapConfig] = useState<Required<Resource.MapConfig>>({
    deskMarkerSize: 50,
    roomMarkerSize: 80,
    wayfinderMarkerWidth: 88,
  });
  const [focusedMarkerId, setFocusedMarkerId] = useState<string | null>(null);
  const [isBoundsReady, setIsBoundsReady] = useState<boolean>(false);
  const [activeReservationMarkerIds, setActiveReservationMarkerIds] = useState<
    string[]
  >([]);
  // memo
  const [mapImageSize, setMapImageSize] = useState<Size | null>(null);
  const overlayBounds = useMemo<L.LatLngBoundsLiteral | null>(() => {
    if (!mapImageSize) {
      return null;
    }

    return [
      [mapImageSize.height / 2, mapImageSize.width / 2],
      [-mapImageSize.height / 2, -mapImageSize.width / 2],
    ];
  }, [mapImageSize]);
  const mapBounds = useMemo<L.LatLngBoundsLiteral | null>(() => {
    if (!mapImageSize) {
      return null;
    }
    function getSideShouldFit(
      container: Size,
      image: Size
    ): "width" | "height" {
      const widthRatio = container.width / image.width;
      const heightRatio = container.height / image.height;

      return widthRatio > heightRatio ? "height" : "width";
    }

    const sideShouldFit = getSideShouldFit(windowSize, mapImageSize);

    if (sideShouldFit === "width") {
      return [
        [0, mapImageSize.width / 2],
        [-0, -mapImageSize.width / 2],
      ];
    }

    return [
      [mapImageSize.height / 2, 0],
      [-mapImageSize.height / 2, -0],
    ];
  }, [mapImageSize, windowSize]);
  const mapMaxBounds = useMemo<L.LatLngBoundsLiteral | null>(() => {
    if (!overlayBounds) {
      return null;
    }

    return [
      [overlayBounds[0][0] * 1.6, overlayBounds[0][1]],
      [overlayBounds[1][0] * 1.6, overlayBounds[1][1]],
    ];
  }, [overlayBounds]);
  // refs
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    sendMessageToRn({
      type: "state",
      payload: "idle",
    });
  }, []);

  useEffect(() => {
    if (overlay && mapImageSize && isBoundsReady) {
      sendMessageToRn({
        type: "state",
        payload: "ready",
      });
    }
  }, [overlay, mapImageSize, isBoundsReady]);

  useEffect(() => {
    if (overlay) {
      setMapImageSize(null);
      getImageSize(overlay.url).then(setMapImageSize);
    }
  }, [overlay]);

  const handleRoomMarkerPress = useCallback(
    (roomId: string, customerId: string) => () => {
      sendMessageToRn({
        type: "room-click",
        payload: {
          customerId,
          roomId,
        },
      });
    },
    []
  );

  const handleDeskMarkerPress = useCallback(
    (deskId: string, customerId: string) => () => {
      sendMessageToRn({
        type: "desk-click",
        payload: {
          customerId,
          deskId,
        },
      });
    },
    []
  );

  const handleReservationMarkerPress = useCallback(
    (idMarker: string) => () => {
      setActiveReservationMarkerIds((prev) => {
        if (prev.includes(idMarker)) {
          setFocusedMarkerId((_focusedMarker) => {
            if (_focusedMarker === idMarker) {
              return null;
            }

            return _focusedMarker;
          });
          return [...prev].filter((id) => id !== idMarker);
        }

        setFocusedMarkerId(idMarker);
        return [idMarker];
      });
    },
    []
  );

  const handleMapConfigChange = useCallback((config: Resource.MapConfig) => {
    setMapConfig((prev) => ({
      ...prev,
      ...config,
    }));
  }, []);

  const handleMapCenter = useCallback(() => {
    setTimeout(() => {
      refreshWindowSize();
    }, 1000);
    setFocusedMarkerId(null);
    mapRef.current?.panTo(center);
    if (mapBounds) {
      mapRef.current?.fitBounds(mapBounds);
    }
  }, [mapBounds, refreshWindowSize]);

  return (
    <div style={{ backgroundColor: bgColor }}>
      <MapContainer
        crs={L.CRS.Simple}
        minZoom={minZoom}
        maxZoom={maxZoom}
        zoom={initialZoom}
        center={center}
        zoomControl={false}
        zoomSnap={0.15}
        zoomDelta={0.25}
        style={style}
        attributionControl={false}
      >
        <MapConsumer>
          {(map) => {
            mapRef.current = map;
            return null;
          }}
        </MapConsumer>
        <MapBoundsController
          mapBounds={mapBounds}
          mapMaxBounds={mapMaxBounds}
          onReady={setIsBoundsReady}
        />
        <PanToController markers={markers} markerIdToFocus={focusedMarkerId} />
        {!!overlay && !!overlayBounds && (
          <ImageOverlay
            url={overlay.url}
            opacity={overlay.opacity}
            bounds={overlayBounds}
          />
        )}
        {markers.rooms?.map(({ lat, lng, id, customerId, isBooked }) => (
          <Marker
            key={id}
            position={[lat, lng]}
            eventHandlers={{
              click: handleRoomMarkerPress(id, customerId),
            }}
            icon={RoomIcon({
              isBooked,
              isFocused: id === focusedMarkerId,
              size: mapConfig.roomMarkerSize,
            })}
          />
        ))}
        {markers.desks?.map(
          ({ lat, lng, id, customerId, isBooked, reservations, isBlocked }) => (
            <>
              {reservations.length > 0 && (
                <Marker
                  key={`${id}-reservation`}
                  position={[lat, lng]}
                  eventHandlers={{
                    click: handleReservationMarkerPress(`${id}-reservation`),
                  }}
                  icon={ReservationsIcon({
                    isActive: activeReservationMarkerIds.includes(
                      `${id}-reservation`
                    ),
                    reservations,
                  })}
                  zIndexOffset={
                    activeReservationMarkerIds.includes(`${id}-reservation`)
                      ? 2
                      : 1
                  }
                />
              )}
              <Marker
                key={id}
                position={[lat, lng]}
                eventHandlers={{
                  click: isBlocked
                    ? () => {}
                    : handleDeskMarkerPress(id, customerId),
                }}
                icon={DeskIcon({
                  isBooked,
                  id,
                  isBlocked,
                  isFocused: id === focusedMarkerId,
                  size: mapConfig.deskMarkerSize,
                })}
              />
            </>
          )
        )}
        {markers.wayfinders?.map(({ lat, lng, id }) => (
          <Marker
            key={id}
            position={[lat, lng]}
            icon={WayfinderIcon({
              width: mapConfig.wayfinderMarkerWidth,
            })}
          />
        ))}
        <MessageListener
          zoom={{ initial: initialZoom, min: minZoom, max: maxZoom }}
          onOverlayUrlChange={setOverlay}
          onMarkerFocusChange={setFocusedMarkerId}
          onMarkersChange={setMarkers}
          onBgColorChange={setBgColor}
          onMapConfigChange={handleMapConfigChange}
          onMarkerReservationFocusSet={(markerReservationId) => {
            setActiveReservationMarkerIds((prev) => {
              return [markerReservationId, ...prev];
            });
          }}
          onMarkerReservationFocusClear={(markerReservationId) => {
            setActiveReservationMarkerIds((prev) => {
              return prev.filter((id) => id !== markerReservationId);
            });
          }}
          onMapCenter={handleMapCenter}
        />
      </MapContainer>
    </div>
  );
};

export { RNMapView };
