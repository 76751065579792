import React, { useEffect, useState } from "react";
import { Form, FormGroup, Modal } from "reactstrap";
import { Icon } from "../../../../shared/Icon/Icon";
import DatePicker from "react-datepicker";
import { timeFormat } from "../../../../Bookings/helpers/displayTimeFormat";

import {
  addMinutes,
  format,
  set,
  subMinutes,
  setMinutes,
  setHours,
} from "date-fns";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";

import { CustomerSettings } from "../../../../../api/grpc/customer/customer";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { CustomerSettingsVariables } from "../../InsightsCard/Insights/Insights";
import { ModalHeaderContent } from "../../../../Modals/ModalHeaderContent/ModalHeaderContent";
import SelectWorkingDays from "./SelectWorkingDays";
import { ModalFooterContent } from "../../../../Modals/ModalFooterContent/ModalFooterContent";

import "../../../../shared/Forms/DisplaySettingsForm/shared/WorkingHours.scss";
import "./WorkHours.scss";

interface Props {
  hasButton?: boolean;
  isOpen: boolean;
  toggleModal: () => void;
  handleWorkingHours: ({
    startTime,
    endTime,
    workingWeekDays,
  }: CustomerSettingsVariables) => void;
  loading: boolean;
  data?: CustomerSettings;
}

export const WorkHours = ({
  hasButton,
  isOpen,
  toggleModal,
  handleWorkingHours,
  loading,
  data,
}: Props) => {
  const { timeZoneFormatted } = useTimeZoneContext();
  const [startTime, setStartTime] = useState(
    set(new Date(), {
      hours: 8,
      minutes: 0,
      seconds: 0,
    })
  );
  const [endTime, setEndTime] = useState(
    set(new Date(), {
      hours: 17,
      minutes: 0,
      seconds: 0,
    })
  );

  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  useEffect(() => {
    if (data) {
      setStartTime(
        set(new Date(), {
          hours: +data.startTime.split(":")[0],
          minutes: +data.startTime.split(":")[1],
          seconds: +data.startTime.split(":")[2],
        })
      );

      setEndTime(
        set(new Date(), {
          hours: +data.endTime.split(":")[0],
          minutes: +data.endTime.split(":")[1],
          seconds: +data.endTime.split(":")[2],
        })
      );

      setSelectedDays(data.workingWeekDays);
    }
  }, [data]);

  return (
    <>
      {hasButton && (
        <div className="flex-a-center cursor-pointer" onClick={toggleModal}>
          <Icon icon="work-hours" />
          <h3>Work Days & Hours</h3>
        </div>
      )}
      {isOpen && (
        <Modal
          title="Work Days & Hours"
          isOpen={isOpen}
          onToggle={toggleModal}
          className="WorkHours"
        >
          <ModalHeaderContent
            title="Work Days & Hours"
            handleToggle={toggleModal}
          />

          <span className="WorkHours__info pb-4">
            These settings are used for the Schedule Only During Work Hours
            property as well as Insights.
          </span>
          <Form className="WorkingHours">
            <SelectWorkingDays
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />

            <span className="WorkHours__form--label">Global work hours</span>
            <div className="WorkingHoursForm">
              <div className="flex-a-center WorkingHoursForm__selectors pt-2">
                <FormGroup>
                  <label>Start time</label>
                  {loading ? (
                    <LoadingBox minHeight={40} />
                  ) : (
                    <DatePicker
                      selected={startTime}
                      onChange={(date) => {
                        if (!date) {
                          return;
                        }

                        setStartTime(date);

                        if (date >= endTime) {
                          setEndTime(addMinutes(new Date(date), 15));
                        }

                        return;
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      timeFormat={timeFormat}
                      dateFormat={timeFormat}
                    />
                  )}
                </FormGroup>

                <FormGroup>
                  <label>End time</label>
                  {loading ? (
                    <LoadingBox minHeight={40} />
                  ) : (
                    <DatePicker
                      selected={endTime}
                      onChange={(date) => {
                        if (!date) {
                          return;
                        }
                        setEndTime(date);

                        if (date <= startTime) {
                          setStartTime(subMinutes(new Date(date), 15));
                        }

                        return;
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      timeFormat={timeFormat}
                      dateFormat={timeFormat}
                      injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
                    />
                  )}
                </FormGroup>
              </div>
            </div>
            <span className="WorkHours__minor">
              The current time zone for your global work hours is{" "}
              {timeZoneFormatted}
            </span>
          </Form>

          <ModalFooterContent
            toggleModal={toggleModal}
            handleSave={() => {
              handleWorkingHours({
                startTime: format(startTime, "HH:mm:ss"),
                endTime: format(endTime, "HH:mm:ss"),
                workingWeekDays: selectedDays,
              });
              toggleModal();
              return;
            }}
          />
        </Modal>
      )}
    </>
  );
};
