import React from "react";
import cs from "classnames";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useValidPersonalToken } from "../../../lib/context/ValidateToken/ValidateTokenContext";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";

import { getSecondLevelRoutes } from "../../../lib/routes";
import { filterMenuLevel } from "../../../lib/routes/filterMenuLevel";

import { MainRouteProps } from "../../../lib/routes/routes";
import { SidebarSubMenuList } from "./SidebarSubMenuList/SidebarSubMenuList";
import { CalendarCredentialsStatus } from "../../../api/grpc/calendarprovider/calendarprovider";

import "./SidebarSubMenu.scss";

export const SidebarSubMenu = () => {
  const { user } = useAuthContext();
  const items = getSecondLevelRoutes();
  const { statusOfToken } = useValidPersonalToken();
  const { dynamicStyles } = useCalendarContext();

  const classes = cs("SidebarSubMenu border-right d-block pt-4", {
    "SidebarSubMenu--adjustable":
      statusOfToken ===
      CalendarCredentialsStatus.CalendarCredentialsStatusInvalid,
  });
  return (
    <div className={classes} style={dynamicStyles}>
      {items.map((item) => {
        const menu = filterMenuLevel(
          item.menu as Array<MainRouteProps>,
          user?.claims.role
        );

        return (
          <SidebarSubMenuList
            items={menu}
            parentID={item.parent}
            parentPath={item.parentPath}
            key={`${item.parent}-submenu-item`}
          />
        );
      })}
    </div>
  );
};
