import React, { useMemo } from "react";
import { addDays, differenceInMinutes, format, isSameDay, set } from "date-fns";
import { useBookingsMapContext } from "../../Context/BookingsMapContext";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime } from "date-fns-tz";
import { getDuration } from "../../../lib/datePickerHelper";
import { BusyTimeSlots } from "../../../../../api/grpc/desk/ggevent/ggevent";
import { Icon } from "../../../../shared/Icon/Icon";

interface Props {
  isBooked: boolean;
  busySlot: BusyTimeSlots[];
}

export const PopUpAvailablitity = ({ isBooked, busySlot }: Props) => {
  const { timeZone } = useTimeZoneContext();

  const { dateAndTime } = useBookingsMapContext();

  let definedTime =
    differenceInMinutes(new Date(), new Date(dateAndTime)) <= 0
      ? dateAndTime
      : new Date().toISOString();

  const availabilityText = useMemo<string>(() => {
    if (!busySlot) {
      return "";
    }

    if (busySlot[0]?.isAllDay === true && isBooked) {
      return "All day";
    }

    if (isBooked && busySlot[0]?.endTime) {
      const { days, hours, minutes } = getDuration(
        new Date(definedTime),

        new Date(busySlot[0]?.endTime)
      );

      const untilTimeIsNextDay = isSameDay(
        utcToZonedTime(new Date(definedTime), timeZone),
        utcToZonedTime(new Date(busySlot[0].endTime), timeZone)
      );

      const untilTime = format(
        utcToZonedTime(new Date(busySlot[0]?.endTime), timeZone),
        untilTimeIsNextDay === false ? "dd MMM HH:mm" : "HH:mm"
      );

      return `Reserved for ${days ? `${days}d ` : ""}${
        hours ? `${hours}h ` : ""
      }${minutes ? `${minutes}min` : ""} until ${untilTime}`;
    }

    if (!isBooked && busySlot[0]?.startTime) {
      const { days, hours, minutes } = getDuration(
        new Date(definedTime),
        new Date(busySlot[0]?.startTime)
      );

      const untilTimeIsNextDay = isSameDay(
        utcToZonedTime(new Date(definedTime), timeZone),
        utcToZonedTime(new Date(busySlot[0].startTime), timeZone)
      );

      const untilTime = format(
        utcToZonedTime(new Date(busySlot[0]?.startTime), timeZone),
        untilTimeIsNextDay === false ? "dd MMM HH:mm" : "HH:mm"
      );

      return `for ${days ? `${days}d ` : ""}${hours ? `${hours} h ` : ""}${
        minutes ? `${minutes} min ` : ""
      }until ${untilTime}`;
    }

    const { hours, minutes } = getDuration(
      utcToZonedTime(new Date(definedTime), timeZone),
      set(addDays(utcToZonedTime(new Date(definedTime), timeZone), 1), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
    );

    return `for ${hours ? `${hours} h ` : ""}${
      minutes ? `${minutes} min ` : ""
    }until 00:00`;
  }, [busySlot, timeZone]);

  return (
    <div className="BookingsMapPopUp__availability pt-2 pb-2">
      <div
        className="flex-a-center
      justify-content-center"
      >
        <Icon icon={isBooked ? "red-rounded" : "green-rounded"} />
        <div className="pl-min">{isBooked ? "Reserved" : "Now Available"}</div>
      </div>
      <div className="pt-1">{availabilityText}</div>
    </div>
  );
};
