import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useCalendarProvider } from "../../../../api/grpc/calendarprovider/useCalendarProvider";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";

import { tooltips } from "../../../../lib/utils/tooltips";
import { Icon } from "../../../../components/shared/Icon/Icon";
import { InformationBox } from "../../../../components/shared/InformationBox/InformationBox";
import { ResourceNavigation } from "../../../../components/shared/ResourceNavigation/ResourceNavigation";
import { CalendarAccountsList } from "../../../../components/Calendars/CalendarAccountsList/CalendarAccountsList";

interface Props {
  type?: String;
  shouldRefetchAllCalendars?: boolean;
}

export const CalendarDefaultSingle = ({
  type,
  shouldRefetchAllCalendars,
}: Props) => {
  const { query, match } = useRouter();
  const { loading, error, data, refetch } = useCalendarProvider(
    query.id,
    shouldRefetchAllCalendars
  );

  // TO DO: Fix memory leak warning when this component is being mounted.
  const routes = [
    {
      exact: true,
      to: match.url,
      label: "Calendars",
      id: "calendars-list",
      render: () => (
        <CalendarAccountsList
          data={data}
          error={error}
          loading={loading}
          calendarType={query.calendarType}
        />
      ),
    },
  ];

  const handleSyncClick = () => {
    if (loading) {
      return;
    }

    refetch();
  };

  return (
    <>
      <ResourceNavigation routes={routes}>
        <div className="CalendarSingle__sync">
          <h4>Sync</h4>
          <InformationBox
            direction="left"
            message={tooltips.calendars[type as "gSuite" | "o365"]}
          />
          <Icon icon="sync-icon" onClick={handleSyncClick} />
        </div>
      </ResourceNavigation>
      <Switch>
        {routes.map((item) => (
          <Route
            key={item.id}
            path={item.to}
            render={item.render}
            exact={item.exact ? true : undefined}
          />
        ))}
        <Redirect to={routes[0].to} />
      </Switch>
    </>
  );
};
