import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import { useToggleModal } from "../../../lib/hooks/useToggleModal";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { Notifications } from "../../Notifications/Notifications";
import { LeaveFeedbackModal } from "../LeaveFeedback/LeaveFeedbackModal";
import { HelpCenter } from "../HelpCenter/HelpCenter";

import { GENERAL_STRINGS as t } from "../../../lib/utils/constants";

import defaultAvatar from "../../../assets/images/default-avatar.png";

import "./UserNavHeader.scss";

export const UserNavHeader = () => {
  const { history } = useRouter();
  const { user, signOut } = useAuthContext();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isOpen, toggleModal } = useToggleModal();
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleSignOut = async () => {
    try {
      await signOut();

      history.push("/login");
    } catch (error: any) {
      console.error(error.message);
    }
  };

  return (
    <div className="UserNavHeader d-flex align-items-center">
      <HelpCenter />
      {/* <Notifications /> */}
      {user?.displayName && (
        <h3 className="UserNavHeader__name">{user.displayName}</h3>
      )}
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <div className="UserNavHeader__avatar">
            <img src={user?.picture || defaultAvatar} alt="GOGET Avatar" />
          </div>
        </DropdownToggle>
        <DropdownMenu right={true}>
          {user?.name && (
            <DropdownItem header>{`Hi, ${user.displayName}`}</DropdownItem>
          )}
          <DropdownItem divider />
          <DropdownItem tag={NavLink} to="/account">
            Account
          </DropdownItem>
          <DropdownItem onClick={toggleModal}>Leave feedback</DropdownItem>
          {isOpen && <LeaveFeedbackModal isOpen toggle={toggleModal} />}
          <DropdownItem>
            <a
              href="https://support.gogetcorp.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="UserNavHeader__link"
            >
              Help center
            </a>
          </DropdownItem>
          <DropdownItem onClick={handleSignOut}>{t.logOut}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
