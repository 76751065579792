import React, { forwardRef, useCallback, useRef } from "react";
import ReactPopup from "reactjs-popup";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";

import { Icon } from "../Icon/Icon";

import { useCombinedRefs } from "../../../lib/hooks/useCombinedRefs";

import "./Popup.scss";

type PopupComponent = React.ForwardRefExoticComponent<
  PopupProps &
    React.RefAttributes<PopupActions> & {
      title?: string;
    }
>;

export const Popup: PopupComponent = forwardRef(
  ({ title, children, ...props }, ref) => {
    const popupRef = useRef<PopupActions>(null);
    const combinedRefs = useCombinedRefs<PopupActions>(ref, popupRef);

    const handleClose = useCallback(() => {
      if (combinedRefs.current) {
        combinedRefs.current.close();
      }
    }, [combinedRefs]);

    return (
      <ReactPopup ref={combinedRefs} arrow={false} offsetY={16} {...props}>
        {!!title && <h5 className="PopupTitle">{title}</h5>}
        <span className="PopupCloseButton" onClick={handleClose}>
          <Icon icon="close-icon" />
        </span>
        {children}
      </ReactPopup>
    );
  }
);
