import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Form, FormGroup, Modal } from "reactstrap";
import { toast } from "react-toastify";

import { CustomerSettingsVariables } from "../InsightsCard/Insights/Insights";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { Input } from "../../../shared/Input/Input";
import { WorkspaceCostInfo } from "./WorkspaceCostInfo/WorkspaceCostInfo";
import { ModalHeaderContent } from "../../../Modals/ModalHeaderContent/ModalHeaderContent";
import { ModalFooterContent } from "../../../Modals/ModalFooterContent/ModalFooterContent";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  cost?: number;
  currency?: string;
  handleCostPerHr: ({
    meetingSpaceCostPerHr,
    currency,
  }: CustomerSettingsVariables) => void;
  loading: boolean;
  error?: Error;
}

export const WorkspaceCostModal = ({
  isOpen,
  setIsOpen,
  cost,
  currency,
  handleCostPerHr,
  error,
  loading,
}: Props) => {
  const [costToChange, setCostToChange] = useState<number | undefined>(0);
  const [currencyToChange, setCurrencyToChange] = useState<string | undefined>(
    ""
  );

  const updateData = useCallback(() => {
    setCostToChange(cost);
    setCurrencyToChange(currency);
  }, [cost, currency]);

  useEffect(() => {
    updateData();
  }, [cost, currency]);

  return (
    <Modal
      title="WorkspaceCostModal"
      isOpen={isOpen}
      onToggle={() => setIsOpen((prev) => !prev)}
      className="WorkHours WorkspaceCostModal"
    >
      <ModalHeaderContent
        title="Input workspace cost per work hour"
        handleToggle={() => setIsOpen(false)}
      />

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <CenteredBox>
            <LoadingBox minHeight={80} maxWidth={300} />
          </CenteredBox>
        }
      >
        <HandleRequestState
          state={!!error}
          placeholder={<CenteredBox>Error fetching data</CenteredBox>}
        >
          <span className="WorkHours__info pb-4">
            The following calculation example can help you.
          </span>

          <WorkspaceCostInfo />

          <div className="WorkingHoursForm WorkHours__info">
            <Form className="flex-a-center WorkingHoursForm__selectors pt-2">
              <FormGroup className="WorkingHoursForm__value w-100">
                <label className="label__main">
                  Value - workspace cost per work hour
                </label>

                <Input
                  type="number"
                  name="cost"
                  id="cost"
                  value={costToChange}
                  onChange={(e) => {
                    setCostToChange(+e.target.value || undefined);
                  }}
                />
              </FormGroup>

              <FormGroup className="WorkingHoursForm__currency position-relative w-100">
                <label className="label__main">Currency</label>

                <Input
                  type="select"
                  name="currency"
                  id="currency"
                  value={currencyToChange}
                  onChange={(e) => setCurrencyToChange(e.target.value)}
                >
                  <option>USD</option>
                  {/* <option>EUR</option> */}
                </Input>

                <span className="WorkingHoursForm__currency--arrow"></span>
              </FormGroup>
            </Form>
          </div>
          <ModalFooterContent
            toggleModal={() => {
              setCostToChange(cost);
              setCurrencyToChange(currency);
              setIsOpen(false);
              return;
            }}
            handleSave={() => {
              if (costToChange && costToChange <= 0) {
                return toast.error("Invalid cost value.");
              }
              handleCostPerHr({
                meetingSpaceCostPerHr: costToChange,
                currency: currencyToChange,
              });
              setIsOpen(false);
              return;
            }}
            disabled={!!!costToChange}
          />
        </HandleRequestState>
      </HandleLoadingState>
    </Modal>
  );
};
