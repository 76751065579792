import React from "react";
import { format } from "date-fns";

import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import { useInsightsContext } from "../../InsightsContext";

import {
  DeskInsightsOverviewResponse,
  GeneralStatsByDesksResponse,
  TimeRangeEventsResponse,
} from "../../../../../api/grpc/insights/insights";
import {
  TimeRangeRequest,
  useDeskInsightsRequests,
} from "../../../../../api/grpc/insights/useDeskInsightsRequests";
import { OverviewInsights, OverviewInsightsContext } from "./OverviewContext";

interface Props {
  children: React.ReactNode;
}

export const OverviewContextProvider = ({ children }: Props) => {
  const { getDesksOverview, getDeskReservationTimeRange, getGeneralDeskStats } =
    useDeskInsightsRequests();
  const { timeZone } = useTimeZoneContext();

  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const {
    loading: loadingOverview,
    error: errorOverview,
    data: dataOverview,
  } = useGrpcQuery<TimeRangeRequest, DeskInsightsOverviewResponse>({
    method: getDesksOverview,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingRange,
    error: errorRange,
    data: dataRange,
  } = useGrpcQuery<TimeRangeRequest, TimeRangeEventsResponse>({
    method: getDeskReservationTimeRange,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingGeneral,
    error: errorGeneral,
    data: dataGeneral,
  } = useGrpcQuery<TimeRangeRequest, GeneralStatsByDesksResponse>({
    method: getGeneralDeskStats,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const context: OverviewInsights = {
    loadingOverview,
    errorOverview,
    dataOverview,
    loadingRange,
    errorRange,
    dataRange,
    loadingGeneral,
    errorGeneral,
    dataGeneral,
  };

  return (
    <OverviewInsightsContext.Provider value={context}>
      {children}
    </OverviewInsightsContext.Provider>
  );
};
