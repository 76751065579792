import React, { useState } from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";

import { Event as DeskEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Event as RoomEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import { useReservationHelpers } from "../../Reservations/helpers/reservationUpdateHelpers";
import { useApolloClient } from "@apollo/client";
import { capitalizeFirstLetter } from "../../../../lib/helpers/capitalizeFirstLetter";
import {
  getWorkplaceFragment,
  getWorkplaceFragmentName,
} from "../../../Workplaces/helpers/getWorkplaceFragment";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";

interface Props {
  type: BookingTypes;
  workplaceId: string;
  event: DeskEvent | RoomEvent;
  refetchDesk: () => void;
  refetchRoom: () => void;
  startTime: string;
  endTime: string;
  name: string;
  locationPath: any;
  isOpen: boolean;
  toggleModal: () => void;
  userName: string;
}

export const UpdateReservationModal = ({
  type,
  workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
  refetchRoom,
}: Props) => {
  const client = useApolloClient();
  const { timeZone } = useTimeZoneContext();
  // state
  const [selectedTime, setSelectedTime] = useState(
    utcToZonedTime(new Date(endTime), timeZone)
  );
  const [loading, setLoading] = useState(false);
  // contexts

  const { updateDeskReservation, updateRoomBooking } = useReservationHelpers();
  const { personalCalendar } = useCalendarContext();
  const { allDay } = useBookingsMapContext();

  const workplaceData = client.readFragment({
    id: `${capitalizeFirstLetter(type)}:${workplaceId}`,
    fragmentName: getWorkplaceFragmentName(capitalizeFirstLetter(type)),
    fragment: getWorkplaceFragment(capitalizeFirstLetter(type)),
  });

  let eventTime: DeskEvent | RoomEvent = {
    ...event,
    endTime: zonedTimeToUtc(selectedTime, timeZone).toISOString(),
    eventTimeZone: timeZone,
    allDay: allDay,
  };

  const definedUsername =
    type === "desk" || !!!personalCalendar.length
      ? userName
      : event.organizer?.displayName || "";

  const handleUpdateReservation = async () => {
    if (event.allDay === true) {
      return;
    }

    setLoading(true);
    if (type === "room") {
      await updateRoomBooking(
        personalCalendar.length > 0 ? "" : workplaceData?.calendarId,
        personalCalendar.length > 0
          ? personalCalendar[0].iD
          : workplaceData?.calendarProviderId,
        eventTime as RoomEvent,
        refetchRoom
      );
      return setLoading(false);
    }

    await updateDeskReservation(
      workplaceId,
      event.metaData.DESK_EVENT_ID,
      refetchDesk,
      eventTime as DeskEvent
    );
    return setLoading(false);
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="Modify reservation?"
      workspaceIconType={type === "desk" ? "desk-reserve" : "room-reserve"}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      // day={useFormatReservationDate(new Date(startTime))}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={
        type === "desk" && event.allDay === true
          ? "00:00"
          : dateToHoursAndMinutes(new Date(endTime), timeZone)
      }
      userName={definedUsername}
      locationPath={locationPath}
      type="update"
      buttonClick={handleUpdateReservation}
      buttonTitle="Update"
      disableButton={loading}
      subject={event?.title}
      loadSpinner={loading}
      loadTitle="Updating"
      handleModalClose={toggleModal}
      updateTime={selectedTime}
      handleUpdateTime={(e) => setSelectedTime(e)}
      classNames="modal-footer-row"
      allDay={event.allDay}
      timeZone={timeZone}
    />
  );
};
