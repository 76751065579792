import React, { useMemo, useState } from "react";
import { BusyTimeSlots } from "../../../../api/grpc/workplaces/ggevent/ggevent";
import { trimText } from "../../../../lib/helpers/trimText";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { MainResources } from "../../../../lib/types/main.types";
import {
  EntityData,
  FloorChildrenList,
} from "../../../Floors/FloorChildrenList/FloorChildrenList";
import { FloorChildrenType } from "../../../Floors/lib/floorData";
import { AddMarkerButton } from "../../../Floors/MarkerButtons/AddMarkerButton";
import { DeleteMarkerButton } from "../../../Floors/MarkerButtons/DeleteMarkerButton";
import { Icon } from "../../../shared/Icon/Icon";
import { BookingsButtonsModal } from "../BookingsMapModal/BookingsButtonsModal";

interface Props {
  rooms: FloorChildrenType[];
  desks: FloorChildrenType[];
  hasUrl?: boolean;
  refetch: () => void;
  handleOpenMarker: (id: string) => void;
  email?: string;
}

export const BookingsMapMarkerList = ({
  rooms,
  desks,
  hasUrl,
  refetch,
  handleOpenMarker,
  email,
}: Props) => {
  const { isOpen, toggleModal } = useToggleModal();
  const [activeWorkplace, setActiveWorkplace] = useState<{
    id: string;
    type: string;
    typeOfWorkplace: MainResources;
    busyTimeSlots: BusyTimeSlots[];
  }>({
    id: "",
    type: "",
    typeOfWorkplace: "desk",
    busyTimeSlots: [],
  });

  const handleButton =
    (
      id: string,
      type: string,
      typeOfWorkplace: MainResources,
      busyTimeSlots?: BusyTimeSlots[]
    ) =>
    () => {
      if (!busyTimeSlots) {
        return;
      }
      setActiveWorkplace({
        id,
        type,
        typeOfWorkplace,
        busyTimeSlots,
      });
      toggleModal();
    };

  const rowsData: EntityData[] = useMemo(() => {
    return [
      {
        data: rooms
          ?.filter(
            (room) => !!room.marker?.latitude && !!room.marker?.longitude
          )
          .map((item: FloorChildrenType) => {
            return {
              name: (
                <span
                  className={
                    !!item?.marker?.latitude && hasUrl ? "primary--text" : ""
                  }
                  onClick={() => {
                    handleOpenMarker(item.id);
                  }}
                >
                  {trimText(item.name, 15)}
                </span>
              ),
              // location: item.zoneName ? item.zoneName : "",
              isBooked: item.isBooked,
              nextReservation: item.busySlots,
              add:
                // item.marker.latitude is a number value that is bigger or lower than 0 if the marker is in map
                // we can use this in multiple cases to check if marker is on map as seen here, if its = 0 it means is not on the map
                !hasUrl || item?.isAssigned ? (
                  <></>
                ) : item?.isBooked ? (
                  <></>
                ) : (
                  <AddMarkerButton
                    className="AddMarkerButton"
                    onClick={handleButton(
                      item?.id,
                      "add",
                      "room",
                      item.busySlots
                    )}
                  />
                ),
            };
          }),
        type: "Room",
        icon: "rooms-icon",
      },
      {
        data: desks
          ?.filter(
            (desk) => !!desk.marker?.latitude && !!desk.marker?.longitude
          )
          .map((item: FloorChildrenType) => {
            return {
              name: (
                <span
                  className={!!item?.marker?.latitude ? "primary--text" : ""}
                  onClick={() => {
                    handleOpenMarker(item.id);
                  }}
                >
                  {trimText(item.name, 15)}{" "}
                  {!!item.organizer ? <Icon icon="single-user-icon" /> : ""}
                </span>
              ),
              isBooked: item.isBooked,
              nextReservation: item.busySlots,
              add:
                !hasUrl || item?.isAssigned || item?.isBlocked ? (
                  <></>
                ) : item?.isBooked ? (
                  <>
                    {item.organizer?.email !== email ? (
                      <></>
                    ) : (
                      <DeleteMarkerButton
                        className="DeleteMarkerButton"
                        color="third"
                        onClick={handleButton(
                          item?.id,
                          "delete",
                          "desk",
                          item.busySlots
                        )}
                      />
                    )}
                  </>
                ) : (
                  <AddMarkerButton
                    className="AddMarkerButton"
                    onClick={handleButton(
                      item?.id,
                      "add",
                      "desk",
                      item.busySlots
                    )}
                  />
                ),
            };
          }),
        type: "Desk",
        icon: "desk_icon_48x48",
      },
    ];
  }, [rooms, desks]);

  return rowsData[0].data?.length === 0 && rowsData[1].data?.length === 0 ? (
    <h4 className="FloorChildrenDefaultList__empty">No rooms or desks</h4>
  ) : (
    <>
      <FloorChildrenList rows={rowsData} typeOfMap="bookings" />

      {isOpen && (
        <BookingsButtonsModal
          id={activeWorkplace.id}
          type={activeWorkplace.type}
          isOpen={isOpen}
          toggleModal={toggleModal}
          typeOfWorkplace={activeWorkplace.typeOfWorkplace}
          busySlots={activeWorkplace.busyTimeSlots}
          refetch={refetch}
        />
      )}
    </>
  );
};
