import React, { PropsWithChildren } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { useDeskInsightsRequests } from "../../../../api/grpc/insights/useDeskInsightsRequests";
import { useCustomerPermissions } from "../../../Billings/lib/useCustomerPermissions";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";

import {
  TimeRangeRequest,
  useInsightsRequests,
} from "../../../../api/grpc/insights/useInsightsRequests";
import { useInsightsContext } from "../../Context/InsightsContext";

import { Button } from "../../../shared/Button/Button";
import { MainResources } from "../../../../lib/types/main.types";

export const ExportButton = ({
  pathname,
}: PropsWithChildren<{ pathname: string }>) => {
  const { isBasicOrBussines } = useCustomerPermissions();
  const { exportRoomInsights } = useInsightsRequests();
  const { exportDeskInsights } = useDeskInsightsRequests();
  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();
  const { timeZone } = useTimeZoneContext();

  const handleExport = async () => {
    if (isBasicOrBussines) {
      return toast.error("This feature requires the Pro subscription plan.");
    }

    try {
      let exportType: MainResources = pathname.includes("room")
        ? "room"
        : "desk";

      let variables: TimeRangeRequest = {
        locationID: locationId,
        startDate: format(startTime, "yyyy-MM-dd"),
        endDate: format(endTime, "yyyy-MM-dd"),
        timeZone,
      };

      const { response } =
        exportType === "room"
          ? await exportRoomInsights(variables)
          : await exportDeskInsights(variables);

      let data = new Blob([response.data], { type: "text/csv" });
      let csvURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute(
        "download",
        `${exportType === "room" ? "Room" : "Desk"}-Insights.csv`
      );

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      tempLink.remove();
    } catch (error: any) {
      return toast.error(error?.message);
    }
  };

  return (
    <Button
      title="Export"
      color="third"
      size="medium"
      onClick={() => handleExport()}
      icon="export-icon"
      className="InsightsHeaderButtons__export"
    />
  );
};
