import gql from "graphql-tag";

import { DESK_FIELDS_FRAGMENT } from "../desks/desks";
import { ROOM_FIELDS_FRAGMENT } from "../rooms/rooms";
import { ZONE_FIELDS_FRAGMENT } from "../zones/zones";

import {
  WorkplaceFields,
  WorkplaceTenantFields,
  WorkplaceDefaultFields,
} from "../workplaces";
import { WAY_FINDER_FIELDS_FRAGMENT } from "../wayfinder/wayfinder";

export interface FloorFields extends WorkplaceDefaultFields {
  zones?: WorkplaceFields[];
  rooms?: WorkplaceFields[];
  desks?: WorkplaceFields[];
  wayfinders?: WorkplaceFields[];
  map: Map | null;
}

export interface MapFields {
  id: string;
  url: string;
  opacity: number;
  showGridline: boolean;
}

export interface UpdateMapResponse {
  updateMap: {
    map: MapFields[];
  };
}

export interface UpdateFloorResponse {
  updateFloor: {
    floor: FloorFields[];
  };
}

export interface Map {
  id: string;
  url: string;
  opacity: number;
  showGridline: boolean;
}

export interface FloorRequestVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
  map: Map | null;
  remove?: {
    tags: string[];
  };
}

export interface AddFloorVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
  map: Map | null;
}

export interface AddFloorResponse {
  addFloor: {
    floor: FloorFields[];
  };
}

export const MAP_FIELDS_FRAGMENT = gql`
  fragment MapFields on Map {
    id
    url
    opacity
    showGridline
  }
`;

export const FLOOR_FIELDS_FRAGMENT = gql`
  fragment FloorFields on Floor {
    id
    name
    tags
    description
    map {
      id
      url
      opacity
      showGridline
    }
    zones(order: { asc: name }) {
      ...ZoneFields
    }
    rooms(order: { asc: name }) {
      ...RoomFields
    }
    desks(order: { asc: name }) {
      ...DeskFields
    }
    wayfinders(order: { asc: name }) {
      ...WayfinderFields
    }
  }
  ${ZONE_FIELDS_FRAGMENT}
  ${ROOM_FIELDS_FRAGMENT}
  ${DESK_FIELDS_FRAGMENT}
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_FLOOR_TO_TENANT = gql`
  mutation addFloorToTenantMutation(
    $id: ID
    $name: String
    $description: String
    $map: MapRef
    $tags: [String]
    $tenantId: String!
  ) {
    addFloor(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
          tenant: { id: $id }
        }
      ]
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const ADD_FLOOR_TO_COMPANY = gql`
  mutation addFloorToCompanyMutation(
    $id: ID
    $name: String
    $description: String
    $map: MapRef
    $tags: [String]
    $tenantId: String!
  ) {
    addFloor(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
          company: { id: $id }
        }
      ]
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const ADD_FLOOR_TO_SITE = gql`
  mutation addFloorToSiteMutation(
    $id: ID
    $name: String
    $description: String
    $map: MapRef
    $tags: [String]
    $tenantId: String!
  ) {
    addFloor(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
          site: { id: $id }
        }
      ]
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const ADD_FLOOR_TO_BUILDING = gql`
  mutation addFloorToBuildingMutation(
    $id: ID
    $name: String
    $description: String
    $map: MapRef
    $tags: [String]
    $tenantId: String!
  ) {
    addFloor(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
          building: { id: $id }
        }
      ]
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const UPDATE_FLOOR = gql`
  mutation updateFloorMutation(
    $id: ID!
    $name: String
    $tags: [String]
    $description: String
    $map: MapRef
    $tenantId: String!
    $remove: FloorPatch
  ) {
    updateFloor(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
        }
        remove: $remove
      }
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const REMOVE_FLOOR_MAP = gql`
  mutation removeMapFromFloor($id: ID!, $mapId: ID) {
    updateFloor(
      input: { filter: { id: [$id] }, remove: { map: { id: $mapId } } }
    ) {
      floor {
        id
        map {
          id
          url
          opacity
          showGridline
        }
      }
    }
  }
`;

export const UPDATE_FLOOR_MAP_SETTINGS = gql`
  mutation updateFloorMapSettings(
    $id: ID!
    $opacity: Float
    $showGridline: Boolean
    $url: String
  ) {
    updateMap(
      input: {
        filter: { id: [$id] }
        set: { opacity: $opacity, showGridline: $showGridline, url: $url }
      }
    ) {
      map {
        ...MapFields
      }
    }
  }
  ${MAP_FIELDS_FRAGMENT}
`;
