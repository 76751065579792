import React from "react";

import "./HomeCardList.scss";

export interface HomeCardList {
  title: string;
  value?: number;
  progress?: number;
}

interface Props {
  list: HomeCardList[];
  adjustable?: boolean;
}

export const HomeCardList = (props: Props) => {
  const { list, adjustable } = props;

  return (
    <div className="HomeCardList">
      {list.map((item) => (
        <div
          key={`${item.title}-${item.value}`}
          className={`HomeCardList__item HomeCardList__${item.title}`}
        >
          <h4 className="HomeCardList__title">{item.title}</h4>
          {adjustable && <div className="adjustable"></div>}
          {item.value !== undefined ? (
            <h4 className="HomeCardList__value">
              {typeof item.progress === "number"
                ? `${item.progress}/${item.value}`
                : item.value}
            </h4>
          ) : (
            <h4 className="HomeCardList__value">N/A</h4>
          )}
          {typeof item.progress === "number" && item.value !== undefined && (
            <div className="HomeCardList__line">
              <span
                className={`progress${
                  (item.progress / item.value) * 100 === 100 ? " full" : ""
                }`}
                style={{
                  width: `${
                    item.progress === 0 ? 0 : (item.progress / item.value) * 100
                  }%`,
                }}
              ></span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
