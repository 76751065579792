import React, { useContext } from "react";
import { CalendarCredentialsStatus } from "../../../api/grpc/calendarprovider/calendarprovider";

export interface ValidateTokenContext {
  openReAuthModal: boolean;
  setOpenReAuthModal: React.Dispatch<React.SetStateAction<boolean>>;
  statusOfToken?: CalendarCredentialsStatus;
  setStatusOfToken: React.Dispatch<
    React.SetStateAction<CalendarCredentialsStatus | undefined>
  >;
  reAuthButton: () => any;
  openEWSForm: boolean;
  setOpenEWSForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValidateTokenContext = React.createContext<ValidateTokenContext>({
  openReAuthModal: false,
  setOpenReAuthModal: () => {},
  statusOfToken: undefined,
  setStatusOfToken: () => {},
  reAuthButton: () => {},
  openEWSForm: false,
  setOpenEWSForm: () => {},
});

export function useValidPersonalToken() {
  return useContext(ValidateTokenContext);
}
