import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Form, Modal } from "reactstrap";
import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";

import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { Switch } from "../../../../shared/Switch/Switch";
import { CustomerSettingsVariables } from "./Insights";
import { tooltips } from "../../../../../lib/utils/tooltips";
import { ModalHeaderContent } from "../../../../Modals/ModalHeaderContent/ModalHeaderContent";
import { ModalFooterContent } from "../../../../Modals/ModalFooterContent/ModalFooterContent";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleInsightsCollect: ({
    collectInsights,
  }: CustomerSettingsVariables) => void;
  collectData?: boolean;
  loading: boolean;
  error?: Error;
}

export const InsightsModal = ({
  isOpen,
  setIsOpen,
  handleInsightsCollect,
  collectData,
  loading,
  error,
}: Props) => {
  const [collectDataToChange, setCollectDataToChange] = useState<
    boolean | undefined
  >(false);

  const updateData = useCallback(() => {
    setCollectDataToChange(collectData);
  }, [collectData]);

  useEffect(() => {
    updateData();
  }, [collectData]);

  return (
    <Modal
      title="Service emails"
      isOpen={isOpen}
      onToggle={() => setIsOpen((prev) => !prev)}
      className="WorkHours"
    >
      <ModalHeaderContent
        title="Insights"
        handleToggle={() => setIsOpen(false)}
      />

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <CenteredBox>
            <LoadingBox minHeight={80} maxWidth={300} />
          </CenteredBox>
        }
      >
        <HandleRequestState
          state={!!error}
          placeholder={<CenteredBox>Error fetching data</CenteredBox>}
        >
          <span className="WorkHours__info pb-4">Manage data collection</span>
          <div className="WorkingHoursForm WorkHours__info">
            <Form className="flex-a-center WorkingHoursForm__selectors pt-2">
              <Switch
                name="collectData"
                value={collectDataToChange}
                onChange={() => {
                  setCollectDataToChange((prev) => !prev);
                }}
                title="Enable data collection of events"
                message={tooltips.account.enableDataCollection}
                className="mb-3"
              />
            </Form>
          </div>
          <ModalFooterContent
            toggleModal={() => {
              setCollectDataToChange(collectData);
              setIsOpen(false);
              return;
            }}
            handleSave={() => {
              handleInsightsCollect({
                collectInsights: collectDataToChange,
              });
              setIsOpen(false);
              return;
            }}
          />
        </HandleRequestState>
      </HandleLoadingState>
    </Modal>
  );
};
