import React, { FC } from "react";

import { DebugInfoCard } from "./DebugInfoCard/DebugInfoCard";
import { BookingAndNotificationsCard } from "./BookingAndNotificationsCard/BookingAndNotificationsCard";
import { InsightsCard } from "./InsightsCard/InsightsCard";
import { SSOCard } from "./SSOCard/SSOCard";
import { TimeZoneCard } from "./TimeZoneCard/TimeZoneCard";

import { useWorkingHoursData } from "../../Account/helpers/useWorkingHoursData";

import "./OrganizationSettings.scss";

export const OrganizationSettings: FC = () => {
  const workingHoursData = useWorkingHoursData();

  return (
    <div className="OrganizationSettigs__root">
      <BookingAndNotificationsCard workingHoursDataQuery={workingHoursData} />
      <InsightsCard workingHoursDataQuery={workingHoursData} />
      <SSOCard workingHoursDataQuery={workingHoursData} />
      <TimeZoneCard />
      <DebugInfoCard />
    </div>
  );
};
