import React from "react";
import { Label, LabelProps } from "reactstrap";
import { InformationBox } from "../InformationBox/InformationBox";
import cs from "classnames";

import "./LabelWithInformationBox.scss";

export type LabelDirectionType = "left" | "right";

interface Props extends LabelProps {
  title: string;
  message?: string;
  direction?: LabelDirectionType;
  className?: string;
  informationBoxClassName?: string;
}

export const LabelWithInformationBox = (props: Props) => {
  const { direction, className, informationBoxClassName } = props;

  const classes = cs("LabelWithInformationBox", className);

  return (
    <div className={classes}>
      <Label for={props.for}>{props.title}</Label>
      {props.message && (
        <InformationBox
          direction={direction}
          message={props.message}
          className={informationBoxClassName}
        />
      )}
    </div>
  );
};
