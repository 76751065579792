import React, { Dispatch, SetStateAction } from "react";
import {
  Contact,
  NullableBoolean,
} from "../../../../api/grpc/contacts/contacts";
import { IContactFilters } from "../../../../components/People/ContactsFilters/ContactsFilters";

import { PaginationContext } from "../../../../lib/context/Pagination/PaginationContext";

export interface PeopleContext {
  error?: Error;
  data?: Contact[];
  loading: boolean;
  pagination: PaginationContext;
  refetchUsers: () => void;
  updateUsersList: () => void;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  debouncedValue: string;
  contactsFilters: IContactFilters;
  setContactsFilters: React.Dispatch<React.SetStateAction<IContactFilters>>;
  deleteLoader: boolean;
  setDeleteLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const values: PeopleContext = {
  loading: false,
  refetchUsers: () => {},
  updateUsersList: () => {},
  deleteLoader: false,
  setDeleteLoader: () => {},
  pagination: {
    key: "",
    page: 1,
    total: 1,
    pageSize: 10,
    setPage: () => {},
    setPageSize: () => {},
    setToNextPage: () => {},
    setToPreviousPage: () => {},
  },
  searchValue: "",
  debouncedValue: "",
  setSearchValue: () => {},
  contactsFilters: {
    licensed: NullableBoolean.NULL,
    role: [],
    status: [],
    desks: NullableBoolean.NULL,
  },
  setContactsFilters: () => {},
};

export const PeopleContext = React.createContext(values);

export const usePeopleContext = () => {
  const context = React.useContext(PeopleContext);

  if (!context) {
    throw new Error("usePeopleContext must be used inside of PeopleContext");
  }

  return context;
};
