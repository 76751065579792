import React from "react";
import { utcToZonedTime } from "date-fns-tz";
import { Icon, IconDefinition } from "../../../../shared/Icon/Icon";
import { timeFormat } from "../../../helpers/displayTimeFormat";
import { LocationPath } from "../../../shared/LocationPath/LocationPath";
import DatePicker from "react-datepicker";
import { LocationItem } from "../../../../../api/grpc/workplaces/workplaces";
import { AllDayEvent } from "../../../shared/UserModal/AllDayEvent";

interface Props {
  title: string;
  icon?: IconDefinition;
  workspaceIconType: IconDefinition;
  workspaceName: string;
  day: string;
  startTime: string;
  endTime: string;
  userName: string;
  subject?: string;
  locationPath: LocationItem[];
  type: "confirm" | "delete" | "check-in" | "update";
  updateTime?: Date;
  handleUpdateTime?: (date: Date) => void;
  allDay?: boolean;
  timeZone?: string;
}

export const BookingsModalBody = ({
  title,
  icon,
  workspaceIconType,
  workspaceName,
  day,
  startTime,
  endTime,
  userName,
  subject,
  locationPath,
  type,
  updateTime,
  handleUpdateTime,
  allDay,
  timeZone,
}: Props) => {
  return (
    <>
      <h5>{title}</h5>

      <Icon icon={icon} className="BookingsModal__mainIcon" />
      <Icon icon={workspaceIconType} />
      <span className="text-center">{workspaceName}</span>
      <Icon icon="reserved-icon" />
      <span>{day}</span>
      <span>{allDay ? "All day" : `${startTime} - ${endTime}`}</span>
      <Icon icon="user-rounded" />
      <span>{userName}</span>
      {subject && <span className="text-pro">{subject}</span>}
      {locationPath.length > 0 && (
        <>
          <Icon icon="location-reserve" />
          <LocationPath location={locationPath} />
        </>
      )}

      {type === "update" && handleUpdateTime && updateTime && (
        <>
          <label>Extend until:</label>

          <DatePicker
            selected={updateTime}
            onChange={(date) => {
              if (!date || allDay) {
                return;
              }
              return handleUpdateTime(date);
            }}
            disabled={allDay}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            className="SearchWorkplaceForm__timePicker"
            timeFormat={timeFormat}
            dateFormat={timeFormat}
            filterTime={(time) => {
              if (!timeZone) {
                return false;
              }
              const currentDate = utcToZonedTime(new Date(), timeZone);
              const selectedDate = new Date(time);

              return currentDate.getTime() < selectedDate.getTime();
            }}
          />

          {!allDay && <AllDayEvent handleUpdateTime={handleUpdateTime} />}
        </>
      )}
    </>
  );
};
