import React, { useEffect, useState } from "react";
import { ModalBody, Form, FormGroup, ModalFooter, Label } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";

import {
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  format,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
  subMinutes,
} from "date-fns";
import { useBookingRequest } from "../../../../api/grpc/booking/useBookingRequest";
import { useApolloClient } from "@apollo/client";
import { useBookingsContext } from "../../Context/BookingsContext";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useBookingsMapContext } from "../Context/BookingsMapContext";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useFormatReservationDate as formatReservationDate } from "../../lib/datePickerHelper";
import { useCalendarRequests } from "../../../../api/grpc/calendarprovider/useCalendarRequests";

import { Button } from "../../../shared/Button/Button";
import { capitalizeFirstLetter } from "../../../../lib/helpers/capitalizeFirstLetter";
import { selectStyles } from "../../../../lib/utils/selectStyles";
import {
  getWorkplaceFragment,
  getWorkplaceFragmentName,
} from "../../../Workplaces/helpers/getWorkplaceFragment";
import { toast } from "react-toastify";
import { attachHoursToDate } from "../../lib/dateInputConvert";
import { Icon } from "../../../shared/Icon/Icon";
import { FindPeopleSelector } from "../BookingsMapSingle/ModalTypes/FindPeopleSelector";
import { Input } from "../../../shared/Input/Input";
import {
  Attendee,
  CheckInRequiredStatus,
  EventCheckInStatus,
} from "../../../../api/grpc/booking/ggevent/ggevent";
import { Person } from "../../../../api/grpc/contacts/contacts";
import { timeFormat } from "../../helpers/displayTimeFormat";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { tooltips } from "../../../../lib/utils/tooltips";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import { defineDatesPicker } from "../../lib/defineDatesPicker";
import { CalendarCredentialsStatus } from "../../../../api/grpc/calendarprovider/calendarprovider";
import { MainResources } from "../../../../lib/types/main.types";

interface Props {
  id: string;
  typeOfWorkplace: MainResources;
  refetch: () => void;
}

export const BookingsMapReserve = ({ id, typeOfWorkplace, refetch }: Props) => {
  const { timeZone } = useTimeZoneContext();
  const { personalCalendar, refetchPersonalCalendar } = useCalendarContext();
  const { validateCalendarIntegrationCredentials } = useCalendarRequests();

  const [startTime, setStartTime] = useState(
    utcToZonedTime(new Date(), timeZone)
  );
  const [endTime, setEndTime] = useState(
    utcToZonedTime(addMinutes(new Date(), 15), timeZone)
  );
  const [day, setDay] = useState<string>(
    format(utcToZonedTime(new Date(), timeZone), "yyyy-MM-dd")
  );
  const [loading, setLoading] = useState(false);

  const { datePickOptions } = defineDatesPicker(timeZone);

  const { reserveDesk } = useDeskBookingRequest();
  const {
    subject,
    handleSubject,
    isOnlineMeeting,
    handleIsOnlineMeeting,
    makePrivate,
    handleMakePrivate,
  } = useBookingsContext();
  const { attendees, handleAttendees, allDay, setAllDay } =
    useBookingsMapContext();

  const { reserveRoom } = useBookingRequest();

  useEffect(() => {
    if (allDay === true) {
      setAllDay(false);
    }

    if (!!subject.length) {
      return handleSubject("");
    }
  }, []);

  const client = useApolloClient();
  const { user } = useAuthContext();

  const workplaceData = client.readFragment({
    id: `${capitalizeFirstLetter(typeOfWorkplace)}:${id}`,
    fragmentName: getWorkplaceFragmentName(
      capitalizeFirstLetter(typeOfWorkplace)
    ),
    fragment: getWorkplaceFragment(capitalizeFirstLetter(typeOfWorkplace)),
  });

  const handleReserveWorkplace = async () => {
    if (!!!subject.length && typeOfWorkplace === "room") {
      return toast.error("Subject is required");
    }

    try {
      if (workplaceData.isBlocked) {
        return toast.error(
          `Reservations blocked for ${typeOfWorkplace} due to admin policy`
        );
      }
      setLoading(true);

      const timeDifference = differenceInMinutes(
        utcToZonedTime(new Date(), timeZone),
        startTime
      );

      const validTimePassed = timeDifference <= 5 && timeDifference > 0;
      let zonedDay = zonedTimeToUtc(day, timeZone);

      let start = attachHoursToDate(
        utcToZonedTime(zonedDay, timeZone).toISOString(),
        validTimePassed ? utcToZonedTime(new Date(), timeZone) : startTime,
        timeZone
      );

      if (differenceInMinutes(new Date(), new Date(start)) > 5) {
        setLoading(false);
        return toast.error("Invalid From time!");
      }

      if (typeOfWorkplace === "desk") {
        await reserveDesk({
          accountID: user?.claims.user_id || "",
          customerID: user?.customerid || "",
          deskID: id,
          event: {
            title: "Desk Reservation",
            allDay: allDay,
            startTime: start,
            endTime: attachHoursToDate(
              utcToZonedTime(zonedDay, timeZone).toISOString(),
              endTime,
              timeZone,
              allDay
            ),
            description: "desc",
            locations: [],
            attendees: [],
            organizer: undefined,
            isPrivate: false,
            extendedProperties: {},
            metaData: {},
            creationTime: "",
            updateTime: "",
            workplaceID: "",
            checkInRequiredStatus: 1,
            eventCheckInStatus: 1,
            workplaceLocationPath: [],
            tags: [],
            isOnlineMeeting: false,
            onlineMeetingUrl: "",
            isCheckOut: false,
            eventTimeZone: timeZone,
            headcount: 0,
            lockPin: "",
            checkInReminderSend: workplaceData?.showCheckInTime,
          },
        });
        toast.success("Reservation done successfully.");
        refetch();
        return;
      }

      let hasPersonalCalendar = personalCalendar.length > 0;
      let statusOfPersonalIntegrationToken;

      if (hasPersonalCalendar) {
        try {
          await refetchPersonalCalendar();

          const {
            response: { status },
          } = await validateCalendarIntegrationCredentials(
            personalCalendar[0].iD
          );

          statusOfPersonalIntegrationToken = status;
        } catch (error: any) {
          toast.error(error?.message);
          return;
        }
      }

      if (
        hasPersonalCalendar &&
        statusOfPersonalIntegrationToken ===
          CalendarCredentialsStatus.CalendarCredentialsStatusUnknown
      ) {
        return toast.info(
          "Couldn't define the status of the personal integration, please contact your administrator."
        );
      }

      let hasValidPersonalToken =
        statusOfPersonalIntegrationToken ===
        CalendarCredentialsStatus.CalendarCredentialsStatusValid;

      let attendee: Attendee[] = [
        {
          displayName: "",
          email:
            hasPersonalCalendar && hasValidPersonalToken
              ? workplaceData?.resourceEmail
              : user?.email,
          firstName: "",
          id: "",
          isHuman: true,
          status: "",
          surName: "",
          allowToFind: true,
        },
      ];

      attendees.map((item) => {
        return attendee.push({
          displayName: "",
          email: item.email,
          firstName: "",
          id: "",
          isHuman: true,
          status: "",
          surName: "",
          allowToFind: true,
        });
      });

      await reserveRoom({
        calendarId:
          hasPersonalCalendar && hasValidPersonalToken
            ? ""
            : workplaceData?.calendarId,
        customerId: "",
        calendarproviderId:
          hasPersonalCalendar && hasValidPersonalToken
            ? personalCalendar[0].iD
            : workplaceData?.calendarProviderId,
        roomID: id,
        meeting: {
          allDay: allDay,
          attendees: attendee,
          checkInRequiredStatus: CheckInRequiredStatus.CHECK_IN_NOT_REQUIRED,
          creationTime: "",
          startTime: start,
          endTime: attachHoursToDate(
            utcToZonedTime(zonedDay, timeZone).toISOString(),
            endTime,
            timeZone,
            allDay
          ),
          isPrivate: makePrivate,
          isCheckOut: false,
          workplaceLocationPath: [],
          tags: [],
          updateTime: "",
          metaData: {},
          description: "",
          eventCheckInStatus: EventCheckInStatus.CHECK_IN_NONE,
          title: subject,
          workplaceID: "",
          extendedProperties: {},
          isOnlineMeeting: isOnlineMeeting,
          onlineMeetingUrl: "",
          locations: [],
          headcount: 0,
          lockPin: "",
          eventTimeZone: timeZone,
          checkInReminderSend:
            !!workplaceData?.displaySettings?.checkinReminder,
          organizer: {
            displayName: "",
            email:
              hasPersonalCalendar && hasValidPersonalToken
                ? user?.email
                : workplaceData?.resourceEmail,
            firstName: "",
            id: "",
            isHuman: true,
            status: "",
            surName: "",
            allowToFind: true,
          },
        },
      });
      //here empty attenddee
      toast.success("Reservation done successfully.");
      setTimeout(() => {
        refetch();
        return;
      }, 4000);
    } catch (error: any) {
      toast.error(
        error.message
          ? error.message
          : "Reservation failed, please reaload and try again!"
      );
      setLoading(false);
    }
  };

  const filterPassedTime = (time: Date) => {
    if (
      differenceInDays(
        startOfDay(parseISO(day)),
        startOfDay(utcToZonedTime(new Date(), timeZone))
      ) > 0
    ) {
      return true;
    }
    const currentDate = utcToZonedTime(new Date(), timeZone);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <ModalBody>
        <div className="text-center BookingsButtonsModal__info">
          <span>When do you need your {workplaceData.__typename} ?</span>
          <Icon
            icon={
              workplaceData.__typename === "Desk"
                ? "desk-reserve"
                : "room-reserve"
            }
          />

          <span>{workplaceData.name}</span>
        </div>

        <Form className="SearchWorkplaceForm">
          <FormGroup className="SearchWorkplaceForm--text">
            <label className="pb-2">When</label>
            <Select
              name="day"
              options={datePickOptions}
              theme={selectStyles}
              minMenuHeight={55}
              isSearchable={false}
              className="SearchWorkplaceFrom__select"
              onChange={(values) => {
                if (values === null) {
                  return;
                }

                setDay(values.value);

                if (allDay === true) {
                  const definedTimeEndTime = setHours(
                    setMinutes(
                      setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                      0
                    ),
                    24
                  );

                  const definedTimeStartTime =
                    differenceInDays(
                      startOfDay(parseISO(values.value)),
                      startOfDay(utcToZonedTime(new Date(), timeZone))
                    ) > 0
                      ? setHours(
                          setMinutes(
                            setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                            0
                          ),
                          24
                        )
                      : utcToZonedTime(new Date(), timeZone);

                  setStartTime(definedTimeStartTime);
                  setEndTime(definedTimeEndTime);
                }

                return;
              }}
              value={
                day
                  ? {
                      label: formatReservationDate(parseISO(day)),
                      value: day,
                    }
                  : datePickOptions[0]
              }
            ></Select>
          </FormGroup>

          <FormGroup className="SearchWorkplaceForm--text">
            <label className="pb-2">From</label>
            <DatePicker
              selected={startTime}
              onChange={(date) => {
                if (!date) {
                  return;
                }

                setStartTime(date);

                let diffMinutes = differenceInMinutes(date, endTime);

                if (
                  (date >= endTime ||
                    (diffMinutes >= -15 && diffMinutes < 0)) &&
                  !allDay
                ) {
                  setEndTime(addMinutes(new Date(date), 15));
                }

                return;
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              className="SearchWorkplaceForm__timePicker"
              filterTime={filterPassedTime}
              timeFormat={timeFormat}
              dateFormat={timeFormat}
            />
          </FormGroup>
          <FormGroup className="SearchWorkplaceForm--text">
            <label className="pb-2">Until</label>
            <DatePicker
              selected={endTime}
              onChange={(date) => {
                if (!date) {
                  return;
                }

                setEndTime(date);

                if (date <= startTime && !allDay) {
                  setStartTime(subMinutes(new Date(date), 15));
                }

                return;
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              filterTime={filterPassedTime}
              timeFormat={timeFormat}
              dateFormat={timeFormat}
            />
          </FormGroup>

          <FormGroup className="flex-a-center">
            <Input
              type="checkbox"
              id="allDay"
              name="allDay"
              data-cy="allDay"
              onChange={() => {
                const definedTimeEndTime =
                  allDay === false
                    ? setHours(
                        setMinutes(
                          setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                          0
                        ),
                        24
                      )
                    : utcToZonedTime(addMinutes(new Date(), 15), timeZone);

                const definedTimeStartTime =
                  allDay === false &&
                  differenceInDays(
                    startOfDay(parseISO(day)),
                    startOfDay(utcToZonedTime(new Date(), timeZone))
                  ) > 0
                    ? setHours(
                        setMinutes(
                          setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                          0
                        ),
                        24
                      )
                    : utcToZonedTime(new Date(), timeZone);

                setEndTime(definedTimeEndTime);
                setStartTime(definedTimeStartTime);

                setAllDay((prev) => !prev);
              }}
            />
            <Label for="allDay" className="checkbox_label">
              <p className="pr-1">All day</p>
              <span className="checkbox_box"></span>
            </Label>
            <InformationBox message={tooltips.bookings.rooms.allDay} />
          </FormGroup>

          {typeOfWorkplace === "room" && (
            <>
              <FormGroup className="SearchWorkplaceForm--text">
                <LabelWithInformationBox
                  title="Subject"
                  for="subject"
                  message={tooltips.bookings.rooms.subject}
                />
                <Input
                  type="text"
                  placeholder="Subject"
                  onChange={(e) => handleSubject(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="flex-a-center mb-2">
                <Input
                  type="checkbox"
                  id="isOnlineMeeting"
                  name="isOnlineMeeting"
                  data-cy="isOnlineMeeting"
                  onChange={() => handleIsOnlineMeeting()}
                />
                <Label for="isOnlineMeeting" className="checkbox_label">
                  <p className="pr-1">Add online meeting</p>
                  <span className="checkbox_box"></span>
                </Label>
                <InformationBox
                  message={tooltips.bookings.rooms.onlineMeeting}
                />
              </FormGroup>

              <FormGroup className="flex-a-center mb-2">
                <Input
                  type="checkbox"
                  id="makePrivate"
                  name="makePrivate"
                  data-cy="makePrivate"
                  onChange={() => handleMakePrivate()}
                />
                <Label for="makePrivate" className="checkbox_label">
                  <p className="pr-1">Make private</p>
                  <span className="checkbox_box"></span>
                </Label>
              </FormGroup>

              <FindPeopleSelector
                onChange={(values: Person[]) => handleAttendees(values)}
                attendees={attendees}
                isAddPeople={true}
              />
            </>
          )}
        </Form>
      </ModalBody>

      <ModalFooter className="justify-content-center">
        <Button
          title="Reserve"
          color="primary"
          size="medium"
          disabled={loading}
          onClick={() => handleReserveWorkplace()}
        />
      </ModalFooter>
    </>
  );
};
