import React, { PropsWithChildren } from "react";
import { useDirectoryContext } from "../../Context/DirectoryContext";

import {
  CalendarType,
  DirectorySyncIntegration,
} from "../../../../api/grpc/calendarprovider/calendarprovider";
import { IntegrationsItem } from "../../../Calendars/CalendarSidebar/Integrations/IntegrationsItem/IntegrationsItem";
import { getCalendarTypeName } from "../../../Calendars/lib/helpers";

export const DirectoryIntegrationsList = ({
  data,
}: PropsWithChildren<{
  data?: DirectorySyncIntegration[];
}>) => {
  const { selectedUsers, setSelectedUsers } = useDirectoryContext();

  const handleClick = () => {
    if (!!selectedUsers.length) {
      return setSelectedUsers([]);
    }

    return;
  };
  return (
    <>
      {!data?.length ? (
        <></>
      ) : (
        <div className="CalendarProviders">
          <div className="Integrations">
            <IntegrationsItem
              type={CalendarType.O365_SYNC}
              title={getCalendarTypeName(CalendarType.O365_SYNC)}
              calendars={data.filter(
                (item) => item.integrationType === CalendarType.O365_SYNC
              )}
              isDirectory={true}
              onClick={handleClick}
            />
            <IntegrationsItem
              type={CalendarType.GSUITE_SYNC}
              title={getCalendarTypeName(CalendarType.GSUITE_SYNC)}
              calendars={data.filter(
                (item) => item.integrationType === CalendarType.GSUITE_SYNC
              )}
              isDirectory={true}
              onClick={handleClick}
            />
          </div>
        </div>
      )}
    </>
  );
};
