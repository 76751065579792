import React, { PropsWithChildren, useEffect, useState } from "react";
import { DatePriceFormik, useDatePriceFormik } from "./useDatePriceFormik";

import DatePicker from "react-datepicker";
import { useCalulactorProContext } from "../../Context/CalculatorProContext";
import { Form, FormGroup } from "reactstrap";
import { Input } from "../../../../../shared/Input/Input";
import { LabelWithInformationBox } from "../../../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { InputDescription } from "../../../../../shared/InputDescription/InputDescription";

export const DatePrice = ({
  formik,
}: PropsWithChildren<{
  formik: DatePriceFormik;
}>) => {
  const { subscription, selectedDate, setSelectedDate } =
    useCalulactorProContext();

  return (
    <div>
      <Form>
        <FormGroup>
          <LabelWithInformationBox
            for="dateActive"
            title="What date should the new items be activated?"
          />

          <div className="DateRangeButton__content--picker">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                if (!date) {
                  return;
                }

                setSelectedDate(date);
              }}
              showMonthDropdown
              showYearDropdown
              minDate={new Date()}
              maxDate={new Date(subscription?.nextRenewal || "")}
            />
            <InputDescription description="(Click to select)" />
          </div>
        </FormGroup>

        <FormGroup>
          <LabelWithInformationBox
            for="itemPrice"
            title="What is the item price for the CUSTOMER for the full 1- or 3-year period?"
          />

          <Input
            id="itemPrice"
            name="itemPrice"
            type="number"
            value={formik.values.itemPrice}
            onChange={formik.handleChange}
            isTouched={formik.touched.itemPrice}
            errorMessage={formik.errors.itemPrice}
            invalid={!!formik.errors.itemPrice}
          />
        </FormGroup>

        <FormGroup>
          <LabelWithInformationBox
            for="userItemPrice"
            title="What is YOUR item price in the GOGET price list for the full 1- or 3-year period?"
          />

          <Input
            id="userItemPrice"
            name="userItemPrice"
            type="number"
            value={formik.values.userItemPrice}
            onChange={formik.handleChange}
            isTouched={formik.touched.userItemPrice}
            errorMessage={formik.errors.userItemPrice}
            invalid={!!formik.errors.userItemPrice}
          />
        </FormGroup>
      </Form>
    </div>
  );
};
