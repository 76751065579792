import React from "react";
import { LocationItem } from "../../../../api/grpc/workplaces/workplaces";
import { Icon } from "../../../shared/Icon/Icon";
import { LocationPath } from "../LocationPath/LocationPath";
import { UserInfoRowItem } from "./UserInfoRowItem";

interface Props {
  name: string;
  reservationStartTime: string;
  reservationEndTime: string;
  createdTime: string;
  locationPath: LocationItem[];
  allDay: boolean;
}

export const UserModalDesks = ({
  name,
  reservationStartTime,
  reservationEndTime,
  createdTime,
  locationPath,
  allDay,
}: Props) => {
  return (
    <div className="flex-center flex-col UserInfo__desk">
      <Icon icon="desk-reserve-16" />
      <span>{name}</span>

      <div className="flex-a-center UserInfo__group">
        <UserInfoRowItem
          icon="clock-icon"
          content={
            allDay
              ? "All day"
              : `${reservationStartTime} - ${reservationEndTime}`
          }
        />
        <UserInfoRowItem icon="calendar-bookings" content={createdTime} />
      </div>
      <UserInfoRowItem
        icon="location-reserve"
        content={<LocationPath location={locationPath} />}
      />
    </div>
  );
};
