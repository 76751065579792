import React from "react";
import { Icon } from "../../../shared/Icon/Icon";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";

interface Props {
  icon?: IconDefinition;
  content: string | JSX.Element;
}

export const UserInfoRowItem = ({ icon, content }: Props) => {
  return (
    <div className="flex-a-center UserInfoRowItem">
      <Icon icon={icon} />
      <span>{content}</span>
    </div>
  );
};
