import React, { useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import { People } from "../../../../api/grpc/contacts/contacts";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { useBookingsContext } from "../../Context/BookingsContext";

interface Props {
  loading: boolean;
  data?: People;
  error: any;
}

export const CreatablePeopleList = ({ loading, error, data }: Props) => {
  const { attendees, handleAttendees } = useBookingsContext();

  const options = useMemo(() => {
    return data?.people.map((item) => ({
      value: item.email,
      label: item.displayName,
    }));
  }, [data]);

  const selectedValues = useMemo(() => {
    return attendees.map((attendee) => ({
      label: attendee,
      value: attendee,
    }));
  }, [attendees]);

  return (
    <>
      {loading ? (
        <LoadingBox minHeight={50} />
      ) : error ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <CreatableSelect
          isMulti
          className="SearchWorkplaceForm__createableOptions"
          onChange={(e) => {
            if (e === null) {
              return;
            }
            const values = e.map((item) => item.value);

            handleAttendees(values);
          }}
          value={selectedValues}
          options={options}
          classNamePrefix={selectedValues.length >= 2 ? "MultipleVal" : ""}
        />
      )}

      <span>Type email when creating new one.</span>
    </>
  );
};
