import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useApolloClient } from "@apollo/client";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";
import { useWayFinderFormik } from "../../../shared/Forms/WayFinderForm/lib/useWayFinderFormik";
import { useChangedRoomDirections } from "../../../shared/Forms/WayFinderForm/lib/useChangedRoomDirections";
import { useWayfinderDisplaySettingsFormik } from "../../../shared/Forms/WayFinderForm/lib/useWayfinderDisplaySettingsForm";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useGlobalWorkingHours } from "../../../../lib/hooks/useGlobalWorkingHours";
import { useCustomerPermissions } from "../../../Billings/lib/useCustomerPermissions";

import { SubRouteProps } from "../../../../lib/routes/routes";

import { tooltips } from "../../../../lib/utils/tooltips";
import { EditSettings } from "../EditSettings/EditSettings";
import { EditWorkplaceWrapper } from "../EditWorkplaceWrapper/EditWorkplaceWrapper";
import { EditWorkplaceProps } from "../../../../views/Manage/Workplaces/EditWorkplace/EditWorkplace";

import { WayFinderForm } from "../../../shared/Forms/WayFinderForm/WayFinderForm";
import { EditViewsTab } from "../EditViewsTab/EditViewsTab";
import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import { WayFinderDisplaySettingsMain } from "../../../shared/Forms/WayFinderForm/WayFinderDisplaySettings/WayFinderDisplaySettingsMain";
import { WayFinderDirections } from "../../../shared/Forms/WayFinderForm/WayFinderDistance/WayFinderDirections";
import {
  UpdateWayfinderDisplaySettingsVariables,
  WayFinderFields,
  WayfinderRequestVariables,
  WAY_FINDER_FIELDS_FRAGMENT,
} from "../../../../api/graphql/wayfinder/wayfinder";
import { EmptyEntity } from "../../../shared/EmptyEntity/EmptyEntity";
import { dateHelper } from "../../../shared/Forms/DisplaySettingsForm/lib/displayDateHelper";
import { findLastObjectsWithSameId } from "../../lib/handleObjectsWithSameId";

export interface ChangedRoomsDirectionsVariables {
  id: string;
  roomDirectionId: string;
  distance: number;
  direction: number;
  name: string;
}

export const EditWayFinder = (props: EditWorkplaceProps) => {
  const { id } = props;
  const { user } = useAuthContext();
  const router = useRouter();
  const client = useApolloClient();

  const { workingHoursData } = useGlobalWorkingHours();
  const { isBussinesOrPro } = useCustomerPermissions();
  const {
    updateWayfinder,
    updateWayfinderDisplay,
    updateWayfinderDistanceUnit,
    addRoomDirectionToWayfinder,
  } = useWorkplaceRequests();
  const { customer } = useCustomerContext();

  const [loadingSettings, setLoadingSettings] = useState(false);
  const [wayfinderData, setWayfinderData] = useState<WayFinderFields | null>(
    null
  );

  const { changedRooms, handleChangedRooms, clearChangedRooms } =
    useChangedRoomDirections();

  const updateData = useCallback(() => {
    setWayfinderData(
      client.readFragment({
        id: `Wayfinder:${id}`,
        fragmentName: "WayfinderFields",
        fragment: WAY_FINDER_FIELDS_FRAGMENT,
      })
    );
  }, [id]);

  useEffect(() => {
    updateData();
  }, [id, customer, updateData]);

  const formik = useWayFinderFormik({
    initialValues: {
      name: wayfinderData?.name || "",
      tags: wayfinderData?.tags || [],
      description: wayfinderData?.description || "",
      desks: wayfinderData?.desks || [],
      rooms: wayfinderData?.rooms || [],
      marker: {
        latitude: wayfinderData?.marker?.latitude || 0,
        longitude: wayfinderData?.marker?.longitude || 0,
      },
      licensed: wayfinderData?.licensed || false,
      distanceUnit: !!wayfinderData?.distanceUnit
        ? wayfinderData.distanceUnit
        : "METRE_M",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoadingSettings(true);

        const removedTags =
          wayfinderData?.tags.filter((item) => !values.tags?.includes(item)) ||
          [];

        const removedRooms = wayfinderData?.rooms
          ? wayfinderData.rooms
              .filter((room) => !values.rooms.some((r) => r.id === room.id))
              .map((room) => ({ id: room.id }))
          : [];

        const removedDesks = wayfinderData?.desks
          ? wayfinderData.desks
              .filter((desk) => !values.desks.some((d) => d.id === desk.id))
              .map((desk) => ({ id: desk.id }))
          : [];

        if (
          wayfinderData?.roomDirection !== undefined &&
          wayfinderData?.rooms?.length !== values.rooms.length
        ) {
          await addRoomDirectionToWayfinder(id, {
            roomDirectionDeleteID: wayfinderData.roomDirection.map(
              (direction) => direction.id
            ) as string[],
            directions: values.rooms.map((room) => {
              let roomDirection = wayfinderData?.roomDirection?.find(
                (direction) => direction.room?.id === room.id
              );
              return {
                wayFinder: {
                  id: id,
                  name: values.name,
                },
                room: {
                  name: room.name,
                  id: room.id,
                },
                direction: !!roomDirection ? roomDirection.direction : 0,
                distance: !!roomDirection ? roomDirection.distance : 0,
              };
            }),
          });
        }

        const variables: WayfinderRequestVariables = {
          id,
          tenantId: customer.tenantId,
          name: values.name,
          tags: values.tags || [],
          description: values.description,
          marker: {
            latitude: values.marker.latitude,
            longitude: values.marker.longitude,
          },
          desks: values.desks.map((item) => {
            return {
              id: item.id,
            };
          }),
          rooms: values.rooms.map((item) => {
            return {
              id: item.id,
            };
          }),
          remove: {
            tags: removedTags,
            rooms: removedRooms,
            desks: removedDesks,
          },
        };

        await updateWayfinder(variables);
      } catch (error: any) {
        toast.error(
          error.message
            ? error.message
            : "Cannot update room, please reload and try again!"
        );
      } finally {
        setLoadingSettings(false);
      }
    },
  });

  const wayfinderDisplaySettingsFormik = useWayfinderDisplaySettingsFormik(
    wayfinderData?.displaySettings,
    {
      onSubmit: async (values) => {
        try {
          const {
            main: { adminPin, backgroundImageUrl, logoImageUrl },
            display,
          } = values;

          const variables: UpdateWayfinderDisplaySettingsVariables = {
            id,
            displaySettings: {
              adminPin: adminPin,
              backgroundImageUrl: backgroundImageUrl,
              logoImageUrl: logoImageUrl,

              customerId: user?.customerid || "",
              showOrganizer: display.showOrganizer,
              showMeetingTitle: display.showMeetingTitle,
              showOccupancyCards: display.showOccupancyCards,
              mapView: display.mapView,
              maximizedMapView: display.mapView
                ? display.maximizedMapView
                : false,
              scalingFactor: display.scalingFactor,
              floor:
                !display.floor?.id || !display.mapView
                  ? null
                  : {
                      id: display.floor.id,
                    },
              allowAdhocBookings: display.allowAdhocBookings,
              energySaveMode: display.energySaveMode,
              energySaveModeStart: format(
                dateHelper("", "endTime", workingHoursData),
                "HH:mm:ss"
              ),
              energySaveModeEnd: format(
                dateHelper("", "startTime", workingHoursData),
                "HH:mm:ss"
              ),
              workingWeekDays: workingHoursData?.workingWeekDays,
              switcherTempo: display.switcherTempo,
              customLanguage: !!display.customLanguage
                ? {
                    id: display.customLanguage?.id || "",
                    value: display.customLanguage?.value || "",
                    name: display.customLanguage?.name || "",
                    isGlobal: display.customLanguage?.isGlobal || false,
                  }
                : undefined,
            },
          };

          await updateWayfinderDisplay(variables);

          toast.success(
            "Display settings updated successfully. Allow up to 5 min to sync settings"
          );
        } catch (error: any) {
          toast.error(
            error.message
              ? error.message
              : "Wayfinder couldn't be updated, please reload and try again!"
          );
          console.error(error.message);
        }
      },
    }
  );

  const handleChangeDirections = async () => {
    if (wayfinderData?.distanceUnit !== formik.values.distanceUnit) {
      try {
        await updateWayfinderDistanceUnit({
          id,
          tenantId: customer.tenantId,
          distanceUnit: formik.values.distanceUnit,
        });

        toast.success(
          "Distance unit updated successfully. Allow up to 5 min to sync settings"
        );
      } catch (error: any) {
        toast.error(
          "Cannot update distance unit, please reload and try again!"
        );
      }
    }

    try {
      let distanceChanged: ChangedRoomsDirectionsVariables[] = [];
      let directionChanged: ChangedRoomsDirectionsVariables[] = [];

      if (!!changedRooms.length) {
        changedRooms.map((i) => {
          wayfinderData?.roomDirection?.map((room) => {
            if (i.id === room.room?.id) {
              if (room) {
                if (room.direction !== i.direction) {
                  directionChanged.push(i);
                }

                if (room.distance !== i.distance) {
                  distanceChanged.push(i);
                }
              }
            }
          });
        });

        let definedDistanceChanged = findLastObjectsWithSameId(distanceChanged);
        let definedDirectionChanged =
          findLastObjectsWithSameId(directionChanged);

        let finalArray: ChangedRoomsDirectionsVariables[] = [];

        if (
          !!definedDirectionChanged.length &&
          !!definedDistanceChanged.length
        ) {
          const mergedArray = definedDistanceChanged
            .map((obj1) => {
              const obj2 = definedDirectionChanged.find(
                (o) => o.id === obj1.id
              );
              if (obj2) {
                return {
                  id: obj1.id,
                  name: obj1.name,
                  distance: obj1.distance,
                  direction: obj2.direction,
                  roomDirectionId: obj1.roomDirectionId,
                };
              } else {
                return obj1;
              }
            })
            .concat(
              definedDirectionChanged.filter(
                (obj2) =>
                  !definedDistanceChanged.some((obj1) => obj1.id === obj2.id)
              )
            );

          finalArray = mergedArray;
        }

        if (
          !!definedDistanceChanged.length &&
          !!!definedDirectionChanged.length
        ) {
          definedDistanceChanged.map((i) => finalArray.push(i));
        }

        if (
          !!definedDirectionChanged.length &&
          !!!definedDistanceChanged.length
        ) {
          definedDirectionChanged.map((i) => finalArray.push(i));
        }

        await addRoomDirectionToWayfinder(id, {
          roomDirectionDeleteID: finalArray.map((room) => {
            return room.roomDirectionId;
          }),
          directions: finalArray.map((room) => {
            return {
              direction: room.direction,
              distance: room.distance,
              room: {
                id: room.id,
                name: room.name,
              },
              wayFinder: {
                id: id,
                name: wayfinderData?.name || "",
              },
            };
          }),
        });

        client.refetchQueries({
          updateCache(cache) {
            cache.evict({
              id: `Wayfinder:${id}`,
            });
          },
        });

        toast.success(
          "Directions updated successfully. Allow up to 5 min to sync settings"
        );

        clearChangedRooms();
      }
    } catch (error: any) {
      toast.error(
        error.message
          ? error.message
          : "Cannot update directions, please reload and try again!"
      );
    }
  };

  const devicesList: WorkplaceListData[] = [
    {
      data: wayfinderData?.devices,
      type: "Device",
    },
  ];

  const hasData = devicesList.some(
    (item: any) => item.data && item.data.length > 0
  );

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Device",
      to: router.match.url,
      id: "devices-page-wayfinder",
      render: () => (
        <EditViewsTab
          id={id}
          type="Wayfinder"
          rows={devicesList}
          deviceType="rfx"
          hasData={hasData}
        />
      ),
    },
    {
      exact: true,
      label: "Core settings",
      id: "core-settings-page",
      to: `${router.match.url}/settings`,
      render: () => (
        <EditSettings
          submitting={loadingSettings}
          onSubmit={formik.submitForm}
          // hasChanges={formik.dirty && formik.isValid}
        >
          <WayFinderForm id={id} formik={formik} isEdit />
        </EditSettings>
      ),
    },
    {
      exact: true,
      label: "Display settings",
      id: "display-settings-page",
      to: `${router.match.url}/display-settings`,
      render: () => (
        <EditSettings
          splitSections
          submitting={loadingSettings}
          onSubmit={wayfinderDisplaySettingsFormik.submitForm}
          // hasChanges={
          //   wayfinderDisplaySettingsFormik.dirty &&
          //   wayfinderDisplaySettingsFormik.isValid
          // }
        >
          <WayFinderDisplaySettingsMain
            formik={wayfinderDisplaySettingsFormik}
            isBussinesOrPro={isBussinesOrPro}
            wayfinderId={id}
            currentCustomLanguageId={
              wayfinderData?.displaySettings?.customLanguage?.id
            }
          />
        </EditSettings>
      ),
    },
    {
      exact: true,
      label: "Directions",
      id: "directions-page",
      to: `${router.match.url}/directions`,
      render: () =>
        !!wayfinderData?.roomDirection &&
        wayfinderData.roomDirection.length > 0 ? (
          <EditSettings
            splitSections
            submitting={loadingSettings}
            onSubmit={() => handleChangeDirections()}
            // hasChanges={
            //   !!changedRooms.length ||
            //   formik.values.distanceUnit !== wayfinderData.distanceUnit
            // }
          >
            <WayFinderDirections
              roomDirections={wayfinderData?.roomDirection || []}
              handleChangedRooms={handleChangedRooms}
              formik={formik}
              changedRooms={changedRooms}
            />
          </EditSettings>
        ) : (
          <EmptyEntity type="RoomDirection" />
        ),
    },
  ];

  return (
    <EditWorkplaceWrapper
      type="Wayfinder"
      id={id}
      routes={routes}
      data={wayfinderData || undefined}
      message={tooltips.workplaces.wayfinder}
      hasDevice={wayfinderData?.devices && wayfinderData.devices.length >= 1}
      className="EditWayFinder"
    />
  );
};
