import { useState } from "react";
import { toast } from "react-toastify";
import { useBookingsMapContext } from "../BookingsMap/Context/BookingsMapContext";
import { useBookingRequest } from "../../../api/grpc/booking/useBookingRequest";
import { useDeskBookingRequest } from "../../../api/grpc/desk/useDeskBookingRequest";
import { useWorkplacesClient } from "../../../api/grpc/workplaces/useWorkplacesClient";
import { useRootIdQuery } from "../../../lib/hooks/useRootIdQuery";
import { useToggleModal } from "../../../lib/hooks/useToggleModal";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { LocationItem } from "../../../api/grpc/workplaces/workplaces";
import {
  Attendee,
  CheckInRequiredStatus,
  EventCheckInStatus,
} from "../../../api/grpc/booking/ggevent/ggevent";

interface DeskReserve {
  accountId: string;
  customerID: string;
  deskId: string;
  startTime: string;
  endTime: string;
  checkInReminderSend: boolean;
}

interface RoomReserve {
  startTime: string;
  endTime: string;
  roomId: string;
  attendees: Attendee[];
  organizerEmail: string;
  calendarId: string;
  calendarproviderId: string;
  title: string;
  isOnlineMeeting: boolean;
  isPrivate: boolean;
  checkInReminderSend: boolean;
}

export const useReserveHelpers = () => {
  const { reserveDesk } = useDeskBookingRequest();
  const { reserveRoom } = useBookingRequest();
  const { getWorkplaceLocationPath } = useWorkplacesClient();
  const [locationPath, setLocationPath] = useState<LocationItem[]>([]);
  const [organizerName, setOrganizerName] = useState<string | undefined>("");
  const { isOpen, toggleModal } = useToggleModal();
  const { rootId } = useRootIdQuery();
  const { timeZone } = useTimeZoneContext();
  const { allDay } = useBookingsMapContext();

  const handleReserveDesk = async ({
    accountId,
    customerID,
    deskId,
    startTime,
    endTime,
    checkInReminderSend,
  }: DeskReserve) => {
    try {
      const {
        response: { locationID },
      } = await reserveDesk({
        accountID: accountId,
        customerID: customerID,
        deskID: deskId,
        event: {
          title: "Desk Reservation",
          allDay: allDay,
          startTime: startTime,
          endTime: endTime,
          description: "",
          locations: [],
          attendees: [],
          organizer: undefined,
          isPrivate: false,
          extendedProperties: {},
          metaData: {},
          creationTime: "",
          updateTime: "",
          workplaceID: "",
          checkInRequiredStatus: 1,
          eventCheckInStatus: 1,
          workplaceLocationPath: [],
          tags: [],
          isOnlineMeeting: false,
          onlineMeetingUrl: "",
          isCheckOut: false,
          headcount: 0,
          lockPin: "",
          eventTimeZone: timeZone,
          checkInReminderSend: checkInReminderSend,
        },
      });

      const {
        response: { path },
      } = await getWorkplaceLocationPath({
        onlyFree: false,
        filterTags: [],
        locationID: locationID || rootId,
        customerID: "",
        allDesks: false,
        ignoreBlocked: false,
        timeZone: timeZone,
        page: 1,
        pageSize: 8,
      });

      setLocationPath(path);
      toggleModal();
    } catch (error: any) {
      toast.error(
        error.message
          ? error.message
          : "Reservation failed, please reaload and try again!"
      );
      console.log(error);
    }
  };

  const handleReserveRoom = async ({
    attendees,
    startTime,
    endTime,
    organizerEmail,
    roomId,
    calendarId,
    calendarproviderId,
    title,
    isOnlineMeeting,
    isPrivate,
    checkInReminderSend,
  }: RoomReserve) => {
    try {
      const {
        response: { locationID, meeting },
      } = await reserveRoom({
        calendarId: calendarId,
        customerId: "",
        calendarproviderId: calendarproviderId,
        roomID: roomId,
        meeting: {
          allDay: allDay,
          attendees: attendees,
          checkInRequiredStatus: CheckInRequiredStatus.CHECK_IN_NOT_REQUIRED,
          creationTime: startTime,
          startTime: startTime,
          endTime: endTime,
          isPrivate: isPrivate,
          isCheckOut: false,
          workplaceLocationPath: [],
          tags: [],
          updateTime: "",
          metaData: {},
          description: "",
          isOnlineMeeting: isOnlineMeeting,
          onlineMeetingUrl: "",
          eventCheckInStatus: EventCheckInStatus.CHECK_IN_NONE,
          title: title,
          workplaceID: "",
          headcount: 0,
          lockPin: "",
          eventTimeZone: timeZone,
          extendedProperties: {},
          locations: [],
          checkInReminderSend: checkInReminderSend,
          organizer: {
            displayName: "",
            email: organizerEmail,
            firstName: "",
            id: "",
            isHuman: true,
            status: "",
            surName: "",
            allowToFind: true,
          },
        },
      });

      const {
        response: { path },
      } = await getWorkplaceLocationPath({
        onlyFree: false,
        filterTags: [],
        locationID: locationID || rootId,
        customerID: "",
        allDesks: false,
        ignoreBlocked: false,
        timeZone: timeZone,
        page: 1,
        pageSize: 8,
      });

      setLocationPath(path);
      setOrganizerName(meeting?.organizer?.displayName);
      toggleModal();
    } catch (error: any) {
      toast.error(
        error.message
          ? error.message
          : "Reservation failed, please reaload and try again!"
      );
      console.log(error);
    }
  };

  return {
    toggleModal,
    isOpen,
    locationPath,
    handleReserveDesk,
    handleReserveRoom,
    organizerName,
  };
};
