// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "booking.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BusyTimeSlots } from "./ggevent/ggevent";
import { Event } from "./ggevent/ggevent";
/**
 * @generated from protobuf message WorkplaceLocationItem
 */
export interface WorkplaceLocationItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: int32 index = 4;
     */
    index: number;
}
/**
 * @generated from protobuf message BookMeetingRequest
 */
export interface BookMeetingRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: ggevent.Event meeting = 4;
     */
    meeting?: Event;
    /**
     * @generated from protobuf field: string roomID = 5;
     */
    roomID: string;
}
/**
 * @generated from protobuf message BookMeetingResponse
 */
export interface BookMeetingResponse {
    /**
     * @generated from protobuf field: ggevent.Event meeting = 1;
     */
    meeting?: Event;
    /**
     * @generated from protobuf field: string roomID = 2;
     */
    roomID: string;
    /**
     * @generated from protobuf field: string roomName = 3;
     */
    roomName: string;
    /**
     * @generated from protobuf field: string locationID = 4;
     */
    locationID: string;
    /**
     * @generated from protobuf field: repeated WorkplaceLocationItem locationPath = 5;
     */
    locationPath: WorkplaceLocationItem[];
}
/**
 * @generated from protobuf message UpdateMeetingRequest
 */
export interface UpdateMeetingRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: ggevent.Event meeting = 4;
     */
    meeting?: Event;
    /**
     * Update event related to event checkout process
     *
     * @generated from protobuf field: bool activateCheckOut = 5;
     */
    activateCheckOut: boolean;
}
/**
 * @generated from protobuf message UpdateMeetingResponse
 */
export interface UpdateMeetingResponse {
    /**
     * @generated from protobuf field: ggevent.Event updatedMeeting = 1;
     */
    updatedMeeting?: Event;
    /**
     * @generated from protobuf field: string roomID = 2;
     */
    roomID: string;
    /**
     * @generated from protobuf field: string roomName = 3;
     */
    roomName: string;
    /**
     * @generated from protobuf field: string locationID = 4;
     */
    locationID: string;
}
/**
 * @generated from protobuf message ListMeetingsRequest
 */
export interface ListMeetingsRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: Interval interval = 5;
     */
    interval: Interval;
    /**
     * @generated from protobuf field: int32 week = 6;
     */
    week: number;
    /**
     * @generated from protobuf field: string timeZone = 7;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: bool isPersonal = 8;
     */
    isPersonal: boolean; // Enable listing meetings for personal calendar.
    /**
     * @generated from protobuf field: string personalEmail = 9;
     */
    personalEmail: string; // Only used if isPersonal is set to true. Will filter room reservations where the organizer matches the personal email.
}
/**
 * @generated from protobuf message ListMeetingsResponse
 */
export interface ListMeetingsResponse {
    /**
     * @generated from protobuf field: repeated ggevent.Event meetings = 1;
     */
    meetings: Event[];
    /**
     * @generated from protobuf field: string roomID = 2;
     */
    roomID: string;
    /**
     * @generated from protobuf field: string roomName = 3;
     */
    roomName: string;
    /**
     * @generated from protobuf field: string locationID = 4;
     */
    locationID: string;
    /**
     * @generated from protobuf field: bool allowDeleteOption = 5;
     */
    allowDeleteOption: boolean;
}
/**
 * @generated from protobuf message ScheduleRequest
 */
export interface ScheduleRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: string startTime = 4;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 5;
     */
    endTime: string;
    /**
     * @generated from protobuf field: string timeZone = 6;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message ScheduleResponse
 */
export interface ScheduleResponse {
    /**
     * @generated from protobuf field: repeated ggevent.BusyTimeSlots timeSlots = 1;
     */
    timeSlots: BusyTimeSlots[];
}
/**
 * @generated from protobuf message TimeSlot
 */
export interface TimeSlot {
    /**
     * The start time the room is busy and unavailable for booking. UTC time format.
     *
     * @generated from protobuf field: string startTime = 1;
     */
    startTime: string;
    /**
     * The end time the room is busy and unavailable for booking. UTC time format.
     *
     * @generated from protobuf field: string endTime = 2;
     */
    endTime: string;
}
/**
 * @generated from protobuf message DeleteMeetingRequest
 */
export interface DeleteMeetingRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: map<string, string> metaData = 4;
     */
    metaData: {
        [key: string]: string;
    };
    /**
     * systemDelete indicate is event delete automatically (e.g. check in is close)
     *
     * @generated from protobuf field: bool systemDelete = 5;
     */
    systemDelete: boolean;
    /**
     * @generated from protobuf field: string organizer_email = 6;
     */
    organizerEmail: string;
}
/**
 * @generated from protobuf message GetMeetingRequest
 */
export interface GetMeetingRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: map<string, string> metaData = 4;
     */
    metaData: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string timeZone = 5;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message GetMeetingResponse
 */
export interface GetMeetingResponse {
    /**
     * @generated from protobuf field: ggevent.Event meeting = 1;
     */
    meeting?: Event;
    /**
     * @generated from protobuf field: string roomID = 2;
     */
    roomID: string;
    /**
     * @generated from protobuf field: string roomName = 3;
     */
    roomName: string;
    /**
     * @generated from protobuf field: string locationID = 4;
     */
    locationID: string;
}
/**
 * @generated from protobuf message ConfirmCheckInRequest
 */
export interface ConfirmCheckInRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: string startTime = 4;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 5;
     */
    endTime: string;
    /**
     * @generated from protobuf field: map<string, string> metaData = 6;
     */
    metaData: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message RoomsScheduleRequest
 */
export interface RoomsScheduleRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string startTime = 2;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 3;
     */
    endTime: string;
    /**
     * @generated from protobuf field: repeated string roomsID = 4;
     */
    roomsID: string[];
    /**
     * @generated from protobuf field: string wayfinderID = 5;
     */
    wayfinderID: string; // prefilter rooms directions by wayfinder
    /**
     * @generated from protobuf field: string deviceID = 6;
     */
    deviceID: string;
    /**
     * @generated from protobuf field: string timeZone = 7;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message RoomsScheduleResponse
 */
export interface RoomsScheduleResponse {
    /**
     * @generated from protobuf field: repeated RoomsScheduleResponse.RoomSchedule rooms = 1;
     */
    rooms: RoomsScheduleResponse_RoomSchedule[];
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message RoomsScheduleResponse.Direction
 */
export interface RoomsScheduleResponse_Direction {
    /**
     * @generated from protobuf field: int32 distance = 1;
     */
    distance: number;
    /**
     * @generated from protobuf field: int32 direction = 2;
     */
    direction: number;
    /**
     * @generated from protobuf field: string distanceUnit = 3;
     */
    distanceUnit: string;
}
/**
 * @generated from protobuf message RoomsScheduleResponse.RoomSchedule
 */
export interface RoomsScheduleResponse_RoomSchedule {
    /**
     * @generated from protobuf field: repeated ggevent.Event meetings = 1;
     */
    meetings: Event[];
    /**
     * @generated from protobuf field: string roomID = 2;
     */
    roomID: string;
    /**
     * @generated from protobuf field: string roomName = 3;
     */
    roomName: string;
    /**
     * @generated from protobuf field: int32 numberOfSeats = 4;
     */
    numberOfSeats: number;
    /**
     * @generated from protobuf field: repeated RoomsScheduleResponse.Direction direction = 5;
     */
    direction: RoomsScheduleResponse_Direction[];
}
/**
 * @generated from protobuf message HealthCheckRequest
 */
export interface HealthCheckRequest {
    /**
     * @generated from protobuf field: string deviceID = 1;
     */
    deviceID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message CheckRoomsScheduleRequest
 */
export interface CheckRoomsScheduleRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: repeated string calendars_id = 3;
     */
    calendarsId: string[];
    /**
     * @generated from protobuf field: string startTime = 4;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 5;
     */
    endTime: string;
    /**
     * @generated from protobuf field: string timeZone = 6;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message RoomsSchedule
 */
export interface RoomsSchedule {
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: repeated ggevent.BusyTimeSlots timeSlots = 4;
     */
    timeSlots: BusyTimeSlots[];
}
/**
 * @generated from protobuf message CheckRoomsScheduleResponse
 */
export interface CheckRoomsScheduleResponse {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarprovider_id = 2;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: repeated RoomsSchedule schedule = 3;
     */
    schedule: RoomsSchedule[];
}
/**
 * @generated from protobuf message CalendarEventsSubscriptionRequest
 */
export interface CalendarEventsSubscriptionRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendar_provider_id = 2;
     */
    calendarProviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 3;
     */
    calendarId: string;
}
/**
 * @generated from protobuf message CalendarEventsSubscriptionResponse
 */
export interface CalendarEventsSubscriptionResponse {
    /**
     * @generated from protobuf field: CalendarEventsSubscriptionStatus status = 1;
     */
    status: CalendarEventsSubscriptionStatus;
}
/**
 * @generated from protobuf message TriggerCheckInReminderRequest
 */
export interface TriggerCheckInReminderRequest {
    /**
     * @generated from protobuf field: string calendarprovider_id = 1;
     */
    calendarproviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 2;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: int32 minutes_before_meeting = 3;
     */
    minutesBeforeMeeting: number;
    /**
     * @generated from protobuf field: string meeting_release_time = 4;
     */
    meetingReleaseTime: string;
    /**
     * @generated from protobuf field: string language = 5;
     */
    language: string;
    /**
     * @generated from protobuf field: string meeting_title = 6;
     */
    meetingTitle: string;
    /**
     * @generated from protobuf field: string organizer_email = 7;
     */
    organizerEmail: string;
    /**
     * @generated from protobuf field: string customer_id = 8;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string event_id = 9;
     */
    eventId: string;
    /**
     * @generated from protobuf field: string time_zone = 10;
     */
    timeZone: string;
}
/**
 * @generated from protobuf enum Interval
 */
export enum Interval {
    /**
     * @generated from protobuf enum value: TODAY = 0;
     */
    TODAY = 0,
    /**
     * @generated from protobuf enum value: WEEK = 1;
     */
    WEEK = 1,
    /**
     * @generated from protobuf enum value: MONTH = 2;
     */
    MONTH = 2,
    /**
     * @generated from protobuf enum value: SEVENDAYS = 3;
     */
    SEVENDAYS = 3
}
/**
 * @generated from protobuf enum CalendarEventsSubscriptionStatus
 */
export enum CalendarEventsSubscriptionStatus {
    /**
     * @generated from protobuf enum value: CalendarEventsSubscriptionStatusNotSupported = 0;
     */
    CalendarEventsSubscriptionStatusNotSupported = 0,
    /**
     * @generated from protobuf enum value: CalendarEventsSubscriptionStatusNotActive = 1;
     */
    CalendarEventsSubscriptionStatusNotActive = 1,
    /**
     * @generated from protobuf enum value: CalendarEventsSubscriptionStatusActive = 2;
     */
    CalendarEventsSubscriptionStatusActive = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class WorkplaceLocationItem$Type extends MessageType<WorkplaceLocationItem> {
    constructor() {
        super("WorkplaceLocationItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "index", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<WorkplaceLocationItem>): WorkplaceLocationItem {
        const message = { id: "", name: "", type: "", index: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WorkplaceLocationItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WorkplaceLocationItem): WorkplaceLocationItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* int32 index */ 4:
                    message.index = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WorkplaceLocationItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* int32 index = 4; */
        if (message.index !== 0)
            writer.tag(4, WireType.Varint).int32(message.index);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WorkplaceLocationItem
 */
export const WorkplaceLocationItem = new WorkplaceLocationItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookMeetingRequest$Type extends MessageType<BookMeetingRequest> {
    constructor() {
        super("BookMeetingRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "meeting", kind: "message", T: () => Event },
            { no: 5, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BookMeetingRequest>): BookMeetingRequest {
        const message = { customerId: "", calendarproviderId: "", calendarId: "", roomID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BookMeetingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BookMeetingRequest): BookMeetingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                case /* ggevent.Event meeting */ 4:
                    message.meeting = Event.internalBinaryRead(reader, reader.uint32(), options, message.meeting);
                    break;
                case /* string roomID */ 5:
                    message.roomID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BookMeetingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        /* ggevent.Event meeting = 4; */
        if (message.meeting)
            Event.internalBinaryWrite(message.meeting, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string roomID = 5; */
        if (message.roomID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.roomID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BookMeetingRequest
 */
export const BookMeetingRequest = new BookMeetingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookMeetingResponse$Type extends MessageType<BookMeetingResponse> {
    constructor() {
        super("BookMeetingResponse", [
            { no: 1, name: "meeting", kind: "message", T: () => Event },
            { no: 2, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "roomName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "locationPath", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkplaceLocationItem }
        ]);
    }
    create(value?: PartialMessage<BookMeetingResponse>): BookMeetingResponse {
        const message = { roomID: "", roomName: "", locationID: "", locationPath: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BookMeetingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BookMeetingResponse): BookMeetingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ggevent.Event meeting */ 1:
                    message.meeting = Event.internalBinaryRead(reader, reader.uint32(), options, message.meeting);
                    break;
                case /* string roomID */ 2:
                    message.roomID = reader.string();
                    break;
                case /* string roomName */ 3:
                    message.roomName = reader.string();
                    break;
                case /* string locationID */ 4:
                    message.locationID = reader.string();
                    break;
                case /* repeated WorkplaceLocationItem locationPath */ 5:
                    message.locationPath.push(WorkplaceLocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BookMeetingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ggevent.Event meeting = 1; */
        if (message.meeting)
            Event.internalBinaryWrite(message.meeting, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string roomID = 2; */
        if (message.roomID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roomID);
        /* string roomName = 3; */
        if (message.roomName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roomName);
        /* string locationID = 4; */
        if (message.locationID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.locationID);
        /* repeated WorkplaceLocationItem locationPath = 5; */
        for (let i = 0; i < message.locationPath.length; i++)
            WorkplaceLocationItem.internalBinaryWrite(message.locationPath[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BookMeetingResponse
 */
export const BookMeetingResponse = new BookMeetingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMeetingRequest$Type extends MessageType<UpdateMeetingRequest> {
    constructor() {
        super("UpdateMeetingRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "meeting", kind: "message", T: () => Event },
            { no: 5, name: "activateCheckOut", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateMeetingRequest>): UpdateMeetingRequest {
        const message = { customerId: "", calendarproviderId: "", calendarId: "", activateCheckOut: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateMeetingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMeetingRequest): UpdateMeetingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                case /* ggevent.Event meeting */ 4:
                    message.meeting = Event.internalBinaryRead(reader, reader.uint32(), options, message.meeting);
                    break;
                case /* bool activateCheckOut */ 5:
                    message.activateCheckOut = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMeetingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        /* ggevent.Event meeting = 4; */
        if (message.meeting)
            Event.internalBinaryWrite(message.meeting, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool activateCheckOut = 5; */
        if (message.activateCheckOut !== false)
            writer.tag(5, WireType.Varint).bool(message.activateCheckOut);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateMeetingRequest
 */
export const UpdateMeetingRequest = new UpdateMeetingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMeetingResponse$Type extends MessageType<UpdateMeetingResponse> {
    constructor() {
        super("UpdateMeetingResponse", [
            { no: 1, name: "updatedMeeting", kind: "message", T: () => Event },
            { no: 2, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "roomName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateMeetingResponse>): UpdateMeetingResponse {
        const message = { roomID: "", roomName: "", locationID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateMeetingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMeetingResponse): UpdateMeetingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ggevent.Event updatedMeeting */ 1:
                    message.updatedMeeting = Event.internalBinaryRead(reader, reader.uint32(), options, message.updatedMeeting);
                    break;
                case /* string roomID */ 2:
                    message.roomID = reader.string();
                    break;
                case /* string roomName */ 3:
                    message.roomName = reader.string();
                    break;
                case /* string locationID */ 4:
                    message.locationID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMeetingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ggevent.Event updatedMeeting = 1; */
        if (message.updatedMeeting)
            Event.internalBinaryWrite(message.updatedMeeting, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string roomID = 2; */
        if (message.roomID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roomID);
        /* string roomName = 3; */
        if (message.roomName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roomName);
        /* string locationID = 4; */
        if (message.locationID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.locationID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateMeetingResponse
 */
export const UpdateMeetingResponse = new UpdateMeetingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMeetingsRequest$Type extends MessageType<ListMeetingsRequest> {
    constructor() {
        super("ListMeetingsRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "interval", kind: "enum", T: () => ["Interval", Interval] },
            { no: 6, name: "week", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "isPersonal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "personalEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMeetingsRequest>): ListMeetingsRequest {
        const message = { customerId: "", calendarproviderId: "", calendarId: "", limit: 0, interval: 0, week: 0, timeZone: "", isPersonal: false, personalEmail: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListMeetingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMeetingsRequest): ListMeetingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                case /* Interval interval */ 5:
                    message.interval = reader.int32();
                    break;
                case /* int32 week */ 6:
                    message.week = reader.int32();
                    break;
                case /* string timeZone */ 7:
                    message.timeZone = reader.string();
                    break;
                case /* bool isPersonal */ 8:
                    message.isPersonal = reader.bool();
                    break;
                case /* string personalEmail */ 9:
                    message.personalEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMeetingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        /* Interval interval = 5; */
        if (message.interval !== 0)
            writer.tag(5, WireType.Varint).int32(message.interval);
        /* int32 week = 6; */
        if (message.week !== 0)
            writer.tag(6, WireType.Varint).int32(message.week);
        /* string timeZone = 7; */
        if (message.timeZone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.timeZone);
        /* bool isPersonal = 8; */
        if (message.isPersonal !== false)
            writer.tag(8, WireType.Varint).bool(message.isPersonal);
        /* string personalEmail = 9; */
        if (message.personalEmail !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.personalEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListMeetingsRequest
 */
export const ListMeetingsRequest = new ListMeetingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMeetingsResponse$Type extends MessageType<ListMeetingsResponse> {
    constructor() {
        super("ListMeetingsResponse", [
            { no: 1, name: "meetings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 2, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "roomName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "allowDeleteOption", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListMeetingsResponse>): ListMeetingsResponse {
        const message = { meetings: [], roomID: "", roomName: "", locationID: "", allowDeleteOption: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListMeetingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMeetingsResponse): ListMeetingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ggevent.Event meetings */ 1:
                    message.meetings.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string roomID */ 2:
                    message.roomID = reader.string();
                    break;
                case /* string roomName */ 3:
                    message.roomName = reader.string();
                    break;
                case /* string locationID */ 4:
                    message.locationID = reader.string();
                    break;
                case /* bool allowDeleteOption */ 5:
                    message.allowDeleteOption = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMeetingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ggevent.Event meetings = 1; */
        for (let i = 0; i < message.meetings.length; i++)
            Event.internalBinaryWrite(message.meetings[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string roomID = 2; */
        if (message.roomID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roomID);
        /* string roomName = 3; */
        if (message.roomName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roomName);
        /* string locationID = 4; */
        if (message.locationID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.locationID);
        /* bool allowDeleteOption = 5; */
        if (message.allowDeleteOption !== false)
            writer.tag(5, WireType.Varint).bool(message.allowDeleteOption);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListMeetingsResponse
 */
export const ListMeetingsResponse = new ListMeetingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScheduleRequest$Type extends MessageType<ScheduleRequest> {
    constructor() {
        super("ScheduleRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScheduleRequest>): ScheduleRequest {
        const message = { customerId: "", calendarproviderId: "", calendarId: "", startTime: "", endTime: "", timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScheduleRequest): ScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                case /* string startTime */ 4:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 5:
                    message.endTime = reader.string();
                    break;
                case /* string timeZone */ 6:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        /* string startTime = 4; */
        if (message.startTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 5; */
        if (message.endTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.endTime);
        /* string timeZone = 6; */
        if (message.timeZone !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScheduleRequest
 */
export const ScheduleRequest = new ScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScheduleResponse$Type extends MessageType<ScheduleResponse> {
    constructor() {
        super("ScheduleResponse", [
            { no: 1, name: "timeSlots", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BusyTimeSlots }
        ]);
    }
    create(value?: PartialMessage<ScheduleResponse>): ScheduleResponse {
        const message = { timeSlots: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScheduleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScheduleResponse): ScheduleResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ggevent.BusyTimeSlots timeSlots */ 1:
                    message.timeSlots.push(BusyTimeSlots.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScheduleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ggevent.BusyTimeSlots timeSlots = 1; */
        for (let i = 0; i < message.timeSlots.length; i++)
            BusyTimeSlots.internalBinaryWrite(message.timeSlots[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScheduleResponse
 */
export const ScheduleResponse = new ScheduleResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeSlot$Type extends MessageType<TimeSlot> {
    constructor() {
        super("TimeSlot", [
            { no: 1, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TimeSlot>): TimeSlot {
        const message = { startTime: "", endTime: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TimeSlot>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeSlot): TimeSlot {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string startTime */ 1:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 2:
                    message.endTime = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeSlot, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string startTime = 1; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 2; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeSlot
 */
export const TimeSlot = new TimeSlot$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMeetingRequest$Type extends MessageType<DeleteMeetingRequest> {
    constructor() {
        super("DeleteMeetingRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "metaData", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 5, name: "systemDelete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "organizer_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMeetingRequest>): DeleteMeetingRequest {
        const message = { customerId: "", calendarproviderId: "", calendarId: "", metaData: {}, systemDelete: false, organizerEmail: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteMeetingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMeetingRequest): DeleteMeetingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                case /* map<string, string> metaData */ 4:
                    this.binaryReadMap4(message.metaData, reader, options);
                    break;
                case /* bool systemDelete */ 5:
                    message.systemDelete = reader.bool();
                    break;
                case /* string organizer_email */ 6:
                    message.organizerEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: DeleteMeetingRequest["metaData"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof DeleteMeetingRequest["metaData"] | undefined, val: DeleteMeetingRequest["metaData"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field DeleteMeetingRequest.metaData");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: DeleteMeetingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        /* map<string, string> metaData = 4; */
        for (let k of Object.keys(message.metaData))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metaData[k]).join();
        /* bool systemDelete = 5; */
        if (message.systemDelete !== false)
            writer.tag(5, WireType.Varint).bool(message.systemDelete);
        /* string organizer_email = 6; */
        if (message.organizerEmail !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.organizerEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteMeetingRequest
 */
export const DeleteMeetingRequest = new DeleteMeetingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMeetingRequest$Type extends MessageType<GetMeetingRequest> {
    constructor() {
        super("GetMeetingRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "metaData", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 5, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMeetingRequest>): GetMeetingRequest {
        const message = { customerId: "", calendarproviderId: "", calendarId: "", metaData: {}, timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMeetingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMeetingRequest): GetMeetingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                case /* map<string, string> metaData */ 4:
                    this.binaryReadMap4(message.metaData, reader, options);
                    break;
                case /* string timeZone */ 5:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: GetMeetingRequest["metaData"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetMeetingRequest["metaData"] | undefined, val: GetMeetingRequest["metaData"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field GetMeetingRequest.metaData");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: GetMeetingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        /* map<string, string> metaData = 4; */
        for (let k of Object.keys(message.metaData))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metaData[k]).join();
        /* string timeZone = 5; */
        if (message.timeZone !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetMeetingRequest
 */
export const GetMeetingRequest = new GetMeetingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMeetingResponse$Type extends MessageType<GetMeetingResponse> {
    constructor() {
        super("GetMeetingResponse", [
            { no: 1, name: "meeting", kind: "message", T: () => Event },
            { no: 2, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "roomName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMeetingResponse>): GetMeetingResponse {
        const message = { roomID: "", roomName: "", locationID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMeetingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMeetingResponse): GetMeetingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ggevent.Event meeting */ 1:
                    message.meeting = Event.internalBinaryRead(reader, reader.uint32(), options, message.meeting);
                    break;
                case /* string roomID */ 2:
                    message.roomID = reader.string();
                    break;
                case /* string roomName */ 3:
                    message.roomName = reader.string();
                    break;
                case /* string locationID */ 4:
                    message.locationID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMeetingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ggevent.Event meeting = 1; */
        if (message.meeting)
            Event.internalBinaryWrite(message.meeting, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string roomID = 2; */
        if (message.roomID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roomID);
        /* string roomName = 3; */
        if (message.roomName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roomName);
        /* string locationID = 4; */
        if (message.locationID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.locationID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetMeetingResponse
 */
export const GetMeetingResponse = new GetMeetingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmCheckInRequest$Type extends MessageType<ConfirmCheckInRequest> {
    constructor() {
        super("ConfirmCheckInRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "metaData", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<ConfirmCheckInRequest>): ConfirmCheckInRequest {
        const message = { customerId: "", calendarproviderId: "", calendarId: "", startTime: "", endTime: "", metaData: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConfirmCheckInRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfirmCheckInRequest): ConfirmCheckInRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                case /* string startTime */ 4:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 5:
                    message.endTime = reader.string();
                    break;
                case /* map<string, string> metaData */ 6:
                    this.binaryReadMap6(message.metaData, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: ConfirmCheckInRequest["metaData"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ConfirmCheckInRequest["metaData"] | undefined, val: ConfirmCheckInRequest["metaData"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ConfirmCheckInRequest.metaData");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: ConfirmCheckInRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        /* string startTime = 4; */
        if (message.startTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 5; */
        if (message.endTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.endTime);
        /* map<string, string> metaData = 6; */
        for (let k of Object.keys(message.metaData))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metaData[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ConfirmCheckInRequest
 */
export const ConfirmCheckInRequest = new ConfirmCheckInRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsScheduleRequest$Type extends MessageType<RoomsScheduleRequest> {
    constructor() {
        super("RoomsScheduleRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "roomsID", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "wayfinderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "deviceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RoomsScheduleRequest>): RoomsScheduleRequest {
        const message = { customerID: "", startTime: "", endTime: "", roomsID: [], wayfinderID: "", deviceID: "", timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoomsScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsScheduleRequest): RoomsScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startTime */ 2:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 3:
                    message.endTime = reader.string();
                    break;
                case /* repeated string roomsID */ 4:
                    message.roomsID.push(reader.string());
                    break;
                case /* string wayfinderID */ 5:
                    message.wayfinderID = reader.string();
                    break;
                case /* string deviceID */ 6:
                    message.deviceID = reader.string();
                    break;
                case /* string timeZone */ 7:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startTime = 2; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 3; */
        if (message.endTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endTime);
        /* repeated string roomsID = 4; */
        for (let i = 0; i < message.roomsID.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.roomsID[i]);
        /* string wayfinderID = 5; */
        if (message.wayfinderID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.wayfinderID);
        /* string deviceID = 6; */
        if (message.deviceID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.deviceID);
        /* string timeZone = 7; */
        if (message.timeZone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsScheduleRequest
 */
export const RoomsScheduleRequest = new RoomsScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsScheduleResponse$Type extends MessageType<RoomsScheduleResponse> {
    constructor() {
        super("RoomsScheduleResponse", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomsScheduleResponse_RoomSchedule },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RoomsScheduleResponse>): RoomsScheduleResponse {
        const message = { rooms: [], customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoomsScheduleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsScheduleResponse): RoomsScheduleResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated RoomsScheduleResponse.RoomSchedule rooms */ 1:
                    message.rooms.push(RoomsScheduleResponse_RoomSchedule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsScheduleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated RoomsScheduleResponse.RoomSchedule rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            RoomsScheduleResponse_RoomSchedule.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsScheduleResponse
 */
export const RoomsScheduleResponse = new RoomsScheduleResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsScheduleResponse_Direction$Type extends MessageType<RoomsScheduleResponse_Direction> {
    constructor() {
        super("RoomsScheduleResponse.Direction", [
            { no: 1, name: "distance", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "direction", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "distanceUnit", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RoomsScheduleResponse_Direction>): RoomsScheduleResponse_Direction {
        const message = { distance: 0, direction: 0, distanceUnit: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoomsScheduleResponse_Direction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsScheduleResponse_Direction): RoomsScheduleResponse_Direction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 distance */ 1:
                    message.distance = reader.int32();
                    break;
                case /* int32 direction */ 2:
                    message.direction = reader.int32();
                    break;
                case /* string distanceUnit */ 3:
                    message.distanceUnit = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsScheduleResponse_Direction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 distance = 1; */
        if (message.distance !== 0)
            writer.tag(1, WireType.Varint).int32(message.distance);
        /* int32 direction = 2; */
        if (message.direction !== 0)
            writer.tag(2, WireType.Varint).int32(message.direction);
        /* string distanceUnit = 3; */
        if (message.distanceUnit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.distanceUnit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsScheduleResponse.Direction
 */
export const RoomsScheduleResponse_Direction = new RoomsScheduleResponse_Direction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsScheduleResponse_RoomSchedule$Type extends MessageType<RoomsScheduleResponse_RoomSchedule> {
    constructor() {
        super("RoomsScheduleResponse.RoomSchedule", [
            { no: 1, name: "meetings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 2, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "roomName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "numberOfSeats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "direction", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomsScheduleResponse_Direction }
        ]);
    }
    create(value?: PartialMessage<RoomsScheduleResponse_RoomSchedule>): RoomsScheduleResponse_RoomSchedule {
        const message = { meetings: [], roomID: "", roomName: "", numberOfSeats: 0, direction: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoomsScheduleResponse_RoomSchedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsScheduleResponse_RoomSchedule): RoomsScheduleResponse_RoomSchedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ggevent.Event meetings */ 1:
                    message.meetings.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string roomID */ 2:
                    message.roomID = reader.string();
                    break;
                case /* string roomName */ 3:
                    message.roomName = reader.string();
                    break;
                case /* int32 numberOfSeats */ 4:
                    message.numberOfSeats = reader.int32();
                    break;
                case /* repeated RoomsScheduleResponse.Direction direction */ 5:
                    message.direction.push(RoomsScheduleResponse_Direction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsScheduleResponse_RoomSchedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ggevent.Event meetings = 1; */
        for (let i = 0; i < message.meetings.length; i++)
            Event.internalBinaryWrite(message.meetings[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string roomID = 2; */
        if (message.roomID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roomID);
        /* string roomName = 3; */
        if (message.roomName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roomName);
        /* int32 numberOfSeats = 4; */
        if (message.numberOfSeats !== 0)
            writer.tag(4, WireType.Varint).int32(message.numberOfSeats);
        /* repeated RoomsScheduleResponse.Direction direction = 5; */
        for (let i = 0; i < message.direction.length; i++)
            RoomsScheduleResponse_Direction.internalBinaryWrite(message.direction[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsScheduleResponse.RoomSchedule
 */
export const RoomsScheduleResponse_RoomSchedule = new RoomsScheduleResponse_RoomSchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckRequest$Type extends MessageType<HealthCheckRequest> {
    constructor() {
        super("HealthCheckRequest", [
            { no: 1, name: "deviceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HealthCheckRequest>): HealthCheckRequest {
        const message = { deviceID: "", customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HealthCheckRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HealthCheckRequest): HealthCheckRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string deviceID */ 1:
                    message.deviceID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HealthCheckRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string deviceID = 1; */
        if (message.deviceID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HealthCheckRequest
 */
export const HealthCheckRequest = new HealthCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckRoomsScheduleRequest$Type extends MessageType<CheckRoomsScheduleRequest> {
    constructor() {
        super("CheckRoomsScheduleRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendars_id", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckRoomsScheduleRequest>): CheckRoomsScheduleRequest {
        const message = { customerId: "", calendarproviderId: "", calendarsId: [], startTime: "", endTime: "", timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckRoomsScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckRoomsScheduleRequest): CheckRoomsScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* repeated string calendars_id */ 3:
                    message.calendarsId.push(reader.string());
                    break;
                case /* string startTime */ 4:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 5:
                    message.endTime = reader.string();
                    break;
                case /* string timeZone */ 6:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckRoomsScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* repeated string calendars_id = 3; */
        for (let i = 0; i < message.calendarsId.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.calendarsId[i]);
        /* string startTime = 4; */
        if (message.startTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 5; */
        if (message.endTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.endTime);
        /* string timeZone = 6; */
        if (message.timeZone !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CheckRoomsScheduleRequest
 */
export const CheckRoomsScheduleRequest = new CheckRoomsScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsSchedule$Type extends MessageType<RoomsSchedule> {
    constructor() {
        super("RoomsSchedule", [
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timeSlots", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BusyTimeSlots }
        ]);
    }
    create(value?: PartialMessage<RoomsSchedule>): RoomsSchedule {
        const message = { calendarId: "", timeSlots: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoomsSchedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsSchedule): RoomsSchedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                case /* repeated ggevent.BusyTimeSlots timeSlots */ 4:
                    message.timeSlots.push(BusyTimeSlots.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsSchedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        /* repeated ggevent.BusyTimeSlots timeSlots = 4; */
        for (let i = 0; i < message.timeSlots.length; i++)
            BusyTimeSlots.internalBinaryWrite(message.timeSlots[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsSchedule
 */
export const RoomsSchedule = new RoomsSchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckRoomsScheduleResponse$Type extends MessageType<CheckRoomsScheduleResponse> {
    constructor() {
        super("CheckRoomsScheduleResponse", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "schedule", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomsSchedule }
        ]);
    }
    create(value?: PartialMessage<CheckRoomsScheduleResponse>): CheckRoomsScheduleResponse {
        const message = { customerId: "", calendarproviderId: "", schedule: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckRoomsScheduleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckRoomsScheduleResponse): CheckRoomsScheduleResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarprovider_id */ 2:
                    message.calendarproviderId = reader.string();
                    break;
                case /* repeated RoomsSchedule schedule */ 3:
                    message.schedule.push(RoomsSchedule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckRoomsScheduleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarprovider_id = 2; */
        if (message.calendarproviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarproviderId);
        /* repeated RoomsSchedule schedule = 3; */
        for (let i = 0; i < message.schedule.length; i++)
            RoomsSchedule.internalBinaryWrite(message.schedule[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CheckRoomsScheduleResponse
 */
export const CheckRoomsScheduleResponse = new CheckRoomsScheduleResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalendarEventsSubscriptionRequest$Type extends MessageType<CalendarEventsSubscriptionRequest> {
    constructor() {
        super("CalendarEventsSubscriptionRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendar_provider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CalendarEventsSubscriptionRequest>): CalendarEventsSubscriptionRequest {
        const message = { customerId: "", calendarProviderId: "", calendarId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalendarEventsSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalendarEventsSubscriptionRequest): CalendarEventsSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendar_provider_id */ 2:
                    message.calendarProviderId = reader.string();
                    break;
                case /* string calendar_id */ 3:
                    message.calendarId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalendarEventsSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendar_provider_id = 2; */
        if (message.calendarProviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderId);
        /* string calendar_id = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CalendarEventsSubscriptionRequest
 */
export const CalendarEventsSubscriptionRequest = new CalendarEventsSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalendarEventsSubscriptionResponse$Type extends MessageType<CalendarEventsSubscriptionResponse> {
    constructor() {
        super("CalendarEventsSubscriptionResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["CalendarEventsSubscriptionStatus", CalendarEventsSubscriptionStatus] }
        ]);
    }
    create(value?: PartialMessage<CalendarEventsSubscriptionResponse>): CalendarEventsSubscriptionResponse {
        const message = { status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalendarEventsSubscriptionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalendarEventsSubscriptionResponse): CalendarEventsSubscriptionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* CalendarEventsSubscriptionStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalendarEventsSubscriptionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* CalendarEventsSubscriptionStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CalendarEventsSubscriptionResponse
 */
export const CalendarEventsSubscriptionResponse = new CalendarEventsSubscriptionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TriggerCheckInReminderRequest$Type extends MessageType<TriggerCheckInReminderRequest> {
    constructor() {
        super("TriggerCheckInReminderRequest", [
            { no: 1, name: "calendarprovider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "minutes_before_meeting", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "meeting_release_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "language", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "meeting_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "organizer_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TriggerCheckInReminderRequest>): TriggerCheckInReminderRequest {
        const message = { calendarproviderId: "", calendarId: "", minutesBeforeMeeting: 0, meetingReleaseTime: "", language: "", meetingTitle: "", organizerEmail: "", customerId: "", eventId: "", timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TriggerCheckInReminderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TriggerCheckInReminderRequest): TriggerCheckInReminderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string calendarprovider_id */ 1:
                    message.calendarproviderId = reader.string();
                    break;
                case /* string calendar_id */ 2:
                    message.calendarId = reader.string();
                    break;
                case /* int32 minutes_before_meeting */ 3:
                    message.minutesBeforeMeeting = reader.int32();
                    break;
                case /* string meeting_release_time */ 4:
                    message.meetingReleaseTime = reader.string();
                    break;
                case /* string language */ 5:
                    message.language = reader.string();
                    break;
                case /* string meeting_title */ 6:
                    message.meetingTitle = reader.string();
                    break;
                case /* string organizer_email */ 7:
                    message.organizerEmail = reader.string();
                    break;
                case /* string customer_id */ 8:
                    message.customerId = reader.string();
                    break;
                case /* string event_id */ 9:
                    message.eventId = reader.string();
                    break;
                case /* string time_zone */ 10:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TriggerCheckInReminderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string calendarprovider_id = 1; */
        if (message.calendarproviderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.calendarproviderId);
        /* string calendar_id = 2; */
        if (message.calendarId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarId);
        /* int32 minutes_before_meeting = 3; */
        if (message.minutesBeforeMeeting !== 0)
            writer.tag(3, WireType.Varint).int32(message.minutesBeforeMeeting);
        /* string meeting_release_time = 4; */
        if (message.meetingReleaseTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.meetingReleaseTime);
        /* string language = 5; */
        if (message.language !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.language);
        /* string meeting_title = 6; */
        if (message.meetingTitle !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.meetingTitle);
        /* string organizer_email = 7; */
        if (message.organizerEmail !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.organizerEmail);
        /* string customer_id = 8; */
        if (message.customerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customerId);
        /* string event_id = 9; */
        if (message.eventId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.eventId);
        /* string time_zone = 10; */
        if (message.timeZone !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TriggerCheckInReminderRequest
 */
export const TriggerCheckInReminderRequest = new TriggerCheckInReminderRequest$Type();
/**
 * @generated ServiceType for protobuf service Booking
 */
export const Booking = new ServiceType("Booking", [
    { name: "BookMeeting", options: { "google.api.http": { post: "/v1/booking/book", body: "*" } }, I: BookMeetingRequest, O: BookMeetingResponse },
    { name: "UpdateMeeting", options: { "google.api.http": { patch: "/v1/booking/book", body: "*" } }, I: UpdateMeetingRequest, O: UpdateMeetingResponse },
    { name: "ListMeetings", options: { "google.api.http": { get: "/v1/booking/book" } }, I: ListMeetingsRequest, O: ListMeetingsResponse },
    { name: "GetMeeting", options: { "google.api.http": { post: "/v1/booking/bool/get", body: "*" } }, I: GetMeetingRequest, O: GetMeetingResponse },
    { name: "CheckSchedule", options: { "google.api.http": { get: "/v1/booking/schedule" } }, I: ScheduleRequest, O: ScheduleResponse },
    { name: "CheckRoomsSchedule", options: { "google.api.http": { get: "/v1/booking/schedule/rooms" } }, I: CheckRoomsScheduleRequest, O: CheckRoomsScheduleResponse },
    { name: "DeleteMeeting", options: { "google.api.http": { delete: "/v1/booking/book", body: "*" } }, I: DeleteMeetingRequest, O: Empty },
    { name: "ConfirmCheckIn", options: { "google.api.http": { post: "/v1/booking/confirm-checkin", body: "*" } }, I: ConfirmCheckInRequest, O: Empty },
    { name: "HealthCheck", options: { "google.api.http": { get: "/v1/booking/health" } }, I: HealthCheckRequest, O: Empty },
    { name: "RoomsSchedule", options: { "google.api.http": { get: "/v1/booking/rooms-schedule" } }, I: RoomsScheduleRequest, O: RoomsScheduleResponse },
    { name: "CalendarEventsSubscription", options: { "google.api.http": { get: "/v1/booking/calendar-subscription" } }, I: CalendarEventsSubscriptionRequest, O: CalendarEventsSubscriptionResponse },
    { name: "TriggerCheckInReminder", options: { "google.api.http": { post: "/v1/booking/trigger-checkin-reminder", body: "*" } }, I: TriggerCheckInReminderRequest, O: Empty }
]);
