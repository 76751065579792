import React, { useEffect, useState } from "react";
import L from "leaflet";
import useLocalStorageState from "use-local-storage-state";

import { useDefineBounds } from "../lib/useDefineBounds";
import { useMarker } from "../lib/useMarkerData";

import { drawMarkers } from "../../../lib/helpers/leaflet";
import { getImageSize } from "../../../api/external-api/react-native-map-view/utils/overlay.utils";

import { MapContainer, ImageOverlay, MapConsumer } from "react-leaflet";
import { FloorSettingsButton } from "../FloorSettings/FloorSettingsButton";
import { floorPlanHelpers } from "../lib/floorPlanHelpers";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { EmptyEntity } from "../../shared/EmptyEntity/EmptyEntity";
import { FloorFields } from "../../../api/graphql/floors/floors";
import { FloorChildrenDefaultList } from "../FloorChildrenList/FloorChildrenDefaultList";
import { FloorMarkersSettings } from "../FloorMarkersSettings/FloorMarkersSettings";
import { FloorMapActionsGroup } from "../FloorMapActionsGroup/FloorMapActionsGroup";

import "leaflet-draw/dist/leaflet.draw";
import "./FloorContent.scss";

interface Props {
  data: FloorFields;
}

export type Size = {
  width: number;
  height: number;
};

export const FloorPlan = ({ data }: Props) => {
  const [disableZoom, setDisableZoom] = useState(false);
  const { validateInputNumber } = floorPlanHelpers();
  const [markersScaleFactor, setMarkersScaleFactor] = useLocalStorageState(
    `markers-scale-factor-${data?.id}`,
    {
      defaultValue: 1,
    }
  );
  const { markers, openMarkerPopup } = useMarker(
    data,
    setDisableZoom,
    disableZoom,
    {
      scaleFactor: markersScaleFactor,
    }
  );

  const [_, setShow] = useState(false);

  const [mapImageSize, setMapImageSize] = useState<Size | null>(null);

  const { overlayBounds, mapBounds, mapMaxBounds } =
    useDefineBounds(mapImageSize);

  useEffect(() => {
    setMapImageSize(null);

    getImageSize(data?.map?.url || "").then(setMapImageSize);
  }, [data?.map?.url]);

  useEffect(() => {
    if (disableZoom === true) {
      setDisableZoom(false);
    }
  }, [data?.id]);

  return (
    <div className="FloorContent">
      <div className="FloorContent__plan">
        {data?.map === null ? (
          <EmptyEntity
            type="FloorMap"
            typeOfFloorMap="floor"
            floorId={data?.id}
          />
        ) : (
          <>
            <MapContainer
              crs={L.CRS.Simple}
              center={[0, 0]}
              zoom={0}
              maxZoom={3}
              minZoom={-3}
              zoomSnap={0.15}
              zoomDelta={0.5}
              id="map-container"
              doubleClickZoom={false}
              scrollWheelZoom={true}
              touchZoom={true}
            >
              <FloorMapActionsGroup>
                <FloorSettingsButton map={data?.map} />
                <FloorMarkersSettings
                  scaleFactor={markersScaleFactor}
                  onScaleFactorChange={setMarkersScaleFactor}
                />
              </FloorMapActionsGroup>
              {data?.map?.url !== undefined &&
              !!overlayBounds &&
              !!mapImageSize ? (
                <>
                  <MapConsumer>
                    {(map) => {
                      if (mapBounds && mapMaxBounds && disableZoom === false) {
                        map.setMaxBounds(mapMaxBounds).fitBounds(mapBounds);
                        setTimeout(() => {
                          setShow(true);
                        }, 500);
                      }
                      drawMarkers(
                        map,
                        markers,
                        {
                          mapSize: {
                            width: mapImageSize?.width / 2,
                            height: mapImageSize?.height / 2,
                          },
                        },
                        {
                          snapEnabled: data?.map?.showGridline || false,
                        }
                      );

                      return null;
                    }}
                  </MapConsumer>

                  <ImageOverlay
                    url={data.map.url}
                    opacity={
                      data.map.opacity && validateInputNumber(data.map.opacity)
                    }
                    bounds={overlayBounds}
                  />
                </>
              ) : (
                <LoadingBox />
              )}
            </MapContainer>
          </>
        )}
      </div>
      <div className="FloorContent__children">
        <FloorChildrenDefaultList onClick={openMarkerPopup} data={data} />
      </div>
    </div>
  );
};
