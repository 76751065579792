import { createContext, useContext } from "react";
import {
  Calendar,
  CalendarType,
  EWSProvider,
  GGCalendarProvider,
  PersonalCalendar,
} from "../../../api/grpc/calendarprovider/calendarprovider";
import { Providers, ProvidersList } from "./CalendarContextProvider";

export interface CalendarContext {
  error: boolean;
  errorPersonalCalendar: boolean;
  loadingPersonalCalendar: boolean;
  loading: boolean;
  providers: Providers[];
  providersList: ProvidersList;
  deleteProvider: (id: string) => void;
  addProvider: (provider: GGCalendarProvider) => void;
  updateProvidersList: (key: string, value: Calendar[]) => void;
  updateProviderSettings: (
    id: string,
    value: EWSProvider,
    includeName?: boolean
  ) => void;
  getProviderByID: (id: string) => GGCalendarProvider | undefined;
  getProvidersByType: (type: CalendarType) => GGCalendarProvider[];
  openEnvironment: boolean;
  setOpenEnvironment: React.Dispatch<React.SetStateAction<boolean>>;
  personalCalendar: PersonalCalendar[];
  setPersonalCalendar: React.Dispatch<React.SetStateAction<PersonalCalendar[]>>;
  refetchPersonalCalendar: () => Promise<void>;
  refetchCalendars: () => Promise<void>;
  dynamicStyles: React.CSSProperties;
}

export const CalendarContext = createContext<CalendarContext>({
  error: false,
  providers: [],
  loading: false,
  providersList: {},
  addProvider: () => {},
  deleteProvider: () => {},
  getProvidersByType: () => [],
  updateProvidersList: () => {},
  updateProviderSettings: () => {},
  getProviderByID: () => undefined,
  openEnvironment: false,
  setOpenEnvironment: () => {},
  personalCalendar: [],
  setPersonalCalendar: () => {},
  loadingPersonalCalendar: false,
  errorPersonalCalendar: false,
  refetchPersonalCalendar: async () => {},
  refetchCalendars: async () => {},
  dynamicStyles: {},
});

export const useCalendarContext = () => useContext(CalendarContext);

export const { Provider } = CalendarContext;
