import React, { useState } from "react";
import {
  IntegrationGroupMember,
  SyncStatus,
} from "../../../api/grpc/contacts/contacts";
import { DirectoryContext } from "./DirectoryContext";

interface Props {
  children: React.ReactNode;
}

export const DirectoryContextProvider = ({ children }: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<IntegrationGroupMember[]>(
    []
  );
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [filterStatus, setFilterStatus] = useState<SyncStatus[]>([]);

  const context: DirectoryContext = {
    selectedUsers,
    setSelectedUsers,
    selectAllUsers,
    setSelectAllUsers,
    filterStatus,
    setFilterStatus,
    searchValue,
    setSearchValue,
  };

  return (
    <DirectoryContext.Provider value={context}>
      {children}
    </DirectoryContext.Provider>
  );
};
