import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";

import { useRouter } from "../../../lib/hooks/useRouter";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";

import { CalendarsMain } from "./CalendarsMain/CalendarsMain";
import { CalendarSingle } from "./CalendarSingle/CalendarSingle";
import { HandleRequestState } from "../../../components/shared/HandleRequestState/HandleRequestState";

export const CalendarsRoutes = () => {
  const { match } = useRouter();
  const { loading, error } = useCalendarContext();

  const calendarsRoutes = [
    {
      to: match.url,
      exact: true,
      label: "CalendarsMain",
      component: CalendarsMain,
      id: "calendars-main-tab",
    },
    {
      to: `${match.url}/:calendarType/:id`,
      label: "Calendar",
      component: CalendarSingle,
      id: "calendar-single",
    },
  ];

  return (
    <HandleRequestState
      state={loading}
      placeholder={<h4 style={{ marginTop: "20px" }}>Loading...</h4>}
    >
      <HandleRequestState
        state={error}
        placeholder={
          <div className="w-100 h-100 mt-4">
            <h4>
              An error happened while trying to retrieve the data, please try
              again!
            </h4>
          </div>
        }
      >
        <Switch>
          {calendarsRoutes.map((item) => (
            <Route
              key={item.id}
              path={item.to}
              component={item.component}
              exact={item.exact ? true : undefined}
            />
          ))}
          <Redirect to={calendarsRoutes[0].to} />
        </Switch>
      </HandleRequestState>
    </HandleRequestState>
  );
};
