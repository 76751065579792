import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cs from "classnames";
import { differenceInMinutes, format } from "date-fns";
import isToday from "date-fns/isToday";
import { utcToZonedTime } from "date-fns-tz";
import { useBookingsContext } from "../../../../../components/Bookings/Context/BookingsContext";
import { useSidebarContext } from "../../../../../lib/context/Sidebar/SidebarContext";
import { useDefaultLocationHelpers } from "../../../../../components/Bookings/lib/defaultLocationHelpers";
import { useBookingsMapContext } from "../../../../../components/Bookings/BookingsMap/Context/BookingsMapContext";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { useRouter } from "../../../../../lib/hooks/useRouter";

import { getWorkplaceIcon } from "../../../../../components/Workplaces/helpers/getWorkplaceInformation";
import { getWorkplaceChildren } from "../../../../../components/Workplaces/helpers/getWorkplaceChildren";

import { Icon } from "../../../../../components/shared/Icon/Icon";
import { WorkplaceFields } from "../../../../../api/graphql/workplaces";
import { AddWorkplaceButton } from "../../../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

import "./WorkplacesTreeLevel.scss";

interface Props {
  item: WorkplaceFields;
  filterRoot?: boolean;
  toggleParent: (value: boolean) => void;
  mainTreeLevel?: boolean;
  isPickerOnly?: boolean;
  isLocationDefault?: boolean;
  defaultLocation?: any;
  typeOfPicker?: string;
  pathTo: string;
  isWayfinder?: boolean;
  onItemClick?: (item: WorkplaceFields) => void;
}

export const WorkplacesTreeLevel = (props: Props) => {
  const {
    item,
    toggleParent,
    filterRoot,
    isPickerOnly,
    isLocationDefault,
    defaultLocation,
    typeOfPicker,
    pathTo,
    mainTreeLevel,
    isWayfinder,
    onItemClick,
  } = props;
  const { pathname } = useRouter();
  const isActive = pathname.includes(item.id);

  const { handleLocationId, emptyActiveTags } = useBookingsContext();
  const [toggle, setToggle] = useState(() => isActive);
  const { togglePageSidebar } = useSidebarContext();
  const { addDefaultLocation, handleUpdateDefaultLocation } =
    useDefaultLocationHelpers();
  const {
    attendees,
    searchedAttendees,
    handleSearchedAttendees,
    handleAttendees,
    setDateAndTime,
    handleDay,
    handleTime,
    time,
    dateAndTime,
  } = useBookingsMapContext();

  const { timeZone } = useTimeZoneContext();

  useEffect(() => {
    if (isActive) {
      toggleParent(true);
    }
  }, [isActive]);

  const icon = getWorkplaceIcon(item.__typename);
  const workplaceChildren = getWorkplaceChildren(item);
  const hasChildren = workplaceChildren.some((item) => item && item.length > 0);
  const isDeskType = item.__typename === "Desk";

  const classes = cs("WorkplacesTreeLevel", {
    "WorkplacesTreeLevel--open": toggle,
    "WorkplacesTreeLevel--active": isActive,
    "WorkplacesTreeLevel--space": !hasChildren,
  });

  const subLevelClasses = cs("WorkplacesTreeLevel__sub-level", {
    "WorkplacesTreeLevel__sub-level--open": toggle,
  });

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const handleItemClick = (item: WorkplaceFields) => () => {
    if (onItemClick) {
      return onItemClick(item);
    }

    if (mainTreeLevel || isWayfinder) {
      return;
    }

    if (!isPickerOnly) {
      if (attendees.length) {
        handleAttendees([]);
      }

      if (searchedAttendees.length) {
        handleSearchedAttendees([]);
      }

      if (!isToday(new Date(dateAndTime))) {
        handleDay(format(new Date(), "yyyy-MM-dd"));
        setDateAndTime(new Date().toISOString());
      }

      if (differenceInMinutes(new Date(time), new Date()) > 0) {
        setDateAndTime(new Date().toISOString());
        handleTime(utcToZonedTime(new Date(), timeZone));
      }

      return;
    }

    togglePageSidebar();

    emptyActiveTags();

    return handleLocationId(item.id, item.name);
  };

  const handleDefaultLocation =
    (referenceId: string, tenantId: string, itemName: string) => () => {
      if (!isLocationDefault || !addDefaultLocation) {
        return;
      }

      if (defaultLocation?.data?.queryDefaultLocationPath.length >= 1) {
        return handleUpdateDefaultLocation(referenceId, itemName);
      }

      return addDefaultLocation(referenceId, itemName);
    };

  const definePathTo = (item: WorkplaceFields): string => {
    if (mainTreeLevel) {
      return `${pathTo}/${item.__typename.toLowerCase()}/${item.id}`;
    }

    if (pathTo !== "#" && item.__typename === "Floor") {
      return `${pathTo}/${item.id}`;
    }

    return "#";
  };

  return (
    <>
      <div className={classes}>
        <div className="WorkplacesTreeLevel__wrapper">
          {hasChildren && (
            <span
              onClick={handleToggle}
              className="WorkplacesTreeLevel__toggle"
            ></span>
          )}
          <NavLink
            className="WorkplacesTreeLevel__link"
            onClick={
              isLocationDefault
                ? handleDefaultLocation(item.id, item.id, item.name)
                : handleItemClick(item)
            }
            to={definePathTo(item)}
          >
            <div className="WorkplacesTreeLevel__icon">
              <Icon icon={icon} />
            </div>
            <h3 className="WorkplacesTreeLevel__name">{item.name}</h3>
          </NavLink>
          {filterRoot ||
          isDeskType ||
          (item.__typename === "Wayfinder" &&
            item.devices &&
            item.devices.length >= 1) ? (
            <></>
          ) : (
            <AddWorkplaceButton
              type={item.__typename}
              parentId={item.id}
              small
            />
          )}
        </div>
      </div>
      {hasChildren && (
        <div className={subLevelClasses}>
          {workplaceChildren.map((type) => {
            return filterRoot
              ? type
                  ?.filter(
                    (i: WorkplaceFields) =>
                      i.__typename === "Company" ||
                      i.__typename === "Site" ||
                      i.__typename === "Building" ||
                      i.__typename === "Floor"
                  )
                  .map((workplace: WorkplaceFields) => (
                    <WorkplacesTreeLevel
                      item={workplace}
                      key={`${workplace.id}-tree-level`}
                      isPickerOnly={isPickerOnly}
                      toggleParent={(value) => {
                        setToggle(value);
                        toggleParent(value);
                      }}
                      filterRoot={true}
                      isLocationDefault={isLocationDefault}
                      defaultLocation={defaultLocation}
                      typeOfPicker={typeOfPicker}
                      pathTo={pathTo}
                      mainTreeLevel={mainTreeLevel}
                      isWayfinder={isWayfinder}
                      onItemClick={onItemClick}
                    />
                  ))
              : type?.map((workplace) => (
                  <WorkplacesTreeLevel
                    item={workplace}
                    key={`${workplace.id}-tree-level`}
                    toggleParent={(value) => {
                      setToggle(value);
                      toggleParent(value);
                    }}
                    pathTo={pathTo}
                    mainTreeLevel={mainTreeLevel}
                    onItemClick={onItemClick}
                  />
                ));
          })}
        </div>
      )}
    </>
  );
};
