import { useApolloClient, useMutation } from "@apollo/client";

import { SITE_FIELDS_FRAGMENT } from "../sites/sites";
import { FLOOR_FIELDS_FRAGMENT } from "../floors/floors";
import { COMPANY_FIELDS_FRAGMENT } from "../companies/companies";
import { BUILDING_FIELDS_FRAGMENT } from "../buildings/buildings";

import { WorkplaceTypes } from "../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../workplaces";

import {
  AddRoomResponse,
  ADD_ROOM_TO_SITE,
  ADD_ROOM_TO_BUILDING,
  ADD_ROOM_TO_COMPANY,
  ADD_ROOM_TO_TENANT,
  RoomRequestVariables,
  UpdateRoomResponse,
  UPDATE_ROOM,
  ADD_ROOM_TO_FLOOR,
  ROOM_FIELDS_FRAGMENT,
  UPDATE_ROOM_DISPLAY_SETTINGS,
  ROOM_DISPLAY_SETTINGS_FRAGMENT,
  UpdateRoomDisplaySettingsVariables,
  UpdateRoomLicenseVariables,
  UPDATE_ROOM_LICENSE,
  defaultDisplaySettings,
  ADD_ROOM_TO_ZONE,
  ADD_MARKER_TO_ROOM,
  UpdateRoomMarkerVariables,
} from "./rooms";
import { ZONE_FIELDS_FRAGMENT } from "../zones/zones";
import { toast } from "react-toastify";
import { displaySettingsObj } from "./displaySettingsObj";

export const useRoomsRequests = () => {
  const client = useApolloClient();
  const updateRoomMutation = useMutation<
    UpdateRoomResponse,
    RoomRequestVariables
  >(UPDATE_ROOM);

  const addRoomToTenant = async (variables: RoomRequestVariables) => {
    return await client.mutate<AddRoomResponse, RoomRequestVariables>({
      mutation: ADD_ROOM_TO_TENANT,
      variables,
      update: (cache, newData) => {
        const previousQuery = cache.readQuery<
          GetWorkplacesResponse,
          WorkplaceRequestVariables
        >({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
        });

        const tenantResults = previousQuery?.queryTenant[0];
        const newRoom = newData.data?.addRoom.room[0];

        cache.writeQuery({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
          data: {
            queryTenant: [
              {
                ...tenantResults,
                rooms: [...(tenantResults?.rooms || []), newRoom],
              },
            ],
          },
        });
      },
    });
  };

  const addRoomToCompany = async (variables: RoomRequestVariables) => {
    return await client.mutate<AddRoomResponse, RoomRequestVariables>({
      mutation: ADD_ROOM_TO_COMPANY,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            rooms: [...previousData.rooms, newData.data?.addRoom.room[0]],
          },
        });
      },
    });
  };

  const addRoomToSite = async (variables: RoomRequestVariables) => {
    return await client.mutate<AddRoomResponse, RoomRequestVariables>({
      mutation: ADD_ROOM_TO_SITE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            rooms: [...previousData.rooms, newData.data?.addRoom.room[0]],
          },
        });
      },
    });
  };

  const addRoomToBuilding = async (variables: RoomRequestVariables) => {
    return await client.mutate<AddRoomResponse, RoomRequestVariables>({
      mutation: ADD_ROOM_TO_BUILDING,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            rooms: [...previousData.rooms, newData.data?.addRoom.room[0]],
          },
        });
      },
    });
  };

  const addRoomToFloor = async (variables: RoomRequestVariables) => {
    return await client.mutate<AddRoomResponse, RoomRequestVariables>({
      mutation: ADD_ROOM_TO_FLOOR,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            rooms: [...previousData.rooms, newData.data?.addRoom.room[0]],
          },
        });
      },
    });
  };

  const addRoomToZone = async (variables: RoomRequestVariables) => {
    return await client.mutate<AddRoomResponse, RoomRequestVariables>({
      mutation: ADD_ROOM_TO_ZONE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Zone:${variables.id}`,
          fragmentName: "ZoneFields",
          fragment: ZONE_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Zone:${variables.id}`,
          fragmentName: "ZoneFields",
          fragment: ZONE_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            rooms: [...previousData.rooms, newData.data?.addRoom.room[0]],
          },
        });
      },
    });
  };

  const updateRoom = async (variables: RoomRequestVariables) => {
    const [request] = updateRoomMutation;

    return await request({
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Room:${variables.id}`,
          fragmentName: "RoomFields",
          fragment: ROOM_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateRoom.room[0],
          },
        });

        toast.success("Room updated successfully!");
      },
      onError: (error: any) => {
        toast.error(error.networkError.result.message);
      },
    });
  };

  const updateRoomMarker = async (variables: UpdateRoomMarkerVariables) => {
    return await client.mutate<UpdateRoomResponse, UpdateRoomMarkerVariables>({
      mutation: ADD_MARKER_TO_ROOM,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Room:${variables.id}`,
          fragmentName: "RoomFields",
          fragment: ROOM_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Room:${variables.id}`,
          fragmentName: "RoomFields",
          fragment: ROOM_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            marker: {
              latitude: newData?.data?.updateRoom.room[0].marker.latitude,
              longitude: newData?.data?.updateRoom.room[0].marker.longitude,
            },
          },
        });
      },
    });
  };

  const updateRoomLicense = async (variables: UpdateRoomLicenseVariables) => {
    return await client.mutate<UpdateRoomResponse, UpdateRoomLicenseVariables>({
      mutation: UPDATE_ROOM_LICENSE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Room:${variables.id}`,
          fragmentName: "RoomFields",
          fragment: ROOM_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Room:${variables.id}`,
          fragmentName: "RoomFields",
          fragment: ROOM_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            licensed: newData.data?.updateRoom.room[0].licensed,
          },
        });
      },
    });
  };

  const updateRoomDisplay = async (
    variables: UpdateRoomDisplaySettingsVariables
  ) => {
    return await client.mutate<
      UpdateRoomResponse,
      UpdateRoomDisplaySettingsVariables
    >({
      mutation: UPDATE_ROOM_DISPLAY_SETTINGS,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Room:${variables.id}`,
          fragmentName: "RoomDisplaySettings",
          fragment: ROOM_DISPLAY_SETTINGS_FRAGMENT,
          data: {
            displaySettings: {
              ...newData.data?.updateRoom.room[0].displaySettings,
            },
          },
        });
      },
    });
  };

  const addRoom = (
    params: RoomRequestVariables,
    parentType?: WorkplaceTypes
  ) => {
    //if room is being created with template, display settings is filled from template
    //otherwise display settings is sent as a defult object from defaultDisplaySettings
    const definedDisplaySettings = params?.displaySettings
      ? displaySettingsObj(params.displaySettings)
      : defaultDisplaySettings;

    const variables: RoomRequestVariables = {
      ...params,
      displaySettings: {
        ...definedDisplaySettings,
        customerId: params.customerId,
        workingWeekDays: params.workingWeekDays,
        nearbyEnabled: params.nearbyEnabled,
      },
    };

    if (parentType === "Company") {
      return addRoomToCompany(variables);
    }

    if (parentType === "Building") {
      return addRoomToBuilding(variables);
    }

    if (parentType === "Site") {
      return addRoomToSite(variables);
    }

    if (parentType === "Floor") {
      return addRoomToFloor(variables);
    }

    if (parentType === "Zone") {
      return addRoomToZone(variables);
    }

    return addRoomToTenant(variables);
  };

  return {
    addRoom,
    updateRoom,
    updateRoomLicense,
    updateRoomDisplay,
    updateRoomMarker,
  };
};
