import {
  CustomerSettings,
  GetCustomerSettingsRequest,
} from "../../api/grpc/customer/customer";
import { useCustomerRequests } from "../../api/grpc/customer/useCustomerRequests";
import useGrpcQuery from "./useGrpcQuery";

export const useGlobalWorkingHours = () => {
  const { getCustomerSettings } = useCustomerRequests();

  const {
    loading,
    data: workingHoursData,
    refetch,
    error,
  } = useGrpcQuery<GetCustomerSettingsRequest, CustomerSettings>({
    method: getCustomerSettings,
    variables: {
      customerId: "",
    },
  });

  return { loading, workingHoursData, refetch, error };
};
