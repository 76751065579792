import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { ModalFooter } from "reactstrap";
import { Button } from "../../shared/Button/Button";

export const ModalFooterContent = ({
  toggleModal,
  disabled,
  handleSave,
  className,
  title,
}: PropsWithChildren<{
  toggleModal: () => void;
  disabled?: boolean;
  handleSave: () => void;
  className?: string;
  title?: string;
}>) => {
  const classes = cs(className);

  return (
    <ModalFooter className={classes}>
      <Button
        size="small"
        color="outline-secondary"
        title="Cancel"
        onClick={toggleModal}
      />

      <Button
        size="small"
        color="primary"
        title={title ?? "Save"}
        disabled={disabled}
        onClick={handleSave}
      />
    </ModalFooter>
  );
};
