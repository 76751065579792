import React from "react";
import Select from "react-select";
import { Form, FormGroup } from "reactstrap";
import cs from "classnames";

import { useLicensesContext } from "../../../../lib/context/Customer/CustomerContext";

import { Input } from "../../Input/Input";
import { Switch } from "../../Switch/Switch";
import { RoomFormik } from "./lib/useRoomFormik";
import { TagInput } from "../../TagInput/TagInput";
import { tooltips } from "../../../../lib/utils/tooltips";
import { Amenity } from "../../../../api/graphql/rooms/rooms";
import { selectStyles } from "../../../../lib/utils/selectStyles";
import { FormSubSection } from "../../FormSubSection/FormSubSection";
import { EntityType } from "../../../../api/grpc/subscription/subscription";
import {
  LabelDirectionType,
  LabelWithInformationBox,
} from "../../LabelWithInformationBox/LabelWithInformationBox";
import { CalendarProviderSelect } from "../../../Calendars/CalendarProviderSelect/CalendarProviderSelect";
import { Icon } from "../../Icon/Icon";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { ServiceEmailModal } from "../../../Organization/OrganizationSettings/BookingAndNotificationsCard/ServiceEmail/ServiceEmailModal";
import { InformationBox } from "../../InformationBox/InformationBox";
import { LoadingBox } from "../../LoadingBox/LoadingBox";
import { CustomerSettings } from "../../../../api/grpc/customer/customer";
import { CustomerSettingsVariables } from "../../../Organization/OrganizationSettings/InsightsCard/Insights/Insights";
import { CreateRoomFromTemplate } from "./CreateRoomFromTemplate";

interface Props {
  id?: string;
  edit?: boolean;
  informationBoxDirection?: LabelDirectionType;
  formik: RoomFormik;
  isBussinesOrPro?: boolean;
  loading: boolean;
  error: any;
  workingHoursData?: CustomerSettings;
  handleWorkingHours: ({
    errorReportingEmail,
  }: CustomerSettingsVariables) => void;
  isProfile?: boolean;
  className?: string;
  defineLicenseNumber?: () => JSX.Element;
  refetch?: () => void;
  fillFieldsFromProfile?: (id: string) => void;
}

export const roomAmenetiesOptions = [
  { label: "Video Conferencing", value: "videoConference" },
  { label: "Presentation Tech", value: "presentationTech" },
  { label: "Audio", value: "audio" },
  { label: "Climate (AC)", value: "climate" },
  { label: "Casting", value: "casting" },
  { label: "Charging Port", value: "chargingPort" },
  { label: "Desk (Sit-Stand)", value: "desk" },
  { label: "Accessibility", value: "accessibility" },
];

export const roomAmenetiesNames = roomAmenetiesOptions.map(
  (item) => item.value as Amenity
);

export const RoomForm = (props: Props) => {
  const {
    edit,
    id,
    formik,
    informationBoxDirection,
    isBussinesOrPro,
    loading,
    error,
    handleWorkingHours,
    workingHoursData,
    isProfile,
    className,
    defineLicenseNumber,
    refetch,
    fillFieldsFromProfile,
  } = props;
  const { disabled, spinner, handleLicenseUpdate } = useLicensesContext();
  const { isOpen, toggleModal } = useToggleModal();

  const classes = cs("RoomForm", className);

  return (
    <Form className={classes}>
      {!isProfile && (
        <FormGroup>
          <LabelWithInformationBox
            for="name"
            title="Name"
            message={tooltips.workplaces.name}
            direction={informationBoxDirection}
          />
          <Input
            id="name"
            type="text"
            name="name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isTouched={formik.touched.name}
            errorMessage={formik.errors.name}
            invalid={!!formik.errors.name && formik.touched.name}
          />
        </FormGroup>
      )}
      {!isProfile && !edit && (
        <CreateRoomFromTemplate
          fillFieldsFromProfile={fillFieldsFromProfile}
          resetFormikFields={formik.resetForm}
        />
      )}
      <FormGroup>
        <LabelWithInformationBox
          for="description"
          title="Description"
          message={tooltips.workplaces.description}
          direction={informationBoxDirection}
        />
        <Input
          type="text"
          id="description"
          name="description"
          onBlur={formik.handleBlur}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          for="numberOfSeats"
          title="Number of seats"
          message={tooltips.rooms.settings.seats}
          direction={informationBoxDirection}
        />
        <Input
          min="1"
          type="number"
          id="numberOfSeats"
          name="numberOfSeats"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.numberOfSeats}
        />
      </FormGroup>
      <TagInput
        value={formik.values.tags || []}
        direction={informationBoxDirection}
        onChange={(tags) => {
          formik.setFieldValue("tags", tags);
          formik.setFieldTouched("tags");
        }}
      />

      <Switch
        title="Block room"
        message={"message"}
        name="reservation.blockRoom"
        value={formik.values.isBlocked}
        onChange={(value) => {
          formik.setFieldValue("isBlocked", value);
          formik.setFieldTouched("isBlocked", true);
          return;
        }}
      />

      <FormSubSection column title="Room equipment">
        <FormGroup>
          <LabelWithInformationBox
            for="roomAmenities"
            title="Select equipment"
            message={tooltips.rooms.settings.amenities}
            direction={informationBoxDirection}
          />
          <Select
            isMulti
            isClearable
            id="roomAmenities"
            isSearchable={true}
            name="roomAmenities"
            theme={selectStyles}
            options={roomAmenetiesOptions}
            onChange={(e) => {
              if (!e) {
                return;
              }

              const values = e.map((item) => item.value);

              formik.setFieldValue("roomAmenities", values);
              formik.setFieldTouched("roomAmenities");
            }}
            value={roomAmenetiesOptions.filter((item) =>
              formik.values.roomAmenities.includes(item.value as Amenity)
            )}
          />
        </FormGroup>
        <Switch
          title="Error reporting"
          name="brokenAmenitiesReporting"
          value={
            isBussinesOrPro ? formik.values.brokenAmenitiesReporting : false
          }
          message={tooltips.rooms.settings.amenities}
          disabled={!isBussinesOrPro}
          onChange={(value) => {
            formik.setFieldValue("brokenAmenitiesReporting", value);
            formik.setFieldTouched("brokenAmenitiesReporting");
            return;
          }}
        >
          <FormGroup>
            <LabelWithInformationBox
              for="reportingEmail"
              title="Service email"
              message={tooltips.rooms.settings.reportEmail}
            />

            {loading ? (
              <LoadingBox minHeight={40} />
            ) : (
              <Input
                type="email"
                id="reportingEmail"
                name="reportingEmail"
                onBlur={formik.handleBlur}
                disabled
                value={
                  error ? error?.message : workingHoursData?.errorReportingEmail
                }
              />
            )}

            {!isProfile && edit && (
              <div className="flex-a-center RoomForm__reporting">
                <div className="flex-a-center" onClick={toggleModal}>
                  <Icon icon="edit-icon" className="mr-2" />
                  <span className="label__main pt-1">
                    Edit global service email
                  </span>
                </div>

                <InformationBox
                  direction="right"
                  message={tooltips.rooms.settings.changeEmail}
                  className="pl-2"
                />
              </div>
            )}
            {isOpen && (
              <ServiceEmailModal
                isOpen={isOpen}
                setIsOpen={toggleModal}
                email={workingHoursData?.errorReportingEmail}
                handleErrorReportingEmail={handleWorkingHours}
                loading={loading}
                error={error}
              />
            )}
          </FormGroup>
        </Switch>
      </FormSubSection>
      {!isProfile && (
        <>
          <FormSubSection column title="Add calendar">
            {/* <FormSubSection sectionId="add-calendar" title="Add Calendar"> */}
            <CalendarProviderSelect
              formik={formik}
              direction={informationBoxDirection}
              roomId={id}
            />
          </FormSubSection>

          <Switch
            name="licensed"
            disabled={disabled}
            spinner={spinner}
            title="Assign license"
            value={formik.values.licensed}
            message={tooltips.rooms.settings.assignLicense}
            direction={informationBoxDirection}
            onChange={async (value) => {
              if (!edit) {
                formik.setFieldValue("licensed", value);
                formik.setFieldTouched("licensed", true);
                return;
              }

              if (!id) {
                return;
              }

              await handleLicenseUpdate({
                id,
                value,
                type: EntityType.ROOM,
              });

              if (refetch) {
                return refetch();
              }
            }}
          />

          {defineLicenseNumber && defineLicenseNumber()}
        </>
      )}
    </Form>
  );
};
