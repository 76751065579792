import React from "react";
import { BrowserRouter, NavLink } from "react-router-dom";
import { WORKPLACES_ROOT_PATH } from "../../../lib/routes/routes";
import { Icon } from "../../shared/Icon/Icon";

import "./Popup.scss";

interface Props {
  name?: string;
  location?: string;
  id: string;
  type: string;
  user: string;
  onClick?: () => void;
}

export const Popup = ({ name, location, onClick, id, type, user }: Props) => {
  return (
    <div className="Popup">
      <span className="Popup__name">{name}</span>
      <div className="Popup__content">
        <span className="Popup__location">{location}</span>
        {type === "Desk" && (
          <div className="Popup__user flex-a-center">
            <Icon icon="single-user-icon" />
            <span className="pl-min">{user ? user : "-"}</span>
          </div>
        )}

        <BrowserRouter>
          <NavLink
            to={`${WORKPLACES_ROOT_PATH}/${type.toLowerCase()}/${id}`}
            className="Popup__settings flex-a-center"
          >
            <Icon icon="settings" />
            <span>Settings</span>
          </NavLink>
        </BrowserRouter>

        {/* <Button
          onClick={() => onClick && onClick()}
          title="Remove from map"
          size="small"
          color="primary"
        /> */}
      </div>
    </div>
  );
};
