// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "desk.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { DeskBooking } from "./desk";
import type { DeskCheckInDefferRequest } from "./desk";
import type { MultipleDeskScheduleResponse } from "./desk";
import type { MultipleDeskScheduleRequest } from "./desk";
import type { DeskScheduleResponse } from "./desk";
import type { DeskScheduleRequest } from "./desk";
import type { DeskCheckOutRequest } from "./desk";
import type { DeskCheckInResponse } from "./desk";
import type { DeskCheckInRequest } from "./desk";
import type { PersonBookingRequest } from "./desk";
import type { MatchingDeskRequest } from "./desk";
import type { ListDesksResponse } from "./desk";
import type { ListDeskBookingRequest } from "./desk";
import type { RetrieveDeskBookingRequest } from "./desk";
import type { Empty } from "./google/protobuf/empty";
import type { DeleteDeskBookingRequest } from "./desk";
import type { UpdateDeskBookingRequest } from "./desk";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { DeskBookingEvent } from "./desk";
import type { CreateDeskBookingRequest } from "./desk";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Crud for our Desk booking service
 *
 * @generated from protobuf service DeskBooking
 */
export interface IDeskBookingClient {
    /**
     * Creates a booking record for desk
     *
     * @generated from protobuf rpc: CreateDeskBooking(CreateDeskBookingRequest) returns (DeskBookingEvent);
     */
    createDeskBooking(input: CreateDeskBookingRequest, options?: RpcOptions): UnaryCall<CreateDeskBookingRequest, DeskBookingEvent>;
    /**
     * Update a booking record for desk
     *
     * @generated from protobuf rpc: UpdateDeskBooking(UpdateDeskBookingRequest) returns (DeskBookingEvent);
     */
    updateDeskBooking(input: UpdateDeskBookingRequest, options?: RpcOptions): UnaryCall<UpdateDeskBookingRequest, DeskBookingEvent>;
    /**
     * Delete a booking record for desk
     *
     * @generated from protobuf rpc: DeleteDeskBooking(DeleteDeskBookingRequest) returns (google.protobuf.Empty);
     */
    deleteDeskBooking(input: DeleteDeskBookingRequest, options?: RpcOptions): UnaryCall<DeleteDeskBookingRequest, Empty>;
    /**
     * Retrieve a single booking record by ID for desk
     *
     * @generated from protobuf rpc: RetrieveDeskBooking(RetrieveDeskBookingRequest) returns (DeskBookingEvent);
     */
    retrieveDeskBooking(input: RetrieveDeskBookingRequest, options?: RpcOptions): UnaryCall<RetrieveDeskBookingRequest, DeskBookingEvent>;
    /**
     * Get events list for desk
     *
     * @generated from protobuf rpc: ListDeskBooking(ListDeskBookingRequest) returns (ListDesksResponse);
     */
    listDeskBooking(input: ListDeskBookingRequest, options?: RpcOptions): UnaryCall<ListDeskBookingRequest, ListDesksResponse>;
    /**
     * Get matching desks for timeslot and location
     *
     * @generated from protobuf rpc: SearchMatchedDesks(MatchingDeskRequest) returns (ListDesksResponse);
     */
    searchMatchedDesks(input: MatchingDeskRequest, options?: RpcOptions): UnaryCall<MatchingDeskRequest, ListDesksResponse>;
    /**
     * Get matching desks for timeslot and location
     *
     * @generated from protobuf rpc: SearchPersonBooking(PersonBookingRequest) returns (ListDesksResponse);
     */
    searchPersonBooking(input: PersonBookingRequest, options?: RpcOptions): UnaryCall<PersonBookingRequest, ListDesksResponse>;
    /**
     * Get nearby desks for person
     *
     * @generated from protobuf rpc: NearbyDesks(MatchingDeskRequest) returns (ListDesksResponse);
     */
    nearbyDesks(input: MatchingDeskRequest, options?: RpcOptions): UnaryCall<MatchingDeskRequest, ListDesksResponse>;
    /**
     * DeskCheckIn handle activate checkin status
     *
     * @generated from protobuf rpc: DeskCheckIn(DeskCheckInRequest) returns (DeskCheckInResponse);
     */
    deskCheckIn(input: DeskCheckInRequest, options?: RpcOptions): UnaryCall<DeskCheckInRequest, DeskCheckInResponse>;
    /**
     * DeskCheckOut remove event if checkin is closed and no checkout
     *
     * @generated from protobuf rpc: DeskCheckOut(DeskCheckOutRequest) returns (google.protobuf.Empty);
     */
    deskCheckOut(input: DeskCheckOutRequest, options?: RpcOptions): UnaryCall<DeskCheckOutRequest, Empty>;
    /**
     * DeskSchedule return busy timeslots for desk in time range
     * if time range is not set then using daily time range
     *
     * @generated from protobuf rpc: DeskSchedule(DeskScheduleRequest) returns (DeskScheduleResponse);
     */
    deskSchedule(input: DeskScheduleRequest, options?: RpcOptions): UnaryCall<DeskScheduleRequest, DeskScheduleResponse>;
    /**
     * MultipleDeskSchedule return busy timeslots for desks in time range
     * if time range is not set then using daily time range
     *
     * @generated from protobuf rpc: MultipleDeskSchedule(MultipleDeskScheduleRequest) returns (MultipleDeskScheduleResponse);
     */
    multipleDeskSchedule(input: MultipleDeskScheduleRequest, options?: RpcOptions): UnaryCall<MultipleDeskScheduleRequest, MultipleDeskScheduleResponse>;
    /**
     * DeskDefferChekInNotification sending deffer push notification for desk checkIn status
     *
     * @generated from protobuf rpc: DeskDefferChekInNotification(DeskCheckInDefferRequest) returns (google.protobuf.Empty);
     */
    deskDefferChekInNotification(input: DeskCheckInDefferRequest, options?: RpcOptions): UnaryCall<DeskCheckInDefferRequest, Empty>;
}
/**
 * Crud for our Desk booking service
 *
 * @generated from protobuf service DeskBooking
 */
export class DeskBookingClient implements IDeskBookingClient, ServiceInfo {
    typeName = DeskBooking.typeName;
    methods = DeskBooking.methods;
    options = DeskBooking.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Creates a booking record for desk
     *
     * @generated from protobuf rpc: CreateDeskBooking(CreateDeskBookingRequest) returns (DeskBookingEvent);
     */
    createDeskBooking(input: CreateDeskBookingRequest, options?: RpcOptions): UnaryCall<CreateDeskBookingRequest, DeskBookingEvent> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateDeskBookingRequest, DeskBookingEvent>("unary", this._transport, method, opt, input);
    }
    /**
     * Update a booking record for desk
     *
     * @generated from protobuf rpc: UpdateDeskBooking(UpdateDeskBookingRequest) returns (DeskBookingEvent);
     */
    updateDeskBooking(input: UpdateDeskBookingRequest, options?: RpcOptions): UnaryCall<UpdateDeskBookingRequest, DeskBookingEvent> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateDeskBookingRequest, DeskBookingEvent>("unary", this._transport, method, opt, input);
    }
    /**
     * Delete a booking record for desk
     *
     * @generated from protobuf rpc: DeleteDeskBooking(DeleteDeskBookingRequest) returns (google.protobuf.Empty);
     */
    deleteDeskBooking(input: DeleteDeskBookingRequest, options?: RpcOptions): UnaryCall<DeleteDeskBookingRequest, Empty> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteDeskBookingRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve a single booking record by ID for desk
     *
     * @generated from protobuf rpc: RetrieveDeskBooking(RetrieveDeskBookingRequest) returns (DeskBookingEvent);
     */
    retrieveDeskBooking(input: RetrieveDeskBookingRequest, options?: RpcOptions): UnaryCall<RetrieveDeskBookingRequest, DeskBookingEvent> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<RetrieveDeskBookingRequest, DeskBookingEvent>("unary", this._transport, method, opt, input);
    }
    /**
     * Get events list for desk
     *
     * @generated from protobuf rpc: ListDeskBooking(ListDeskBookingRequest) returns (ListDesksResponse);
     */
    listDeskBooking(input: ListDeskBookingRequest, options?: RpcOptions): UnaryCall<ListDeskBookingRequest, ListDesksResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListDeskBookingRequest, ListDesksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get matching desks for timeslot and location
     *
     * @generated from protobuf rpc: SearchMatchedDesks(MatchingDeskRequest) returns (ListDesksResponse);
     */
    searchMatchedDesks(input: MatchingDeskRequest, options?: RpcOptions): UnaryCall<MatchingDeskRequest, ListDesksResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<MatchingDeskRequest, ListDesksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get matching desks for timeslot and location
     *
     * @generated from protobuf rpc: SearchPersonBooking(PersonBookingRequest) returns (ListDesksResponse);
     */
    searchPersonBooking(input: PersonBookingRequest, options?: RpcOptions): UnaryCall<PersonBookingRequest, ListDesksResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<PersonBookingRequest, ListDesksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get nearby desks for person
     *
     * @generated from protobuf rpc: NearbyDesks(MatchingDeskRequest) returns (ListDesksResponse);
     */
    nearbyDesks(input: MatchingDeskRequest, options?: RpcOptions): UnaryCall<MatchingDeskRequest, ListDesksResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<MatchingDeskRequest, ListDesksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeskCheckIn handle activate checkin status
     *
     * @generated from protobuf rpc: DeskCheckIn(DeskCheckInRequest) returns (DeskCheckInResponse);
     */
    deskCheckIn(input: DeskCheckInRequest, options?: RpcOptions): UnaryCall<DeskCheckInRequest, DeskCheckInResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeskCheckInRequest, DeskCheckInResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeskCheckOut remove event if checkin is closed and no checkout
     *
     * @generated from protobuf rpc: DeskCheckOut(DeskCheckOutRequest) returns (google.protobuf.Empty);
     */
    deskCheckOut(input: DeskCheckOutRequest, options?: RpcOptions): UnaryCall<DeskCheckOutRequest, Empty> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeskCheckOutRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * DeskSchedule return busy timeslots for desk in time range
     * if time range is not set then using daily time range
     *
     * @generated from protobuf rpc: DeskSchedule(DeskScheduleRequest) returns (DeskScheduleResponse);
     */
    deskSchedule(input: DeskScheduleRequest, options?: RpcOptions): UnaryCall<DeskScheduleRequest, DeskScheduleResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeskScheduleRequest, DeskScheduleResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * MultipleDeskSchedule return busy timeslots for desks in time range
     * if time range is not set then using daily time range
     *
     * @generated from protobuf rpc: MultipleDeskSchedule(MultipleDeskScheduleRequest) returns (MultipleDeskScheduleResponse);
     */
    multipleDeskSchedule(input: MultipleDeskScheduleRequest, options?: RpcOptions): UnaryCall<MultipleDeskScheduleRequest, MultipleDeskScheduleResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<MultipleDeskScheduleRequest, MultipleDeskScheduleResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeskDefferChekInNotification sending deffer push notification for desk checkIn status
     *
     * @generated from protobuf rpc: DeskDefferChekInNotification(DeskCheckInDefferRequest) returns (google.protobuf.Empty);
     */
    deskDefferChekInNotification(input: DeskCheckInDefferRequest, options?: RpcOptions): UnaryCall<DeskCheckInDefferRequest, Empty> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeskCheckInDefferRequest, Empty>("unary", this._transport, method, opt, input);
    }
}
