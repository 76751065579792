import { useMemo } from "react";
import { CustomerzClient } from "../../../api/grpc/customer/customer.client";
import { transport } from "../../../api/grpc/grpcTransport";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { ContactSalesTeamRequest, CustomerFeendbackRequest } from "./customer";

export const useCustomerRequests = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const customerClient = useMemo(() => new CustomerzClient(transport), []);

  const getCustomerDetails = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await customerClient.getCustomer(
      {
        customerId: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const contactSales = async ({
    name,
    email,
    message,
  }: Omit<ContactSalesTeamRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await customerClient.contactSalesTeam(
      {
        customerId: user.customerid,
        name: name,
        email: email,
        message: message,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const leaveFeedback = async ({
    name,
    email,
    feebackBody,
  }: Omit<CustomerFeendbackRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await customerClient.sendCustomerFeendback(
      {
        customerId: user.customerid,
        name: name,
        email: email,
        feebackBody: feebackBody,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getCustomerSettings = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await customerClient.getCustomerSettings(
      {
        customerId: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const createCustomerSettings = async (
    startTime: string,
    endTime: string,
    errorReportingEmail: string,
    meetingSpaceCostPerHr: number,
    currency: string,
    workingWeekDays: number[],
    ssoEnable: boolean
  ) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await customerClient.createCustomerSettings(
      {
        customerSettings: {
          customerId: user.customerid,
          startTime: startTime,
          endTime: endTime,
          collectInsigths: false,
          errorReportingEmail: errorReportingEmail,
          meetingSpaceCostPerHr: meetingSpaceCostPerHr,
          currency: currency,
          workingWeekDays: workingWeekDays,
          ssoEnable: ssoEnable,
        },
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateCustomerSettings = async (
    startTime: string,
    endTime: string,
    errorReportingEmail: string,
    meetingSpaceCostPerHr: number,
    currency: string,
    workingWeekDays: number[],
    collectInsights: boolean,
    ssoEnable: boolean
  ) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await customerClient.updateCustomerSettings(
      {
        customerId: user.customerid,
        customerSettings: {
          customerId: user.customerid,
          startTime: startTime,
          endTime: endTime,
          errorReportingEmail: errorReportingEmail,
          meetingSpaceCostPerHr: meetingSpaceCostPerHr,
          currency: currency,
          workingWeekDays: workingWeekDays,
          collectInsigths: collectInsights,
          ssoEnable: ssoEnable,
        },
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getCustomerPermissions = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await customerClient.customerPermissions(
      {
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    getCustomerDetails,
    contactSales,
    createCustomerSettings,
    getCustomerSettings,
    updateCustomerSettings,
    leaveFeedback,
    getCustomerPermissions,
  };
};
