import React from "react";
import { PageHeader } from "../../../components/shared/PageHeader/PageHeader";
import cs from "classnames";
import { tooltips } from "../../../lib/utils/tooltips";

import "./ReserveCard.scss";

interface Props {
  children: JSX.Element;
  footer?: JSX.Element;
  type?: string;
  workplaceType?: string;
  className?: string;
}

export const ReserveCard = ({
  children,
  footer,
  type,
  workplaceType,
  className,
}: Props) => {
  const classes = cs("ReserveCard", className, {
    ReserveCard__main: type === "main",
  });

  return (
    <div className={classes}>
      <PageHeader
        title={
          workplaceType
            ? `Find & Reserve a ${
                workplaceType[0].toUpperCase() + workplaceType.slice(1)
              }`
            : "Find & Reserve"
        }
        message={tooltips.bookings.findAndReserve}
      />
      <div className="ReserveCard__content">
        {children}
        {footer}
      </div>
    </div>
  );
};
