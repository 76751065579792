import { useMemo } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../grpcTransport";
import {
  CheckDeviceVersionRequest,
  DeleteCustomLanguageRequest,
  DesksBulkCreateRequest,
  FloorMapRequest,
  GetCustomLanguageRequest,
  ListAvailableRoomsRequest,
  ListTagsEntity,
  ListTagsRequest,
  OnLevelSearchRequest,
  SearchRequest,
  SyncFloorMapMetadataRequest,
  UpdateDeviceAppRequest,
  UploadCustomLanguageRequest,
} from "./workplaces";
import { WorkplacesClient } from "./workplaces.client";

export interface DownloadQRCodeVariables {
  entityID: string;
  entityType: ListTagsEntity;
}

export const useWorkplacesClient = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const workplacesClient = useMemo(() => new WorkplacesClient(transport), []);

  const createBulkDesks = async (variables: DesksBulkCreateRequest) => {
    if (!user) {
      throw new Error("User not found when trying to delete workplace!");
    }

    const {
      desksNumber,
      startIndex,
      name,
      description,
      tags,
      isBooked,
      showCheckInTime,
      qrCodeRequired,
      checkInTime,
      locationID,
      marker,
      licensed,
    } = variables;

    return await workplacesClient.desksBulkCreate(
      {
        desksNumber: desksNumber,
        startIndex: startIndex,
        name: name,
        description: description,
        tags: tags,
        isBooked: isBooked,
        showCheckInTime: showCheckInTime,
        qrCodeRequired: qrCodeRequired,
        checkInTime: checkInTime,
        tenantID: user.customerid,
        locationID: locationID,
        marker: marker,
        licensed: licensed,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteWorkplace = async (id: string) => {
    if (!user) {
      throw new Error("User not found when trying to delete workplace!");
    }

    return await workplacesClient.deleteWorkplace(
      {
        customerID: user.customerid,
        id: id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const uploadMap = async (
    image: Uint8Array,
    format: "image/jpeg" | "image/png" | "image/jpg",
    floorId: string
  ) => {
    if (!user) {
      throw new Error("User not found when trying to delete workplace!");
    }

    return await workplacesClient.uploadMap(
      {
        customerId: user.customerid,
        accountId: user.claims.user_id,
        imageData: image,
        imageType: format,
        floorId: floorId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteMap = async (imageUrl: string) => {
    if (!user) {
      throw new Error("User not found when trying to delete workplace!");
    }

    return await workplacesClient.deleteMap(
      {
        imageUrl: imageUrl,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const downloadQRCode = async ({
    entityID,
    entityType,
  }: DownloadQRCodeVariables) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.generateQRCode(
      {
        entityID: entityID,
        entityType: entityType,
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listTags = async ({
    locationId,
    entityType,
    limit,
  }: ListTagsRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.listTags(
      {
        locationId: locationId,
        customerId: user.customerid,
        limit: limit,
        entityType: entityType,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getWorkplaceLocationPath = async ({
    locationID,
    onlyFree,
    filterTags,
    timeZone,
    page,
    pageSize,
  }: SearchRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.locationPath(
      {
        locationID: locationID,
        onlyFree: onlyFree,
        filterTags: filterTags,
        customerID: user.customerid,
        ignoreBlocked: false,
        allDesks: false,
        timeZone: timeZone,
        page: page,
        pageSize: pageSize,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const searchWorkplaceItems = async ({
    id,
    licenseStatus,
    nameFilter,
    locationFilter,
    resourceFilter,
    tagsFilter,
  }: Omit<OnLevelSearchRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.onLevelSearch(
      {
        customerId: user.customerid,
        id: id,
        licenseStatus: licenseStatus,
        nameFilter: nameFilter,
        locationFilter: locationFilter,
        resourceFilter: resourceFilter,
        tagsFilter: tagsFilter,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listAvailableRooms = async ({
    locationId,
    from,
    to,
    tags,
    equipment,
    minNrSeats,
    maxNrSeats,
    timeZone,
    personalCalendarProviderId,
    page,
  }: ListAvailableRoomsRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.listAvailableRooms(
      {
        customerId: user.customerid,
        locationId: locationId,
        from: from,
        to: to,
        tags: tags,
        equipment: equipment,
        minNrSeats: minNrSeats,
        maxNrSeats: maxNrSeats,
        timeZone: timeZone,
        personalCalendarProviderId: personalCalendarProviderId,
        page: page,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getAssignedDesks = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.searchAccountDesks(
      {
        customerID: user.customerid,
        accountID: user.claims.user_id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getFloorMap = async ({
    floorId,
    dateTime,
    timeZone,
  }: FloorMapRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.floorMap(
      {
        customerId: user.customerid,
        floorId: floorId,
        dateTime: dateTime,
        timeZone: timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getCustomLanguage = async ({
    langCode,
  }: Omit<GetCustomLanguageRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.customLanguage(
      {
        customerId: user.customerid,
        langCode,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateCustomLanguage = async ({
    languageCode,
    languageData,
  }: Omit<UploadCustomLanguageRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.uploadCustomLanguage(
      {
        customerId: user.customerid,
        languageCode,
        languageData,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteCustomLanguage = async ({
    langUrl,
  }: DeleteCustomLanguageRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.deleteCustomLanguage(
      {
        langUrl,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const checkDeviceVersion = async ({
    deviceId,
  }: Omit<CheckDeviceVersionRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.checkDeviceVersion(
      {
        customerId: user.customerid,
        deviceId: deviceId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateDeviceVersion = async ({
    deviceId,
  }: Omit<UpdateDeviceAppRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.updateDeviceApp(
      {
        customerId: user.customerid,
        deviceId: deviceId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const syncFloorData = async ({
    floorId,
    timeZone,
  }: Omit<SyncFloorMapMetadataRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return workplacesClient.syncFloorMapMetadata(
      {
        customerId: user.customerid,
        floorId: floorId,
        timeZone: timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    createBulkDesks,
    deleteWorkplace,
    uploadMap,
    deleteMap,
    downloadQRCode,
    listTags,
    getWorkplaceLocationPath,
    searchWorkplaceItems,
    listAvailableRooms,
    getAssignedDesks,
    getFloorMap,
    getCustomLanguage,
    updateCustomLanguage,
    deleteCustomLanguage,
    checkDeviceVersion,
    updateDeviceVersion,
    syncFloorData,
  };
};
