import React, { useCallback, useMemo, useEffect, useState } from "react";
import _, { isString } from "lodash";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useRoomFormik } from "../../../shared/Forms/RoomForm/lib/useRoomFormik";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useDisplaySettingsFormik } from "../../../shared/Forms/DisplaySettingsForm/lib/useDisplaySettingsForm";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useCustomerPermissions } from "../../../Billings/lib/useCustomerPermissions";
import { useWorkingHoursData } from "../../../Account/helpers/useWorkingHoursData";
import { useDebounce } from "../../../../lib/hooks/useDebounce";
import { useDefineLicense } from "../../../../lib/hooks/useDefineLicense";

import {
  Amenity,
  RoomFields,
  RoomRequestVariables,
  ROOM_FIELDS_FRAGMENT,
  UpdateRoomDisplaySettingsVariables,
} from "../../../../api/graphql/rooms/rooms";

import { SubRouteProps } from "../../../../lib/routes/routes";

import {
  RoomForm,
  roomAmenetiesNames,
} from "../../../shared/Forms/RoomForm/RoomForm";
import { tooltips } from "../../../../lib/utils/tooltips";
import { EditSettings } from "../EditSettings/EditSettings";
import { EditRoomViews } from "./EditRoomViews/EditRoomViews";
import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import { EditWorkplaceWrapper } from "../EditWorkplaceWrapper/EditWorkplaceWrapper";
import { DisplaySettingsForm } from "../../../shared/Forms/DisplaySettingsForm/DisplaySettingsForm";
import { EditWorkplaceProps } from "../../../../views/Manage/Workplaces/EditWorkplace/EditWorkplace";
import { DownloadQRCode } from "../../../DownloadQRCode/DownloadQRCode";
import { ListTagsEntity } from "../../../../api/grpc/workplaces/workplaces";
import format from "date-fns/format";
import { dateHelper } from "../../../shared/Forms/DisplaySettingsForm/lib/displayDateHelper";
import { CalendarType } from "../../../../api/grpc/calendarprovider/calendarprovider";
import { EntityType } from "../../../../api/grpc/subscription/subscription";

export const EditRoom = (props: EditWorkplaceProps) => {
  const { id } = props;
  const router = useRouter();
  const client = useApolloClient();
  const { user } = useAuthContext();
  const { providers } = useCalendarContext();
  const { customer } = useCustomerContext();
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [loadingDisplaySettings, setLoadingDisplaySettings] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce<string>(searchValue, 500);

  const { updateRoom, updateRoomDisplay } = useWorkplaceRequests();
  const [roomData, setRoomData] = useState<RoomFields | null>(null);
  const {
    data: customerPermissions,
    error: customerError,
    isBussinesOrPro,
  } = useCustomerPermissions();
  const { error, loading, workingHoursData, handleWorkingHours, refetch } =
    useWorkingHoursData();

  const { defineLicenseNumber, refetch: refetchLicenseNumber } =
    useDefineLicense(EntityType.ROOM, true);

  // TO DO: Move on how the data are loaded to a hook useWorkplaceData
  const updateData = useCallback(() => {
    setRoomData(
      client.readFragment({
        id: `Room:${id}`,
        fragmentName: "RoomFields",
        fragment: ROOM_FIELDS_FRAGMENT,
      })
    );
  }, [id, workingHoursData]);

  const calendarType = useMemo(() => {
    const provider = providers.find(
      (item) => item.iD === roomData?.calendarProviderId
    );

    if (!provider) {
      return undefined;
    }

    return provider.calType;
  }, [roomData]);

  useEffect(() => {
    updateData();
  }, [id, customer, updateData]);

  const formik = useRoomFormik({
    initialValues: {
      customerId: user?.customerid || "",
      name: roomData?.name || "",
      description: roomData?.description || "",
      tags: roomData?.tags || [],
      roomAmenities: _.keys(
        _.pickBy(roomData?.amenities, (item) => {
          return item === true;
        })
      ) as Amenity[],
      brokenAmenitiesReporting: roomData?.brokenAmenitiesReporting || false,
      licensed: !!roomData?.licensed,
      numberOfSeats: roomData?.numberOfSeats || 1,
      calendarProviderActive: roomData?.calendarProviderActive || false,
      isBooked: roomData?.isBooked,
      isBlocked: roomData?.isBlocked || false,
      marker: {
        latitude: roomData?.marker?.latitude || 0,
        longitude: roomData?.marker?.longitude || 0,
      },
      // we check if roomDirection === null because the existing rooms
      // didn't have the roomDirection object (it's a new field)
      // so we add it with 0 values
      roomDirection: roomData?.roomDirection?.length
        ? roomData.roomDirection
        : [
            {
              direction: 0,
              distance: 0,
            },
          ],
      showCheckInTime: roomData?.showCheckInTime || false,
      checkInTime: roomData?.checkInTime || 1,
      calendarId: roomData?.calendarId || "",
      calendarType: calendarType,
      calendarProviderId: roomData?.calendarProviderId || "",
      reportingEmail: "",
      resourceEmail: roomData?.resourceEmail || "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoadingSettings(true);

        const removedTags =
          roomData?.tags.filter((item) => !values.tags?.includes(item)) || [];

        // TO DO: Generalize the process for amenities & validation here & on AddRoomModal
        const amenities = roomAmenetiesNames.reduce((acc: any, cur) => {
          acc[cur] = values.roomAmenities.includes(cur);

          return acc;
        }, {});

        const variables: RoomRequestVariables = {
          id,
          name: values.name,
          tags: values.tags || [],
          description: values.description,
          tenantId: customer.tenantId,
          calendarId: values.calendarId,
          numberOfSeats: values.numberOfSeats,
          calendarProviderActive: values.calendarProviderActive,
          isBooked: values.isBooked,
          isBlocked: values.isBlocked,
          marker: {
            latitude: values.marker.latitude,
            longitude: values.marker.longitude,
          },
          roomDirection: values.roomDirection.length
            ? values.roomDirection
            : [
                {
                  distance: 0,
                  direction: 0,
                },
              ],
          showCheckInTime: values.showCheckInTime || false,
          checkInTime: values.checkInTime || 1,
          amenities: {
            ...amenities,
            // reportingEmail: values.reportingEmail,
          },
          brokenAmenitiesReporting: values.brokenAmenitiesReporting,
          calendarProviderId: values.calendarProviderId,
          remove: {
            tags: removedTags,
          },
          resourceEmail:
            values.calendarType === CalendarType.EWS
              ? values.calendarId
              : values.resourceEmail,
        };

        await updateRoom(variables);
      } catch (error: any) {
        console.log(error);
        toast.error(
          error.message
            ? error.message
            : "Cannot update room, please reload and try again!"
        );
      } finally {
        setLoadingSettings(false);
      }
    },
  });

  const displaySettingsFormik = useDisplaySettingsFormik(
    roomData?.displaySettings,
    {
      onSubmit: async (values) => {
        try {
          if (!!customerError) {
            toast.error(
              "Failed to fetch working hours, please contact your administrator."
            );
            return;
          }

          setLoadingDisplaySettings(true);

          const { main, display, reservation } = values;

          const variables: UpdateRoomDisplaySettingsVariables = {
            id,
            displaySettings: {
              adminPin: main.adminPin,
              backgroundImageUrl: main.backgroundImageUrl,
              logoImageUrl: main.logoImageUrl,
              customerId: user?.customerid || "",
              showOrganizer: display.showOrganizer,
              showMeetingTitle: display.showMeetingTitle,
              showAttendees: display.showAttendees,
              showRoomCapacity: display.showRoomCapacity,
              showRoomAmeneties: display.showRoomAmeneties,
              showCheckinRequired: !!display.showCheckinRequired,
              checkinReminder: display.checkinReminder,
              pendingTimeBeforeMeeting: display.pendingTimeBeforeMeeting,
              pendingTimeAfterMeeting: display.pendingTimeAfterMeeting,
              soonFree: display.soonFree,
              enableScreenLock: display.enableScreenLock,
              screenLock: display.screenLock,
              energySaveMode: display.energySaveMode,
              energySaveModeStart: format(
                dateHelper(
                  reservation.onScreenReservation?.workingHrsEnd || "",
                  "endTime",
                  workingHoursData
                ),
                "HH:mm:ss"
              ),
              energySaveModeEnd: format(
                dateHelper(
                  reservation.onScreenReservation?.workingHrsStart || "",
                  "startTime",
                  workingHoursData
                ),
                "HH:mm:ss"
              ),
              qrCodeRequired: display.qrCodeRequired,
              contentBoardActivated: display.contentBoardActivated,
              contentBoardURL: display.contentBoardURL,
              textScaling: display.textScaling,
              customLanguage: !!display.customLanguage
                ? {
                    id: display.customLanguage?.id || "",
                    value: display.customLanguage?.value || "",
                    name: display.customLanguage?.name || "",
                    isGlobal: display.customLanguage?.isGlobal || false,
                  }
                : undefined,
              bwFilter: display.bwFilter,

              onScreenReservation: !!reservation.onScreenReservation,
              showMeetingTitleInput: reservation.onScreenReservation
                ? reservation.onScreenReservation.showMeetingTitleInput
                : false,
              requireMeetingTitleInput: reservation.onScreenReservation
                ? reservation.onScreenReservation.requireMeetingTitleInput
                : false,
              showYourNameInput: reservation.onScreenReservation
                ? reservation.onScreenReservation.showYourNameInput
                : false,
              requireYourNameInput: reservation.onScreenReservation
                ? reservation.onScreenReservation.requireYourNameInput
                : false,
              enforceWorkingHours: reservation.onScreenReservation
                ? reservation.onScreenReservation.enforceWorkingHours
                : false,
              workingHrsActivated: reservation.onScreenReservation
                ? reservation.onScreenReservation.workingHrsActivated
                : false,
              requireHeadcount: reservation.onScreenReservation
                ? reservation.onScreenReservation.requireHeadcount
                : false,
              oneTimePinProtection: reservation.onScreenReservation
                ? reservation.onScreenReservation.oneTimePinProtection
                : false,
              workingWeekDays: workingHoursData?.workingWeekDays,
              workingHrsStart: !!reservation.onScreenReservation
                ?.workingHrsStart
                ? isString(reservation.onScreenReservation.workingHrsStart) &&
                  reservation.onScreenReservation.workingHrsStart.length <= 9
                  ? reservation.onScreenReservation.workingHrsStart
                  : format(
                      new Date(
                        reservation.onScreenReservation.workingHrsStart || ""
                      ),
                      "HH:mm:ss"
                    )
                : "",
              workingHrsEnd: !!reservation.onScreenReservation?.workingHrsEnd
                ? isString(reservation.onScreenReservation.workingHrsEnd) &&
                  reservation.onScreenReservation.workingHrsEnd.length <= 9
                  ? reservation.onScreenReservation.workingHrsEnd
                  : format(
                      new Date(
                        reservation.onScreenReservation.workingHrsEnd || ""
                      ),
                      "HH:mm:ss"
                    )
                : "",
              deleteMeeting: reservation.deleteMeeting,
              endMeetingEarly: reservation.endMeetingEarly,
              extendMeeting: reservation.extendMeeting,
              nearbyRooms: reservation.nearbyRooms,
              nearbyEnabled: customerPermissions?.nearbyRooms || false,
              mapView: reservation.mapView,
              floor: !reservation.floor?.id
                ? null
                : {
                    id: reservation.floor.id,
                  },
            },
          };

          await updateRoomDisplay(variables);
          toast.success("Display settings updated successfully!");
        } catch (error: any) {
          toast.error(
            error?.message ||
              "Room couldn't be updated, please reload and try again!"
          );
          console.error(error.message);
        } finally {
          setLoadingDisplaySettings(false);
        }
      },
    }
  );

  const devicesList: WorkplaceListData[] = [
    {
      data: roomData?.devices,
      type: "Device",
    },
  ];

  let devicesData = useMemo(() => {
    if (!roomData?.devices) {
      return [
        {
          data: undefined,
          type: "Device",
        },
      ] as WorkplaceListData[];
    }

    if (!!!debouncedValue.length) {
      return devicesList;
    }

    return [
      {
        data: roomData.devices.filter((device) =>
          device.name.toLowerCase().includes(debouncedValue.toLowerCase())
        ),
        type: "Device",
      },
    ] as WorkplaceListData[];
  }, [debouncedValue, roomData?.devices]);

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Devices",
      to: router.match.url,
      id: "devices-page-room",
      render: () => (
        <EditRoomViews
          id={id}
          rows={devicesData}
          searchValue={searchValue}
          debouncedValue={debouncedValue}
          onChange={(e) => setSearchValue(e.target.value)}
          clearSearchValue={() => setSearchValue("")}
        />
      ),
    },
    {
      exact: true,
      label: "Room Settings",
      id: "settings-page-room",
      to: `${router.match.url}/settings`,
      render: () => (
        <EditSettings submitting={loadingSettings} onSubmit={formik.submitForm}>
          <RoomForm
            id={id}
            edit
            formik={formik}
            isBussinesOrPro={isBussinesOrPro}
            loading={loading}
            error={error}
            workingHoursData={workingHoursData}
            handleWorkingHours={handleWorkingHours}
            defineLicenseNumber={defineLicenseNumber}
            refetch={refetchLicenseNumber}
          />
        </EditSettings>
      ),
    },
    {
      exact: true,
      label: "Display Settings",
      id: "display-settings-page-room",
      to: `${router.match.url}/display-settings`,
      render: () => (
        <EditSettings
          splitSections
          onSubmit={displaySettingsFormik.submitForm}
          submitting={loadingDisplaySettings}
          // hasChanges={
          //   displaySettingsFormik.dirty && displaySettingsFormik.isValid
          // }
        >
          <DisplaySettingsForm
            roomId={id}
            formik={displaySettingsFormik}
            isBussinesOrPro={isBussinesOrPro}
            error={error}
            loading={loading}
            workingHoursData={workingHoursData}
            refetch={refetch}
            currentCustomLanguageId={
              roomData?.displaySettings?.customLanguage?.id
            }
            displayEditWorkHours={
              roomData?.displaySettings?.workingHrsActivated
            }
            downloadQRicon={
              roomData ? (
                <DownloadQRCode
                  name={roomData?.name}
                  type={ListTagsEntity.TYPE_ROOM}
                  id={roomData?.id}
                />
              ) : (
                <></>
              )
            }
          />
        </EditSettings>
      ),
    },
  ];

  return (
    <EditWorkplaceWrapper
      type="Room"
      id={id}
      routes={routes}
      data={roomData || undefined}
      message={tooltips.workplaces.room}
      numberOfDeviceAdded={roomData?.devices?.length}
    />
  );
};
