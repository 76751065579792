// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "customer.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Customerz } from "./customer";
import type { SyncResponse } from "./customer";
import type { CustomerPermissionsResponse } from "./customer";
import type { CustomerPermissionsRequest } from "./customer";
import type { CustomerFeendbackRequest } from "./customer";
import type { GetRFXLastReleaseResponse } from "./customer";
import type { GetRDXLastReleaseResponse } from "./customer";
import type { ValidateOpenHoursResponse } from "./customer";
import type { ValidateOpenHoursRequest } from "./customer";
import type { DeleteCustomerSettingsRequest } from "./customer";
import type { UpdateCustomerSettingsRequest } from "./customer";
import type { GetCustomerSettingsRequest } from "./customer";
import type { CustomerSettings } from "./customer";
import type { CreateCustomerSettingsRequest } from "./customer";
import type { ContactSalesTeamRequest } from "./customer";
import type { FindCustomerRequest } from "./customer";
import type { Empty } from "./google/protobuf/empty";
import type { DeleteCustomerRequest } from "./customer";
import type { UpdateCustomerRequest } from "./customer";
import type { GetCustomerRequest } from "./customer";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Customer } from "./customer";
import type { CreateCustomerRequest } from "./customer";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Customerz
 */
export interface ICustomerzClient {
    /**
     * @generated from protobuf rpc: CreateCustomer(CreateCustomerRequest) returns (Customer);
     */
    createCustomer(input: CreateCustomerRequest, options?: RpcOptions): UnaryCall<CreateCustomerRequest, Customer>;
    /**
     * @generated from protobuf rpc: GetCustomer(GetCustomerRequest) returns (Customer);
     */
    getCustomer(input: GetCustomerRequest, options?: RpcOptions): UnaryCall<GetCustomerRequest, Customer>;
    /**
     * @generated from protobuf rpc: UpdateCustomer(UpdateCustomerRequest) returns (Customer);
     */
    updateCustomer(input: UpdateCustomerRequest, options?: RpcOptions): UnaryCall<UpdateCustomerRequest, Customer>;
    /**
     * UpdateCustomerRecord update only customer info in db
     *
     * @generated from protobuf rpc: UpdateCustomerRecord(UpdateCustomerRequest) returns (Customer);
     */
    updateCustomerRecord(input: UpdateCustomerRequest, options?: RpcOptions): UnaryCall<UpdateCustomerRequest, Customer>;
    /**
     * @generated from protobuf rpc: DeleteCustomer(DeleteCustomerRequest) returns (google.protobuf.Empty);
     */
    deleteCustomer(input: DeleteCustomerRequest, options?: RpcOptions): UnaryCall<DeleteCustomerRequest, Empty>;
    /**
     * Finds the customer id based on account email
     *
     * @generated from protobuf rpc: FindCustomer(FindCustomerRequest) returns (Customer);
     */
    findCustomer(input: FindCustomerRequest, options?: RpcOptions): UnaryCall<FindCustomerRequest, Customer>;
    /**
     * ContactSalesTeam send email to team
     *
     * @generated from protobuf rpc: ContactSalesTeam(ContactSalesTeamRequest) returns (google.protobuf.Empty);
     */
    contactSalesTeam(input: ContactSalesTeamRequest, options?: RpcOptions): UnaryCall<ContactSalesTeamRequest, Empty>;
    // CRUD operations for the Customer Settings

    /**
     * Create operation for the Customer Settings
     *
     * @generated from protobuf rpc: CreateCustomerSettings(CreateCustomerSettingsRequest) returns (CustomerSettings);
     */
    createCustomerSettings(input: CreateCustomerSettingsRequest, options?: RpcOptions): UnaryCall<CreateCustomerSettingsRequest, CustomerSettings>;
    /**
     * Read operation for the Customer Settings
     *
     * @generated from protobuf rpc: GetCustomerSettings(GetCustomerSettingsRequest) returns (CustomerSettings);
     */
    getCustomerSettings(input: GetCustomerSettingsRequest, options?: RpcOptions): UnaryCall<GetCustomerSettingsRequest, CustomerSettings>;
    /**
     * Update operation for the Customer Settings
     *
     * @generated from protobuf rpc: UpdateCustomerSettings(UpdateCustomerSettingsRequest) returns (CustomerSettings);
     */
    updateCustomerSettings(input: UpdateCustomerSettingsRequest, options?: RpcOptions): UnaryCall<UpdateCustomerSettingsRequest, CustomerSettings>;
    /**
     * Delete operation for the Customer Settings
     *
     * @generated from protobuf rpc: DeleteCustomerSettings(DeleteCustomerSettingsRequest) returns (google.protobuf.Empty);
     */
    deleteCustomerSettings(input: DeleteCustomerSettingsRequest, options?: RpcOptions): UnaryCall<DeleteCustomerSettingsRequest, Empty>;
    /**
     * is used fot the checking if given time is in the range of the customer settings time
     *
     * @generated from protobuf rpc: ValidateOpenHours(ValidateOpenHoursRequest) returns (ValidateOpenHoursResponse);
     */
    validateOpenHours(input: ValidateOpenHoursRequest, options?: RpcOptions): UnaryCall<ValidateOpenHoursRequest, ValidateOpenHoursResponse>;
    /**
     * GetRDXLastRelease return url for last RDX apk release
     *
     * @generated from protobuf rpc: GetRDXLastRelease(google.protobuf.Empty) returns (GetRDXLastReleaseResponse);
     */
    getRDXLastRelease(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetRDXLastReleaseResponse>;
    /**
     * GetRFXLastRelease return url for last RFX apk release
     *
     * @generated from protobuf rpc: GetRFXLastRelease(google.protobuf.Empty) returns (GetRFXLastReleaseResponse);
     */
    getRFXLastRelease(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetRFXLastReleaseResponse>;
    /**
     * @generated from protobuf rpc: SendCustomerFeendback(CustomerFeendbackRequest) returns (google.protobuf.Empty);
     */
    sendCustomerFeendback(input: CustomerFeendbackRequest, options?: RpcOptions): UnaryCall<CustomerFeendbackRequest, Empty>;
    /**
     * @generated from protobuf rpc: CustomerPermissions(CustomerPermissionsRequest) returns (CustomerPermissionsResponse);
     */
    customerPermissions(input: CustomerPermissionsRequest, options?: RpcOptions): UnaryCall<CustomerPermissionsRequest, CustomerPermissionsResponse>;
    /**
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (SyncResponse);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, SyncResponse>;
}
/**
 * @generated from protobuf service Customerz
 */
export class CustomerzClient implements ICustomerzClient, ServiceInfo {
    typeName = Customerz.typeName;
    methods = Customerz.methods;
    options = Customerz.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateCustomer(CreateCustomerRequest) returns (Customer);
     */
    createCustomer(input: CreateCustomerRequest, options?: RpcOptions): UnaryCall<CreateCustomerRequest, Customer> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateCustomerRequest, Customer>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCustomer(GetCustomerRequest) returns (Customer);
     */
    getCustomer(input: GetCustomerRequest, options?: RpcOptions): UnaryCall<GetCustomerRequest, Customer> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerRequest, Customer>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCustomer(UpdateCustomerRequest) returns (Customer);
     */
    updateCustomer(input: UpdateCustomerRequest, options?: RpcOptions): UnaryCall<UpdateCustomerRequest, Customer> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCustomerRequest, Customer>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateCustomerRecord update only customer info in db
     *
     * @generated from protobuf rpc: UpdateCustomerRecord(UpdateCustomerRequest) returns (Customer);
     */
    updateCustomerRecord(input: UpdateCustomerRequest, options?: RpcOptions): UnaryCall<UpdateCustomerRequest, Customer> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCustomerRequest, Customer>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteCustomer(DeleteCustomerRequest) returns (google.protobuf.Empty);
     */
    deleteCustomer(input: DeleteCustomerRequest, options?: RpcOptions): UnaryCall<DeleteCustomerRequest, Empty> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteCustomerRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Finds the customer id based on account email
     *
     * @generated from protobuf rpc: FindCustomer(FindCustomerRequest) returns (Customer);
     */
    findCustomer(input: FindCustomerRequest, options?: RpcOptions): UnaryCall<FindCustomerRequest, Customer> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<FindCustomerRequest, Customer>("unary", this._transport, method, opt, input);
    }
    /**
     * ContactSalesTeam send email to team
     *
     * @generated from protobuf rpc: ContactSalesTeam(ContactSalesTeamRequest) returns (google.protobuf.Empty);
     */
    contactSalesTeam(input: ContactSalesTeamRequest, options?: RpcOptions): UnaryCall<ContactSalesTeamRequest, Empty> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactSalesTeamRequest, Empty>("unary", this._transport, method, opt, input);
    }
    // CRUD operations for the Customer Settings

    /**
     * Create operation for the Customer Settings
     *
     * @generated from protobuf rpc: CreateCustomerSettings(CreateCustomerSettingsRequest) returns (CustomerSettings);
     */
    createCustomerSettings(input: CreateCustomerSettingsRequest, options?: RpcOptions): UnaryCall<CreateCustomerSettingsRequest, CustomerSettings> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateCustomerSettingsRequest, CustomerSettings>("unary", this._transport, method, opt, input);
    }
    /**
     * Read operation for the Customer Settings
     *
     * @generated from protobuf rpc: GetCustomerSettings(GetCustomerSettingsRequest) returns (CustomerSettings);
     */
    getCustomerSettings(input: GetCustomerSettingsRequest, options?: RpcOptions): UnaryCall<GetCustomerSettingsRequest, CustomerSettings> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerSettingsRequest, CustomerSettings>("unary", this._transport, method, opt, input);
    }
    /**
     * Update operation for the Customer Settings
     *
     * @generated from protobuf rpc: UpdateCustomerSettings(UpdateCustomerSettingsRequest) returns (CustomerSettings);
     */
    updateCustomerSettings(input: UpdateCustomerSettingsRequest, options?: RpcOptions): UnaryCall<UpdateCustomerSettingsRequest, CustomerSettings> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCustomerSettingsRequest, CustomerSettings>("unary", this._transport, method, opt, input);
    }
    /**
     * Delete operation for the Customer Settings
     *
     * @generated from protobuf rpc: DeleteCustomerSettings(DeleteCustomerSettingsRequest) returns (google.protobuf.Empty);
     */
    deleteCustomerSettings(input: DeleteCustomerSettingsRequest, options?: RpcOptions): UnaryCall<DeleteCustomerSettingsRequest, Empty> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteCustomerSettingsRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * is used fot the checking if given time is in the range of the customer settings time
     *
     * @generated from protobuf rpc: ValidateOpenHours(ValidateOpenHoursRequest) returns (ValidateOpenHoursResponse);
     */
    validateOpenHours(input: ValidateOpenHoursRequest, options?: RpcOptions): UnaryCall<ValidateOpenHoursRequest, ValidateOpenHoursResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ValidateOpenHoursRequest, ValidateOpenHoursResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetRDXLastRelease return url for last RDX apk release
     *
     * @generated from protobuf rpc: GetRDXLastRelease(google.protobuf.Empty) returns (GetRDXLastReleaseResponse);
     */
    getRDXLastRelease(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetRDXLastReleaseResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetRDXLastReleaseResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetRFXLastRelease return url for last RFX apk release
     *
     * @generated from protobuf rpc: GetRFXLastRelease(google.protobuf.Empty) returns (GetRFXLastReleaseResponse);
     */
    getRFXLastRelease(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetRFXLastReleaseResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetRFXLastReleaseResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendCustomerFeendback(CustomerFeendbackRequest) returns (google.protobuf.Empty);
     */
    sendCustomerFeendback(input: CustomerFeendbackRequest, options?: RpcOptions): UnaryCall<CustomerFeendbackRequest, Empty> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomerFeendbackRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CustomerPermissions(CustomerPermissionsRequest) returns (CustomerPermissionsResponse);
     */
    customerPermissions(input: CustomerPermissionsRequest, options?: RpcOptions): UnaryCall<CustomerPermissionsRequest, CustomerPermissionsResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomerPermissionsRequest, CustomerPermissionsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (SyncResponse);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, SyncResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, SyncResponse>("unary", this._transport, method, opt, input);
    }
}
