import React, { useState } from "react";
import { Icon } from "../../../../shared/Icon/Icon";
import { InsightsModal } from "./InsightsModal";

export interface CustomerSettingsVariables {
  startTime?: string;
  endTime?: string;
  errorReportingEmail?: string;
  meetingSpaceCostPerHr?: number;
  currency?: string;
  workingWeekDays?: number[];
  collectInsights?: boolean;
  ssoEnable?: boolean;
}

interface Props {
  handleInsightsCollect: ({
    collectInsights,
  }: CustomerSettingsVariables) => void;
  collectData?: boolean;
  loading: boolean;
  error?: Error;
}

export const Insights = ({
  handleInsightsCollect,
  collectData,
  loading,
  error,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="flex-a-center cursor-pointer"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Icon icon="insights_icon" />
        <h3>Insights</h3>
      </div>

      {isOpen && (
        <InsightsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleInsightsCollect={handleInsightsCollect}
          collectData={collectData}
          loading={loading}
          error={error}
        />
      )}
    </>
  );
};
