import React from "react";
import { Addon } from "../../../../api/grpc/subscription/subscription";
import { getAddonsName } from "../../lib/getAddonsName";

interface SubscriptionItem {
  type: string;
  count: number;
}

interface Props {
  data?: Addon[];
}

export const SubscriptionItemsCount = ({ data }: Props) => {
  return (
    <>
      {data &&
        data.map((item) => (
          <div
            key={`${item.entityType}-${item.id}`}
            className="SubscriptionItemsCount"
          >
            <span>
              {getAddonsName(item.name.toLocaleLowerCase(), item.quantity)}
            </span>
            <span>{item.quantity}x</span>
          </div>
        ))}
    </>
  );
};
