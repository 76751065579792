import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Form, FormGroup, Modal } from "reactstrap";
import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { Input } from "../../../../shared/Input/Input";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { CustomerSettingsVariables } from "../../InsightsCard/Insights/Insights";
import { ModalHeaderContent } from "../../../../Modals/ModalHeaderContent/ModalHeaderContent";
import { ModalFooterContent } from "../../../../Modals/ModalFooterContent/ModalFooterContent";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  email?: string;
  handleErrorReportingEmail: ({
    errorReportingEmail,
  }: CustomerSettingsVariables) => void;
  loading: boolean;
  error?: Error;
}

export const ServiceEmailModal = ({
  isOpen,
  setIsOpen,
  email,
  handleErrorReportingEmail,
  error,
  loading,
}: Props) => {
  const [emailToChange, setEmailToChange] = useState<string | undefined>("");

  const updateData = useCallback(() => {
    setEmailToChange(email);
  }, [email]);

  useEffect(() => {
    updateData();
  }, [email]);

  return (
    <Modal
      title="Service emails"
      isOpen={isOpen}
      onToggle={() => setIsOpen((prev) => !prev)}
      className="WorkHours"
    >
      <ModalHeaderContent
        title="Service emails"
        handleToggle={() => setIsOpen(false)}
      />

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <CenteredBox>
            <LoadingBox minHeight={80} maxWidth={300} />
          </CenteredBox>
        }
      >
        <HandleRequestState
          state={!!error}
          placeholder={<CenteredBox>Error fetching data</CenteredBox>}
        >
          <span className="WorkHours__info pb-4">
            Service emails are used for sending service messages
          </span>
          <div className="WorkingHoursForm WorkHours__info">
            <Form className="flex-a-center WorkingHoursForm__selectors pt-2">
              <FormGroup className="w-100">
                <label className="label__main">Reporting email</label>

                <Input
                  type="email"
                  name="errorReportingEmail"
                  id="errorReportingEmail"
                  value={emailToChange}
                  errorMessage="Please add a valid email"
                  invalid={
                    !/\S+@\S+\.\S+/.test(emailToChange ? emailToChange : "")
                  }
                  onChange={(e) => setEmailToChange(e.target.value)}
                />
              </FormGroup>
            </Form>
          </div>

          <ModalFooterContent
            disabled={
              !/\S+@\S+\.\S+/.test(emailToChange ? emailToChange : "") ||
              email === emailToChange
            }
            toggleModal={() => {
              setEmailToChange(email);
              setIsOpen(false);
              return;
            }}
            handleSave={() => {
              handleErrorReportingEmail({
                errorReportingEmail: emailToChange,
              });
              setIsOpen(false);
              return;
            }}
          />
        </HandleRequestState>
      </HandleLoadingState>
    </Modal>
  );
};
