import React, { PropsWithChildren, useState } from "react";
import { DateRangeContext, dateRangeType } from "./DateRangeContext";

export const DateRangeContextProvider = ({
  children,
}: PropsWithChildren<{
  children: React.ReactNode;
}>) => {
  const [initialDateRange, setInitialDateRange] = useState<dateRangeType>(null);
  const [confirmedRange, setConfirmedRange] = useState<dateRangeType>("7");

  const context: DateRangeContext = {
    initialDateRange,
    confirmedRange,
    setConfirmedRange,
    setInitialDateRange,
  };

  return (
    <DateRangeContext.Provider value={context}>
      {children}
    </DateRangeContext.Provider>
  );
};
