// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "customer.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message ValidateOpenHoursRequest
 */
export interface ValidateOpenHoursRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * in 00:00:00 format or RFC3339 UTC
     *
     * @generated from protobuf field: string event_start_time = 2;
     */
    eventStartTime: string;
    /**
     * @generated from protobuf field: string event_end_time = 3;
     */
    eventEndTime: string;
    /**
     * @generated from protobuf field: string working_hours_start_time = 4;
     */
    workingHoursStartTime: string;
    /**
     * @generated from protobuf field: string working_hours_end_time = 5;
     */
    workingHoursEndTime: string;
}
/**
 * @generated from protobuf message ValidateOpenHoursResponse
 */
export interface ValidateOpenHoursResponse {
    /**
     * @generated from protobuf field: bool valid = 1;
     */
    valid: boolean;
}
/**
 * @generated from protobuf message CreateCustomerSettingsRequest
 */
export interface CreateCustomerSettingsRequest {
    /**
     * @generated from protobuf field: CustomerSettings customer_settings = 1;
     */
    customerSettings?: CustomerSettings;
}
/**
 * @generated from protobuf message GetCustomerSettingsRequest
 */
export interface GetCustomerSettingsRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message UpdateCustomerSettingsRequest
 */
export interface UpdateCustomerSettingsRequest {
    /**
     * @generated from protobuf field: CustomerSettings customer_settings = 1;
     */
    customerSettings?: CustomerSettings;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message DeleteCustomerSettingsRequest
 */
export interface DeleteCustomerSettingsRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CreateCustomerRequest
 */
export interface CreateCustomerRequest {
    /**
     * The parent resource where this customer will be created.
     * Format: customer/{customer}
     *
     * @generated from protobuf field: Customer customer = 1;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: bool validate_domain = 2;
     */
    validateDomain: boolean; // validate email domain. if customer with same domain already exists then throw and error.
}
/**
 * @generated from protobuf message GetCustomerRequest
 */
export interface GetCustomerRequest {
    /**
     * The name of the book to retrieve.
     * Format: customers/{customer}
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message UpdateCustomerRequest
 */
export interface UpdateCustomerRequest {
    /**
     * The customer to update.
     *
     * The customer's `name` field is used to identify the customer to be updated.
     * Format: customers/{customer}
     *
     * @generated from protobuf field: Customer customer = 1;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message DeleteCustomerRequest
 */
export interface DeleteCustomerRequest {
    /**
     * The name of the book to delete.
     * Format: customers/{customer}
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message FindCustomerRequest
 */
export interface FindCustomerRequest {
    /**
     * The customer to find based on the email
     *
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message Customer
 */
export interface Customer {
    /**
     * The resource name of the customer : customers/cus-goget_corp-x134
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The company of the customer
     *
     * @generated from protobuf field: string company_name = 2;
     */
    companyName: string;
    /**
     * Admin password for customer
     *
     * @generated from protobuf field: string password = 3;
     */
    password: string;
    /**
     * The vat for a customer
     *
     * @generated from protobuf field: string vat = 4;
     */
    vat: string;
    /**
     * The vat_status for a customer
     *
     * @generated from protobuf field: string vat_status = 5;
     */
    vatStatus: string;
    /**
     * The customer id
     *
     * @generated from protobuf field: string id = 6;
     */
    id: string;
    /**
     * When do you need it?
     *
     * @generated from protobuf field: string whenYouNeed = 7;
     */
    whenYouNeed: string;
    /**
     * Number of rooms
     *
     * @generated from protobuf field: string roomsNumber = 8;
     */
    roomsNumber: string;
    /**
     * Company Size
     *
     * @generated from protobuf field: string deskNumber = 9;
     */
    deskNumber: string;
    /**
     * I'm interested in
     *
     * @generated from protobuf field: repeated string interested = 10;
     */
    interested: string[];
    /**
     * @generated from protobuf field: BillingDetails billingDetails = 11;
     */
    billingDetails?: BillingDetails;
    /**
     * @generated from protobuf field: string recoveryEmail = 12;
     */
    recoveryEmail: string;
}
/**
 * @generated from protobuf message BillingDetails
 */
export interface BillingDetails {
    /**
     * @generated from protobuf field: string city = 1;
     */
    city: string;
    /**
     * @generated from protobuf field: string postalCode = 2;
     */
    postalCode: string;
    /**
     * @generated from protobuf field: string country = 3;
     */
    country: string;
    /**
     * @generated from protobuf field: string state = 4;
     */
    state: string;
    /**
     * @generated from protobuf field: string firstName = 5;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string lastName = 6;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string phone = 7;
     */
    phone: string;
    /**
     * @generated from protobuf field: string email = 8;
     */
    email: string;
    /**
     * @generated from protobuf field: uint32 billing_id = 9;
     */
    billingId: number;
    /**
     * @generated from protobuf field: string address = 10;
     */
    address: string;
    /**
     * @generated from protobuf field: string line1 = 11;
     */
    line1: string;
    /**
     * @generated from protobuf field: string line2 = 12;
     */
    line2: string;
    /**
     * @generated from protobuf field: string line3 = 13;
     */
    line3: string;
    /**
     * @generated from protobuf field: string countryCode = 14;
     */
    countryCode: string;
    /**
     * @generated from protobuf field: string currency = 15;
     */
    currency: string;
}
/**
 * @generated from protobuf message ContactSalesTeamRequest
 */
export interface ContactSalesTeamRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: string message = 4;
     */
    message: string;
}
/**
 * @generated from protobuf message CustomerSettings
 */
export interface CustomerSettings {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * start_time in format HH:MM
     *
     * @generated from protobuf field: string start_time = 2;
     */
    startTime: string;
    /**
     * end_time in format HH:MM
     *
     * @generated from protobuf field: string end_time = 3;
     */
    endTime: string;
    /**
     * for analytics
     *
     * @generated from protobuf field: bool collect_insigths = 4;
     */
    collectInsigths: boolean;
    /**
     * emails for any errors report
     *
     * @generated from protobuf field: string errorReportingEmail = 5;
     */
    errorReportingEmail: string;
    /**
     * meetingSpaceCostPerHr - this value is used x recuperated h to give saved money in USD
     *
     * @generated from protobuf field: float meetingSpaceCostPerHr = 6;
     */
    meetingSpaceCostPerHr: number;
    /**
     * @generated from protobuf field: string currency = 7;
     */
    currency: string;
    /**
     * workingWeekDays is a working week start day index (0 - Sunday, 1 - Monday, ....)
     *
     * @generated from protobuf field: repeated int32 workingWeekDays = 8;
     */
    workingWeekDays: number[];
    /**
     * sso_enable is a flag to enable SSO
     *
     * @generated from protobuf field: bool sso_enable = 9;
     */
    ssoEnable: boolean;
}
/**
 * @generated from protobuf message GetRDXLastReleaseResponse
 */
export interface GetRDXLastReleaseResponse {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * @generated from protobuf message GetRFXLastReleaseResponse
 */
export interface GetRFXLastReleaseResponse {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * @generated from protobuf message CustomerFeendbackRequest
 */
export interface CustomerFeendbackRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string feeback_body = 3;
     */
    feebackBody: string;
    /**
     * @generated from protobuf field: string customer_id = 4;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CustomerPermissionsRequest
 */
export interface CustomerPermissionsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message CustomerPermissionsResponse
 */
export interface CustomerPermissionsResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * RDX features
     *
     * @generated from protobuf field: bool backgroundImage = 2;
     */
    backgroundImage: boolean;
    /**
     * @generated from protobuf field: bool logoImage = 3;
     */
    logoImage: boolean;
    /**
     * @generated from protobuf field: bool contactlessQRBooking = 4;
     */
    contactlessQRBooking: boolean;
    /**
     * @generated from protobuf field: bool nearbyRooms = 5;
     */
    nearbyRooms: boolean;
    /**
     * @generated from protobuf field: bool equipmentReport = 6;
     */
    equipmentReport: boolean;
    /**
     * @generated from protobuf field: bool pinCode = 7;
     */
    pinCode: boolean;
    /**
     * @generated from protobuf field: bool mapView = 8;
     */
    mapView: boolean;
    /**
     * @generated from protobuf field: bool requireHeadcount = 9;
     */
    requireHeadcount: boolean;
    /**
     * @generated from protobuf field: bool onScreenReservation = 10;
     */
    onScreenReservation: boolean;
    /**
     * @generated from protobuf field: bool checkInRequired = 11;
     */
    checkInRequired: boolean;
    /**
     * Desk reservation
     *
     * @generated from protobuf field: bool usersSection = 12;
     */
    usersSection: boolean;
    /**
     * Dashboard
     *
     * @generated from protobuf field: bool officeMaps = 13;
     */
    officeMaps: boolean;
    /**
     * @generated from protobuf field: uint32 maxIntegrations = 14;
     */
    maxIntegrations: number;
    /**
     * @generated from protobuf field: uint32 maxAdminUsers = 15;
     */
    maxAdminUsers: number;
    /**
     * @generated from protobuf field: bool users = 16;
     */
    users: boolean;
    /**
     * Workplace Insights
     *
     * @generated from protobuf field: bool basicStatistic = 17;
     */
    basicStatistic: boolean;
    /**
     * @generated from protobuf field: bool advancedStatistic = 18;
     */
    advancedStatistic: boolean;
    /**
     * @generated from protobuf field: bool downloadReports = 19;
     */
    downloadReports: boolean;
    /**
     * Support
     *
     * @generated from protobuf field: bool supportByTicket = 20;
     */
    supportByTicket: boolean;
    /**
     * @generated from protobuf field: bool dedicatedSupportAgent = 21;
     */
    dedicatedSupportAgent: boolean;
    /**
     * @generated from protobuf field: bool dedicatedAccountManager = 22;
     */
    dedicatedAccountManager: boolean;
    /**
     * Security
     *
     * @generated from protobuf field: bool basicAuth = 23;
     */
    basicAuth: boolean;
    /**
     * @generated from protobuf field: bool ssoEnabled = 24;
     */
    ssoEnabled: boolean;
    /**
     * @generated from protobuf field: bool modernAuth = 25;
     */
    modernAuth: boolean;
}
/**
 * @generated from protobuf message SyncResponse
 */
export interface SyncResponse {
    /**
     * @generated from protobuf field: int32 customerSettingsUpdated = 1;
     */
    customerSettingsUpdated: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ValidateOpenHoursRequest$Type extends MessageType<ValidateOpenHoursRequest> {
    constructor() {
        super("ValidateOpenHoursRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "customer/Customer" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "event_start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "event_end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "working_hours_start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "working_hours_end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ValidateOpenHoursRequest>): ValidateOpenHoursRequest {
        const message = { customerId: "", eventStartTime: "", eventEndTime: "", workingHoursStartTime: "", workingHoursEndTime: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ValidateOpenHoursRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateOpenHoursRequest): ValidateOpenHoursRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string event_start_time */ 2:
                    message.eventStartTime = reader.string();
                    break;
                case /* string event_end_time */ 3:
                    message.eventEndTime = reader.string();
                    break;
                case /* string working_hours_start_time */ 4:
                    message.workingHoursStartTime = reader.string();
                    break;
                case /* string working_hours_end_time */ 5:
                    message.workingHoursEndTime = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateOpenHoursRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string event_start_time = 2; */
        if (message.eventStartTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.eventStartTime);
        /* string event_end_time = 3; */
        if (message.eventEndTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.eventEndTime);
        /* string working_hours_start_time = 4; */
        if (message.workingHoursStartTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.workingHoursStartTime);
        /* string working_hours_end_time = 5; */
        if (message.workingHoursEndTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.workingHoursEndTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ValidateOpenHoursRequest
 */
export const ValidateOpenHoursRequest = new ValidateOpenHoursRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateOpenHoursResponse$Type extends MessageType<ValidateOpenHoursResponse> {
    constructor() {
        super("ValidateOpenHoursResponse", [
            { no: 1, name: "valid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ValidateOpenHoursResponse>): ValidateOpenHoursResponse {
        const message = { valid: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ValidateOpenHoursResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateOpenHoursResponse): ValidateOpenHoursResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool valid */ 1:
                    message.valid = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateOpenHoursResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool valid = 1; */
        if (message.valid !== false)
            writer.tag(1, WireType.Varint).bool(message.valid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ValidateOpenHoursResponse
 */
export const ValidateOpenHoursResponse = new ValidateOpenHoursResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomerSettingsRequest$Type extends MessageType<CreateCustomerSettingsRequest> {
    constructor() {
        super("CreateCustomerSettingsRequest", [
            { no: 1, name: "customer_settings", kind: "message", T: () => CustomerSettings, options: { "google.api.resource_reference": { childType: "customer/Customer" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<CreateCustomerSettingsRequest>): CreateCustomerSettingsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCustomerSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCustomerSettingsRequest): CreateCustomerSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* CustomerSettings customer_settings */ 1:
                    message.customerSettings = CustomerSettings.internalBinaryRead(reader, reader.uint32(), options, message.customerSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCustomerSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* CustomerSettings customer_settings = 1; */
        if (message.customerSettings)
            CustomerSettings.internalBinaryWrite(message.customerSettings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateCustomerSettingsRequest
 */
export const CreateCustomerSettingsRequest = new CreateCustomerSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerSettingsRequest$Type extends MessageType<GetCustomerSettingsRequest> {
    constructor() {
        super("GetCustomerSettingsRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "customer/Customer" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetCustomerSettingsRequest>): GetCustomerSettingsRequest {
        const message = { customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerSettingsRequest): GetCustomerSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetCustomerSettingsRequest
 */
export const GetCustomerSettingsRequest = new GetCustomerSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCustomerSettingsRequest$Type extends MessageType<UpdateCustomerSettingsRequest> {
    constructor() {
        super("UpdateCustomerSettingsRequest", [
            { no: 1, name: "customer_settings", kind: "message", T: () => CustomerSettings, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCustomerSettingsRequest>): UpdateCustomerSettingsRequest {
        const message = { customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCustomerSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCustomerSettingsRequest): UpdateCustomerSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* CustomerSettings customer_settings */ 1:
                    message.customerSettings = CustomerSettings.internalBinaryRead(reader, reader.uint32(), options, message.customerSettings);
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCustomerSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* CustomerSettings customer_settings = 1; */
        if (message.customerSettings)
            CustomerSettings.internalBinaryWrite(message.customerSettings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateCustomerSettingsRequest
 */
export const UpdateCustomerSettingsRequest = new UpdateCustomerSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCustomerSettingsRequest$Type extends MessageType<DeleteCustomerSettingsRequest> {
    constructor() {
        super("DeleteCustomerSettingsRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "customer/Customer" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<DeleteCustomerSettingsRequest>): DeleteCustomerSettingsRequest {
        const message = { customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteCustomerSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCustomerSettingsRequest): DeleteCustomerSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteCustomerSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteCustomerSettingsRequest
 */
export const DeleteCustomerSettingsRequest = new DeleteCustomerSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomerRequest$Type extends MessageType<CreateCustomerRequest> {
    constructor() {
        super("CreateCustomerRequest", [
            { no: 1, name: "customer", kind: "message", T: () => Customer, options: { "google.api.resource_reference": { childType: "customer/Customer" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "validate_domain", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateCustomerRequest>): CreateCustomerRequest {
        const message = { validateDomain: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCustomerRequest): CreateCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Customer customer */ 1:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* bool validate_domain */ 2:
                    message.validateDomain = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Customer customer = 1; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool validate_domain = 2; */
        if (message.validateDomain !== false)
            writer.tag(2, WireType.Varint).bool(message.validateDomain);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateCustomerRequest
 */
export const CreateCustomerRequest = new CreateCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerRequest$Type extends MessageType<GetCustomerRequest> {
    constructor() {
        super("GetCustomerRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "customer/Customer" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetCustomerRequest>): GetCustomerRequest {
        const message = { customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerRequest): GetCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetCustomerRequest
 */
export const GetCustomerRequest = new GetCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCustomerRequest$Type extends MessageType<UpdateCustomerRequest> {
    constructor() {
        super("UpdateCustomerRequest", [
            { no: 1, name: "customer", kind: "message", T: () => Customer, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCustomerRequest>): UpdateCustomerRequest {
        const message = { customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCustomerRequest): UpdateCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Customer customer */ 1:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Customer customer = 1; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateCustomerRequest
 */
export const UpdateCustomerRequest = new UpdateCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCustomerRequest$Type extends MessageType<DeleteCustomerRequest> {
    constructor() {
        super("DeleteCustomerRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "customer/Customer" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<DeleteCustomerRequest>): DeleteCustomerRequest {
        const message = { customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCustomerRequest): DeleteCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteCustomerRequest
 */
export const DeleteCustomerRequest = new DeleteCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FindCustomerRequest$Type extends MessageType<FindCustomerRequest> {
    constructor() {
        super("FindCustomerRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<FindCustomerRequest>): FindCustomerRequest {
        const message = { email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FindCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FindCustomerRequest): FindCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FindCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FindCustomerRequest
 */
export const FindCustomerRequest = new FindCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Customer$Type extends MessageType<Customer> {
    constructor() {
        super("Customer", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "company_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "vat", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "vat_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "whenYouNeed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "roomsNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "deskNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "interested", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "billingDetails", kind: "message", T: () => BillingDetails },
            { no: 12, name: "recoveryEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "google.api.resource": { type: "customer/Customer", pattern: ["customers/{customer}"] } });
    }
    create(value?: PartialMessage<Customer>): Customer {
        const message = { name: "", companyName: "", password: "", vat: "", vatStatus: "", id: "", whenYouNeed: "", roomsNumber: "", deskNumber: "", interested: [], recoveryEmail: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Customer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Customer): Customer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string company_name */ 2:
                    message.companyName = reader.string();
                    break;
                case /* string password */ 3:
                    message.password = reader.string();
                    break;
                case /* string vat */ 4:
                    message.vat = reader.string();
                    break;
                case /* string vat_status */ 5:
                    message.vatStatus = reader.string();
                    break;
                case /* string id */ 6:
                    message.id = reader.string();
                    break;
                case /* string whenYouNeed */ 7:
                    message.whenYouNeed = reader.string();
                    break;
                case /* string roomsNumber */ 8:
                    message.roomsNumber = reader.string();
                    break;
                case /* string deskNumber */ 9:
                    message.deskNumber = reader.string();
                    break;
                case /* repeated string interested */ 10:
                    message.interested.push(reader.string());
                    break;
                case /* BillingDetails billingDetails */ 11:
                    message.billingDetails = BillingDetails.internalBinaryRead(reader, reader.uint32(), options, message.billingDetails);
                    break;
                case /* string recoveryEmail */ 12:
                    message.recoveryEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Customer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string company_name = 2; */
        if (message.companyName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.companyName);
        /* string password = 3; */
        if (message.password !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.password);
        /* string vat = 4; */
        if (message.vat !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.vat);
        /* string vat_status = 5; */
        if (message.vatStatus !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.vatStatus);
        /* string id = 6; */
        if (message.id !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.id);
        /* string whenYouNeed = 7; */
        if (message.whenYouNeed !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.whenYouNeed);
        /* string roomsNumber = 8; */
        if (message.roomsNumber !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.roomsNumber);
        /* string deskNumber = 9; */
        if (message.deskNumber !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.deskNumber);
        /* repeated string interested = 10; */
        for (let i = 0; i < message.interested.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.interested[i]);
        /* BillingDetails billingDetails = 11; */
        if (message.billingDetails)
            BillingDetails.internalBinaryWrite(message.billingDetails, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string recoveryEmail = 12; */
        if (message.recoveryEmail !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.recoveryEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Customer
 */
export const Customer = new Customer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingDetails$Type extends MessageType<BillingDetails> {
    constructor() {
        super("BillingDetails", [
            { no: 1, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "postalCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "country", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "firstName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "lastName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "billing_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "line1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "line2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "line3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "countryCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BillingDetails>): BillingDetails {
        const message = { city: "", postalCode: "", country: "", state: "", firstName: "", lastName: "", phone: "", email: "", billingId: 0, address: "", line1: "", line2: "", line3: "", countryCode: "", currency: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BillingDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillingDetails): BillingDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string city */ 1:
                    message.city = reader.string();
                    break;
                case /* string postalCode */ 2:
                    message.postalCode = reader.string();
                    break;
                case /* string country */ 3:
                    message.country = reader.string();
                    break;
                case /* string state */ 4:
                    message.state = reader.string();
                    break;
                case /* string firstName */ 5:
                    message.firstName = reader.string();
                    break;
                case /* string lastName */ 6:
                    message.lastName = reader.string();
                    break;
                case /* string phone */ 7:
                    message.phone = reader.string();
                    break;
                case /* string email */ 8:
                    message.email = reader.string();
                    break;
                case /* uint32 billing_id */ 9:
                    message.billingId = reader.uint32();
                    break;
                case /* string address */ 10:
                    message.address = reader.string();
                    break;
                case /* string line1 */ 11:
                    message.line1 = reader.string();
                    break;
                case /* string line2 */ 12:
                    message.line2 = reader.string();
                    break;
                case /* string line3 */ 13:
                    message.line3 = reader.string();
                    break;
                case /* string countryCode */ 14:
                    message.countryCode = reader.string();
                    break;
                case /* string currency */ 15:
                    message.currency = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillingDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string city = 1; */
        if (message.city !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.city);
        /* string postalCode = 2; */
        if (message.postalCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.postalCode);
        /* string country = 3; */
        if (message.country !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.country);
        /* string state = 4; */
        if (message.state !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.state);
        /* string firstName = 5; */
        if (message.firstName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.firstName);
        /* string lastName = 6; */
        if (message.lastName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.lastName);
        /* string phone = 7; */
        if (message.phone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.phone);
        /* string email = 8; */
        if (message.email !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.email);
        /* uint32 billing_id = 9; */
        if (message.billingId !== 0)
            writer.tag(9, WireType.Varint).uint32(message.billingId);
        /* string address = 10; */
        if (message.address !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.address);
        /* string line1 = 11; */
        if (message.line1 !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.line1);
        /* string line2 = 12; */
        if (message.line2 !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.line2);
        /* string line3 = 13; */
        if (message.line3 !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.line3);
        /* string countryCode = 14; */
        if (message.countryCode !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.countryCode);
        /* string currency = 15; */
        if (message.currency !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.currency);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillingDetails
 */
export const BillingDetails = new BillingDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactSalesTeamRequest$Type extends MessageType<ContactSalesTeamRequest> {
    constructor() {
        super("ContactSalesTeamRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactSalesTeamRequest>): ContactSalesTeamRequest {
        const message = { customerId: "", name: "", email: "", message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactSalesTeamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactSalesTeamRequest): ContactSalesTeamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string message */ 4:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactSalesTeamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string message = 4; */
        if (message.message !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactSalesTeamRequest
 */
export const ContactSalesTeamRequest = new ContactSalesTeamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerSettings$Type extends MessageType<CustomerSettings> {
    constructor() {
        super("CustomerSettings", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "collect_insigths", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "errorReportingEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "meetingSpaceCostPerHr", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "workingWeekDays", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "sso_enable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CustomerSettings>): CustomerSettings {
        const message = { customerId: "", startTime: "", endTime: "", collectInsigths: false, errorReportingEmail: "", meetingSpaceCostPerHr: 0, currency: "", workingWeekDays: [], ssoEnable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerSettings): CustomerSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string start_time */ 2:
                    message.startTime = reader.string();
                    break;
                case /* string end_time */ 3:
                    message.endTime = reader.string();
                    break;
                case /* bool collect_insigths */ 4:
                    message.collectInsigths = reader.bool();
                    break;
                case /* string errorReportingEmail */ 5:
                    message.errorReportingEmail = reader.string();
                    break;
                case /* float meetingSpaceCostPerHr */ 6:
                    message.meetingSpaceCostPerHr = reader.float();
                    break;
                case /* string currency */ 7:
                    message.currency = reader.string();
                    break;
                case /* repeated int32 workingWeekDays */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.workingWeekDays.push(reader.int32());
                    else
                        message.workingWeekDays.push(reader.int32());
                    break;
                case /* bool sso_enable */ 9:
                    message.ssoEnable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string start_time = 2; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string end_time = 3; */
        if (message.endTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endTime);
        /* bool collect_insigths = 4; */
        if (message.collectInsigths !== false)
            writer.tag(4, WireType.Varint).bool(message.collectInsigths);
        /* string errorReportingEmail = 5; */
        if (message.errorReportingEmail !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.errorReportingEmail);
        /* float meetingSpaceCostPerHr = 6; */
        if (message.meetingSpaceCostPerHr !== 0)
            writer.tag(6, WireType.Bit32).float(message.meetingSpaceCostPerHr);
        /* string currency = 7; */
        if (message.currency !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.currency);
        /* repeated int32 workingWeekDays = 8; */
        if (message.workingWeekDays.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.workingWeekDays.length; i++)
                writer.int32(message.workingWeekDays[i]);
            writer.join();
        }
        /* bool sso_enable = 9; */
        if (message.ssoEnable !== false)
            writer.tag(9, WireType.Varint).bool(message.ssoEnable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerSettings
 */
export const CustomerSettings = new CustomerSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRDXLastReleaseResponse$Type extends MessageType<GetRDXLastReleaseResponse> {
    constructor() {
        super("GetRDXLastReleaseResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRDXLastReleaseResponse>): GetRDXLastReleaseResponse {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRDXLastReleaseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRDXLastReleaseResponse): GetRDXLastReleaseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRDXLastReleaseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetRDXLastReleaseResponse
 */
export const GetRDXLastReleaseResponse = new GetRDXLastReleaseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRFXLastReleaseResponse$Type extends MessageType<GetRFXLastReleaseResponse> {
    constructor() {
        super("GetRFXLastReleaseResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRFXLastReleaseResponse>): GetRFXLastReleaseResponse {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRFXLastReleaseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRFXLastReleaseResponse): GetRFXLastReleaseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRFXLastReleaseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetRFXLastReleaseResponse
 */
export const GetRFXLastReleaseResponse = new GetRFXLastReleaseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerFeendbackRequest$Type extends MessageType<CustomerFeendbackRequest> {
    constructor() {
        super("CustomerFeendbackRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "feeback_body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CustomerFeendbackRequest>): CustomerFeendbackRequest {
        const message = { name: "", email: "", feebackBody: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerFeendbackRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerFeendbackRequest): CustomerFeendbackRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string feeback_body */ 3:
                    message.feebackBody = reader.string();
                    break;
                case /* string customer_id */ 4:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerFeendbackRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string feeback_body = 3; */
        if (message.feebackBody !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.feebackBody);
        /* string customer_id = 4; */
        if (message.customerId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerFeendbackRequest
 */
export const CustomerFeendbackRequest = new CustomerFeendbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerPermissionsRequest$Type extends MessageType<CustomerPermissionsRequest> {
    constructor() {
        super("CustomerPermissionsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CustomerPermissionsRequest>): CustomerPermissionsRequest {
        const message = { customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerPermissionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerPermissionsRequest): CustomerPermissionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerPermissionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerPermissionsRequest
 */
export const CustomerPermissionsRequest = new CustomerPermissionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerPermissionsResponse$Type extends MessageType<CustomerPermissionsResponse> {
    constructor() {
        super("CustomerPermissionsResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "backgroundImage", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "logoImage", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "contactlessQRBooking", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "nearbyRooms", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "equipmentReport", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "pinCode", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "mapView", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "requireHeadcount", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "onScreenReservation", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "checkInRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "usersSection", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "officeMaps", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "maxIntegrations", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "maxAdminUsers", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 16, name: "users", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "basicStatistic", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "advancedStatistic", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "downloadReports", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "supportByTicket", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "dedicatedSupportAgent", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "dedicatedAccountManager", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "basicAuth", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 24, name: "ssoEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 25, name: "modernAuth", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CustomerPermissionsResponse>): CustomerPermissionsResponse {
        const message = { id: "", backgroundImage: false, logoImage: false, contactlessQRBooking: false, nearbyRooms: false, equipmentReport: false, pinCode: false, mapView: false, requireHeadcount: false, onScreenReservation: false, checkInRequired: false, usersSection: false, officeMaps: false, maxIntegrations: 0, maxAdminUsers: 0, users: false, basicStatistic: false, advancedStatistic: false, downloadReports: false, supportByTicket: false, dedicatedSupportAgent: false, dedicatedAccountManager: false, basicAuth: false, ssoEnabled: false, modernAuth: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerPermissionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerPermissionsResponse): CustomerPermissionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool backgroundImage */ 2:
                    message.backgroundImage = reader.bool();
                    break;
                case /* bool logoImage */ 3:
                    message.logoImage = reader.bool();
                    break;
                case /* bool contactlessQRBooking */ 4:
                    message.contactlessQRBooking = reader.bool();
                    break;
                case /* bool nearbyRooms */ 5:
                    message.nearbyRooms = reader.bool();
                    break;
                case /* bool equipmentReport */ 6:
                    message.equipmentReport = reader.bool();
                    break;
                case /* bool pinCode */ 7:
                    message.pinCode = reader.bool();
                    break;
                case /* bool mapView */ 8:
                    message.mapView = reader.bool();
                    break;
                case /* bool requireHeadcount */ 9:
                    message.requireHeadcount = reader.bool();
                    break;
                case /* bool onScreenReservation */ 10:
                    message.onScreenReservation = reader.bool();
                    break;
                case /* bool checkInRequired */ 11:
                    message.checkInRequired = reader.bool();
                    break;
                case /* bool usersSection */ 12:
                    message.usersSection = reader.bool();
                    break;
                case /* bool officeMaps */ 13:
                    message.officeMaps = reader.bool();
                    break;
                case /* uint32 maxIntegrations */ 14:
                    message.maxIntegrations = reader.uint32();
                    break;
                case /* uint32 maxAdminUsers */ 15:
                    message.maxAdminUsers = reader.uint32();
                    break;
                case /* bool users */ 16:
                    message.users = reader.bool();
                    break;
                case /* bool basicStatistic */ 17:
                    message.basicStatistic = reader.bool();
                    break;
                case /* bool advancedStatistic */ 18:
                    message.advancedStatistic = reader.bool();
                    break;
                case /* bool downloadReports */ 19:
                    message.downloadReports = reader.bool();
                    break;
                case /* bool supportByTicket */ 20:
                    message.supportByTicket = reader.bool();
                    break;
                case /* bool dedicatedSupportAgent */ 21:
                    message.dedicatedSupportAgent = reader.bool();
                    break;
                case /* bool dedicatedAccountManager */ 22:
                    message.dedicatedAccountManager = reader.bool();
                    break;
                case /* bool basicAuth */ 23:
                    message.basicAuth = reader.bool();
                    break;
                case /* bool ssoEnabled */ 24:
                    message.ssoEnabled = reader.bool();
                    break;
                case /* bool modernAuth */ 25:
                    message.modernAuth = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerPermissionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool backgroundImage = 2; */
        if (message.backgroundImage !== false)
            writer.tag(2, WireType.Varint).bool(message.backgroundImage);
        /* bool logoImage = 3; */
        if (message.logoImage !== false)
            writer.tag(3, WireType.Varint).bool(message.logoImage);
        /* bool contactlessQRBooking = 4; */
        if (message.contactlessQRBooking !== false)
            writer.tag(4, WireType.Varint).bool(message.contactlessQRBooking);
        /* bool nearbyRooms = 5; */
        if (message.nearbyRooms !== false)
            writer.tag(5, WireType.Varint).bool(message.nearbyRooms);
        /* bool equipmentReport = 6; */
        if (message.equipmentReport !== false)
            writer.tag(6, WireType.Varint).bool(message.equipmentReport);
        /* bool pinCode = 7; */
        if (message.pinCode !== false)
            writer.tag(7, WireType.Varint).bool(message.pinCode);
        /* bool mapView = 8; */
        if (message.mapView !== false)
            writer.tag(8, WireType.Varint).bool(message.mapView);
        /* bool requireHeadcount = 9; */
        if (message.requireHeadcount !== false)
            writer.tag(9, WireType.Varint).bool(message.requireHeadcount);
        /* bool onScreenReservation = 10; */
        if (message.onScreenReservation !== false)
            writer.tag(10, WireType.Varint).bool(message.onScreenReservation);
        /* bool checkInRequired = 11; */
        if (message.checkInRequired !== false)
            writer.tag(11, WireType.Varint).bool(message.checkInRequired);
        /* bool usersSection = 12; */
        if (message.usersSection !== false)
            writer.tag(12, WireType.Varint).bool(message.usersSection);
        /* bool officeMaps = 13; */
        if (message.officeMaps !== false)
            writer.tag(13, WireType.Varint).bool(message.officeMaps);
        /* uint32 maxIntegrations = 14; */
        if (message.maxIntegrations !== 0)
            writer.tag(14, WireType.Varint).uint32(message.maxIntegrations);
        /* uint32 maxAdminUsers = 15; */
        if (message.maxAdminUsers !== 0)
            writer.tag(15, WireType.Varint).uint32(message.maxAdminUsers);
        /* bool users = 16; */
        if (message.users !== false)
            writer.tag(16, WireType.Varint).bool(message.users);
        /* bool basicStatistic = 17; */
        if (message.basicStatistic !== false)
            writer.tag(17, WireType.Varint).bool(message.basicStatistic);
        /* bool advancedStatistic = 18; */
        if (message.advancedStatistic !== false)
            writer.tag(18, WireType.Varint).bool(message.advancedStatistic);
        /* bool downloadReports = 19; */
        if (message.downloadReports !== false)
            writer.tag(19, WireType.Varint).bool(message.downloadReports);
        /* bool supportByTicket = 20; */
        if (message.supportByTicket !== false)
            writer.tag(20, WireType.Varint).bool(message.supportByTicket);
        /* bool dedicatedSupportAgent = 21; */
        if (message.dedicatedSupportAgent !== false)
            writer.tag(21, WireType.Varint).bool(message.dedicatedSupportAgent);
        /* bool dedicatedAccountManager = 22; */
        if (message.dedicatedAccountManager !== false)
            writer.tag(22, WireType.Varint).bool(message.dedicatedAccountManager);
        /* bool basicAuth = 23; */
        if (message.basicAuth !== false)
            writer.tag(23, WireType.Varint).bool(message.basicAuth);
        /* bool ssoEnabled = 24; */
        if (message.ssoEnabled !== false)
            writer.tag(24, WireType.Varint).bool(message.ssoEnabled);
        /* bool modernAuth = 25; */
        if (message.modernAuth !== false)
            writer.tag(25, WireType.Varint).bool(message.modernAuth);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerPermissionsResponse
 */
export const CustomerPermissionsResponse = new CustomerPermissionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncResponse$Type extends MessageType<SyncResponse> {
    constructor() {
        super("SyncResponse", [
            { no: 1, name: "customerSettingsUpdated", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SyncResponse>): SyncResponse {
        const message = { customerSettingsUpdated: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncResponse): SyncResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 customerSettingsUpdated */ 1:
                    message.customerSettingsUpdated = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 customerSettingsUpdated = 1; */
        if (message.customerSettingsUpdated !== 0)
            writer.tag(1, WireType.Varint).int32(message.customerSettingsUpdated);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SyncResponse
 */
export const SyncResponse = new SyncResponse$Type();
/**
 * @generated ServiceType for protobuf service Customerz
 */
export const Customerz = new ServiceType("Customerz", [
    { name: "CreateCustomer", options: { "google.api.http": { post: "/v1/customers", body: "customer" }, "google.api.method_signature": ["customer"] }, I: CreateCustomerRequest, O: Customer },
    { name: "GetCustomer", options: { "google.api.http": { get: "/v1/customers/{customerId}" }, "google.api.method_signature": ["customer_id"] }, I: GetCustomerRequest, O: Customer },
    { name: "UpdateCustomer", options: { "google.api.http": { patch: "/v1/customers/{customerId}", body: "customer" }, "google.api.method_signature": ["customer_id,customer,update_mask"] }, I: UpdateCustomerRequest, O: Customer },
    { name: "UpdateCustomerRecord", options: { "google.api.http": { patch: "/v1/customers/{customerId}/record", body: "customer" }, "google.api.method_signature": ["customer_id,customer,update_mask"] }, I: UpdateCustomerRequest, O: Customer },
    { name: "DeleteCustomer", options: { "google.api.http": { delete: "/v1/customers/{customerId}" }, "google.api.method_signature": ["customer_id"] }, I: DeleteCustomerRequest, O: Empty },
    { name: "FindCustomer", options: { "google.api.http": { post: "/v1/customers:find", body: "*" } }, I: FindCustomerRequest, O: Customer },
    { name: "ContactSalesTeam", options: { "google.api.http": { post: "/v1/customers/sales-contact", body: "*" } }, I: ContactSalesTeamRequest, O: Empty },
    { name: "CreateCustomerSettings", options: { "google.api.http": { post: "/v1/customers/customer-settings", body: "*" } }, I: CreateCustomerSettingsRequest, O: CustomerSettings },
    { name: "GetCustomerSettings", options: { "google.api.http": { get: "/v1/customers/customer-settings/{customerId}" }, "google.api.method_signature": ["customer_id"] }, I: GetCustomerSettingsRequest, O: CustomerSettings },
    { name: "UpdateCustomerSettings", options: { "google.api.http": { post: "/v1/customers/customer-settings/{customerId}", body: "*" }, "google.api.method_signature": ["customer_id"] }, I: UpdateCustomerSettingsRequest, O: CustomerSettings },
    { name: "DeleteCustomerSettings", options: { "google.api.http": { delete: "/v1/customers/customer-settings/{customerId}" }, "google.api.method_signature": ["customer_id"] }, I: DeleteCustomerSettingsRequest, O: Empty },
    { name: "ValidateOpenHours", options: { "google.api.http": { post: "/v1/customers/customer-settings/validation" } }, I: ValidateOpenHoursRequest, O: ValidateOpenHoursResponse },
    { name: "GetRDXLastRelease", options: { "google.api.http": { get: "/v1/customers/rdx/download" } }, I: Empty, O: GetRDXLastReleaseResponse },
    { name: "GetRFXLastRelease", options: { "google.api.http": { get: "/v1/customers/rfx/download" } }, I: Empty, O: GetRFXLastReleaseResponse },
    { name: "SendCustomerFeendback", options: { "google.api.http": { post: "/v1/customers/feedback" } }, I: CustomerFeendbackRequest, O: Empty },
    { name: "CustomerPermissions", options: { "google.api.http": { get: "/v1/customers/permissions" } }, I: CustomerPermissionsRequest, O: CustomerPermissionsResponse },
    { name: "Sync", options: {}, I: Empty, O: SyncResponse }
]);
