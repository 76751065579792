import React, { PropsWithChildren, useMemo, useState } from "react";
import subDays from "date-fns/subDays";
import { useDateRangeContext } from "./DateRangeContext/DateRangeContext";
import { InsightsContext, InsightsLocation } from "./InsightsContext";

export const InsightsContextProvider = ({
  children,
}: PropsWithChildren<{
  children: React.ReactNode;
}>) => {
  const { confirmedRange } = useDateRangeContext();
  const [startTime, setStartTime] = useState(subDays(new Date(), 7));
  const [endTime, setEndTime] = useState(subDays(new Date(), 1));

  const [insightsLocation, setInsightsLocation] = useState<InsightsLocation>({
    locationId: "",
    locationName: "",
    locationType: "",
  });

  useMemo(() => {
    if (confirmedRange === "7" || confirmedRange === "30") {
      setStartTime(subDays(new Date(), +confirmedRange));
      setEndTime(subDays(new Date(), 1));
    }
  }, [confirmedRange]);

  const context: InsightsContext = {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    insightsLocation,
    setInsightsLocation,
  };
  return (
    <InsightsContext.Provider value={context}>
      {children}
    </InsightsContext.Provider>
  );
};
