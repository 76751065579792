import React from "react";
import cs from "classnames";
import { FormGroup, Input, Spinner } from "reactstrap";
import {
  LabelDirectionType,
  LabelWithInformationBox,
} from "../LabelWithInformationBox/LabelWithInformationBox";

import "./Switch.scss";

interface Props {
  name: string;
  title?: string;
  message?: string;
  disabled?: boolean;
  spinner?: boolean;
  align?: "right" | "left";
  value: boolean | undefined;
  children?: React.ReactNode;
  onChange: (value: boolean) => void;
  paddingBottom?: boolean;
  paddingTop?: boolean;
  direction?: LabelDirectionType;
  icon?: JSX.Element;
  className?: string;
}

export const Switch = (props: Props) => {
  const {
    name,
    title,
    message,
    align,
    disabled,
    spinner,
    children,
    value,
    onChange,
    paddingBottom,
    paddingTop,
    direction,
    icon,
    className,
  } = props;

  const classes = cs("Switch", className, {
    "Switch--selected": value,
    "Switch--disabled": disabled,
    "Switch--left-aligned": align === "left",
    "pb-min": paddingBottom,
    "pt-min": paddingTop,
  });

  const groupClasses = cs({
    "gap-min": spinner,
  });

  return (
    <div className={classes}>
      <FormGroup check className={groupClasses}>
        {title && (
          <>
            <LabelWithInformationBox
              check
              for={name}
              title={title}
              message={message}
              direction={direction}
            />
            {icon && icon}
          </>
        )}

        {spinner && <Spinner color="primary" className="ml-auto" size="sm" />}

        <div className="Switch__input">
          <Input
            id={name}
            name={name}
            type="checkbox"
            checked={!!value}
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
          />
          <span
            className={`Switch__toggle ${
              value ? "Switch__toggle--active" : ""
            }`}
          ></span>
        </div>
      </FormGroup>
      {children && <div className="Switch__content">{children}</div>}
    </div>
  );
};
