import React, { PropsWithChildren, useState } from "react";
import cs from "classnames";

import { Button } from "../../../shared/Button/Button";
import { DateRangeButton } from "../DateRangeButton/DateRangeButton";
import { ExportButton } from "../ExportButton/ExportButton";
import { LocationInsightsButton } from "../LocationInsightsButton/LocationInsightsButton";

import "./InsightsHeaderButtons.scss";

export const InsightsHeaderButtons = ({
  pathname,
}: PropsWithChildren<{ pathname: string }>) => {
  const [hamburgerButton, setHamburgerButton] = useState(false);

  const classes = cs("InsightsHeaderButtons__large flex-a-center", {
    "InsightsHeaderButtons__large--active": hamburgerButton,
  });

  return (
    <div className="InsightsHeaderButtons">
      <div className={classes}>
        <DateRangeButton />

        <LocationInsightsButton />

        <ExportButton pathname={pathname} />
      </div>

      <div className="InsightsHeaderButtons__small adjustable-button">
        <Button
          title="Buttons"
          color="primary"
          size="medium"
          onClick={() => setHamburgerButton((prev) => !prev)}
        />
      </div>
    </div>
  );
};
