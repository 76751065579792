import React from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";

import { Event as RoomEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";
import {
  Event as DeskEvent,
  EventCheckInStatus,
} from "../../../../api/grpc/desk/ggevent/ggevent";
import { Icon } from "../../../shared/Icon/Icon";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";
import { ReservationListActions } from "./ReservationListActions";
import { InputDescription } from "../../../shared/InputDescription/InputDescription";

import "./ReservationListCard.scss";

interface Props {
  type: BookingTypes;
  name: string;
  startTime: string;
  endTime: string;
  checkInStatus?: EventCheckInStatus | null;
  workplaceId: string;
  event: DeskEvent | RoomEvent;
  refetchDesk?: () => void;
  refetchRoom?: () => void;
  locationPath: DeskLocationItem[];
  qrCodeRequired?: boolean;
  allowDeleteOption: boolean;
}

export const ReservationListCard = ({
  type,
  name,
  startTime,
  endTime,
  workplaceId,
  checkInStatus,
  event,
  refetchDesk,
  refetchRoom,
  locationPath,
  qrCodeRequired,
  allowDeleteOption,
}: Props) => {
  const { timeZone } = useTimeZoneContext();

  return (
    <div
      className={`ReservationListCard ${
        ["Declined", "Maybe"].includes(event.locations[0].status)
          ? "ReservationListCard__warning"
          : ""
      }`}
    >
      <div className="ReservationListCard__name flex-a-center">
        <Icon icon={type === "desk" ? "desk_icon_18x18" : "rooms-icon"} />
        <div>
          <h6
            className={`pt-1 ${
              event.locations[0].status === "Declined"
                ? "text-decoration-line-through"
                : ""
            }`}
          >
            {name}
          </h6>
          {type === "room" &&
            ["Declined", "Maybe"].includes(event.locations[0].status) && (
              <InputDescription
                title={
                  event.locations[0].status === "Declined"
                    ? "Room declined"
                    : "Awaitng approval"
                }
                titleColor={
                  event.locations[0].status === "Declined"
                    ? "danger"
                    : "warning"
                }
              />
            )}
        </div>
      </div>

      <div className="ReservationListCard__time flex-a-center">
        {type === "desk" && (
          <Icon
            icon={
              checkInStatus === EventCheckInStatus.CHECK_IN_ACTIVATED ||
              checkInStatus === EventCheckInStatus.CHECK_IN_NONE
                ? "approve-icon"
                : "approve-empty"
            }
          />
        )}

        {type === "room" &&
          ["Declined", "Maybe"].includes(event.locations[0].status) && (
            <Icon
              icon={
                event.locations[0].status === "Declined"
                  ? "delete-mark"
                  : "question-mark"
              }
            />
          )}

        <h6
          className={`pt-1 ${
            event.locations[0].status === "Declined"
              ? "text-decoration-line-through"
              : ""
          }`}
        >
          {event.allDay === true
            ? "All day"
            : dateToHoursAndMinutes(new Date(startTime), timeZone) +
              " - " +
              dateToHoursAndMinutes(new Date(endTime), timeZone)}
        </h6>

        <ReservationListActions
          type={type}
          workplaceId={workplaceId}
          event={event}
          refetchDesk={refetchDesk}
          refetchRoom={refetchRoom}
          startTime={startTime}
          endTime={endTime}
          name={name}
          locationPath={locationPath}
          checkInStatus={checkInStatus || null}
          qrCodeRequired={qrCodeRequired}
          allowDeleteOption={allowDeleteOption}
        />
      </div>
    </div>
  );
};
