// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "insights.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message InsightsSyncResponse
 */
export interface InsightsSyncResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message TimeRangeEventsRequest
 */
export interface TimeRangeEventsRequest {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * locationID in format 0x3da
     * if location id is empty then search in all graph
     *
     * @generated from protobuf field: string locationID = 4;
     */
    locationID: string;
    /**
     * @generated from protobuf field: string timeZone = 5;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message TimeRangeEventsResponse
 */
export interface TimeRangeEventsResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * data in format date: number_of_events
     * E.g. 2006-10-21: 130
     *
     * @generated from protobuf field: map<string, int32> data = 4;
     */
    data: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message HourlyDistribution
 */
export interface HourlyDistribution {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: float value = 3;
     */
    value: number;
    /**
     * @generated from protobuf field: string color = 4;
     */
    color: string;
}
/**
 * @generated from protobuf message GeneralStatsResponse
 */
export interface GeneralStatsResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * Percentage distribution of events depending on the duration.
     * Available Intervals:
     * - 0-15 min
     * - 15-30 min
     * - 30-60 min
     * - 60+ min
     *
     * @generated from protobuf field: repeated HourlyDistribution hourlyDistribution = 4;
     */
    hourlyDistribution: HourlyDistribution[];
    /**
     * Total events for selected time span in selected location
     *
     * @generated from protobuf field: int32 totalMeetings = 5;
     */
    totalMeetings: number;
    /**
     * Average value of the events per day in the selected time interval
     *
     * @generated from protobuf field: float avgMeetings = 6;
     */
    avgMeetings: number;
    /**
     * The total duration of all events (in minutes) for the selected time period in the selected location
     *
     * @generated from protobuf field: float totalMinutes = 7;
     */
    totalMinutes: number;
    /**
     * Average value of the event duration in the selected time interval
     *
     * @generated from protobuf field: float avgMinutes = 8;
     */
    avgMinutes: number;
    /**
     * The total number of people that the rooms can accommodate in selected location
     *
     * @generated from protobuf field: int32 totalPeoples = 9;
     */
    totalPeoples: number;
    /**
     * The total number of participants who participated in events for the selected period in the selected location
     *
     * @generated from protobuf field: int32 totalAttendees = 10;
     */
    totalAttendees: number;
    /**
     * Total number of working minutes in the selected time period
     *
     * @generated from protobuf field: float totalWorkingMinutes = 11;
     */
    totalWorkingMinutes: number;
    /**
     * Percentage value of the number of events that were automatically deleted due to the fact
     * that no one confirmed participation (check-in or check-out) relative to total events in selected time span
     *
     * @generated from protobuf field: float noShows = 12;
     */
    noShows: number;
    /**
     * The number of minutes in events that were automatically deleted due to the fact
     * that no one confirmed participation (check-in or check-out)
     *
     * @generated from protobuf field: float noShowsMinutes = 13;
     */
    noShowsMinutes: number;
    /**
     * The total number of events for the period preceding the selected time period in the selected location
     *
     * @generated from protobuf field: int32 prevPeriodTotalMeetings = 14;
     */
    prevPeriodTotalMeetings: number;
    /**
     * Average value of the events per day for the period preceding the selected time period in the selected location
     *
     * @generated from protobuf field: float prevPeriodAVGMeetings = 15;
     */
    prevPeriodAVGMeetings: number;
    /**
     * The total duration of all events (in minutes) for the period preceding the selected time period
     * in the selected location
     *
     * @generated from protobuf field: float prevPeriodTotalMinutes = 16;
     */
    prevPeriodTotalMinutes: number;
    /**
     * Average value of the event duration for the period preceding the selected time period in the selected location
     *
     * @generated from protobuf field: float prevPeriodAVGMinutes = 17;
     */
    prevPeriodAVGMinutes: number;
    /**
     * The number of minutes that were reused for booking after deleting events without confirmation of participation.
     *
     * @generated from protobuf field: float recuperatedMinutes = 18;
     */
    recuperatedMinutes: number;
    /**
     * Estimated amount of money saved by reusing the time of auto-deleted events without confirmation.
     *
     * @generated from protobuf field: float recuperatedMoney = 19;
     */
    recuperatedMoney: number;
    /**
     * Flag to indicate whether previous period is included for comparison.
     * If the requested period is less than 7 days, the previous period will not be included.
     *
     * @generated from protobuf field: bool prevPeriodIncluded = 20;
     */
    prevPeriodIncluded: boolean;
}
/**
 * @generated from protobuf message HeatMapResponse
 */
export interface HeatMapResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * heat map of the distribution of events by hours per day of the week in selected time span
     *
     * @generated from protobuf field: repeated HeatMapResponse.HeatMapRow data = 4;
     */
    data: HeatMapResponse_HeatMapRow[];
}
/**
 * @generated from protobuf message HeatMapResponse.HeatMapRow
 */
export interface HeatMapResponse_HeatMapRow {
    /**
     * day of week
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * data value
     *
     * @generated from protobuf field: repeated HeatMapResponse.HeatMapRow.Cell data = 2;
     */
    data: HeatMapResponse_HeatMapRow_Cell[];
}
/**
 * @generated from protobuf message HeatMapResponse.HeatMapRow.Cell
 */
export interface HeatMapResponse_HeatMapRow_Cell {
    /**
     * hour in format "hh:mm"
     *
     * @generated from protobuf field: string x = 1;
     */
    x: string;
    /**
     * number of events
     *
     * @generated from protobuf field: int32 y = 2;
     */
    y: number;
}
/**
 * @generated from protobuf message GeneralStatsByRoomsResponse
 */
export interface GeneralStatsByRoomsResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * List of rooms with statistics in selected location and time span
     *
     * @generated from protobuf field: repeated GeneralStatsByRoomsResponse.RoomStatsData data = 4;
     */
    data: GeneralStatsByRoomsResponse_RoomStatsData[];
}
/**
 * @generated from protobuf message GeneralStatsByRoomsResponse.RoomStatsData
 */
export interface GeneralStatsByRoomsResponse_RoomStatsData {
    /**
     * room id in format 0x32we2
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * room name
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Total events for selected time span for room
     *
     * @generated from protobuf field: int32 totalMeetings = 3;
     */
    totalMeetings: number;
    /**
     * Average value of the events per day in the selected time interval for room
     *
     * @generated from protobuf field: float avgMeetings = 4;
     */
    avgMeetings: number;
    /**
     * Average value of the event duration in the selected time interval for room
     *
     * @generated from protobuf field: float avgMeetingTime = 5;
     */
    avgMeetingTime: number;
    /**
     * Percentage of room occupancy by participants relative to maximum capacity
     *
     * @generated from protobuf field: float occupancy = 6;
     */
    occupancy: number;
    /**
     * Percentage value of the number of events that were automatically deleted due to the fact
     * that no one confirmed participation (check-in or check-out) relative
     * to total events in selected time span for room
     *
     * @generated from protobuf field: float noShows = 7;
     */
    noShows: number;
}
/**
 * @generated from protobuf message ItemStats
 */
export interface ItemStats {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: float value = 3;
     */
    value: number;
}
/**
 * @generated from protobuf message TotalOccupancyResponse
 */
export interface TotalOccupancyResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * List of occupancy statistic aggregated by day of week
     *
     * @generated from protobuf field: repeated ItemStats dayOfWeekOccupancy = 4;
     */
    dayOfWeekOccupancy: ItemStats[];
    /**
     * Percentage value of total occupancy
     *
     * @generated from protobuf field: float totalOccupancyPercentage = 5;
     */
    totalOccupancyPercentage: number;
    /**
     * List of occupancy statistic aggregated by rooms
     *
     * @generated from protobuf field: repeated ItemStats roomsStats = 6;
     */
    roomsStats: ItemStats[];
    /**
     * List of occupancy statistic aggregated by rooms seats range
     * Available seats range:
     *  1-5
     *  6-10
     *  11-20
     *  20+
     *
     * @generated from protobuf field: repeated ItemStats roomsSeatsStats = 7;
     */
    roomsSeatsStats: ItemStats[];
}
/**
 * @generated from protobuf message SankeyChartResponse
 */
export interface SankeyChartResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * List of available nodes
     *
     * @generated from protobuf field: repeated SankeyChartResponse.Node nodes = 4;
     */
    nodes: SankeyChartResponse_Node[];
    /**
     * List of links
     *
     * @generated from protobuf field: repeated SankeyChartResponse.Link links = 5;
     */
    links: SankeyChartResponse_Link[];
}
/**
 * Single node in Sankey chart
 *
 * @generated from protobuf message SankeyChartResponse.Node
 */
export interface SankeyChartResponse_Node {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * Link describe relationship between two nodes
 *
 * @generated from protobuf message SankeyChartResponse.Link
 */
export interface SankeyChartResponse_Link {
    /**
     * @generated from protobuf field: string source = 1;
     */
    source: string;
    /**
     * @generated from protobuf field: string target = 2;
     */
    target: string;
    /**
     * @generated from protobuf field: int32 value = 3;
     */
    value: number;
}
/**
 * @generated from protobuf message MeetingBehaviorResponse
 */
export interface MeetingBehaviorResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * adHocEvents these are unplanned events.
     * An event is considered unplanned if the room is the organizer and
     * the creation time is not earlier than 30 minutes before the start of the event.
     *
     * @generated from protobuf field: int32 adHocEvents = 4;
     */
    adHocEvents: number;
    /**
     * plannedEvents - a scheduled event is an event that was created earlier than 30 minutes before the start.
     *
     * @generated from protobuf field: int32 plannedEvents = 5;
     */
    plannedEvents: number;
    /**
     * roomsByNoShows return array of objects with no shows event percentage for room
     *
     * @generated from protobuf field: repeated ItemStats roomsByNoShows = 6;
     */
    roomsByNoShows: ItemStats[];
    /**
     * data return array of objects with no shows event percentage for room
     *
     * @generated from protobuf field: repeated ItemStats attendanceByCapacity = 7;
     */
    attendanceByCapacity: ItemStats[];
    /**
     * data return array of objects for realized meetings funnel
     *
     * @generated from protobuf field: repeated ItemStats funnel = 8;
     */
    funnel: ItemStats[];
}
/**
 * @generated from protobuf message MeetingExportResponse
 */
export interface MeetingExportResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * Link to GCP storage for csv file download
     *
     * @generated from protobuf field: string downloadLink = 4;
     */
    downloadLink: string;
    /**
     * @generated from protobuf field: bytes data = 5;
     */
    data: Uint8Array;
    /**
     * @generated from protobuf field: string filename = 6;
     */
    filename: string;
}
/**
 * @generated from protobuf message DeskInsightsOverviewResponse
 */
export interface DeskInsightsOverviewResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * @generated from protobuf field: int32 activeDesks = 4;
     */
    activeDesks: number;
    /**
     * @generated from protobuf field: int32 assignedDesks = 5;
     */
    assignedDesks: number;
    /**
     * @generated from protobuf field: int32 blockedDesks = 6;
     */
    blockedDesks: number;
    /**
     * @generated from protobuf field: int32 unusedDesks = 7;
     */
    unusedDesks: number;
    /**
     * @generated from protobuf field: int32 totalReservations = 8;
     */
    totalReservations: number;
    /**
     * @generated from protobuf field: int32 prevPeriodTotalReservations = 9;
     */
    prevPeriodTotalReservations: number;
    /**
     * @generated from protobuf field: float avgDaily = 10;
     */
    avgDaily: number;
    /**
     * @generated from protobuf field: float prevPeriodAvgDaily = 11;
     */
    prevPeriodAvgDaily: number;
    /**
     * @generated from protobuf field: float avgDuration = 12;
     */
    avgDuration: number;
    /**
     * @generated from protobuf field: float prevPeriodAvgDuration = 13;
     */
    prevPeriodAvgDuration: number;
    /**
     * @generated from protobuf field: float noShowsMinutes = 14;
     */
    noShowsMinutes: number;
    /**
     * @generated from protobuf field: float noShowsPercentage = 15;
     */
    noShowsPercentage: number;
    /**
     * @generated from protobuf field: repeated HourlyDistribution reservationLengthDistribution = 16;
     */
    reservationLengthDistribution: HourlyDistribution[];
    /**
     * @generated from protobuf field: bool isAllDesksIsActive = 17;
     */
    isAllDesksIsActive: boolean;
    /**
     * @generated from protobuf field: bool prevPeriodIncluded = 18;
     */
    prevPeriodIncluded: boolean;
}
/**
 * @generated from protobuf message GeneralStatsByDesksResponse
 */
export interface GeneralStatsByDesksResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * List of rooms with statistics in selected location and time span
     *
     * @generated from protobuf field: repeated GeneralStatsByDesksResponse.DesksStatsData data = 4;
     */
    data: GeneralStatsByDesksResponse_DesksStatsData[];
}
/**
 * @generated from protobuf message GeneralStatsByDesksResponse.DesksStatsData
 */
export interface GeneralStatsByDesksResponse_DesksStatsData {
    /**
     * desk id in format 0x32we2
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * room name
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Total events for selected time span for room
     *
     * @generated from protobuf field: int32 totalMeetings = 3;
     */
    totalMeetings: number;
    /**
     * Average value of the events per day in the selected time interval for room
     *
     * @generated from protobuf field: float avgMeetings = 4;
     */
    avgMeetings: number;
    /**
     * Average value of the event duration in the selected time interval for room
     *
     * @generated from protobuf field: float avgMeetingTime = 5;
     */
    avgMeetingTime: number;
    /**
     * Percentage of room occupancy by participants relative to maximum capacity
     *
     * @generated from protobuf field: float occupancy = 6;
     */
    occupancy: number;
    /**
     * Percentage value of the number of events that were automatically deleted due to the fact
     * that no one confirmed participation (check-in or check-out) relative
     * to total events in selected time span for room
     *
     * @generated from protobuf field: float noShows = 7;
     */
    noShows: number;
}
/**
 * @generated from protobuf message DesksTotalOccupancyResponse
 */
export interface DesksTotalOccupancyResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * List of occupancy statistic aggregated by day of week
     *
     * @generated from protobuf field: repeated ItemStats dayOfWeekOccupancy = 4;
     */
    dayOfWeekOccupancy: ItemStats[];
    /**
     * Percentage value of total occupancy
     *
     * @generated from protobuf field: float totalOccupancyPercentage = 5;
     */
    totalOccupancyPercentage: number;
    /**
     * List of occupancy statistic aggregated by rooms
     *
     * @generated from protobuf field: repeated ItemStats popularDesksStats = 6;
     */
    popularDesksStats: ItemStats[];
}
/**
 * @generated from protobuf message DesksMeetingBehaviorResponse
 */
export interface DesksMeetingBehaviorResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * adHocEvents these are unplanned events.
     * An event is considered unplanned if the room is the organizer and
     * the creation time is not earlier than 30 minutes before the start of the event.
     *
     * @generated from protobuf field: int32 adHocEvents = 4;
     */
    adHocEvents: number;
    /**
     * plannedEvents - a scheduled event is an event that was created earlier than 30 minutes before the start.
     *
     * @generated from protobuf field: int32 plannedEvents = 5;
     */
    plannedEvents: number;
    /**
     * roomsByNoShows return array of objects with no shows event percentage for room
     *
     * @generated from protobuf field: repeated ItemStats desksByNoShows = 6;
     */
    desksByNoShows: ItemStats[];
    /**
     * data return array of objects with no shows event percentage for room
     *
     * @generated from protobuf field: repeated ItemStats funnel = 7;
     */
    funnel: ItemStats[];
}
// @generated message type with reflection information, may provide speed optimized methods
class InsightsSyncResponse$Type extends MessageType<InsightsSyncResponse> {
    constructor() {
        super("InsightsSyncResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InsightsSyncResponse>): InsightsSyncResponse {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsightsSyncResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsightsSyncResponse): InsightsSyncResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InsightsSyncResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InsightsSyncResponse
 */
export const InsightsSyncResponse = new InsightsSyncResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeRangeEventsRequest$Type extends MessageType<TimeRangeEventsRequest> {
    constructor() {
        super("TimeRangeEventsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TimeRangeEventsRequest>): TimeRangeEventsRequest {
        const message = { customerID: "", startDate: "", endDate: "", locationID: "", timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TimeRangeEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeRangeEventsRequest): TimeRangeEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* string locationID */ 4:
                    message.locationID = reader.string();
                    break;
                case /* string timeZone */ 5:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeRangeEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* string locationID = 4; */
        if (message.locationID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.locationID);
        /* string timeZone = 5; */
        if (message.timeZone !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeRangeEventsRequest
 */
export const TimeRangeEventsRequest = new TimeRangeEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeRangeEventsResponse$Type extends MessageType<TimeRangeEventsResponse> {
    constructor() {
        super("TimeRangeEventsResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "data", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<TimeRangeEventsResponse>): TimeRangeEventsResponse {
        const message = { customerID: "", startDate: "", endDate: "", data: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TimeRangeEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeRangeEventsResponse): TimeRangeEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* map<string, int32> data */ 4:
                    this.binaryReadMap4(message.data, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: TimeRangeEventsResponse["data"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof TimeRangeEventsResponse["data"] | undefined, val: TimeRangeEventsResponse["data"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field TimeRangeEventsResponse.data");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: TimeRangeEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* map<string, int32> data = 4; */
        for (let k of Object.keys(message.data))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.data[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeRangeEventsResponse
 */
export const TimeRangeEventsResponse = new TimeRangeEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HourlyDistribution$Type extends MessageType<HourlyDistribution> {
    constructor() {
        super("HourlyDistribution", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HourlyDistribution>): HourlyDistribution {
        const message = { id: "", label: "", value: 0, color: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HourlyDistribution>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HourlyDistribution): HourlyDistribution {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* float value */ 3:
                    message.value = reader.float();
                    break;
                case /* string color */ 4:
                    message.color = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HourlyDistribution, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* float value = 3; */
        if (message.value !== 0)
            writer.tag(3, WireType.Bit32).float(message.value);
        /* string color = 4; */
        if (message.color !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.color);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HourlyDistribution
 */
export const HourlyDistribution = new HourlyDistribution$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneralStatsResponse$Type extends MessageType<GeneralStatsResponse> {
    constructor() {
        super("GeneralStatsResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "hourlyDistribution", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HourlyDistribution },
            { no: 5, name: "totalMeetings", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "avgMeetings", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "totalMinutes", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "avgMinutes", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 9, name: "totalPeoples", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "totalAttendees", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "totalWorkingMinutes", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 12, name: "noShows", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 13, name: "noShowsMinutes", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 14, name: "prevPeriodTotalMeetings", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "prevPeriodAVGMeetings", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 16, name: "prevPeriodTotalMinutes", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 17, name: "prevPeriodAVGMinutes", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 18, name: "recuperatedMinutes", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 19, name: "recuperatedMoney", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 20, name: "prevPeriodIncluded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GeneralStatsResponse>): GeneralStatsResponse {
        const message = { customerID: "", startDate: "", endDate: "", hourlyDistribution: [], totalMeetings: 0, avgMeetings: 0, totalMinutes: 0, avgMinutes: 0, totalPeoples: 0, totalAttendees: 0, totalWorkingMinutes: 0, noShows: 0, noShowsMinutes: 0, prevPeriodTotalMeetings: 0, prevPeriodAVGMeetings: 0, prevPeriodTotalMinutes: 0, prevPeriodAVGMinutes: 0, recuperatedMinutes: 0, recuperatedMoney: 0, prevPeriodIncluded: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneralStatsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneralStatsResponse): GeneralStatsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* repeated HourlyDistribution hourlyDistribution */ 4:
                    message.hourlyDistribution.push(HourlyDistribution.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalMeetings */ 5:
                    message.totalMeetings = reader.int32();
                    break;
                case /* float avgMeetings */ 6:
                    message.avgMeetings = reader.float();
                    break;
                case /* float totalMinutes */ 7:
                    message.totalMinutes = reader.float();
                    break;
                case /* float avgMinutes */ 8:
                    message.avgMinutes = reader.float();
                    break;
                case /* int32 totalPeoples */ 9:
                    message.totalPeoples = reader.int32();
                    break;
                case /* int32 totalAttendees */ 10:
                    message.totalAttendees = reader.int32();
                    break;
                case /* float totalWorkingMinutes */ 11:
                    message.totalWorkingMinutes = reader.float();
                    break;
                case /* float noShows */ 12:
                    message.noShows = reader.float();
                    break;
                case /* float noShowsMinutes */ 13:
                    message.noShowsMinutes = reader.float();
                    break;
                case /* int32 prevPeriodTotalMeetings */ 14:
                    message.prevPeriodTotalMeetings = reader.int32();
                    break;
                case /* float prevPeriodAVGMeetings */ 15:
                    message.prevPeriodAVGMeetings = reader.float();
                    break;
                case /* float prevPeriodTotalMinutes */ 16:
                    message.prevPeriodTotalMinutes = reader.float();
                    break;
                case /* float prevPeriodAVGMinutes */ 17:
                    message.prevPeriodAVGMinutes = reader.float();
                    break;
                case /* float recuperatedMinutes */ 18:
                    message.recuperatedMinutes = reader.float();
                    break;
                case /* float recuperatedMoney */ 19:
                    message.recuperatedMoney = reader.float();
                    break;
                case /* bool prevPeriodIncluded */ 20:
                    message.prevPeriodIncluded = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeneralStatsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* repeated HourlyDistribution hourlyDistribution = 4; */
        for (let i = 0; i < message.hourlyDistribution.length; i++)
            HourlyDistribution.internalBinaryWrite(message.hourlyDistribution[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalMeetings = 5; */
        if (message.totalMeetings !== 0)
            writer.tag(5, WireType.Varint).int32(message.totalMeetings);
        /* float avgMeetings = 6; */
        if (message.avgMeetings !== 0)
            writer.tag(6, WireType.Bit32).float(message.avgMeetings);
        /* float totalMinutes = 7; */
        if (message.totalMinutes !== 0)
            writer.tag(7, WireType.Bit32).float(message.totalMinutes);
        /* float avgMinutes = 8; */
        if (message.avgMinutes !== 0)
            writer.tag(8, WireType.Bit32).float(message.avgMinutes);
        /* int32 totalPeoples = 9; */
        if (message.totalPeoples !== 0)
            writer.tag(9, WireType.Varint).int32(message.totalPeoples);
        /* int32 totalAttendees = 10; */
        if (message.totalAttendees !== 0)
            writer.tag(10, WireType.Varint).int32(message.totalAttendees);
        /* float totalWorkingMinutes = 11; */
        if (message.totalWorkingMinutes !== 0)
            writer.tag(11, WireType.Bit32).float(message.totalWorkingMinutes);
        /* float noShows = 12; */
        if (message.noShows !== 0)
            writer.tag(12, WireType.Bit32).float(message.noShows);
        /* float noShowsMinutes = 13; */
        if (message.noShowsMinutes !== 0)
            writer.tag(13, WireType.Bit32).float(message.noShowsMinutes);
        /* int32 prevPeriodTotalMeetings = 14; */
        if (message.prevPeriodTotalMeetings !== 0)
            writer.tag(14, WireType.Varint).int32(message.prevPeriodTotalMeetings);
        /* float prevPeriodAVGMeetings = 15; */
        if (message.prevPeriodAVGMeetings !== 0)
            writer.tag(15, WireType.Bit32).float(message.prevPeriodAVGMeetings);
        /* float prevPeriodTotalMinutes = 16; */
        if (message.prevPeriodTotalMinutes !== 0)
            writer.tag(16, WireType.Bit32).float(message.prevPeriodTotalMinutes);
        /* float prevPeriodAVGMinutes = 17; */
        if (message.prevPeriodAVGMinutes !== 0)
            writer.tag(17, WireType.Bit32).float(message.prevPeriodAVGMinutes);
        /* float recuperatedMinutes = 18; */
        if (message.recuperatedMinutes !== 0)
            writer.tag(18, WireType.Bit32).float(message.recuperatedMinutes);
        /* float recuperatedMoney = 19; */
        if (message.recuperatedMoney !== 0)
            writer.tag(19, WireType.Bit32).float(message.recuperatedMoney);
        /* bool prevPeriodIncluded = 20; */
        if (message.prevPeriodIncluded !== false)
            writer.tag(20, WireType.Varint).bool(message.prevPeriodIncluded);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeneralStatsResponse
 */
export const GeneralStatsResponse = new GeneralStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeatMapResponse$Type extends MessageType<HeatMapResponse> {
    constructor() {
        super("HeatMapResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeatMapResponse_HeatMapRow }
        ]);
    }
    create(value?: PartialMessage<HeatMapResponse>): HeatMapResponse {
        const message = { customerID: "", startDate: "", endDate: "", data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HeatMapResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HeatMapResponse): HeatMapResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* repeated HeatMapResponse.HeatMapRow data */ 4:
                    message.data.push(HeatMapResponse_HeatMapRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HeatMapResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* repeated HeatMapResponse.HeatMapRow data = 4; */
        for (let i = 0; i < message.data.length; i++)
            HeatMapResponse_HeatMapRow.internalBinaryWrite(message.data[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HeatMapResponse
 */
export const HeatMapResponse = new HeatMapResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeatMapResponse_HeatMapRow$Type extends MessageType<HeatMapResponse_HeatMapRow> {
    constructor() {
        super("HeatMapResponse.HeatMapRow", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HeatMapResponse_HeatMapRow_Cell }
        ]);
    }
    create(value?: PartialMessage<HeatMapResponse_HeatMapRow>): HeatMapResponse_HeatMapRow {
        const message = { id: "", data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HeatMapResponse_HeatMapRow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HeatMapResponse_HeatMapRow): HeatMapResponse_HeatMapRow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated HeatMapResponse.HeatMapRow.Cell data */ 2:
                    message.data.push(HeatMapResponse_HeatMapRow_Cell.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HeatMapResponse_HeatMapRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated HeatMapResponse.HeatMapRow.Cell data = 2; */
        for (let i = 0; i < message.data.length; i++)
            HeatMapResponse_HeatMapRow_Cell.internalBinaryWrite(message.data[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HeatMapResponse.HeatMapRow
 */
export const HeatMapResponse_HeatMapRow = new HeatMapResponse_HeatMapRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HeatMapResponse_HeatMapRow_Cell$Type extends MessageType<HeatMapResponse_HeatMapRow_Cell> {
    constructor() {
        super("HeatMapResponse.HeatMapRow.Cell", [
            { no: 1, name: "x", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "y", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<HeatMapResponse_HeatMapRow_Cell>): HeatMapResponse_HeatMapRow_Cell {
        const message = { x: "", y: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HeatMapResponse_HeatMapRow_Cell>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HeatMapResponse_HeatMapRow_Cell): HeatMapResponse_HeatMapRow_Cell {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string x */ 1:
                    message.x = reader.string();
                    break;
                case /* int32 y */ 2:
                    message.y = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HeatMapResponse_HeatMapRow_Cell, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string x = 1; */
        if (message.x !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.x);
        /* int32 y = 2; */
        if (message.y !== 0)
            writer.tag(2, WireType.Varint).int32(message.y);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HeatMapResponse.HeatMapRow.Cell
 */
export const HeatMapResponse_HeatMapRow_Cell = new HeatMapResponse_HeatMapRow_Cell$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneralStatsByRoomsResponse$Type extends MessageType<GeneralStatsByRoomsResponse> {
    constructor() {
        super("GeneralStatsByRoomsResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GeneralStatsByRoomsResponse_RoomStatsData }
        ]);
    }
    create(value?: PartialMessage<GeneralStatsByRoomsResponse>): GeneralStatsByRoomsResponse {
        const message = { customerID: "", startDate: "", endDate: "", data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneralStatsByRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneralStatsByRoomsResponse): GeneralStatsByRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* repeated GeneralStatsByRoomsResponse.RoomStatsData data */ 4:
                    message.data.push(GeneralStatsByRoomsResponse_RoomStatsData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeneralStatsByRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* repeated GeneralStatsByRoomsResponse.RoomStatsData data = 4; */
        for (let i = 0; i < message.data.length; i++)
            GeneralStatsByRoomsResponse_RoomStatsData.internalBinaryWrite(message.data[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeneralStatsByRoomsResponse
 */
export const GeneralStatsByRoomsResponse = new GeneralStatsByRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneralStatsByRoomsResponse_RoomStatsData$Type extends MessageType<GeneralStatsByRoomsResponse_RoomStatsData> {
    constructor() {
        super("GeneralStatsByRoomsResponse.RoomStatsData", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "totalMeetings", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "avgMeetings", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "avgMeetingTime", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "occupancy", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "noShows", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<GeneralStatsByRoomsResponse_RoomStatsData>): GeneralStatsByRoomsResponse_RoomStatsData {
        const message = { id: "", name: "", totalMeetings: 0, avgMeetings: 0, avgMeetingTime: 0, occupancy: 0, noShows: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneralStatsByRoomsResponse_RoomStatsData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneralStatsByRoomsResponse_RoomStatsData): GeneralStatsByRoomsResponse_RoomStatsData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 totalMeetings */ 3:
                    message.totalMeetings = reader.int32();
                    break;
                case /* float avgMeetings */ 4:
                    message.avgMeetings = reader.float();
                    break;
                case /* float avgMeetingTime */ 5:
                    message.avgMeetingTime = reader.float();
                    break;
                case /* float occupancy */ 6:
                    message.occupancy = reader.float();
                    break;
                case /* float noShows */ 7:
                    message.noShows = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeneralStatsByRoomsResponse_RoomStatsData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 totalMeetings = 3; */
        if (message.totalMeetings !== 0)
            writer.tag(3, WireType.Varint).int32(message.totalMeetings);
        /* float avgMeetings = 4; */
        if (message.avgMeetings !== 0)
            writer.tag(4, WireType.Bit32).float(message.avgMeetings);
        /* float avgMeetingTime = 5; */
        if (message.avgMeetingTime !== 0)
            writer.tag(5, WireType.Bit32).float(message.avgMeetingTime);
        /* float occupancy = 6; */
        if (message.occupancy !== 0)
            writer.tag(6, WireType.Bit32).float(message.occupancy);
        /* float noShows = 7; */
        if (message.noShows !== 0)
            writer.tag(7, WireType.Bit32).float(message.noShows);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeneralStatsByRoomsResponse.RoomStatsData
 */
export const GeneralStatsByRoomsResponse_RoomStatsData = new GeneralStatsByRoomsResponse_RoomStatsData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemStats$Type extends MessageType<ItemStats> {
    constructor() {
        super("ItemStats", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<ItemStats>): ItemStats {
        const message = { id: "", name: "", value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ItemStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemStats): ItemStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* float value */ 3:
                    message.value = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* float value = 3; */
        if (message.value !== 0)
            writer.tag(3, WireType.Bit32).float(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ItemStats
 */
export const ItemStats = new ItemStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TotalOccupancyResponse$Type extends MessageType<TotalOccupancyResponse> {
    constructor() {
        super("TotalOccupancyResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "dayOfWeekOccupancy", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats },
            { no: 5, name: "totalOccupancyPercentage", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "roomsStats", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats },
            { no: 7, name: "roomsSeatsStats", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats }
        ]);
    }
    create(value?: PartialMessage<TotalOccupancyResponse>): TotalOccupancyResponse {
        const message = { customerID: "", startDate: "", endDate: "", dayOfWeekOccupancy: [], totalOccupancyPercentage: 0, roomsStats: [], roomsSeatsStats: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TotalOccupancyResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TotalOccupancyResponse): TotalOccupancyResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* repeated ItemStats dayOfWeekOccupancy */ 4:
                    message.dayOfWeekOccupancy.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* float totalOccupancyPercentage */ 5:
                    message.totalOccupancyPercentage = reader.float();
                    break;
                case /* repeated ItemStats roomsStats */ 6:
                    message.roomsStats.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ItemStats roomsSeatsStats */ 7:
                    message.roomsSeatsStats.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TotalOccupancyResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* repeated ItemStats dayOfWeekOccupancy = 4; */
        for (let i = 0; i < message.dayOfWeekOccupancy.length; i++)
            ItemStats.internalBinaryWrite(message.dayOfWeekOccupancy[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* float totalOccupancyPercentage = 5; */
        if (message.totalOccupancyPercentage !== 0)
            writer.tag(5, WireType.Bit32).float(message.totalOccupancyPercentage);
        /* repeated ItemStats roomsStats = 6; */
        for (let i = 0; i < message.roomsStats.length; i++)
            ItemStats.internalBinaryWrite(message.roomsStats[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated ItemStats roomsSeatsStats = 7; */
        for (let i = 0; i < message.roomsSeatsStats.length; i++)
            ItemStats.internalBinaryWrite(message.roomsSeatsStats[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TotalOccupancyResponse
 */
export const TotalOccupancyResponse = new TotalOccupancyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SankeyChartResponse$Type extends MessageType<SankeyChartResponse> {
    constructor() {
        super("SankeyChartResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "nodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SankeyChartResponse_Node },
            { no: 5, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SankeyChartResponse_Link }
        ]);
    }
    create(value?: PartialMessage<SankeyChartResponse>): SankeyChartResponse {
        const message = { customerID: "", startDate: "", endDate: "", nodes: [], links: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SankeyChartResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SankeyChartResponse): SankeyChartResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* repeated SankeyChartResponse.Node nodes */ 4:
                    message.nodes.push(SankeyChartResponse_Node.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated SankeyChartResponse.Link links */ 5:
                    message.links.push(SankeyChartResponse_Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SankeyChartResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* repeated SankeyChartResponse.Node nodes = 4; */
        for (let i = 0; i < message.nodes.length; i++)
            SankeyChartResponse_Node.internalBinaryWrite(message.nodes[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated SankeyChartResponse.Link links = 5; */
        for (let i = 0; i < message.links.length; i++)
            SankeyChartResponse_Link.internalBinaryWrite(message.links[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SankeyChartResponse
 */
export const SankeyChartResponse = new SankeyChartResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SankeyChartResponse_Node$Type extends MessageType<SankeyChartResponse_Node> {
    constructor() {
        super("SankeyChartResponse.Node", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SankeyChartResponse_Node>): SankeyChartResponse_Node {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SankeyChartResponse_Node>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SankeyChartResponse_Node): SankeyChartResponse_Node {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SankeyChartResponse_Node, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SankeyChartResponse.Node
 */
export const SankeyChartResponse_Node = new SankeyChartResponse_Node$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SankeyChartResponse_Link$Type extends MessageType<SankeyChartResponse_Link> {
    constructor() {
        super("SankeyChartResponse.Link", [
            { no: 1, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "target", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SankeyChartResponse_Link>): SankeyChartResponse_Link {
        const message = { source: "", target: "", value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SankeyChartResponse_Link>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SankeyChartResponse_Link): SankeyChartResponse_Link {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string source */ 1:
                    message.source = reader.string();
                    break;
                case /* string target */ 2:
                    message.target = reader.string();
                    break;
                case /* int32 value */ 3:
                    message.value = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SankeyChartResponse_Link, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string source = 1; */
        if (message.source !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.source);
        /* string target = 2; */
        if (message.target !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.target);
        /* int32 value = 3; */
        if (message.value !== 0)
            writer.tag(3, WireType.Varint).int32(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SankeyChartResponse.Link
 */
export const SankeyChartResponse_Link = new SankeyChartResponse_Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeetingBehaviorResponse$Type extends MessageType<MeetingBehaviorResponse> {
    constructor() {
        super("MeetingBehaviorResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "adHocEvents", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "plannedEvents", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "roomsByNoShows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats },
            { no: 7, name: "attendanceByCapacity", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats },
            { no: 8, name: "funnel", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats }
        ]);
    }
    create(value?: PartialMessage<MeetingBehaviorResponse>): MeetingBehaviorResponse {
        const message = { customerID: "", startDate: "", endDate: "", adHocEvents: 0, plannedEvents: 0, roomsByNoShows: [], attendanceByCapacity: [], funnel: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeetingBehaviorResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeetingBehaviorResponse): MeetingBehaviorResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* int32 adHocEvents */ 4:
                    message.adHocEvents = reader.int32();
                    break;
                case /* int32 plannedEvents */ 5:
                    message.plannedEvents = reader.int32();
                    break;
                case /* repeated ItemStats roomsByNoShows */ 6:
                    message.roomsByNoShows.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ItemStats attendanceByCapacity */ 7:
                    message.attendanceByCapacity.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ItemStats funnel */ 8:
                    message.funnel.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MeetingBehaviorResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* int32 adHocEvents = 4; */
        if (message.adHocEvents !== 0)
            writer.tag(4, WireType.Varint).int32(message.adHocEvents);
        /* int32 plannedEvents = 5; */
        if (message.plannedEvents !== 0)
            writer.tag(5, WireType.Varint).int32(message.plannedEvents);
        /* repeated ItemStats roomsByNoShows = 6; */
        for (let i = 0; i < message.roomsByNoShows.length; i++)
            ItemStats.internalBinaryWrite(message.roomsByNoShows[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated ItemStats attendanceByCapacity = 7; */
        for (let i = 0; i < message.attendanceByCapacity.length; i++)
            ItemStats.internalBinaryWrite(message.attendanceByCapacity[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated ItemStats funnel = 8; */
        for (let i = 0; i < message.funnel.length; i++)
            ItemStats.internalBinaryWrite(message.funnel[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MeetingBehaviorResponse
 */
export const MeetingBehaviorResponse = new MeetingBehaviorResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeetingExportResponse$Type extends MessageType<MeetingExportResponse> {
    constructor() {
        super("MeetingExportResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "downloadLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "filename", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MeetingExportResponse>): MeetingExportResponse {
        const message = { customerID: "", startDate: "", endDate: "", downloadLink: "", data: new Uint8Array(0), filename: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeetingExportResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeetingExportResponse): MeetingExportResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* string downloadLink */ 4:
                    message.downloadLink = reader.string();
                    break;
                case /* bytes data */ 5:
                    message.data = reader.bytes();
                    break;
                case /* string filename */ 6:
                    message.filename = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MeetingExportResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* string downloadLink = 4; */
        if (message.downloadLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.downloadLink);
        /* bytes data = 5; */
        if (message.data.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.data);
        /* string filename = 6; */
        if (message.filename !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.filename);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MeetingExportResponse
 */
export const MeetingExportResponse = new MeetingExportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskInsightsOverviewResponse$Type extends MessageType<DeskInsightsOverviewResponse> {
    constructor() {
        super("DeskInsightsOverviewResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "activeDesks", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "assignedDesks", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "blockedDesks", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "unusedDesks", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "totalReservations", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "prevPeriodTotalReservations", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "avgDaily", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 11, name: "prevPeriodAvgDaily", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 12, name: "avgDuration", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 13, name: "prevPeriodAvgDuration", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 14, name: "noShowsMinutes", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 15, name: "noShowsPercentage", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 16, name: "reservationLengthDistribution", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HourlyDistribution },
            { no: 17, name: "isAllDesksIsActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "prevPeriodIncluded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DeskInsightsOverviewResponse>): DeskInsightsOverviewResponse {
        const message = { customerID: "", startDate: "", endDate: "", activeDesks: 0, assignedDesks: 0, blockedDesks: 0, unusedDesks: 0, totalReservations: 0, prevPeriodTotalReservations: 0, avgDaily: 0, prevPeriodAvgDaily: 0, avgDuration: 0, prevPeriodAvgDuration: 0, noShowsMinutes: 0, noShowsPercentage: 0, reservationLengthDistribution: [], isAllDesksIsActive: false, prevPeriodIncluded: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeskInsightsOverviewResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskInsightsOverviewResponse): DeskInsightsOverviewResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* int32 activeDesks */ 4:
                    message.activeDesks = reader.int32();
                    break;
                case /* int32 assignedDesks */ 5:
                    message.assignedDesks = reader.int32();
                    break;
                case /* int32 blockedDesks */ 6:
                    message.blockedDesks = reader.int32();
                    break;
                case /* int32 unusedDesks */ 7:
                    message.unusedDesks = reader.int32();
                    break;
                case /* int32 totalReservations */ 8:
                    message.totalReservations = reader.int32();
                    break;
                case /* int32 prevPeriodTotalReservations */ 9:
                    message.prevPeriodTotalReservations = reader.int32();
                    break;
                case /* float avgDaily */ 10:
                    message.avgDaily = reader.float();
                    break;
                case /* float prevPeriodAvgDaily */ 11:
                    message.prevPeriodAvgDaily = reader.float();
                    break;
                case /* float avgDuration */ 12:
                    message.avgDuration = reader.float();
                    break;
                case /* float prevPeriodAvgDuration */ 13:
                    message.prevPeriodAvgDuration = reader.float();
                    break;
                case /* float noShowsMinutes */ 14:
                    message.noShowsMinutes = reader.float();
                    break;
                case /* float noShowsPercentage */ 15:
                    message.noShowsPercentage = reader.float();
                    break;
                case /* repeated HourlyDistribution reservationLengthDistribution */ 16:
                    message.reservationLengthDistribution.push(HourlyDistribution.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool isAllDesksIsActive */ 17:
                    message.isAllDesksIsActive = reader.bool();
                    break;
                case /* bool prevPeriodIncluded */ 18:
                    message.prevPeriodIncluded = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskInsightsOverviewResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* int32 activeDesks = 4; */
        if (message.activeDesks !== 0)
            writer.tag(4, WireType.Varint).int32(message.activeDesks);
        /* int32 assignedDesks = 5; */
        if (message.assignedDesks !== 0)
            writer.tag(5, WireType.Varint).int32(message.assignedDesks);
        /* int32 blockedDesks = 6; */
        if (message.blockedDesks !== 0)
            writer.tag(6, WireType.Varint).int32(message.blockedDesks);
        /* int32 unusedDesks = 7; */
        if (message.unusedDesks !== 0)
            writer.tag(7, WireType.Varint).int32(message.unusedDesks);
        /* int32 totalReservations = 8; */
        if (message.totalReservations !== 0)
            writer.tag(8, WireType.Varint).int32(message.totalReservations);
        /* int32 prevPeriodTotalReservations = 9; */
        if (message.prevPeriodTotalReservations !== 0)
            writer.tag(9, WireType.Varint).int32(message.prevPeriodTotalReservations);
        /* float avgDaily = 10; */
        if (message.avgDaily !== 0)
            writer.tag(10, WireType.Bit32).float(message.avgDaily);
        /* float prevPeriodAvgDaily = 11; */
        if (message.prevPeriodAvgDaily !== 0)
            writer.tag(11, WireType.Bit32).float(message.prevPeriodAvgDaily);
        /* float avgDuration = 12; */
        if (message.avgDuration !== 0)
            writer.tag(12, WireType.Bit32).float(message.avgDuration);
        /* float prevPeriodAvgDuration = 13; */
        if (message.prevPeriodAvgDuration !== 0)
            writer.tag(13, WireType.Bit32).float(message.prevPeriodAvgDuration);
        /* float noShowsMinutes = 14; */
        if (message.noShowsMinutes !== 0)
            writer.tag(14, WireType.Bit32).float(message.noShowsMinutes);
        /* float noShowsPercentage = 15; */
        if (message.noShowsPercentage !== 0)
            writer.tag(15, WireType.Bit32).float(message.noShowsPercentage);
        /* repeated HourlyDistribution reservationLengthDistribution = 16; */
        for (let i = 0; i < message.reservationLengthDistribution.length; i++)
            HourlyDistribution.internalBinaryWrite(message.reservationLengthDistribution[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* bool isAllDesksIsActive = 17; */
        if (message.isAllDesksIsActive !== false)
            writer.tag(17, WireType.Varint).bool(message.isAllDesksIsActive);
        /* bool prevPeriodIncluded = 18; */
        if (message.prevPeriodIncluded !== false)
            writer.tag(18, WireType.Varint).bool(message.prevPeriodIncluded);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskInsightsOverviewResponse
 */
export const DeskInsightsOverviewResponse = new DeskInsightsOverviewResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneralStatsByDesksResponse$Type extends MessageType<GeneralStatsByDesksResponse> {
    constructor() {
        super("GeneralStatsByDesksResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GeneralStatsByDesksResponse_DesksStatsData }
        ]);
    }
    create(value?: PartialMessage<GeneralStatsByDesksResponse>): GeneralStatsByDesksResponse {
        const message = { customerID: "", startDate: "", endDate: "", data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneralStatsByDesksResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneralStatsByDesksResponse): GeneralStatsByDesksResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* repeated GeneralStatsByDesksResponse.DesksStatsData data */ 4:
                    message.data.push(GeneralStatsByDesksResponse_DesksStatsData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeneralStatsByDesksResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* repeated GeneralStatsByDesksResponse.DesksStatsData data = 4; */
        for (let i = 0; i < message.data.length; i++)
            GeneralStatsByDesksResponse_DesksStatsData.internalBinaryWrite(message.data[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeneralStatsByDesksResponse
 */
export const GeneralStatsByDesksResponse = new GeneralStatsByDesksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneralStatsByDesksResponse_DesksStatsData$Type extends MessageType<GeneralStatsByDesksResponse_DesksStatsData> {
    constructor() {
        super("GeneralStatsByDesksResponse.DesksStatsData", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "totalMeetings", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "avgMeetings", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "avgMeetingTime", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "occupancy", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "noShows", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<GeneralStatsByDesksResponse_DesksStatsData>): GeneralStatsByDesksResponse_DesksStatsData {
        const message = { id: "", name: "", totalMeetings: 0, avgMeetings: 0, avgMeetingTime: 0, occupancy: 0, noShows: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneralStatsByDesksResponse_DesksStatsData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneralStatsByDesksResponse_DesksStatsData): GeneralStatsByDesksResponse_DesksStatsData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 totalMeetings */ 3:
                    message.totalMeetings = reader.int32();
                    break;
                case /* float avgMeetings */ 4:
                    message.avgMeetings = reader.float();
                    break;
                case /* float avgMeetingTime */ 5:
                    message.avgMeetingTime = reader.float();
                    break;
                case /* float occupancy */ 6:
                    message.occupancy = reader.float();
                    break;
                case /* float noShows */ 7:
                    message.noShows = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeneralStatsByDesksResponse_DesksStatsData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 totalMeetings = 3; */
        if (message.totalMeetings !== 0)
            writer.tag(3, WireType.Varint).int32(message.totalMeetings);
        /* float avgMeetings = 4; */
        if (message.avgMeetings !== 0)
            writer.tag(4, WireType.Bit32).float(message.avgMeetings);
        /* float avgMeetingTime = 5; */
        if (message.avgMeetingTime !== 0)
            writer.tag(5, WireType.Bit32).float(message.avgMeetingTime);
        /* float occupancy = 6; */
        if (message.occupancy !== 0)
            writer.tag(6, WireType.Bit32).float(message.occupancy);
        /* float noShows = 7; */
        if (message.noShows !== 0)
            writer.tag(7, WireType.Bit32).float(message.noShows);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeneralStatsByDesksResponse.DesksStatsData
 */
export const GeneralStatsByDesksResponse_DesksStatsData = new GeneralStatsByDesksResponse_DesksStatsData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DesksTotalOccupancyResponse$Type extends MessageType<DesksTotalOccupancyResponse> {
    constructor() {
        super("DesksTotalOccupancyResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "dayOfWeekOccupancy", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats },
            { no: 5, name: "totalOccupancyPercentage", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "popularDesksStats", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats }
        ]);
    }
    create(value?: PartialMessage<DesksTotalOccupancyResponse>): DesksTotalOccupancyResponse {
        const message = { customerID: "", startDate: "", endDate: "", dayOfWeekOccupancy: [], totalOccupancyPercentage: 0, popularDesksStats: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DesksTotalOccupancyResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DesksTotalOccupancyResponse): DesksTotalOccupancyResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* repeated ItemStats dayOfWeekOccupancy */ 4:
                    message.dayOfWeekOccupancy.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* float totalOccupancyPercentage */ 5:
                    message.totalOccupancyPercentage = reader.float();
                    break;
                case /* repeated ItemStats popularDesksStats */ 6:
                    message.popularDesksStats.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DesksTotalOccupancyResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* repeated ItemStats dayOfWeekOccupancy = 4; */
        for (let i = 0; i < message.dayOfWeekOccupancy.length; i++)
            ItemStats.internalBinaryWrite(message.dayOfWeekOccupancy[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* float totalOccupancyPercentage = 5; */
        if (message.totalOccupancyPercentage !== 0)
            writer.tag(5, WireType.Bit32).float(message.totalOccupancyPercentage);
        /* repeated ItemStats popularDesksStats = 6; */
        for (let i = 0; i < message.popularDesksStats.length; i++)
            ItemStats.internalBinaryWrite(message.popularDesksStats[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DesksTotalOccupancyResponse
 */
export const DesksTotalOccupancyResponse = new DesksTotalOccupancyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DesksMeetingBehaviorResponse$Type extends MessageType<DesksMeetingBehaviorResponse> {
    constructor() {
        super("DesksMeetingBehaviorResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "adHocEvents", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "plannedEvents", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "desksByNoShows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats },
            { no: 7, name: "funnel", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemStats }
        ]);
    }
    create(value?: PartialMessage<DesksMeetingBehaviorResponse>): DesksMeetingBehaviorResponse {
        const message = { customerID: "", startDate: "", endDate: "", adHocEvents: 0, plannedEvents: 0, desksByNoShows: [], funnel: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DesksMeetingBehaviorResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DesksMeetingBehaviorResponse): DesksMeetingBehaviorResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* int32 adHocEvents */ 4:
                    message.adHocEvents = reader.int32();
                    break;
                case /* int32 plannedEvents */ 5:
                    message.plannedEvents = reader.int32();
                    break;
                case /* repeated ItemStats desksByNoShows */ 6:
                    message.desksByNoShows.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ItemStats funnel */ 7:
                    message.funnel.push(ItemStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DesksMeetingBehaviorResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* int32 adHocEvents = 4; */
        if (message.adHocEvents !== 0)
            writer.tag(4, WireType.Varint).int32(message.adHocEvents);
        /* int32 plannedEvents = 5; */
        if (message.plannedEvents !== 0)
            writer.tag(5, WireType.Varint).int32(message.plannedEvents);
        /* repeated ItemStats desksByNoShows = 6; */
        for (let i = 0; i < message.desksByNoShows.length; i++)
            ItemStats.internalBinaryWrite(message.desksByNoShows[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated ItemStats funnel = 7; */
        for (let i = 0; i < message.funnel.length; i++)
            ItemStats.internalBinaryWrite(message.funnel[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DesksMeetingBehaviorResponse
 */
export const DesksMeetingBehaviorResponse = new DesksMeetingBehaviorResponse$Type();
/**
 * @generated ServiceType for protobuf service Insights
 */
export const Insights = new ServiceType("Insights", [
    { name: "Sync", options: {}, I: Empty, O: InsightsSyncResponse },
    { name: "RoomTimeRangeEvents", options: { "google.api.http": { get: "/v1/insights/rooms" } }, I: TimeRangeEventsRequest, O: TimeRangeEventsResponse },
    { name: "RoomGeneralStats", options: { "google.api.http": { get: "/v1/insights/rooms/general" } }, I: TimeRangeEventsRequest, O: GeneralStatsResponse },
    { name: "GeneralStatsByRooms", options: { "google.api.http": { get: "/v1/insights/rooms/rooms-general" } }, I: TimeRangeEventsRequest, O: GeneralStatsByRoomsResponse },
    { name: "RoomEventsHeatMap", options: { "google.api.http": { get: "/v1/insights/rooms/heatmap" } }, I: TimeRangeEventsRequest, O: HeatMapResponse },
    { name: "TotalOccupancy", options: { "google.api.http": { get: "/v1/insights/rooms/occupancy" } }, I: TimeRangeEventsRequest, O: TotalOccupancyResponse },
    { name: "RoomSankeyChart", options: { "google.api.http": { get: "/v1/insights/rooms/sankey" } }, I: TimeRangeEventsRequest, O: SankeyChartResponse },
    { name: "RoomMeetingBehavior", options: { "google.api.http": { get: "/v1/insights/rooms/behavior" } }, I: TimeRangeEventsRequest, O: MeetingBehaviorResponse },
    { name: "RoomInsightsExport", options: { "google.api.http": { get: "/v1/insights/rooms/export" } }, I: TimeRangeEventsRequest, O: MeetingExportResponse },
    { name: "DeskInsightsOverview", options: { "google.api.http": { get: "/v1/insights/desks" } }, I: TimeRangeEventsRequest, O: DeskInsightsOverviewResponse },
    { name: "DeskReservationsTimeRange", options: { "google.api.http": { get: "/v1/insights/desks/time-range" } }, I: TimeRangeEventsRequest, O: TimeRangeEventsResponse },
    { name: "GeneralStatsByDesks", options: { "google.api.http": { get: "/v1/insights/desks/rooms-general" } }, I: TimeRangeEventsRequest, O: GeneralStatsByDesksResponse },
    { name: "DeskEventsHeatMap", options: { "google.api.http": { get: "/v1/insights/desks/heatmap" } }, I: TimeRangeEventsRequest, O: HeatMapResponse },
    { name: "DesksTotalOccupancy", options: { "google.api.http": { get: "/v1/insights/desks/occupancy" } }, I: TimeRangeEventsRequest, O: DesksTotalOccupancyResponse },
    { name: "DesksSankeyChart", options: { "google.api.http": { get: "/v1/insights/desks/sankey" } }, I: TimeRangeEventsRequest, O: SankeyChartResponse },
    { name: "DesksMeetingBehavior", options: { "google.api.http": { get: "/v1/insights/desks/behavior" } }, I: TimeRangeEventsRequest, O: DesksMeetingBehaviorResponse },
    { name: "DesksInsightsExport", options: { "google.api.http": { get: "/v1/insights/desks/export" } }, I: TimeRangeEventsRequest, O: MeetingExportResponse }
]);
