import React, { useMemo } from "react";
import { useListWorkspaceChildren } from "../../lib/useListWorkspaceChildren";

import {
  OnLevelSearchRequestLicenseStatus,
  WorkplaceLocationType,
  WorkplaceResourceType,
} from "../../../../api/grpc/workplaces/workplaces";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { WorkspaceChildrenList } from "../WorkspaceChildrenList/WorkspaceChildrenList";

interface Props {
  id: string;
  debouncedValue: string;
  licenseFilter: OnLevelSearchRequestLicenseStatus;
  location: WorkplaceLocationType[];
  resource: WorkplaceResourceType[];
  tags: string[];
}

export const SearchedWorkplaceChildren = ({
  licenseFilter,
  id,
  debouncedValue,
  location,
  resource,
  tags,
}: Props) => {
  const { loading, error, data, refetch } = useListWorkspaceChildren({
    id: id,
    nameFilter: debouncedValue,
    licenseStatus: licenseFilter,
    locationFilter: location,
    resourceFilter: resource,
    tagsFilter: tags,
  });

  const filteredRows = useMemo(() => {
    if (!!!data?.data?.length) {
      return [];
    }

    if (!!!debouncedValue.length) {
      return data.data;
    }

    //we check item.id !== id because the method returns also the parent
    //in it's child lists:
    return data.data.filter(
      (item) =>
        item.name.toLowerCase().includes(debouncedValue.toLowerCase()) &&
        item.id !== id
    );
  }, [debouncedValue, licenseFilter, location, resource, data?.data]);

  return (
    <HandleRequestState
      state={!!error}
      placeholder={
        <CenteredBox>Error fetching sub-children of workspace</CenteredBox>
      }
    >
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<LoadingBox count={5} minHeight={60} />}
      >
        {!!!filteredRows.length ? (
          <CenteredBox>No workspace found</CenteredBox>
        ) : (
          <div className="WorkplaceList SearchWorkplaces">
            <WorkspaceChildrenList data={filteredRows} refetch={refetch} />
          </div>
        )}
      </HandleLoadingState>
    </HandleRequestState>
  );
};
