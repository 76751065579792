import React, { FC } from "react";
import { useWorkingHoursData } from "../../../Account/helpers/useWorkingHoursData";
import { tooltips } from "../../../../lib/utils/tooltips";

import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { Insights } from "./Insights/Insights";
import { WorkspaceCost } from "../WorkspaceCost/WorkspaceCost";

type Props = {
  workingHoursDataQuery: ReturnType<typeof useWorkingHoursData>;
};

export const InsightsCard: FC<Props> = ({
  workingHoursDataQuery: {
    loading,
    error,
    workingHoursData,
    handleWorkingHours,
  },
}) => {
  return (
    <InfoCard
      title="Insights"
      message={tooltips.organizationSettings.generalSettings.insights}
      direction="left"
    >
      <div className="OrganizationSettigs__cardContent OrganizationSettigs__insignts">
        <Insights
          collectData={workingHoursData?.collectInsigths}
          handleInsightsCollect={handleWorkingHours}
          loading={loading}
          error={error}
        />
        <WorkspaceCost
          cost={Math.round(workingHoursData?.meetingSpaceCostPerHr || 0)}
          currency={workingHoursData?.currency}
          handleCostPerHr={handleWorkingHours}
          loading={loading}
          error={error}
        />
      </div>
    </InfoCard>
  );
};
