import React, { ReactNode, useState } from "react";
import { Subscription } from "../../../../../api/grpc/subscription/subscription";
import { CalculatorProContext } from "./CalculatorProContext";

interface Props {
  children: ReactNode;
}

export const CalculatorProContextProvider = ({ children }: Props) => {
  const [selectedSubscription, setSelectedSubscription] = useState<
    Subscription | undefined
  >(undefined);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const context: CalculatorProContext = {
    subscription: selectedSubscription,
    setSubscription: setSelectedSubscription,
    selectedDate,
    setSelectedDate,
  };

  return (
    <CalculatorProContext.Provider value={context}>
      {children}
    </CalculatorProContext.Provider>
  );
};
