import React from "react";
import { toast } from "react-toastify";
import { Map } from "../../../../api/graphql/floors/floors";
import { useFloorsRequests } from "../../../../api/graphql/floors/useFloorsRequests";
import { Input } from "../../../shared/Input/Input";
import { Switch } from "../../../shared/Switch/Switch";

import "./FloorSettingsPopupForm.scss";

interface Props {
  map: Map;
}

export const FloorSettingsPopupForm = ({ map }: Props) => {
  const { updateMapFloor } = useFloorsRequests();

  const validateInput = (inputValue: number) => {
    if (inputValue < 10 || inputValue > 100) {
      toast.error("Please add a value between 10 and 100");
      return;
    }

    return inputValue;
  };

  const handleUpdateMap = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateMapFloor({
      id: map?.id,
      url: map?.url,
      showGridline: map?.showGridline,
      opacity: Number(validateInput(Number(event.target.value))),
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleUpdateMap(event);
    }
  };

  return (
    <div className="FloorSettingsPopupForm">
      <div className="FloorSettingsPopupForm__group">
        <span>Pass through (%)</span>
        <span className="FloorSettingsPopupForm__group--value">%</span>
        <Input
          type="number"
          pattern="[0-9]*"
          defaultValue={map?.opacity}
          onKeyDown={handleKeyDown}
          onBlur={(e) => {
            if (+e.target.value === map?.opacity) {
              return;
            }

            handleUpdateMap(e);
          }}
        />
      </div>

      <div className="FloorSettingsPopupForm__group">
        <span>Snap elements to grid</span>
        <Switch
          value={map?.showGridline}
          name="showGirdline"
          onChange={(value) => {
            updateMapFloor({
              id: map?.id,
              url: map?.url,
              showGridline: value,
              opacity: map?.opacity,
            });
          }}
        />
      </div>
    </div>
  );
};
