import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRouter } from "../../../lib/hooks/useRouter";

import { SubRouteProps } from "../../../lib/routes/routes";
import { PageHeader } from "../../shared/PageHeader/PageHeader";
import { ResourceNavigation } from "../../shared/ResourceNavigation/ResourceNavigation";
import { InsightsHeaderButtons } from "../shared/InsightsHeaderButtons/InsightsHeaderButtons";
import { RoomValueInsights } from "./Value/RoomValueInsights";
import { RoomUtilization } from "./Utilization/RoomUtilization";
import { RoomRelease } from "./RoomRelease/RoomRelease";
import { InsightsContextProvider } from "../Context/InsightsContextProvider";
import { DateRangeContextProvider } from "../Context/DateRangeContext/DateRangeContextProvider";
import { InsightsForInfo } from "../shared/InsightsForInfo/InsightsForInfo";
import { tooltips } from "../../../lib/utils/tooltips";
import { RoomInsightsContextProvider } from "../Context/Room/Overview/RoomInsightsContextProvider";
import { UtilizationContextProvider } from "../Context/Room/Utilization/UtilizationContextProvider";
import { TimeZoneLabel } from "../shared/TimeZoneLabel/TimeZoneLabel";

import "../shared/WorkplaceInsights.scss";
import { BehaviourContextProvider } from "../Context/Room/Behaviour/BehaviourContextProvider";

export const RoomInsights = () => {
  const { match, pathname } = useRouter();

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Overview",
      to: match.url,
      id: "room-value-insights-page",
      component: RoomValueInsights,
    },
    {
      exact: true,
      label: "Utilization",
      to: `${match.url}/utilization`,
      id: "room-utilization-page",
      component: RoomUtilization,
    },
    {
      exact: true,
      label: "Behaviour",
      to: `${match.url}/behaviour`,
      id: "room-efficiency-page",
      component: RoomRelease,
    },
  ];

  return (
    <DateRangeContextProvider>
      <InsightsContextProvider>
        <RoomInsightsContextProvider>
          <UtilizationContextProvider>
            <BehaviourContextProvider>
              <div className="WorkplaceInsights default-page">
                <div className="PeopleHeader position-relative">
                  <PageHeader
                    title="Room Insights Dashboard"
                    hideBorder
                    message={tooltips.insights.rooms.heading}
                  >
                    <TimeZoneLabel />
                  </PageHeader>

                  <ResourceNavigation routes={routes}>
                    <InsightsHeaderButtons pathname={pathname} />
                  </ResourceNavigation>
                </div>

                <div className="position-relative">
                  <InsightsForInfo />

                  <Switch>
                    {routes.map((item) => (
                      <Route
                        key={item.id}
                        path={item.to}
                        component={item.component}
                        exact={item.exact ? true : undefined}
                      />
                    ))}
                    <Redirect to={routes[0].to} />
                  </Switch>
                </div>
              </div>
            </BehaviourContextProvider>
          </UtilizationContextProvider>
        </RoomInsightsContextProvider>
      </InsightsContextProvider>
    </DateRangeContextProvider>
  );
};
