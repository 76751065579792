import React from "react";
import { Navbar } from "reactstrap";
import { NavLink } from "react-router-dom";

import { useSidebarContext } from "../../lib/context/Sidebar/SidebarContext";
import { HeaderNavigation } from "./HeaderNavigation/HeaderNavigation";
import { PersonalCalendarValidation } from "../Calendars/PersonalCalendarValidation/PersonalCalendarValidation";
// import { Beta } from "../sherared/Beta/Beta";

import logo from "../../assets/images/logo.png";
import { useCalendarContext } from "../../lib/context/Calendar/CalendarContext";

import "./Header.scss";
import { IntegrationBanner } from "../Calendars/shared/IntegrationBanner/IntegrationBanner";
import { getCalendarTypeSlug } from "../Calendars/lib/helpers";
import { GGCalendarProviderStatus } from "../../api/grpc/calendarprovider/calendarprovider";
import { AdminIntegrationBanner } from "../Calendars/AdminIntegrationBanner/AdminIntegrationBanner";

export const Header = () => {
  const { toggleMainMenu, closeSidebar } = useSidebarContext();
  const { providers } = useCalendarContext();

  const handleHamburgerToggle = () => {
    toggleMainMenu();
  };

  return (
    <div
      onClick={() => closeSidebar()}
      className="Header w-100 border-bottom p-0 pb-0"
    >
      {providers
        .filter(
          (integration) =>
            integration.status === GGCalendarProviderStatus.FAILED
        )
        .map((calendar) => (
          <AdminIntegrationBanner calendar={calendar} key={calendar.iD} />
        ))}
      <PersonalCalendarValidation />
      <Navbar expand="md" className="Header__navbar">
        <span
          className="Header__hamburger"
          onClick={handleHamburgerToggle}
        ></span>
        <div className="Header__logo">
          <NavLink to="/">
            <img src={logo} alt="GOGET" />
            {/* <Beta /> */}
          </NavLink>
        </div>
        <HeaderNavigation />
      </Navbar>
    </div>
  );
};
