import React, { PropsWithChildren } from "react";
import { SearchDesk } from "../../../../api/grpc/workplaces/workplaces";
import { tooltips } from "../../../../lib/utils/tooltips";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { Icon } from "../../../shared/Icon/Icon";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";

export const AssignedDesk = ({
  desks,
  error,
}: PropsWithChildren<{
  desks?: SearchDesk[];
  error?: Error;
}>) => {
  return (
    <>
      {/* this means there is no error and no assigned desks, so we don't show
      anything */}
      {!!!error && !!!desks?.length ? (
        <></>
      ) : (
        <>
          <div className="flex-a-center">
            <div className="Reservations__day">Assigned</div>

            <InformationBox
              message={tooltips.bookings.desks.assignedDesks}
              className="mt-4 pl-2"
            />
          </div>
          <HandleRequestState
            state={!!error}
            placeholder={
              <div>{error?.message || "Error fetching assigned desks"}</div>
            }
          >
            {desks?.map((desk) => (
              <div key={desk.id} className="ReservationListCard">
                <div className="flex-a-center ReservationListCard__name">
                  <Icon icon="desk_icon_18x18" />
                  <h6>{desk.name}</h6>
                </div>

                <div className="flex-a-center ReservationListCard__time">
                  <Icon icon="pinned-icon" />

                  <span>Every day</span>
                </div>
              </div>
            ))}
          </HandleRequestState>
        </>
      )}
    </>
  );
};
