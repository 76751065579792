import { RouteProps } from "react-router-dom";
import { RpcInterceptor } from "@protobuf-ts/runtime-rpc";

import { Home } from "../../views/Home/Home";
import { Users } from "../../views/Users/Users";
import { Logout } from "../../views/Logout/Logout";
import { Manage } from "../../views/Manage/Manage";
import { Teams } from "../../views/Users/Teams/Teams";
import { Contact } from "../../views/Contact/Contact";
import { Account } from "../../views/Account/Account";
import { People } from "../../views/Users/People/People";
import { Insights } from "../../views/Insights/Insights";
import { Settings } from "../../views/Settings/Settings";
import { Register } from "../../views/Register/Register";
import { Devices } from "../../views/Manage/Devices/Devices";
import { SetPassword } from "../../views/SetPassword/SetPassword";
import { Calendars } from "../../views/Manage/Calendars/Calendars";
import { Workplaces } from "../../views/Manage/Workplaces/Workplaces";
import { VerifyAccount } from "../../views/VerifyAccount/VerifyAccount";
import { ResetPassword } from "../../views/ResetPassword/ResetPassword";
import { Billings } from "../../views/Billings/Billings";
import { SetContactPassword } from "../../views/SetContactPassword/SetContactPassword";
// import { SettingsProfiles } from "../../views/Settings/SettingsProfiles/SettingsProfiles";
import { RNMapView } from "../../api/external-api/react-native-map-view/RNMapView";
import { SignupMethod } from "../../views/CompleteSignup/SignupMethod";

import { UserRoles } from "../UserRoute";
import { IconDefinition } from "../../components/shared/Icon/Icon.generated";
import { FloorsMap } from "../../views/Manage/Floor/FloorsMap";
import { Bookings } from "../../views/Bookings/Bookings";
import { BookingsNested } from "../../views/Bookings/BookingsNested";
// import { Reservations } from "../../components/Bookings/Reservations/Reservations";
import { BookingsSettings } from "../../components/Bookings/BookingsSettings/BookingsSettings";
import { BookingsMainRoute } from "../../components/Bookings/BookingsMainRoute";
import { DownloadApk } from "../../views/DownloadApk/DownloadApk";
import { RoomInsights } from "../../components/Insights/Room/RoomInsights";
import { DeskInsights } from "../../components/Insights/Desk/DeskInsights";
import { ReservationsRoute } from "../../components/Bookings/Reservations/ReservationsRoute";
import { ConfirmEmailChange } from "../../views/ConfirmEmailChange/ConfirmEmailChange";
import { ConfirmAccountRegistration } from "../../views/CompleteSignup/ConfirmAccountRegistration";
import { Organization } from "../../views/Organization/Organization";
import { Directory } from "../../views/Users/DirectorySync/Directory";
import { LoginPage } from "../../views/Login/LoginPage";
import { RecoverMFA } from "../../views/RecoverMFA/RecoverMFA";
import { SettingsProfilesView } from "../../views/Settings/SettingsProfiles/SettingsProfilesView";

export type TargetValue = "_blank" | "_self";

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}

export const DEVICES_ROOT_PATH = "/manage/devices";
export const CALENDARS_ROOT_PATH = "/manage/calendars";
export const DIRECTORY_ROOT_PATH = "/users/sync";
export const BOOKINGS_ROOT_PATH = "/bookings/reserve";
export const WORKPLACES_ROOT_PATH = "/manage/organization";
export const FLOORPLAN_ROOT_PATH = "/manage/map";
export const BILLING_ROOT_PATH = "/settings/billing/subscriptions";
export const RESERVATIONS_FLOORPLAN_ROOT_PATH = "/bookings/map";
export const RESERVATIONS_MAP_ROOT_PATH = "/bookings/reserve/map";
export const TEMPLATE_ROOT_PATH = "/settings/settings-profiles";

export interface MainRouteProps extends RouteProps {
  id: string;
  to: string;
  label: string;
  type: RouteType;
  role?: UserRoles;
  disabled?: boolean;
  target?: TargetValue;
  icon?: IconDefinition;
  showInSidebar?: boolean;
  menu?: MainRouteProps[];
  onClick?: (customerId: string, interceptor: RpcInterceptor) => void;
}

export interface SubRouteProps extends RouteProps {
  to: string;
  id: string;
  label: string;
}

export const routes: MainRouteProps[] = [
  // PUBLIC ROUTES
  {
    exact: true,
    to: "/contact",
    label: "Contact",
    component: Contact,
    id: "contact-page",
    type: RouteType.PUBLIC,
  },
  {
    exact: true,
    to: "/verify-account",
    label: "Verify Account",
    id: "verify-account-page",
    component: VerifyAccount,
    type: RouteType.PUBLIC,
  },
  {
    exact: true,
    to: "/reset-mfa",
    label: "Recover Account",
    id: "recover-account",
    component: RecoverMFA,
    type: RouteType.PUBLIC,
  },
  {
    exact: true,
    to: "/email-change",
    label: "Confirm Email Change",
    id: "email-change-page",
    component: ConfirmEmailChange,
    type: RouteType.PUBLIC,
  },
  {
    exact: true,
    to: "/set-password",
    label: "Set Password",
    component: SetPassword,
    id: "set-password-page",
    type: RouteType.PUBLIC,
  },
  {
    exact: true,
    type: RouteType.PUBLIC,
    to: "/set-contact-password",
    component: SetContactPassword,
    label: "Set Contact Password",
    id: "set-contact-password-page",
  },
  {
    exact: true,
    to: "/complete-signup",
    type: RouteType.PUBLIC,
    label: "Complete Signup",
    component: SignupMethod,
    id: "complete-signup-page",
  },
  {
    exact: true,
    to: "/external/react-native/map-view",
    label: "Map View",
    id: "react-native-map-view",
    component: RNMapView,
    type: RouteType.RESTRICTED,
  },
  {
    exact: true,
    to: "/download/:id",
    label: "DownloadApk",
    component: DownloadApk,
    showInSidebar: false,
    id: "download-apk",
    type: RouteType.PUBLIC,
  },

  //RESTRICTED ROUTES
  {
    exact: true,
    to: "/login",
    label: "Login",
    component: LoginPage,
    id: "login-page",
    type: RouteType.RESTRICTED,
  },
  {
    exact: true,
    to: "/logout",
    label: "Logout",
    component: Logout,
    id: "logout-page",
    type: RouteType.RESTRICTED,
  },
  {
    exact: true,
    to: "/register",
    label: "Register",
    component: Register,
    id: "register-page",
    type: RouteType.RESTRICTED,
  },
  {
    exact: true,
    to: "/reset-password",
    label: "Reset Password",
    id: "reset-password-page",
    component: ResetPassword,
    type: RouteType.RESTRICTED,
  },
  {
    exact: true,
    to: "/account-confirmed",
    label: "Confirmed",
    id: "confirmed-account",
    component: ConfirmAccountRegistration,
    type: RouteType.RESTRICTED,
  },

  // PRIVATE ROUTES
  {
    to: "/",
    exact: true,
    component: Home,
    label: "Dashboards",
    showInSidebar: true,
    icon: "dashboard-icon",
    type: RouteType.PRIVATE,
    id: "dashboards-sidebar",
  },
  {
    exact: false,
    to: "/manage",
    label: "Manage",
    component: Manage,
    icon: "manage-icon",
    showInSidebar: true,
    id: "manage-sidebar",
    type: RouteType.PRIVATE,
    menu: [
      {
        exact: false,
        label: "Workspaces",
        component: Workplaces,
        to: "/organization",
        type: RouteType.PRIVATE,
        id: "workplaces-submenu",
        icon: "company_icon_18x18",
      },
      {
        exact: false,
        label: "Calendars",
        to: "/calendars",
        component: Calendars,
        type: RouteType.PRIVATE,
        id: "calendars-submenu",
        icon: "calendar_icon_18x18",
      },
      {
        exact: true,
        label: "Devices",
        to: "/devices",
        component: Devices,
        id: "devices-submenu",
        type: RouteType.PRIVATE,
        icon: "device_icon_18x18",
      },
      {
        exact: false,
        label: "Maps",
        to: "/map",
        component: FloorsMap,
        id: "floors-submenu",
        type: RouteType.PRIVATE,
        icon: "floor-map-2",
        // disabled: true,
      },
    ],
  },
  {
    exact: false,
    to: "/insights",
    label: "Insights",
    component: Insights,
    showInSidebar: true,
    icon: "insights_icon",
    id: "insights-sidebar",
    type: RouteType.PRIVATE,
    menu: [
      {
        exact: false,
        label: "Room Insights",
        component: RoomInsights,
        to: "/room",
        type: RouteType.PRIVATE,
        id: "room-insights",
        icon: "rooms-icon",
      },
      {
        exact: false,
        label: "Desk Insights",
        component: DeskInsights,
        to: "/desk",
        type: RouteType.PRIVATE,
        id: "desk-insights",
        icon: "desk_icon_18x18",
      },
    ],
  },
  {
    exact: false,
    to: "/users",
    label: "Users",
    component: Users,
    icon: "people_icon",
    showInSidebar: true,
    id: "users-sidebar",
    type: RouteType.PRIVATE,
    // disabled: true,
    menu: [
      {
        exact: false,
        label: "People",
        component: People,
        to: "/people",
        type: RouteType.PRIVATE,
        id: "people-submenu",
        icon: "people_icon_18x18",
      },
      // {
      //   exact: false,
      //   label: "Teams",
      //   to: "/teams",
      //   component: Teams,
      //   type: RouteType.PRIVATE,
      //   id: "teams-submenu",
      //   icon: "teams_icon_18x18",
      //   disabled: true,
      // },
      {
        exact: false,
        label: "Directory Sync",
        to: "/sync",
        component: Directory,
        type: RouteType.PRIVATE,
        id: "sync-submenu",
        icon: "directory-icon",
      },
    ],
  },
  // {
  //   exact: true,
  //   disabled: true,
  //   to: "/monitoring",
  //   label: "Monitoring",
  //   showInSidebar: true,
  //   component: Monitoring,
  //   icon: "monitoring-icon",
  //   id: "monitoring-sidebar",
  //   type: RouteType.PRIVATE,
  // },
  {
    exact: false,
    role: "contact",
    to: "/bookings",
    label: "Reserve",
    showInSidebar: true,
    component: BookingsNested,
    icon: "bookings-icon",
    id: "bookings-sidebar",
    type: RouteType.PRIVATE,
    menu: [
      {
        exact: false,
        to: "/reserve",
        label: "Find & Reserve",
        showInSidebar: true,
        component: Bookings,
        icon: "search-main",
        id: "bookings-sidebar",
        type: RouteType.PRIVATE,
      },
      {
        exact: false,
        to: "/reservations",
        label: "My Reservations",
        showInSidebar: true,
        component: ReservationsRoute,
        icon: "calendar_icon_18x18",
        id: "reservations",
        type: RouteType.PRIVATE,
      },
      {
        exact: false,
        to: "/reserve/map",
        label: "Map View",
        showInSidebar: true,
        component: BookingsMainRoute,
        icon: "floor-map-2",
        id: "bookings-map",
        type: RouteType.PRIVATE,
      },
      {
        exact: false,
        to: "/settings",
        label: "Settings",
        showInSidebar: true,
        component: BookingsSettings,
        icon: "settings-main",
        id: "bookings-settings",
        type: RouteType.PRIVATE,
      },
    ],
  },
  {
    exact: false,
    to: "/account",
    label: "Account",
    component: Account,
    id: "account-route",
    showInSidebar: false,
    type: RouteType.PRIVATE,
  },
  {
    exact: false,
    role: "admin",
    to: "/settings",
    showInSidebar: true,
    component: Settings,
    id: "settings-sidebar",
    type: RouteType.PRIVATE,
    icon: "organization-icon",
    label: "Organization Settings",
    menu: [
      {
        exact: false,
        id: "organization-general",
        to: "/general",
        label: "General",
        type: RouteType.PRIVATE,
        component: Organization,
        icon: "settings-main",
      },
      {
        exact: false,
        id: "billing",
        to: "/billing",
        label: "Billing",
        type: RouteType.PRIVATE,
        component: Billings,
        icon: "billing_icon_18x18",
      },
      {
        exact: false,
        id: "settings-profiles",
        to: "/settings-profiles",
        type: RouteType.PRIVATE,
        label: "Settings Templates",
        component: SettingsProfilesView,
        icon: "settings_profiles_icon_18x18",
      },
    ],
  },
];
