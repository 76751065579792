import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";

import mobileApp from "../../../../assets/images/MobileApp.png";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import { tooltips } from "../../../../lib/utils/tooltips";

interface Props {
  toggleModal: () => void;
  isOpen: boolean;
}

export const CheckInRequired = ({ toggleModal, isOpen }: Props) => {
  return (
    <Modal isOpen={isOpen} onToggle={toggleModal} className="BookingsModal">
      <ModalHeader>
        <span onClick={toggleModal} className="btn-close">
          <Icon icon="close-icon" />
        </span>
      </ModalHeader>

      <h5>QR SCAN REQUIRED</h5>

      <Icon icon="qr-code-example" className="BookingsModal__mainIcon" />

      <span className="text-center">
        Head over to the GOGET mobile app to complete check-in
      </span>

      <img
        src={mobileApp}
        alt="Mobile app"
        className="PromotionCard__mobileApp"
      />

      <ModalFooter>
        <Button size="small" color="primary" title="Ok" onClick={toggleModal} />

        <div className="flex-a-center">
          <InformationBox message={tooltips.bookings.manuallyScanDesk} />
          <span className="label__main pl-min BookingsModal__required">
            Scan desk's QR code
          </span>
        </div>
      </ModalFooter>
    </Modal>
  );
};
