import React, { useMemo } from "react";
import { Form, FormGroup } from "reactstrap";

import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useDefineLicense } from "../../../../lib/hooks/useDefineLicense";
import { useLicensesContext } from "../../../../lib/context/Customer/CustomerContext";
import { Input } from "../../Input/Input";
import { TagInput } from "../../TagInput/TagInput";
import {
  LabelDirectionType,
  LabelWithInformationBox,
} from "../../LabelWithInformationBox/LabelWithInformationBox";

import { tooltips } from "../../../../lib/utils/tooltips";
import { WayFinderFormik } from "./lib/useWayFinderFormik";
import { Switch } from "../../Switch/Switch";
import { Button } from "../../Button/Button";
import { WayFinderFormWorkspaces } from "./shared/WayFinderFormWorkspaces/WayFinderFormWorkspaces";
import { WayFinderWorkspacePicker } from "../../../WayFinder/WayFinderPicker/WayFinderWorkspacePicker";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { EntityType } from "../../../../api/grpc/subscription/subscription";

import "./WayFinderFrom.scss";

interface Props {
  id?: string;
  informationBoxDirection?: LabelDirectionType;
  formik: WayFinderFormik;
  isEdit?: boolean;
}

export const WayFinderForm = ({
  formik,
  informationBoxDirection,
  isEdit,
  id,
}: Props) => {
  const { toggleModal, isOpen } = useToggleModal();
  const { disabled, spinner, handleLicenseUpdate } = useLicensesContext();
  const { defineLicenseNumber, refetch } = useDefineLicense(
    EntityType.WAYFINDER,
    isEdit
  );
  const handleWorkspace = (
    item: WorkplaceFields,
    type: "desks" | "rooms",
    methodType: "add" | "delete"
  ) => {
    const currentValues = formik.values[type];
    const itemExists = currentValues.some(
      (formikItem) => formikItem.id === item.id
    );

    if (methodType === "add" && !itemExists) {
      return formik.setFieldValue(type, [...currentValues, item]);
    }

    const updatedValues = currentValues.filter(
      (workplaceItem) => workplaceItem.id !== item.id
    );
    return formik.setFieldValue(type, updatedValues);
  };

  const clearWorkspaces = () => {
    formik.setFieldValue("rooms", []);
    formik.setFieldValue("desks", []);
    return;
  };

  const emptyWorkspaces = useMemo(() => {
    return formik.values.rooms.length <= 0 && formik.values.desks.length <= 0;
  }, [formik.values.rooms, formik.values.desks]);

  return (
    <Form className="WayFinderForm">
      {isEdit && isOpen && (
        <>
          <WayFinderWorkspacePicker
            closePicker={toggleModal}
            handleWorkspace={handleWorkspace}
            formik={formik}
            clearWorkspaces={clearWorkspaces}
            maxNumberOfDesks={formik.values.desks.length >= 42}
            maxNumberOfRooms={formik.values.rooms.length >= 24}
          />
          <div onClick={toggleModal} className="background__shadow"></div>
        </>
      )}

      <FormGroup>
        <LabelWithInformationBox
          for="name"
          title="Name"
          message={tooltips.workplaces.name}
          direction={informationBoxDirection}
        />
        <Input
          id="name"
          name="name"
          type="text"
          value={formik.values.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isTouched={formik.touched.name}
          errorMessage={formik.errors.name}
          invalid={!!formik.errors.name && formik.touched.name}
        />
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          for="description"
          title="Description"
          message={tooltips.workplaces.description}
          direction={informationBoxDirection}
        />
        <Input
          type="text"
          id="description"
          name="description"
          onBlur={formik.handleBlur}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <TagInput
        value={formik.values.tags || []}
        direction={informationBoxDirection}
        onChange={(tags) => {
          formik.setFieldValue("tags", tags);
          formik.setFieldTouched("tags");
        }}
      />

      {isEdit && (
        <>
          <LabelWithInformationBox
            for="description"
            title="Rooms & Desks"
            message={tooltips.wayfinders.settings.items}
            direction={informationBoxDirection}
            className="pt-1"
          />
          <div className="Switch__border WayFinderFrom__workspace--picker">
            <label className="label__main pb-3">Add from workspaces</label>
            <Button
              icon="workplace-picker"
              title="Open workspace picker"
              color={emptyWorkspaces ? "outline-primary" : "primary"}
              size="small"
              onClick={toggleModal}
              className="WayFinderFrom__workspace--opener mb-2"
            />

            <WayFinderFormWorkspaces
              type="room"
              data={formik.values.rooms}
              handleWorkspace={handleWorkspace}
            />
            <WayFinderFormWorkspaces
              type="desk"
              data={formik.values.desks}
              handleWorkspace={handleWorkspace}
            />
          </div>
        </>
      )}

      <Switch
        name="licensed"
        title="Assign license"
        value={formik.values.licensed}
        message={tooltips.wayfinders.settings.license}
        direction={informationBoxDirection}
        disabled={disabled}
        spinner={spinner}
        onChange={async (value) => {
          if (!isEdit) {
            formik.setFieldValue("licensed", value);
            formik.setFieldTouched("licensed", true);
            return;
          }

          if (!id) {
            return;
          }

          await handleLicenseUpdate({
            id,
            value,
            type: EntityType.WAYFINDER,
          });

          if (refetch) {
            return refetch();
          }
        }}
        className="mt-4 mb-2"
      />

      {defineLicenseNumber && defineLicenseNumber()}
    </Form>
  );
};
