// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "account.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AccountService } from "./account";
import type { DisableManualMFAResponse } from "./account";
import type { DisableManualMFARequest } from "./account";
import type { DisableMFAResponse } from "./account";
import type { DisableMFARequest } from "./account";
import type { SendRecoveryEmailResponse } from "./account";
import type { SendRecoveryEmailRequest } from "./account";
import type { RecoveryEmailResponse } from "./account";
import type { RecoveryEmailRequest } from "./account";
import type { VerifyEmailChangeRequest } from "./account";
import type { RequestEmailChangeRequest } from "./account";
import type { GetWorkingScheduleRequest } from "./account";
import type { WorkingScheduleResponse } from "./account";
import type { SetWorkingScheduleRequest } from "./account";
import type { PushNotificationsUnsubscribeRequest } from "./account";
import type { PushNotificationsSubscribeResponse } from "./account";
import type { PushNotificationsSubscribeRequest } from "./account";
import type { VerifyMobileAPPDeviceResponse } from "./account";
import type { VerifyMobileAPPDeviceRequest } from "./account";
import type { VerifyMobileAPPAccountResponse } from "./account";
import type { VerifyMobileAPPAccountRequest } from "./account";
import type { ContactPasswordResetRequest } from "./account";
import type { VerifyEmailRequest } from "./account";
import type { SendActivationEmailResponse } from "./account";
import type { SendActivationEmailRequest } from "./account";
import type { DeleteAccountRequest } from "./account";
import type { DisableAccountRequest } from "./account";
import type { EnableAccountRequest } from "./account";
import type { ResetPasswordRequest } from "./account";
import type { UpdateAccountRequest } from "./account";
import type { GetAccountRequest } from "./account";
import type { CreateAccountRequest } from "./account";
import type { Empty } from "./google/protobuf/empty";
import type { DeletePictureRequest } from "./account";
import type { PictureResponse } from "./account";
import type { PictureRequest } from "./account";
import type { UpdateTimeZoneRequest } from "./account";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Account } from "./account";
import type { UpdateAllowToFindRequest } from "./account";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service AccountService
 */
export interface IAccountServiceClient {
    /**
     * @generated from protobuf rpc: UpdateAllowToFind(UpdateAllowToFindRequest) returns (Account);
     */
    updateAllowToFind(input: UpdateAllowToFindRequest, options?: RpcOptions): UnaryCall<UpdateAllowToFindRequest, Account>;
    /**
     * @generated from protobuf rpc: UpdateTimeZone(UpdateTimeZoneRequest) returns (Account);
     */
    updateTimeZone(input: UpdateTimeZoneRequest, options?: RpcOptions): UnaryCall<UpdateTimeZoneRequest, Account>;
    /**
     * @generated from protobuf rpc: UploadProfilePicture(PictureRequest) returns (PictureResponse);
     */
    uploadProfilePicture(input: PictureRequest, options?: RpcOptions): UnaryCall<PictureRequest, PictureResponse>;
    /**
     * @generated from protobuf rpc: DeleteProfilePicture(DeletePictureRequest) returns (google.protobuf.Empty);
     */
    deleteProfilePicture(input: DeletePictureRequest, options?: RpcOptions): UnaryCall<DeletePictureRequest, Empty>;
    /**
     * @generated from protobuf rpc: CreateAccount(CreateAccountRequest) returns (Account);
     */
    createAccount(input: CreateAccountRequest, options?: RpcOptions): UnaryCall<CreateAccountRequest, Account>;
    /**
     * @generated from protobuf rpc: GetAccount(GetAccountRequest) returns (Account);
     */
    getAccount(input: GetAccountRequest, options?: RpcOptions): UnaryCall<GetAccountRequest, Account>;
    /**
     * @generated from protobuf rpc: UpdateAccount(UpdateAccountRequest) returns (Account);
     */
    updateAccount(input: UpdateAccountRequest, options?: RpcOptions): UnaryCall<UpdateAccountRequest, Account>;
    /**
     * Resets a users password
     *
     * @generated from protobuf rpc: ResetPassword(ResetPasswordRequest) returns (google.protobuf.Empty);
     */
    resetPassword(input: ResetPasswordRequest, options?: RpcOptions): UnaryCall<ResetPasswordRequest, Empty>;
    /**
     * Enables an account in Firebase Auth which means a user that was previously
     * disabled can now login again. Returns empty response if successful,
     * otherwise an error.
     *
     * @generated from protobuf rpc: EnableAccount(EnableAccountRequest) returns (google.protobuf.Empty);
     */
    enableAccount(input: EnableAccountRequest, options?: RpcOptions): UnaryCall<EnableAccountRequest, Empty>;
    /**
     * Disables an account in Firebase Auth which means a user can't login
     * anymore. Returns empty response if successful, otherwise an error.
     *
     * @generated from protobuf rpc: DisableAccount(DisableAccountRequest) returns (google.protobuf.Empty);
     */
    disableAccount(input: DisableAccountRequest, options?: RpcOptions): UnaryCall<DisableAccountRequest, Empty>;
    /**
     * @generated from protobuf rpc: DeleteAccount(DeleteAccountRequest) returns (google.protobuf.Empty);
     */
    deleteAccount(input: DeleteAccountRequest, options?: RpcOptions): UnaryCall<DeleteAccountRequest, Empty>;
    /**
     * @generated from protobuf rpc: SendActivationLink(SendActivationEmailRequest) returns (SendActivationEmailResponse);
     */
    sendActivationLink(input: SendActivationEmailRequest, options?: RpcOptions): UnaryCall<SendActivationEmailRequest, SendActivationEmailResponse>;
    /**
     * @generated from protobuf rpc: VerifyEmail(VerifyEmailRequest) returns (google.protobuf.Empty);
     */
    verifyEmail(input: VerifyEmailRequest, options?: RpcOptions): UnaryCall<VerifyEmailRequest, Empty>;
    /**
     * @generated from protobuf rpc: ResetContactPassword(ContactPasswordResetRequest) returns (google.protobuf.Empty);
     */
    resetContactPassword(input: ContactPasswordResetRequest, options?: RpcOptions): UnaryCall<ContactPasswordResetRequest, Empty>;
    /**
     * VerifyMobileAPPAccount check if account exist and can login
     * This method is used at the very beginning of the authentication process.
     * It does the following:
     * 1. Check if account exist
     * 2. Checks if this account is being used on another device.
     *    If the account is used on another device then a logout pusher event is sent to that device.
     * The method returns information about the account and the customer.
     *
     * @generated from protobuf rpc: VerifyMobileAPPAccount(VerifyMobileAPPAccountRequest) returns (VerifyMobileAPPAccountResponse);
     */
    verifyMobileAPPAccount(input: VerifyMobileAPPAccountRequest, options?: RpcOptions): UnaryCall<VerifyMobileAPPAccountRequest, VerifyMobileAPPAccountResponse>;
    /**
     * VerifyMobileAPPDevice check if device exist and can send request
     *
     * @generated from protobuf rpc: VerifyMobileAPPDevice(VerifyMobileAPPDeviceRequest) returns (VerifyMobileAPPDeviceResponse);
     */
    verifyMobileAPPDevice(input: VerifyMobileAPPDeviceRequest, options?: RpcOptions): UnaryCall<VerifyMobileAPPDeviceRequest, VerifyMobileAPPDeviceResponse>;
    /**
     * PushNotificationsSubscribe generate access token for receiving push notifications for user.
     * Token valid only 24h
     *
     * @generated from protobuf rpc: PushNotificationsSubscribe(PushNotificationsSubscribeRequest) returns (PushNotificationsSubscribeResponse);
     */
    pushNotificationsSubscribe(input: PushNotificationsSubscribeRequest, options?: RpcOptions): UnaryCall<PushNotificationsSubscribeRequest, PushNotificationsSubscribeResponse>;
    /**
     * PushNotificationsUnsubscribe deactivate push notifications for user
     *
     * @generated from protobuf rpc: PushNotificationsUnsubscribe(PushNotificationsUnsubscribeRequest) returns (google.protobuf.Empty);
     */
    pushNotificationsUnsubscribe(input: PushNotificationsUnsubscribeRequest, options?: RpcOptions): UnaryCall<PushNotificationsUnsubscribeRequest, Empty>;
    /**
     * SetWorkingSchedule create/update working schedule for account
     *
     * @generated from protobuf rpc: SetWorkingSchedule(SetWorkingScheduleRequest) returns (WorkingScheduleResponse);
     */
    setWorkingSchedule(input: SetWorkingScheduleRequest, options?: RpcOptions): UnaryCall<SetWorkingScheduleRequest, WorkingScheduleResponse>;
    /**
     * GetWorkingSchedule return hybrid working schedule for account
     *
     * @generated from protobuf rpc: GetWorkingSchedule(GetWorkingScheduleRequest) returns (WorkingScheduleResponse);
     */
    getWorkingSchedule(input: GetWorkingScheduleRequest, options?: RpcOptions): UnaryCall<GetWorkingScheduleRequest, WorkingScheduleResponse>;
    /**
     * SyncAccounts sync accounts info in graph with info from SQL database
     *
     * @generated from protobuf rpc: SyncAccounts(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    syncAccounts(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * RequestEmailChange send user request to change auth email
     *
     * @generated from protobuf rpc: RequestEmailChange(RequestEmailChangeRequest) returns (google.protobuf.Empty);
     */
    requestEmailChange(input: RequestEmailChangeRequest, options?: RpcOptions): UnaryCall<RequestEmailChangeRequest, Empty>;
    /**
     * VerifyEmailChange send user request to confirm change auth email
     *
     * @generated from protobuf rpc: VerifyEmailChange(VerifyEmailChangeRequest) returns (google.protobuf.Empty);
     */
    verifyEmailChange(input: VerifyEmailChangeRequest, options?: RpcOptions): UnaryCall<VerifyEmailChangeRequest, Empty>;
    /**
     * @generated from protobuf rpc: SetRecoveryEmail(RecoveryEmailRequest) returns (RecoveryEmailResponse);
     */
    setRecoveryEmail(input: RecoveryEmailRequest, options?: RpcOptions): UnaryCall<RecoveryEmailRequest, RecoveryEmailResponse>;
    /**
     * @generated from protobuf rpc: SendRecoveryEmail(SendRecoveryEmailRequest) returns (SendRecoveryEmailResponse);
     */
    sendRecoveryEmail(input: SendRecoveryEmailRequest, options?: RpcOptions): UnaryCall<SendRecoveryEmailRequest, SendRecoveryEmailResponse>;
    /**
     * @generated from protobuf rpc: DisableMFA(DisableMFARequest) returns (DisableMFAResponse);
     */
    disableMFA(input: DisableMFARequest, options?: RpcOptions): UnaryCall<DisableMFARequest, DisableMFAResponse>;
    /**
     * @generated from protobuf rpc: DisableManualMFA(DisableManualMFARequest) returns (DisableManualMFAResponse);
     */
    disableManualMFA(input: DisableManualMFARequest, options?: RpcOptions): UnaryCall<DisableManualMFARequest, DisableManualMFAResponse>;
}
/**
 * @generated from protobuf service AccountService
 */
export class AccountServiceClient implements IAccountServiceClient, ServiceInfo {
    typeName = AccountService.typeName;
    methods = AccountService.methods;
    options = AccountService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: UpdateAllowToFind(UpdateAllowToFindRequest) returns (Account);
     */
    updateAllowToFind(input: UpdateAllowToFindRequest, options?: RpcOptions): UnaryCall<UpdateAllowToFindRequest, Account> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateAllowToFindRequest, Account>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTimeZone(UpdateTimeZoneRequest) returns (Account);
     */
    updateTimeZone(input: UpdateTimeZoneRequest, options?: RpcOptions): UnaryCall<UpdateTimeZoneRequest, Account> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateTimeZoneRequest, Account>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UploadProfilePicture(PictureRequest) returns (PictureResponse);
     */
    uploadProfilePicture(input: PictureRequest, options?: RpcOptions): UnaryCall<PictureRequest, PictureResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<PictureRequest, PictureResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteProfilePicture(DeletePictureRequest) returns (google.protobuf.Empty);
     */
    deleteProfilePicture(input: DeletePictureRequest, options?: RpcOptions): UnaryCall<DeletePictureRequest, Empty> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeletePictureRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateAccount(CreateAccountRequest) returns (Account);
     */
    createAccount(input: CreateAccountRequest, options?: RpcOptions): UnaryCall<CreateAccountRequest, Account> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateAccountRequest, Account>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAccount(GetAccountRequest) returns (Account);
     */
    getAccount(input: GetAccountRequest, options?: RpcOptions): UnaryCall<GetAccountRequest, Account> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAccountRequest, Account>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateAccount(UpdateAccountRequest) returns (Account);
     */
    updateAccount(input: UpdateAccountRequest, options?: RpcOptions): UnaryCall<UpdateAccountRequest, Account> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateAccountRequest, Account>("unary", this._transport, method, opt, input);
    }
    /**
     * Resets a users password
     *
     * @generated from protobuf rpc: ResetPassword(ResetPasswordRequest) returns (google.protobuf.Empty);
     */
    resetPassword(input: ResetPasswordRequest, options?: RpcOptions): UnaryCall<ResetPasswordRequest, Empty> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ResetPasswordRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Enables an account in Firebase Auth which means a user that was previously
     * disabled can now login again. Returns empty response if successful,
     * otherwise an error.
     *
     * @generated from protobuf rpc: EnableAccount(EnableAccountRequest) returns (google.protobuf.Empty);
     */
    enableAccount(input: EnableAccountRequest, options?: RpcOptions): UnaryCall<EnableAccountRequest, Empty> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<EnableAccountRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Disables an account in Firebase Auth which means a user can't login
     * anymore. Returns empty response if successful, otherwise an error.
     *
     * @generated from protobuf rpc: DisableAccount(DisableAccountRequest) returns (google.protobuf.Empty);
     */
    disableAccount(input: DisableAccountRequest, options?: RpcOptions): UnaryCall<DisableAccountRequest, Empty> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<DisableAccountRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteAccount(DeleteAccountRequest) returns (google.protobuf.Empty);
     */
    deleteAccount(input: DeleteAccountRequest, options?: RpcOptions): UnaryCall<DeleteAccountRequest, Empty> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteAccountRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendActivationLink(SendActivationEmailRequest) returns (SendActivationEmailResponse);
     */
    sendActivationLink(input: SendActivationEmailRequest, options?: RpcOptions): UnaryCall<SendActivationEmailRequest, SendActivationEmailResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<SendActivationEmailRequest, SendActivationEmailResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: VerifyEmail(VerifyEmailRequest) returns (google.protobuf.Empty);
     */
    verifyEmail(input: VerifyEmailRequest, options?: RpcOptions): UnaryCall<VerifyEmailRequest, Empty> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerifyEmailRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ResetContactPassword(ContactPasswordResetRequest) returns (google.protobuf.Empty);
     */
    resetContactPassword(input: ContactPasswordResetRequest, options?: RpcOptions): UnaryCall<ContactPasswordResetRequest, Empty> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<ContactPasswordResetRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * VerifyMobileAPPAccount check if account exist and can login
     * This method is used at the very beginning of the authentication process.
     * It does the following:
     * 1. Check if account exist
     * 2. Checks if this account is being used on another device.
     *    If the account is used on another device then a logout pusher event is sent to that device.
     * The method returns information about the account and the customer.
     *
     * @generated from protobuf rpc: VerifyMobileAPPAccount(VerifyMobileAPPAccountRequest) returns (VerifyMobileAPPAccountResponse);
     */
    verifyMobileAPPAccount(input: VerifyMobileAPPAccountRequest, options?: RpcOptions): UnaryCall<VerifyMobileAPPAccountRequest, VerifyMobileAPPAccountResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerifyMobileAPPAccountRequest, VerifyMobileAPPAccountResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * VerifyMobileAPPDevice check if device exist and can send request
     *
     * @generated from protobuf rpc: VerifyMobileAPPDevice(VerifyMobileAPPDeviceRequest) returns (VerifyMobileAPPDeviceResponse);
     */
    verifyMobileAPPDevice(input: VerifyMobileAPPDeviceRequest, options?: RpcOptions): UnaryCall<VerifyMobileAPPDeviceRequest, VerifyMobileAPPDeviceResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerifyMobileAPPDeviceRequest, VerifyMobileAPPDeviceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * PushNotificationsSubscribe generate access token for receiving push notifications for user.
     * Token valid only 24h
     *
     * @generated from protobuf rpc: PushNotificationsSubscribe(PushNotificationsSubscribeRequest) returns (PushNotificationsSubscribeResponse);
     */
    pushNotificationsSubscribe(input: PushNotificationsSubscribeRequest, options?: RpcOptions): UnaryCall<PushNotificationsSubscribeRequest, PushNotificationsSubscribeResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<PushNotificationsSubscribeRequest, PushNotificationsSubscribeResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * PushNotificationsUnsubscribe deactivate push notifications for user
     *
     * @generated from protobuf rpc: PushNotificationsUnsubscribe(PushNotificationsUnsubscribeRequest) returns (google.protobuf.Empty);
     */
    pushNotificationsUnsubscribe(input: PushNotificationsUnsubscribeRequest, options?: RpcOptions): UnaryCall<PushNotificationsUnsubscribeRequest, Empty> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<PushNotificationsUnsubscribeRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * SetWorkingSchedule create/update working schedule for account
     *
     * @generated from protobuf rpc: SetWorkingSchedule(SetWorkingScheduleRequest) returns (WorkingScheduleResponse);
     */
    setWorkingSchedule(input: SetWorkingScheduleRequest, options?: RpcOptions): UnaryCall<SetWorkingScheduleRequest, WorkingScheduleResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetWorkingScheduleRequest, WorkingScheduleResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetWorkingSchedule return hybrid working schedule for account
     *
     * @generated from protobuf rpc: GetWorkingSchedule(GetWorkingScheduleRequest) returns (WorkingScheduleResponse);
     */
    getWorkingSchedule(input: GetWorkingScheduleRequest, options?: RpcOptions): UnaryCall<GetWorkingScheduleRequest, WorkingScheduleResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWorkingScheduleRequest, WorkingScheduleResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * SyncAccounts sync accounts info in graph with info from SQL database
     *
     * @generated from protobuf rpc: SyncAccounts(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    syncAccounts(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * RequestEmailChange send user request to change auth email
     *
     * @generated from protobuf rpc: RequestEmailChange(RequestEmailChangeRequest) returns (google.protobuf.Empty);
     */
    requestEmailChange(input: RequestEmailChangeRequest, options?: RpcOptions): UnaryCall<RequestEmailChangeRequest, Empty> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<RequestEmailChangeRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * VerifyEmailChange send user request to confirm change auth email
     *
     * @generated from protobuf rpc: VerifyEmailChange(VerifyEmailChangeRequest) returns (google.protobuf.Empty);
     */
    verifyEmailChange(input: VerifyEmailChangeRequest, options?: RpcOptions): UnaryCall<VerifyEmailChangeRequest, Empty> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerifyEmailChangeRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetRecoveryEmail(RecoveryEmailRequest) returns (RecoveryEmailResponse);
     */
    setRecoveryEmail(input: RecoveryEmailRequest, options?: RpcOptions): UnaryCall<RecoveryEmailRequest, RecoveryEmailResponse> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecoveryEmailRequest, RecoveryEmailResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendRecoveryEmail(SendRecoveryEmailRequest) returns (SendRecoveryEmailResponse);
     */
    sendRecoveryEmail(input: SendRecoveryEmailRequest, options?: RpcOptions): UnaryCall<SendRecoveryEmailRequest, SendRecoveryEmailResponse> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<SendRecoveryEmailRequest, SendRecoveryEmailResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DisableMFA(DisableMFARequest) returns (DisableMFAResponse);
     */
    disableMFA(input: DisableMFARequest, options?: RpcOptions): UnaryCall<DisableMFARequest, DisableMFAResponse> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<DisableMFARequest, DisableMFAResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DisableManualMFA(DisableManualMFARequest) returns (DisableManualMFAResponse);
     */
    disableManualMFA(input: DisableManualMFARequest, options?: RpcOptions): UnaryCall<DisableManualMFARequest, DisableManualMFAResponse> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<DisableManualMFARequest, DisableManualMFAResponse>("unary", this._transport, method, opt, input);
    }
}
