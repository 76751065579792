import React from "react";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";

import { useEWSFormik } from "../lib/useEWSFormik";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { useCalendarSettings } from "../../../api/grpc/calendarprovider/useCalendarSettings";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";

import { EWSServiceForm } from "../CalendarForms/EWSServiceForm";
import { EditSettings } from "../../Workplaces/EditWorkplace/EditSettings/EditSettings";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";

import "./CalendarsEWSSettings.scss";

interface Props {
  id: string;
  shouldRefetchAllCalendars?: boolean;
}

export const CalendarsEWSSettings = (props: Props) => {
  const { user } = useAuthContext();
  const { updateEWSProvider } = useCalendarRequests();
  const { updateProviderSettings } = useCalendarContext();
  const { loading, error, data } = useCalendarSettings(props.id);

  const formik = useEWSFormik({
    initialValues: {
      name: data?.name || "",
      domain: data?.domain || "",
      password: "",
      svcAccount: data?.svcAccount || "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (!user || !values) {
          return;
        }

        await updateEWSProvider({
          customerID: user?.customerid || "",
          iD: props.id,
          ...values,
        });

        updateProviderSettings(
          props.id,
          {
            iD: props.id,
            password: "",
            name: values.name,
            domain: values.domain,
            svcAccount: values.svcAccount,
            customerID: user?.customerid || "",
          },
          true
        );

        formik.setFieldValue("password", "");
        formik.setFieldTouched("password", false);

        toast.success("Calendar updated successfully!");
      } catch (error: any) {
        console.error(error);
        console.log(error.message);
        toast.error(
          error.message ? error.message : "Calendar couldn't be updated!"
        );
      }
    },
  });

  return (
    <div className="CalendarsEWSSettings">
      <HandleRequestState
        state={loading}
        placeholder={
          <div className="w-100 d-flex w-100 h-100 align-items-center justify-content-center">
            <Spinner
              color="primary"
              style={{ width: "5rem", height: "5rem" }}
            />
          </div>
        }
      >
        <HandleRequestState
          state={error}
          placeholder={<h4>Something went wrong, please try again!</h4>}
        >
          <EditSettings
            // hasChanges={formik.dirty}
            title="Edit EWS Connection"
            onSubmit={formik.handleSubmit}
            disableButton={!props.shouldRefetchAllCalendars}
          >
            <EWSServiceForm formik={formik} />
          </EditSettings>
        </HandleRequestState>
      </HandleRequestState>
    </div>
  );
};
