import React from "react";
import { Button } from "../../shared/Button/Button";

interface Props {
  desksCounter: number;
  roomsCounter: number;
  clearWorkspaces: () => void;
  closePicker: () => void;
}

export const WayFinderWorkspaceFooterPicker = ({
  desksCounter,
  roomsCounter,
  clearWorkspaces,
  closePicker,
}: Props) => {
  return (
    <div className="pt-1 WayFinderWorkspaceFooterPicker">
      <span className="pt-1 d-block text-center WayFinderFormWorkspaces__counter">
        {roomsCounter} of max 24 rooms selected
      </span>
      <span className="pb-3 d-block text-center WayFinderFormWorkspaces__counter">
        {desksCounter} of max 42 desks selected
      </span>

      <div className="flex-a-center justify-content-between WayFinderWorkspaceFooterPicker__buttons">
        <Button
          title="All Clear"
          size="small"
          color="outline-secondary"
          onClick={clearWorkspaces}
        />
        <Button
          title="Confirm"
          size="small"
          color="primary"
          onClick={closePicker}
        />
      </div>
    </div>
  );
};
