import React from "react";
import { usePaginationHelper } from "../../../lib/hooks/usePaginationHelper";
import { PaginationContext } from "../../../lib/context/Pagination/PaginationContext";
import { Pagination } from "../../shared/Pagination/Pagination";
import { StructureTable } from "../../shared/StructureTable/StructureTable";
import { FloorChildrenEntity } from "./FloorChildrenList";

interface Props {
  data: FloorChildrenEntity[];
  onClose: () => void;
  typeOfMap?: "bookings" | "floor";
}

export const FloorWayfinderExpandedList = ({ data, onClose }: Props) => {
  const { page, pageSize, pagination } = usePaginationHelper(data.length);

  const indexOfLastItem = page * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentWayfinderData = data?.slice(indexOfFirstItem, indexOfLastItem);

  const columns = [
    { key: "name", header: "Name", onClick: () => onClose() },
    { key: "add", header: "Add" },
    { key: "location", header: "" },
  ];

  const rows = currentWayfinderData?.map((item: FloorChildrenEntity) => {
    return {
      name: item.name,
      add: item.add,
      location: item.location,
    };
  });

  return (
    <PaginationContext.Provider value={pagination}>
      <StructureTable rows={rows} columns={columns} />
      <Pagination />
    </PaginationContext.Provider>
  );
};
