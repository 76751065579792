import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";

import { getReservationTypes } from "../helpers/getReservationActionsTypes";
import {
  Event as RoomEvent,
  EventCheckInStatus,
} from "../../../../api/grpc/booking/ggevent/ggevent";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";
import { Event as DeskEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import { RESERVATIONS_MAP_ROOT_PATH } from "../../../../lib/routes/routes";
import {
  DropdownList,
  DropdownListOptions,
} from "../../../shared/DropdownList/DropdownList";
import { Icon } from "../../../shared/Icon/Icon";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { CheckInDeskModal } from "../../Reserve/Modals/CheckInDeskModal";
import { CheckOutModal } from "../../Reserve/Modals/CheckOutModal";

import { DeleteModal } from "../../Reserve/Modals/DeleteModal";
import { UpdateReservationModal } from "../../Reserve/Modals/UpdateReservationModal";
import { CheckInRequired } from "../../Reserve/Modals/CheckInRequired";

type ModalTypes =
  | "modifyReservation"
  | "deleteReservation"
  | "checkInDesk"
  | "checkOut";

interface Props {
  type: BookingTypes;
  workplaceId: string;
  event: DeskEvent | RoomEvent;
  refetchDesk?: () => void;
  refetchRoom?: () => void;
  startTime: string;
  endTime: string;
  name: string;
  locationPath: DeskLocationItem[];
  checkInStatus: EventCheckInStatus | null;
  qrCodeRequired?: boolean;
  allowDeleteOption: boolean;
}

interface ExtendedProps extends Props {
  isOpen: boolean;
  toggleModal: () => void;
  userName: string;
}

export const ReservationListActions = ({
  type,
  workplaceId,
  event,
  refetchDesk,
  refetchRoom,
  startTime,
  endTime,
  name,
  locationPath,
  checkInStatus,
  qrCodeRequired,
  allowDeleteOption,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const { user } = useAuthContext();
  const { history } = useRouter();
  const ref = useRef(null);
  const { isOpen, toggleModal } = useToggleModal();
  const [hide, setHide] = useState("d-none");

  const components: {
    [key in ModalTypes]: React.FunctionComponent<any>;
  } = {
    modifyReservation: UpdateReservationModal,
    deleteReservation: DeleteModal,
    checkInDesk: qrCodeRequired ? CheckInRequired : CheckInDeskModal,
    checkOut: CheckOutModal,
  };

  const options: DropdownListOptions[] = getReservationTypes({
    typeOfCheckin: checkInStatus,
    workplaceType: type,
    startTime: event?.startTime,
    isCheckInRequired: event.checkInRequiredStatus,
    allowDeleteOption: allowDeleteOption,
    allDay: event.allDay,
    status: event?.locations[0]?.status,
  });

  const handleReserveAction = async (
    type: ModalTypes,
    typeOfWorkplace: "desk" | "room"
  ) => {
    setShowDropdown(false);
    // setLoading(true);

    toggleModal();
  };
  const Component = components[activeModal as ModalTypes];

  let componentProps: ExtendedProps = {
    workplaceId: workplaceId,
    name: name,
    userName: user?.displayName || "",
    refetchDesk: refetchDesk,
    refetchRoom: refetchRoom,
    locationPath: locationPath,
    isOpen: isOpen,
    toggleModal: toggleModal,
    startTime: startTime,
    endTime: endTime,
    event: event,
    type: type,
    checkInStatus: checkInStatus,
    allowDeleteOption: allowDeleteOption,
  };

  useOutsideClickDetection(ref, () => {
    setHide("d-none");
    setShowDropdown(false);
  });

  return (
    <div ref={ref}>
      <Icon
        icon="actions-icon"
        className={hide}
        onClick={() => {
          if (showDropdown) {
            setHide("d-none");
          }

          setHide("d-block");
          setShowDropdown((prev) => !prev);
        }}
      />

      {showDropdown && (
        <DropdownList
          extended
          options={options}
          color="gray"
          onClick={(value) => {
            if (value === "openMap") {
              let clickedMarker = locationPath.filter(
                (location) => location.type === "Floor"
              );

              if (clickedMarker.length <= 0) {
                return toast.warn("This workspace is not part of a floor!");
              }

              return history.push(
                `${RESERVATIONS_MAP_ROOT_PATH}/${clickedMarker[0].id}/markerOpen=${workplaceId}`
              );
            }

            setActiveModal(value as ModalTypes);
            handleReserveAction(value as ModalTypes, type);
          }}
        />
      )}
      {isOpen && <Component {...componentProps}></Component>}
    </div>
  );
};
