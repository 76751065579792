import React, { Dispatch, SetStateAction } from "react";
import { Person } from "../../../../api/grpc/contacts/contacts";

export interface BookingsMapContext {
  time: Date;
  handleTime: (time: Date) => void;
  day: string;
  handleDay: (day: string) => void;
  searchByDate: () => void;
  dateAndTime: string;
  setDateAndTime: Dispatch<SetStateAction<string>>;
  attendees: Person[];
  handleAttendees: (attendee: Person[]) => void;
  searchedAttendees: Person[];
  handleSearchedAttendees: (attendee: Person[]) => void;
  allDay: boolean;
  setAllDay: React.Dispatch<React.SetStateAction<boolean>>;
}

const values: BookingsMapContext = {
  time: new Date(),
  handleTime: () => {},
  day: "",
  handleDay: () => {},
  searchByDate: () => {},
  dateAndTime: "",
  setDateAndTime: () => {},
  handleAttendees: () => {},
  attendees: [],
  searchedAttendees: [],
  handleSearchedAttendees: () => {},
  allDay: false,
  setAllDay: () => {},
};

export const BookingsMapContext = React.createContext(values);

export const useBookingsMapContext = () => {
  const context = React.useContext(BookingsMapContext);

  if (!context) {
    throw new Error(
      "useBookingsMapContext must be used inside of BookingsMapContext"
    );
  }

  return context;
};
