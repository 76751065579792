import React, { Dispatch, SetStateAction } from "react";
import { WorkplaceTypes } from "../../Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

export interface InsightsLocation {
  locationId: string;
  locationName: string;
  locationType: WorkplaceTypes | "";
}

export interface InsightsContext {
  startTime: Date;
  endTime: Date;
  setStartTime: Dispatch<SetStateAction<Date>>;
  setEndTime: Dispatch<SetStateAction<Date>>;
  insightsLocation: InsightsLocation;
  setInsightsLocation: Dispatch<SetStateAction<InsightsLocation>>;
}

const values: InsightsContext = {
  startTime: new Date(),
  endTime: new Date(),
  setStartTime: () => {},
  setEndTime: () => {},
  insightsLocation: {
    locationId: "",
    locationName: "",
    locationType: "",
  },
  setInsightsLocation: () => {},
};

export const InsightsContext = React.createContext(values);

export const useInsightsContext = () => {
  const context = React.useContext(InsightsContext);

  if (!context) {
    throw new Error(
      "useInsightsContext must be used inside of InsightsContext"
    );
  }

  return context;
};
