import React from "react";
import cs from "classnames";
import { NavLink } from "react-router-dom";

import { Icon, IconDefinition } from "../Icon/Icon";
import { HomeCardList } from "./HomeCardList/HomeCardList";
import { InformationBox } from "../InformationBox/InformationBox";

import "./HomeCard.scss";

interface Props {
  link?: string;
  title: string;
  tooltip?: string;
  disabled?: boolean;
  icon: IconDefinition;
  list: HomeCardList[];
  className?: string;
  hasDivider?: boolean;
  adjustInfoLeft?: boolean;
}

export const HomeCard = ({
  icon,
  title,
  tooltip,
  link,
  disabled,
  list,
  className,
  hasDivider,
  adjustInfoLeft,
}: Props) => {
  const classes = cs("HomeCard default-box", className, {
    "HomeCard--disabled": disabled,
  });

  return (
    <div className={classes}>
      <div className="HomeCard__icon">
        <Icon icon={icon} />
      </div>
      <h3 className="HomeCard__title">
        {link ? <NavLink to={link}>{title}</NavLink> : title}
        {tooltip && (
          <InformationBox
            direction="left"
            leftDiff
            message={tooltip}
            adjustInfoLeft={adjustInfoLeft}
          />
        )}
      </h3>
      {hasDivider && <div className="HomeCard__divider"></div>}
      <HomeCardList list={list} adjustable />
    </div>
  );
};
