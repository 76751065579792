// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "booking.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Booking } from "./booking";
import type { TriggerCheckInReminderRequest } from "./booking";
import type { CalendarEventsSubscriptionResponse } from "./booking";
import type { CalendarEventsSubscriptionRequest } from "./booking";
import type { RoomsScheduleResponse } from "./booking";
import type { RoomsScheduleRequest } from "./booking";
import type { HealthCheckRequest } from "./booking";
import type { ConfirmCheckInRequest } from "./booking";
import type { Empty } from "./google/protobuf/empty";
import type { DeleteMeetingRequest } from "./booking";
import type { CheckRoomsScheduleResponse } from "./booking";
import type { CheckRoomsScheduleRequest } from "./booking";
import type { ScheduleResponse } from "./booking";
import type { ScheduleRequest } from "./booking";
import type { GetMeetingResponse } from "./booking";
import type { GetMeetingRequest } from "./booking";
import type { ListMeetingsResponse } from "./booking";
import type { ListMeetingsRequest } from "./booking";
import type { UpdateMeetingResponse } from "./booking";
import type { UpdateMeetingRequest } from "./booking";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { BookMeetingResponse } from "./booking";
import type { BookMeetingRequest } from "./booking";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Booking
 */
export interface IBookingClient {
    /**
     * @generated from protobuf rpc: BookMeeting(BookMeetingRequest) returns (BookMeetingResponse);
     */
    bookMeeting(input: BookMeetingRequest, options?: RpcOptions): UnaryCall<BookMeetingRequest, BookMeetingResponse>;
    /**
     * @generated from protobuf rpc: UpdateMeeting(UpdateMeetingRequest) returns (UpdateMeetingResponse);
     */
    updateMeeting(input: UpdateMeetingRequest, options?: RpcOptions): UnaryCall<UpdateMeetingRequest, UpdateMeetingResponse>;
    /**
     * @generated from protobuf rpc: ListMeetings(ListMeetingsRequest) returns (ListMeetingsResponse);
     */
    listMeetings(input: ListMeetingsRequest, options?: RpcOptions): UnaryCall<ListMeetingsRequest, ListMeetingsResponse>;
    /**
     * @generated from protobuf rpc: GetMeeting(GetMeetingRequest) returns (GetMeetingResponse);
     */
    getMeeting(input: GetMeetingRequest, options?: RpcOptions): UnaryCall<GetMeetingRequest, GetMeetingResponse>;
    /**
     * @generated from protobuf rpc: CheckSchedule(ScheduleRequest) returns (ScheduleResponse);
     */
    checkSchedule(input: ScheduleRequest, options?: RpcOptions): UnaryCall<ScheduleRequest, ScheduleResponse>;
    /**
     * @generated from protobuf rpc: CheckRoomsSchedule(CheckRoomsScheduleRequest) returns (CheckRoomsScheduleResponse);
     */
    checkRoomsSchedule(input: CheckRoomsScheduleRequest, options?: RpcOptions): UnaryCall<CheckRoomsScheduleRequest, CheckRoomsScheduleResponse>;
    /**
     * @generated from protobuf rpc: DeleteMeeting(DeleteMeetingRequest) returns (google.protobuf.Empty);
     */
    deleteMeeting(input: DeleteMeetingRequest, options?: RpcOptions): UnaryCall<DeleteMeetingRequest, Empty>;
    /**
     * @generated from protobuf rpc: ConfirmCheckIn(ConfirmCheckInRequest) returns (google.protobuf.Empty);
     */
    confirmCheckIn(input: ConfirmCheckInRequest, options?: RpcOptions): UnaryCall<ConfirmCheckInRequest, Empty>;
    /**
     * HealthCheck check is service is available
     *
     * @generated from protobuf rpc: HealthCheck(HealthCheckRequest) returns (google.protobuf.Empty);
     */
    healthCheck(input: HealthCheckRequest, options?: RpcOptions): UnaryCall<HealthCheckRequest, Empty>;
    /**
     * RoomsSchedule ...
     *
     * @generated from protobuf rpc: RoomsSchedule(RoomsScheduleRequest) returns (RoomsScheduleResponse);
     */
    roomsSchedule(input: RoomsScheduleRequest, options?: RpcOptions): UnaryCall<RoomsScheduleRequest, RoomsScheduleResponse>;
    /**
     * CalendarEventsSubscription return calendar subscription status for live updates from calendar.
     *
     * @generated from protobuf rpc: CalendarEventsSubscription(CalendarEventsSubscriptionRequest) returns (CalendarEventsSubscriptionResponse);
     */
    calendarEventsSubscription(input: CalendarEventsSubscriptionRequest, options?: RpcOptions): UnaryCall<CalendarEventsSubscriptionRequest, CalendarEventsSubscriptionResponse>;
    /**
     * @generated from protobuf rpc: TriggerCheckInReminder(TriggerCheckInReminderRequest) returns (google.protobuf.Empty);
     */
    triggerCheckInReminder(input: TriggerCheckInReminderRequest, options?: RpcOptions): UnaryCall<TriggerCheckInReminderRequest, Empty>;
}
/**
 * @generated from protobuf service Booking
 */
export class BookingClient implements IBookingClient, ServiceInfo {
    typeName = Booking.typeName;
    methods = Booking.methods;
    options = Booking.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: BookMeeting(BookMeetingRequest) returns (BookMeetingResponse);
     */
    bookMeeting(input: BookMeetingRequest, options?: RpcOptions): UnaryCall<BookMeetingRequest, BookMeetingResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<BookMeetingRequest, BookMeetingResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateMeeting(UpdateMeetingRequest) returns (UpdateMeetingResponse);
     */
    updateMeeting(input: UpdateMeetingRequest, options?: RpcOptions): UnaryCall<UpdateMeetingRequest, UpdateMeetingResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateMeetingRequest, UpdateMeetingResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMeetings(ListMeetingsRequest) returns (ListMeetingsResponse);
     */
    listMeetings(input: ListMeetingsRequest, options?: RpcOptions): UnaryCall<ListMeetingsRequest, ListMeetingsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMeetingsRequest, ListMeetingsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMeeting(GetMeetingRequest) returns (GetMeetingResponse);
     */
    getMeeting(input: GetMeetingRequest, options?: RpcOptions): UnaryCall<GetMeetingRequest, GetMeetingResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMeetingRequest, GetMeetingResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckSchedule(ScheduleRequest) returns (ScheduleResponse);
     */
    checkSchedule(input: ScheduleRequest, options?: RpcOptions): UnaryCall<ScheduleRequest, ScheduleResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScheduleRequest, ScheduleResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckRoomsSchedule(CheckRoomsScheduleRequest) returns (CheckRoomsScheduleResponse);
     */
    checkRoomsSchedule(input: CheckRoomsScheduleRequest, options?: RpcOptions): UnaryCall<CheckRoomsScheduleRequest, CheckRoomsScheduleResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckRoomsScheduleRequest, CheckRoomsScheduleResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteMeeting(DeleteMeetingRequest) returns (google.protobuf.Empty);
     */
    deleteMeeting(input: DeleteMeetingRequest, options?: RpcOptions): UnaryCall<DeleteMeetingRequest, Empty> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMeetingRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ConfirmCheckIn(ConfirmCheckInRequest) returns (google.protobuf.Empty);
     */
    confirmCheckIn(input: ConfirmCheckInRequest, options?: RpcOptions): UnaryCall<ConfirmCheckInRequest, Empty> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConfirmCheckInRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * HealthCheck check is service is available
     *
     * @generated from protobuf rpc: HealthCheck(HealthCheckRequest) returns (google.protobuf.Empty);
     */
    healthCheck(input: HealthCheckRequest, options?: RpcOptions): UnaryCall<HealthCheckRequest, Empty> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<HealthCheckRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomsSchedule ...
     *
     * @generated from protobuf rpc: RoomsSchedule(RoomsScheduleRequest) returns (RoomsScheduleResponse);
     */
    roomsSchedule(input: RoomsScheduleRequest, options?: RpcOptions): UnaryCall<RoomsScheduleRequest, RoomsScheduleResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<RoomsScheduleRequest, RoomsScheduleResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CalendarEventsSubscription return calendar subscription status for live updates from calendar.
     *
     * @generated from protobuf rpc: CalendarEventsSubscription(CalendarEventsSubscriptionRequest) returns (CalendarEventsSubscriptionResponse);
     */
    calendarEventsSubscription(input: CalendarEventsSubscriptionRequest, options?: RpcOptions): UnaryCall<CalendarEventsSubscriptionRequest, CalendarEventsSubscriptionResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalendarEventsSubscriptionRequest, CalendarEventsSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: TriggerCheckInReminder(TriggerCheckInReminderRequest) returns (google.protobuf.Empty);
     */
    triggerCheckInReminder(input: TriggerCheckInReminderRequest, options?: RpcOptions): UnaryCall<TriggerCheckInReminderRequest, Empty> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<TriggerCheckInReminderRequest, Empty>("unary", this._transport, method, opt, input);
    }
}
