import React, { useEffect } from "react";
import { FormGroup } from "reactstrap";
import cs from "classnames";
import { useCustomerRequests } from "../../../../../api/grpc/customer/useCustomerRequests";
import { useSelectedFloor } from "../../../../../lib/hooks/useSelectedFloor";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";

import { Switch } from "../../../Switch/Switch";
import { FormSection } from "../../../FormSection/FormSection";
import { DisplaySettingsFormik } from "../lib/useDisplaySettingsForm";
import { FormSubSection } from "../../../FormSubSection/FormSubSection";
import { NearbyRoomsSelect } from "../../../NearbyRoomsSelect/NearbyRoomsSelect";
import { LabelWithInformationBox } from "../../../LabelWithInformationBox/LabelWithInformationBox";
import { tooltips } from "../../../../../lib/utils/tooltips";
import { WorkingHours } from "../shared/WorkingHours";

import { LoadingBox } from "../../../LoadingBox/LoadingBox";
import { dateFormat, dateHelper } from "../lib/displayDateHelper";
import { FilteredWorkplacesTree } from "../../../../../views/Manage/Workplaces/FilteredWorkplaces/FilteredWorkplacesTree";
import { Button } from "../../../Button/Button";
import { WorkplaceFields } from "../../../../../api/graphql/workplaces";
import { selectFloorForWorkspace } from "../../../../../lib/helpers/selectFloorForWorkspace";
import { InputDescription } from "../../../InputDescription/InputDescription";
import { CustomerSettings } from "../../../../../api/grpc/customer/customer";

export interface DisplaySettingsSection {
  roomId?: string;
  formik: DisplaySettingsFormik;
  onChange: (
    key: string,
    value: string | string[] | number | number[] | boolean | Date | null
  ) => void;
  downloadQRicon?: JSX.Element;
  isBussinesOrPro?: boolean;
  currentCustomLanguageId?: string;
  className?: string;
  isProfile?: boolean;
}

export interface ReservationSettings extends DisplaySettingsSection {
  displayEditWorkHours?: boolean;
  loading: boolean;
  error: any;
  workingHoursData?: CustomerSettings;
  refetch: () => void;
}

export const ReservationSettings = (props: ReservationSettings) => {
  const {
    roomId,
    formik,
    formik: { values },
    onChange: displaySettingsOnChange,
    isBussinesOrPro,
    displayEditWorkHours,
    className,
    isProfile,
    error: workingHoursError,
    loading,
    workingHoursData,
    refetch,
  } = props;
  const { user } = useAuthContext();
  const { getRootWorkplaces } = useWorkplaceRequests();
  const { toggleModal, isOpen } = useToggleModal();
  const { updateCustomerSettings } = useCustomerRequests();

  const selectedFloorName = useSelectedFloor(values.reservation.floor);

  const { rooms } = tooltips;
  const { reservation } = values;

  const [request, { loading: loadingRootWorkplaces, error, data }] =
    getRootWorkplaces;

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  const handleWorkspacePickerItemClick = (item: WorkplaceFields) => {
    return selectFloorForWorkspace(item, formik, "reservation.floor");
  };

  const classes = cs(className);

  return (
    <FormSection title="Reservation settings" toggleable className={classes}>
      {isOpen && (
        <>
          <FilteredWorkplacesTree
            showOnDesktop={false}
            loading={loading}
            error={error}
            rootWorkplaces={rootWorkplaces}
            showOnInitialization={false}
            isPickerOnly
            isWayfinder
            className="PageSidebar--toggled PageSidebar--Custom"
            onTitleClick={toggleModal}
            onItemClick={handleWorkspacePickerItemClick}
            pathTo="#"
            hasCloseButton
            handleClearFloorMap={() => {
              if (values.reservation.floor) {
                return displaySettingsOnChange("reservation.floor", null);
              }
            }}
          />
          <div onClick={toggleModal} className="background__shadow"></div>
        </>
      )}
      <Switch
        title="On-screen reservation​"
        message={tooltips.rooms.reservation.onScreen}
        name="reservation.onScreenReservation"
        value={!!reservation.onScreenReservation}
        onChange={(value) =>
          displaySettingsOnChange(
            "reservation.onScreenReservation",
            value || null
          )
        }
      >
        <FormSubSection>
          <Switch
            title={`Show "Meeting title" input​`}
            message={rooms.reservation.showTitleInput}
            name="reservation.onScreenReservation.showMeetingTitleInput"
            value={reservation.onScreenReservation?.showMeetingTitleInput}
            onChange={(value) => {
              if (!value) {
                displaySettingsOnChange(
                  "reservation.onScreenReservation.requireMeetingTitleInput",
                  false
                );
              }

              displaySettingsOnChange(
                "reservation.onScreenReservation.showMeetingTitleInput",
                value
              );
            }}
          />
          <Switch
            title={`Require "Meeting title" input`}
            message={rooms.reservation.requireTitleInput}
            name="reservation.onScreenReservation.requireMeetingTitleInput"
            value={reservation.onScreenReservation?.requireMeetingTitleInput}
            disabled={!reservation.onScreenReservation?.showMeetingTitleInput}
            onChange={(value) =>
              displaySettingsOnChange(
                "reservation.onScreenReservation.requireMeetingTitleInput",
                value
              )
            }
          />
          <Switch
            title={`Show "Your name" input​`}
            message={rooms.reservation.showNameInput}
            name="reservation.onScreenReservation.showYourNameInput"
            value={reservation.onScreenReservation?.showYourNameInput}
            onChange={(value) => {
              if (!value) {
                displaySettingsOnChange(
                  "reservation.onScreenReservation.requireYourNameInput",
                  false
                );
              }

              displaySettingsOnChange(
                "reservation.onScreenReservation.showYourNameInput",
                value
              );
            }}
          />
          <Switch
            title={`Require "Your name" input`}
            message={rooms.reservation.requireNameInput}
            name="reservation.onScreenReservation.requireYourNameInput"
            value={reservation.onScreenReservation?.requireYourNameInput}
            disabled={!reservation.onScreenReservation?.showYourNameInput}
            onChange={(value) =>
              displaySettingsOnChange(
                "reservation.onScreenReservation.requireYourNameInput",
                value
              )
            }
          />

          <Switch
            title={`Schedule only during work hour`}
            message={rooms.reservation.enforceWorkingHours}
            name="reservation.onScreenReservation.workingHrsActivated"
            value={reservation.onScreenReservation?.workingHrsActivated}
            onChange={(value) =>
              displaySettingsOnChange(
                "reservation.onScreenReservation.workingHrsActivated",
                value
              )
            }
          >
            {values.reservation?.onScreenReservation?.workingHrsActivated && (
              <FormSubSection
                className={displayEditWorkHours ? "" : "disabled"}
              >
                {loading ? (
                  <LoadingBox minHeight={50} />
                ) : workingHoursError || !workingHoursData ? (
                  <div>Error fetching working hours data</div>
                ) : (
                  <>
                    {!displayEditWorkHours && !isProfile && (
                      <InputDescription
                        title="Press Save in order to change working hours & days."
                        className="custom-font mb-3"
                      />
                    )}
                    <WorkingHours
                      id={roomId}
                      loading={loading}
                      startHours={dateHelper(
                        values.reservation.onScreenReservation
                          .workingHrsStart || "",
                        "startTime",
                        workingHoursData
                      )}
                      endHours={dateHelper(
                        values.reservation.onScreenReservation.workingHrsEnd ||
                          "",
                        "endTime",
                        workingHoursData
                      )}
                      handleEndHours={(value) => {
                        displaySettingsOnChange(
                          "reservation.onScreenReservation.workingHrsEnd",
                          value
                        );
                        if (
                          !!values.reservation.onScreenReservation
                            ?.workingHrsStart === false
                        ) {
                          formik.setFieldValue(
                            "reservation.onScreenReservation.workingHrsStart",
                            dateFormat(workingHoursData?.startTime || "")
                          );
                        }
                        return;
                      }}
                      handleStartHours={(value) => {
                        displaySettingsOnChange(
                          "reservation.onScreenReservation.workingHrsStart",
                          value
                        );

                        if (
                          !!values.reservation.onScreenReservation
                            ?.workingHrsEnd === false
                        ) {
                          formik.setFieldValue(
                            "reservation.onScreenReservation.workingHrsEnd",
                            dateFormat(workingHoursData?.endTime || "")
                          );
                        }

                        return;
                      }}
                      isGlobal={
                        !!values.reservation.onScreenReservation
                          ?.workingHrsStart ||
                        !!values.reservation.onScreenReservation?.workingHrsEnd
                      }
                      isProfile={isProfile}
                      customerSettings={workingHoursData}
                      onChange={() => {
                        displaySettingsOnChange(
                          "reservation.onScreenReservation.workingHrsStart",
                          null
                        );

                        displaySettingsOnChange(
                          "reservation.onScreenReservation.workingHrsEnd",
                          null
                        );

                        return;
                      }}
                      updateCustomerSettings={updateCustomerSettings}
                      refetch={refetch}
                      disabledWorkingHours={!displayEditWorkHours}
                    />
                  </>
                )}
              </FormSubSection>
            )}
          </Switch>

          <Switch
            title="Require headcount"
            message={tooltips.rooms.reservation.requireHeadcount}
            name="reservation.onScreenReservation.requireHeadcount"
            value={
              isBussinesOrPro
                ? reservation.onScreenReservation?.requireHeadcount
                : false
            }
            onChange={(value) =>
              displaySettingsOnChange(
                "reservation.onScreenReservation.requireHeadcount",
                value
              )
            }
            disabled={!isBussinesOrPro}
          />

          <Switch
            title="One-time PIN protection"
            message={rooms.reservation.oneTimePinProtection}
            name="reservation.onScreenReservation.oneTimePinProtection"
            value={
              isBussinesOrPro
                ? reservation.onScreenReservation?.oneTimePinProtection
                : false
            }
            onChange={(value) =>
              displaySettingsOnChange(
                "reservation.onScreenReservation.oneTimePinProtection",
                value
              )
            }
            disabled={!isBussinesOrPro}
          />
        </FormSubSection>
      </Switch>

      <FormSubSection
        column
        title="Edit meeting settings​"
        message={rooms.reservation.editMeetingSettings}
      >
        <Switch
          title="Delete option"
          name="reservation.deleteMeeting"
          value={reservation.deleteMeeting}
          message={rooms.reservation.deleteOption}
          onChange={(value) =>
            displaySettingsOnChange("reservation.deleteMeeting", value)
          }
        />
        <Switch
          title="End early option​"
          name="reservation.endMeetingEarly"
          value={reservation.endMeetingEarly}
          message={rooms.reservation.endEarlyOption}
          onChange={(value) =>
            displaySettingsOnChange("reservation.endMeetingEarly", value)
          }
        />
        <Switch
          title="Extend option"
          name="reservation.extendMeeting"
          value={reservation.extendMeeting}
          message={rooms.reservation.extendOption}
          onChange={(value) =>
            displaySettingsOnChange("reservation.extendMeeting", value)
          }
        />
      </FormSubSection>
      {!isProfile && (
        <FormGroup>
          <LabelWithInformationBox
            title="Nearby rooms"
            for="reservation.nearbyRooms"
            message={rooms.reservation.nearbyRooms}
          />
          {roomId && (
            <NearbyRoomsSelect
              currentRoom={roomId}
              initialValues={values.reservation.nearbyRooms}
              isDisabled={!isBussinesOrPro}
              onChange={(values) =>
                displaySettingsOnChange("reservation.nearbyRooms", values)
              }
            />
          )}
        </FormGroup>
      )}

      <FormSubSection>
        <Switch
          title="Map view"
          name="reservation.mapView"
          value={isBussinesOrPro ? reservation.mapView : false}
          message={rooms.reservation.mapView}
          onChange={(value) =>
            displaySettingsOnChange("reservation.mapView", value)
          }
          disabled={!isBussinesOrPro}
        >
          <label className="label__main pb-3">Select floor with map</label>
          <Button
            icon="floor-map-2"
            title="Select floor map"
            color={
              !values.reservation.floor?.id ? "outline-primary" : "primary"
            }
            size="small"
            className="WayFinderFrom__workspace--opener mb-2"
            onClick={toggleModal}
            disabled={!isBussinesOrPro}
          />
          {selectedFloorName}
        </Switch>
      </FormSubSection>
    </FormSection>
  );
};
