import React, { useState } from "react";
import cs from "classnames";
import { NavLink } from "react-router-dom";
import { useRouter } from "../../../../../lib/hooks/useRouter";
import { useTemplateContext } from "../../../../../lib/context/Templates/Templates/TemplatesContext";

import { WorkplaceFields } from "../../../../../api/graphql/workplaces";
import { getWorkplaceChildren } from "../../../../Workplaces/helpers/getWorkplaceChildren";
import { getWorkplaceIcon } from "../../../../Workplaces/helpers/getWorkplaceInformation";
import { Icon } from "../../../../shared/Icon/Icon";

interface Props {
  item: WorkplaceFields;
  toggleParent: (value: boolean) => void;
  filterWorkplace: "Room" | "Desk";
}

export const ItemsWorkspaceSelector = ({
  item,
  toggleParent,
  filterWorkplace,
}: Props) => {
  const { pathname } = useRouter();
  const isActive = pathname.includes(item.id);
  const { pickedWorkspaces, setPickedWorkspaces } = useTemplateContext();

  const [toggle, setToggle] = useState(() => isActive);

  const icon = getWorkplaceIcon(item.__typename);
  const workplaceChildren = getWorkplaceChildren(item);
  const hasChildren = workplaceChildren.some((item) => item && item.length > 0);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const classes = cs("WorkplacesTreeLevel", {
    "WorkplacesTreeLevel--open": toggle,
    "WorkplacesTreeLevel--active": isActive,
    "WorkplacesTreeLevel--space": !hasChildren,
  });

  const subLevelClasses = cs("WorkplacesTreeLevel__sub-level", {
    "WorkplacesTreeLevel__sub-level--open": toggle,
  });
  return (
    <>
      <div className={classes}>
        <div className="WorkplacesTreeLevel__wrapper">
          {hasChildren && (
            <span
              onClick={handleToggle}
              className="WorkplacesTreeLevel__toggle"
            ></span>
          )}
          <NavLink
            className="WorkplacesTreeLevel__link"
            onClick={() => {}}
            to={"#"}
          >
            <div className="WorkplacesTreeLevel__icon">
              <Icon icon={icon} />
            </div>
            <h3 className="WorkplacesTreeLevel__name">{item.name}</h3>
          </NavLink>
          {item.__typename === "Desk" || item.__typename === "Room" ? (
            <>
              <div
                className="WorkplacesTreeLevel__status"
                onClick={() => {
                  const typeOfMethod: "add" | "delete" =
                    pickedWorkspaces.data.some(
                      (formikItem) => formikItem.id === item.id
                    )
                      ? "delete"
                      : "add";

                  if (typeOfMethod === "add") {
                    setPickedWorkspaces({
                      data: [...pickedWorkspaces.data, item],
                    });
                    return;
                  }

                  //delete handler
                  const updatedWorkspaces = pickedWorkspaces.data.filter(
                    (workspace) => workspace.id !== item.id
                  );

                  setPickedWorkspaces({ data: updatedWorkspaces });
                }}
              >
                <Icon
                  icon={
                    pickedWorkspaces.data.some(
                      (formikItem) => formikItem.id === item.id
                    )
                      ? "checkbox-full"
                      : "checkbox-empty"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {hasChildren && (
        <div className={subLevelClasses}>
          {workplaceChildren.map((child) => {
            return child
              ?.filter(
                (i) =>
                  i.__typename !== filterWorkplace &&
                  i.__typename !== "Wayfinder"
              )
              ?.map((workplace) => (
                <ItemsWorkspaceSelector
                  key={workplace.id}
                  item={workplace}
                  toggleParent={(value) => {
                    setToggle(value);
                    toggleParent(value);
                  }}
                  filterWorkplace={filterWorkplace}
                />
              ));
          })}
        </div>
      )}
    </>
  );
};
