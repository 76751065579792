import { format, parseISO, set } from "date-fns";
import React, { Fragment, PropsWithChildren, useMemo } from "react";
import { toast } from "react-toastify";
import { useDeskReservations } from "./helpers/useDeskReservations";
import { useRoomReservations } from "./helpers/useRoomReservations";
import { useWorkplacesClient } from "../../../api/grpc/workplaces/useWorkplacesClient";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { useFormatReservationDate as formatReservationDate } from "../lib/datePickerHelper";

import { ReservationListCard } from "./ReservationList/ReservationListCard";
import { ReservationInfo } from "./types";
import { ReservationListLoading } from "./ReservationListLoading";
import { PromotionCard } from "../Promotion/PromotionCard";
import { DeskLocationItem } from "../../../api/grpc/desk/desk";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { QRCodeRequiredStatus } from "../../../api/grpc/ggevent/ggevent";
import {
  DesksListResponse,
  SearchAccountDesksRequest,
} from "../../../api/grpc/workplaces/workplaces";
import { AssignedDesk } from "./AssignedDesk/AssignedDesk";

import "./Reservations.scss";

export type DateRFC339 = string;

type LocationIndex = {
  Company: 0;
  Site: 1;
  Building: 2;
  Floor: 3;
};

const locationIndexObj: LocationIndex = {
  Company: 0,
  Site: 1,
  Building: 2,
  Floor: 3,
};

export const Reservations = ({
  calendarProviderId,
  personalEmail,
}: PropsWithChildren<{
  calendarProviderId: string;
  personalEmail: string;
}>) => {
  const { deskData, loadingDesk, errorDesk, refetchDesk } =
    useDeskReservations();
  const { roomData, loadingRoom, errorRoom, refetchRoom } = useRoomReservations(
    calendarProviderId,
    personalEmail
  );
  const { timeZone } = useTimeZoneContext();

  const { getAssignedDesks } = useWorkplacesClient();

  const {
    loading,
    error,
    data: assignedDesks,
  } = useGrpcQuery<SearchAccountDesksRequest, DesksListResponse>({
    method: getAssignedDesks,
    variables: {
      accountID: "",
      customerID: "",
    },
  });

  const isLoading = useMemo<boolean>(() => {
    return loadingDesk || loadingRoom || loading;
  }, [loadingDesk, loadingRoom, loading]);

  const isListEmpty = useMemo<boolean>(() => {
    const isDeskBookingsEmpty = !deskData || deskData.desks.length === 0;

    const isRoomBookingsEmpty = !roomData || roomData.meetings.length === 0;

    return (
      isDeskBookingsEmpty &&
      isRoomBookingsEmpty &&
      !!!assignedDesks?.desks?.length
    );
  }, [deskData, roomData, assignedDesks]);

  useMemo(() => {
    //the requst is to add some manual error messages if room/desk reservations methods fail
    if (loadingDesk || loadingRoom) {
      return;
    }

    let showRoomError = errorRoom && !!calendarProviderId?.length;

    if (errorDesk && showRoomError) {
      return toast.error(
        "Sorry, an unexpected issue occurred when loading your reservations"
      );
    }

    if (errorDesk) {
      return toast.error(
        "Sorry, an unexpected issue occurred for desk reservations"
      );
    }

    if (showRoomError) {
      return toast.error(
        "Sorry, an unexpected issue occurred for room reservations"
      );
    }
  }, [errorDesk, errorRoom, loadingDesk, loadingRoom]);

  const groupedList = useMemo<Record<DateRFC339, ReservationInfo[]>>(() => {
    const result: Record<DateRFC339, ReservationInfo[]> = {};

    if (deskData) {
      deskData.desks.forEach((deskBooking) => {
        if (deskBooking.event && deskBooking.event.length > 0) {
          deskBooking.event.forEach((event) => {
            const day = set(
              parseISO(
                format(
                  utcToZonedTime(new Date(event.startTime), timeZone),
                  "yyyy-MM-dd"
                )
              ),
              {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
              }
            );

            const dateRFC339 = zonedTimeToUtc(day, timeZone).toISOString();

            let locationPathOfDesk: DeskLocationItem[] =
              deskBooking.locationPath.map((locationItem) => {
                return {
                  name: locationItem.name,
                  type: locationItem.type,
                  id: locationItem.id,
                  index:
                    locationIndexObj[locationItem.type as keyof LocationIndex],
                };
              });

            const deskReservationInfo: ReservationInfo = {
              reservationType: "desk",
              reservationId: deskBooking.deskID,
              reservationEntityName: deskBooking.deskName,
              reservationLocationId: deskBooking.locationID,
              locationPath: locationPathOfDesk,
              qrCodeRequired:
                deskBooking.qrCodeRequired ===
                QRCodeRequiredStatus.QR_STATUS_REQUIRED,
              event,
              allowDeleteOption: true,
            };

            if (result[dateRFC339]) {
              result[dateRFC339].push(deskReservationInfo);

              return;
            }

            result[dateRFC339] = [deskReservationInfo];
          });
        }
      });
    }

    if (roomData) {
      roomData.meetings.forEach((roomEvent) => {
        const day = set(
          parseISO(
            format(
              utcToZonedTime(new Date(roomEvent.startTime), timeZone),
              "yyyy-MM-dd"
            )
          ),
          {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }
        );

        const dateRFC339 = zonedTimeToUtc(day, timeZone).toISOString();

        let locationPathOfRoom: DeskLocationItem[] =
          roomEvent.workplaceLocationPath.map((locationItem) => {
            return {
              name: locationItem.displayName,
              type: locationItem.type,
              id: locationItem.id,
              index: locationIndexObj[locationItem.type as keyof LocationIndex],
            };
          });

        const roomReservationInfo: ReservationInfo = {
          reservationType: "room",
          reservationId: roomEvent.workplaceID,
          reservationEntityName: roomEvent.locations[0]?.displayName ?? "",
          reservationLocationId: roomEvent.workplaceID,
          event: roomEvent,
          locationPath: locationPathOfRoom,
          qrCodeRequired: false,
          allowDeleteOption: roomData.allowDeleteOption,
        };

        if (result[dateRFC339]) {
          result[dateRFC339].push(roomReservationInfo);

          return;
        }

        result[dateRFC339] = [roomReservationInfo];
      });
    }

    Object.keys(result).forEach((key) => {
      result[key] = result[key].sort(
        (el1, el2) =>
          +new Date(el1.event?.startTime ?? "") -
          +new Date(el2.event?.startTime ?? "")
      );
    });

    return result;
  }, [deskData, roomData]);

  return (
    <HandleLoadingState
      loading={isLoading}
      loadingPlaceholder={<ReservationListLoading />}
    >
      <div className="Reservations__content">
        <div className="Reservations__content--reservations">
          {isListEmpty ? (
            <div>List is empty</div>
          ) : (
            <>
              {!loading && (
                <AssignedDesk desks={assignedDesks?.desks} error={error} />
              )}

              {Object.keys(groupedList)
                .sort((el1, el2) => +new Date(el1) - +new Date(el2))
                .map((date, i) => (
                  <Fragment key={i}>
                    <div className="Reservations__day">
                      {formatReservationDate(
                        utcToZonedTime(new Date(date), timeZone)
                      )}
                    </div>
                    {groupedList[date].map((reservationInfo, key) => (
                      <ReservationListCard
                        key={key}
                        name={reservationInfo.reservationEntityName}
                        startTime={reservationInfo.event?.startTime}
                        endTime={reservationInfo.event?.endTime}
                        workplaceId={reservationInfo.reservationId}
                        type={reservationInfo.reservationType}
                        checkInStatus={
                          reservationInfo.reservationType === "desk"
                            ? reservationInfo.event.eventCheckInStatus
                            : null
                        }
                        event={reservationInfo.event}
                        refetchDesk={refetchDesk}
                        refetchRoom={refetchRoom}
                        locationPath={reservationInfo.locationPath}
                        qrCodeRequired={reservationInfo.qrCodeRequired}
                        allowDeleteOption={reservationInfo.allowDeleteOption}
                      />
                    ))}
                  </Fragment>
                ))}
            </>
          )}
        </div>
        <PromotionCard />
      </div>
    </HandleLoadingState>
  );
};
