import { Role, Status } from "../../../../api/grpc/account/account";
import { NullableBoolean } from "../../../../api/grpc/contacts/contacts";

export type PossibleFilters = "licensed" | "role" | "status" | "desks";

export const contactFilters: Array<{
  name: PossibleFilters;
  options: Array<{
    value: number;
    name: string;
    title: string;
  }>;
}> = [
  {
    name: "role",
    options: [
      {
        value: Role.OWNER,
        name: "owner",
        title: "Owner",
      },
      {
        value: Role.ADMIN,
        name: "admin",
        title: "Admin",
      },
      {
        value: Role.MEMBER,
        name: "member",
        title: "Member",
      },
    ],
  },
  {
    name: "status",
    options: [
      {
        value: Status.ACTIVE,
        name: "active",
        title: "Active",
      },
      {
        value: Status.PENDING,
        name: "pending",
        title: "Pending",
      },
      {
        value: Status.INACTIVE,
        name: "inactive",
        title: "Inactive",
      },
    ],
  },
  {
    name: "licensed",
    options: [
      {
        value: NullableBoolean.TRUE,
        name: "licensed",
        title: "Yes",
      },
      {
        value: NullableBoolean.FALSE,
        name: "unlicensed",
        title: "No",
      },
    ],
  },

  {
    name: "desks",
    options: [
      {
        value: NullableBoolean.TRUE,
        name: "assigned",
        title: "Yes",
      },
      {
        value: NullableBoolean.FALSE,
        name: "unassigned",
        title: "No",
      },
    ],
  },
];
