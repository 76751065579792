import { toast } from "react-toastify";
import { useCustomerRequests } from "../../../api/grpc/customer/useCustomerRequests";
import { useGlobalWorkingHours } from "../../../lib/hooks/useGlobalWorkingHours";
import { CustomerSettingsVariables } from "../../Organization/OrganizationSettings/InsightsCard/Insights/Insights";

export const useWorkingHoursData = () => {
  const { loading, error, workingHoursData, refetch } = useGlobalWorkingHours();
  const { updateCustomerSettings } = useCustomerRequests();

  const handleWorkingHours = async ({
    startTime,
    endTime,
    errorReportingEmail,
    meetingSpaceCostPerHr,
    currency,
    workingWeekDays,
    collectInsights,
    ssoEnable,
  }: CustomerSettingsVariables) => {
    try {
      if (!!workingHoursData) {
        await updateCustomerSettings(
          startTime ? startTime : workingHoursData.startTime,
          endTime ? endTime : workingHoursData.endTime,
          errorReportingEmail
            ? errorReportingEmail
            : workingHoursData.errorReportingEmail,
          meetingSpaceCostPerHr
            ? meetingSpaceCostPerHr
            : workingHoursData.meetingSpaceCostPerHr,
          currency ? currency : workingHoursData?.currency,
          workingWeekDays ? workingWeekDays : workingHoursData.workingWeekDays,
          collectInsights !== undefined && collectInsights !== null
            ? collectInsights
            : workingHoursData.collectInsigths,
          ssoEnable !== undefined && ssoEnable !== null
            ? ssoEnable
            : workingHoursData.ssoEnable
        );

        toast.success("Customer settings updated successfuly");
        refetch();
        return;
      }
    } catch (error: any) {
      toast.error(
        error.message
          ? error.message
          : "Cannot set working hours, please try again"
      );
    }
  };

  return { loading, error, workingHoursData, handleWorkingHours, refetch };
};
