import { useState } from "react";
import { getPaginationDataTotal } from "../../components/Floors/lib/paginationDataTotal";
import { PaginationContext } from "../context/Pagination/PaginationContext";

export const usePaginationHelper = (dataLength: number) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const totalData = () => {
    if (pageSize > dataLength) {
      return 1;
    }

    return Number(getPaginationDataTotal(dataLength));
  };

  const pagination: PaginationContext = {
    key: "",
    page: page,
    pageSize: pageSize,
    total: totalData(),
    setPage: (page) => setPage(page),
    setPageSize: (pageSize) => {
      setPage(1);
      setPageSize(pageSize);
    },
    setToNextPage: () => {
      setPage((prev) => prev + 1);
    },
    setToPreviousPage: () => {
      setPage((prev) => prev - 1);
    },
  };

  return {
    page,
    pageSize,
    pagination,
  };
};
